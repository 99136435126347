import React, { useEffect, useState } from 'react';
import { filter, map, size } from 'lodash';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import useFindDataCodes from 'api/data/useFindDataCodes';
import type { SelectModalItem } from 'models/Search';
import SelectModal from 'components/SelectModal/SelectModal';

interface PriceOverrideReasonSelectProps extends BaseComponentProps {
  setOverrideReason: (o: SelectModalItem) => void;
}

const PriceOverrideReasonSelect = ({
  setOverrideReason,
  selItem,
  testid = 'price-override-reason-select',
  ...props
}: PriceOverrideReasonSelectProps &
  Partial<React.ComponentProps<typeof SelectModal>>): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const {
    data: overrideReasons,
    error,
    isLoading,
  } = useFindDataCodes({ codeType: 'CRPPRCOV' });

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('');
    }
  }, [isOpen, searchQuery, setSearchQuery]);

  const items = map(
    filter(overrideReasons, ({ codeName }) =>
      new RegExp(searchQuery, 'i').test(codeName)
    ),
    (item) => {
      return {
        item,
        key: item.id,
        title: item.codeName,
      };
    }
  );

  const inputDisplay = selItem?.key ? selItem.title : '';

  return (
    <SelectModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      selItem={selItem || { title: '', description: '', key: '' }}
      setSelItem={(o) => setOverrideReason(o)}
      items={items}
      error={error}
      isEmptyResponse={size(items) === 0}
      showLoader={!!isLoading}
      enableInfiniteScroll
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      value={inputDisplay}
      icon="caret-down"
      testid={testid}
    />
  );
};

export default PriceOverrideReasonSelect;
