import { useTranslation } from 'react-i18next';
import { isEmpty, toNumber, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import {
  inAppNotificationsDataPath,
  inAppNotificationsQueryKey,
} from 'HomeApp/Notifications/api/useFindNotifications';
import type { NotificationItem } from 'HomeApp/Notifications/models/Notifications';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { onErrorUpdate, onMutateUpdate, onSuccessMutation } from 'api/helpers';
import { ToastType } from 'models/Toast';

interface MarkInAppBody {
  notificationId?: string;
}

interface MarkedInAppResponse {
  markedRead: number;
}
interface UseMarkInAppNotificationsAsReadResponse {
  status: MutationStatus;
  onMarkAsRead: (query: MarkInAppBody) => void;
}
const useMarkInAppNotificationAsRead =
  (): UseMarkInAppNotificationsAsReadResponse => {
    const { axios } = useAxios();
    const { markInAppNotificationAsRead } = useAPIUrl();
    const { addToast } = useToasts();
    const { t } = useTranslation('HomeApp-Notifications');
    const queryClient = useQueryClient();

    const doMarkAsRead = async (query?: MarkInAppBody) => {
      const { data } = await axios.post<MarkedInAppResponse>(
        markInAppNotificationAsRead(toString(new URLSearchParams({ ...query })))
      );
      return data;
    };

    const { mutate, status } = useMutation(doMarkAsRead, {
      onMutate: async (vars) => {
        const notificationContext = await onMutateUpdate<NotificationItem>({
          queryClient,
          queryKey: inAppNotificationsQueryKey,
          dataPath: inAppNotificationsDataPath,
          updateAll: isEmpty(vars),
          findPredicates: [{ id: toNumber(vars?.notificationId) }],
          updatedItems: [{ read: true } as NotificationItem],
          isInfiniteQuery: true,
          markAsUpdated: true,
        });
        return { notificationContext };
      },
      onSuccess: () => {
        void onSuccessMutation(queryClient, inAppNotificationsQueryKey);
      },
      onError: (_err, vars, context) => {
        onErrorUpdate<NotificationItem>({
          queryClient,
          dataPath: inAppNotificationsDataPath,
          context: context?.notificationContext,
          isInfiniteQuery: true,
        });
        addToast({
          type: ToastType.error,
          text: t('markReadError'),
          testid: 'mark-as-read-error-toast',
        });
      },
    });

    return {
      status,
      onMarkAsRead: (query) => mutate(query),
    };
  };

export default useMarkInAppNotificationAsRead;
