import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get, toNumber, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { findFollowersQuerykey } from 'ActivitiesApp/api/useFindFollowers';
import useAPIUrl from 'api';
import { or } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import { getActivityQueryKey } from 'api/activities/useGetActivity';
import {
  onMutateUpdate,
  doPromiseAPI,
  onErrorUpdate,
  onSuccessMutation,
  getPlaceholderData,
  getQueryCacheKeys,
} from 'api/helpers';
import type { ActionCardActivity } from 'models/ActivityModels';
import type {
  ActivityNote,
  ActivityNoteAddResponse,
  ActivityNoteSimple,
  UpdateActivityNoteBody,
} from 'models/Notebook';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { findActivityNotesV2QueryKey } from './useFindActivityNotesV2';
import useAddActivityNoteAttachments from './useUpdateActivityNoteAttachments';

interface UseAddActivityNoteProps {
  miLoc: string;
  historyId: string;
}

interface UseAddActivityNoteResponse {
  data?: ActivityNoteAddResponse;
  status: MutationStatus;
  onAddActivityNote: (body: UpdateActivityNoteBody) => void;
}

const useAddActivityNote = ({
  miLoc,
  historyId,
}: UseAddActivityNoteProps): UseAddActivityNoteResponse => {
  const { axios } = useAxios();
  const { addActivityNotesAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.notes);
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid');
  const userFullName = get(userInfo, 'cn');

  const { doUpdateActivityAttachments } = useAddActivityNoteAttachments();

  const doAddActivityNote = (body: UpdateActivityNoteBody) => {
    return doPromiseAPI<ActivityNoteAddResponse>(async (cancelToken) => {
      const request = { ...body };
      delete request.filesForUpload;
      delete request.filesForRemoval;
      const { data } = await axios.post<ActivityNoteAddResponse>(
        addActivityNotesAPI(miLoc, toNumber(historyId)),
        request,
        { cancelToken }
      );
      return data;
    });
  };

  const response = useMutation(doAddActivityNote, {
    onMutate: async (vars) => {
      const cachedActivity = or(
        getPlaceholderData<ActionCardActivity>({
          queryClient,
          queryKey: findActivitiesQueryKey,
          findPredicate: { historyId: toNumber(historyId) },
        }),
        queryClient.getQueryData<ActionCardActivity>(
          getQueryCacheKeys(queryClient, getActivityQueryKey, {
            historyId: toNumber(historyId),
          })?.[0]
        )
      );
      const newCommentCount = or(toNumber(cachedActivity?.commentCount), 0) + 1;

      const activitiesListContext = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        updatedItems: [
          {
            historyId: toNumber(historyId),
            commentCount: toString(newCommentCount),
          },
        ],
        findPredicates: [{ historyId: toNumber(historyId) }],
        isInfiniteQuery: true,
      });

      const activityNoteContext = await onMutateUpdate<ActivityNote>({
        queryClient,
        queryKey: findActivityNotesV2QueryKey,
        queryKeyParams: { historyId: toNumber(historyId) },
        dataPath: 'rows',
        totalDataPath: 'totalRows',
        newItems: [
          {
            eventNoteId: -1 * Date.now(),
            historyId: toNumber(historyId),
            title: vars.title,
            text: vars.text,
            publicFlag: vars.publicFlag,
            attachments: vars.filesForUpload,
            lastUpdatedTimestamp: new Date().toISOString(),
            userId,
            userFullName,
          } as unknown as ActivityNote,
        ],
        sortPredicate: { sortField: 'sortCol' },
        isInfiniteQuery: true,
      });

      return { activityNoteContext, activitiesListContext };
    },
    onSuccess: async (data, vars) => {
      try {
        const activityNote = {
          eventNoteId: toNumber(data.ID),
          historyId: toNumber(historyId),
          miLoc,
        } as ActivityNoteSimple;
        await doUpdateActivityAttachments({
          activityNote,
          updatedImages: vars.filesForUpload,
        });
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'Upload attachment operation failed.',
          testid: 'upload-notebook-attachment-error-toast',
        });
      }
      await onSuccessMutation(queryClient, findActivityNotesV2QueryKey, {
        historyId: toNumber(historyId),
      });
      await onSuccessMutation(queryClient, findFollowersQuerykey, {
        historyId,
      });
      void onSuccessMutation(queryClient, findActivitiesQueryKey);
      if (!vars.skipSuccessToast) {
        addToast({
          variant: 'mipro-toast',
          type: ToastType.success,
          leftIcon: ['fas', 'check-circle'],
          text: or(
            vars.successToastText,
            t('addEventNoteToast', {
              title: or(vars?.title, t('newEventNote')),
            })
          ),
          testid: 'add-activity-note-toast',
        });
      }
    },
    onError: (data, vars, context) => {
      addToast({
        type: ToastType.error,
        text: t('addFailureToast'),
        testid: 'add-notebook-error-toast',
      });

      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: context?.activitiesListContext,
        isInfiniteQuery: true,
      });

      onErrorUpdate<ActivityNote>({
        queryClient,
        context: context?.activityNoteContext,
        sortPredicate: { sortField: 'sortCol' },
        isInfiniteQuery: true,
        dataPath: 'rows',
        totalDataPath: 'totalRows',
      });
    },
  });

  const { data, status, mutate } = response;

  return {
    data,
    status,
    onAddActivityNote: (body: UpdateActivityNoteBody) => mutate(body),
  };
};

export default useAddActivityNote;
