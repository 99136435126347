import type { AxiosError } from 'axios';
import type { Photo } from '@capacitor/camera';

export interface ImageUpload extends Photo {
  forUpload?: boolean;
  caption?: string;
  internalOnly?: string;
  fileName: string;
  imageId?: number;
  inputId?: number;
}

interface FileProps {
  forUpload?: boolean;
  // #region new notes service
  fileName?: string;
  userFile?: string;
  FILE_NAME?: string;
  LINE_NO?: string;
  miLoc?: string;
  ctlNo?: string;
  seqNo?: number;
  entity?: string;
  // #endregion
  // #region api fields
  USER_FILE?: string;
  ENTITY?: string;
  MI_LOC?: string;
  CTL_NO?: string;
  SEQ_NO?: string;
  // #endregion
}

export type MiProFile = File &
  FileProps & {
    fileURL?: string;
    lineNo?: string;
    ctlNo?: string;
    forUpload?: boolean;
    entity?: string;
    ENTITY?: string;
    MI_LOC?: string;
    CTL_NO?: string;
    MI_ONLY?: string;
    miOnly?: string;
    description?: string;
    DESCRIPTION?: string;
    src?: string;
  };

export interface AttachmentsAPIResponse {
  rows: MiProFile[];
  error: AxiosError | null;
}

export interface FileAttachmentProp extends FileProps {
  webPath?: string;
  src?: string;
  file?: File;
  dateWithoutTime?: string;
  // #region new notes service
  creationTmstmp?: string;
  creationUserid?: string;
  description?: string;
  entityType?: string;
  filePrefix?: string;
  lineNo?: number;
  miOnly?: string;
  // #endregion
  // #region api fields
  CREATION_USERID?: string;
  FILE_PREFIX?: string;
  ENTITY_TYPE?: string;
  FILE_NAME?: string;
  DESCRIPTION?: string;
  CREATION_TMSTMP?: string;
  LINE_NO?: string;
  MI_ONLY?: string;
  // #endregion
}

export enum AttachmentSize {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
}
