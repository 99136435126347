import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { find, map } from 'lodash';
import { IonItem } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { FormikInput } from 'common/components/Forms/Input/Input';
import { or } from 'common/utils/logicHelpers';
import { FormikProvider, useFormik } from 'formik';
import { namespaces } from 'i18n/i18n.constants';
import useAddContact from 'api/contacts/useAddContact';
import useUpdateContact from 'api/contacts/useUpdateContact';
import useFindDataCodes from 'api/data/useFindDataCodes';
import type { Contact, ContactFormData } from 'models/Contact';
import type { SearchItemType, SnapshotsURLParams } from 'models/Search';
import CheckBox from 'components/CheckBox/CheckBox';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Select from 'components/Select/Select';
import classes from './ContactModalV2.module.scss';
import validationSchema from './validationSchema';

interface ContactModalV2Props {
  searchType: SearchItemType;
  miLoc?: string;
  data?: ContactFormData;
  showInvite?: boolean;
  inviteMiCom?: boolean;
  testid: string;
  customerId?: string;
  onSuccess?: (contact?: Contact) => void;
  onError?: () => void;
}

const ContactModalV2 = ({
  searchType,
  miLoc: itemMiLoc,
  data,
  showInvite,
  inviteMiCom,
  isOpen,
  title,
  testid,
  setIsOpen,
  onSuccess,
  onError,
  customerId,
  ...props
}: ContactModalV2Props &
  React.ComponentProps<typeof SheetModal> &
  IonicReactProps): JSX.Element => {
  const { miLoc: routeMiLoc, id: urlParamId } = useParams<SnapshotsURLParams>();
  const id = or(customerId, urlParamId);
  const { t } = useTranslation(namespaces.contact);
  const miLoc = itemMiLoc ?? routeMiLoc;
  const initialFormData: ContactFormData = useMemo(
    () => ({
      sequenceNo: data?.sequenceNo ?? -1,
      name: data?.name,
      type: data?.type,
      phone: data?.phone,
      email: data?.email,
      isMainContact: data?.isMainContact,
      sendInvite: data?.sendInvite,
    }),
    [data]
  );

  const { data: codeList } = useFindDataCodes({
    codeType: searchType === 'customer' ? 'CUCONTYP' : 'CONTYPE',
  });
  const {
    status: addStatus,
    addContact,
    data: newContact,
  } = useAddContact({
    searchType,
    miLoc,
    id,
  });
  const sequenceNo = data?.sequenceNo ?? -1;
  const { status: updateStatus, updateContact } = useUpdateContact({
    searchType,
    miLoc,
    id,
    sequenceNo,
  });

  const hasSequenceNo = sequenceNo !== -1;
  const status = hasSequenceNo ? updateStatus : addStatus;
  const selectOptions = map(codeList, ({ id: codeId, codeName }) => ({
    id: codeId,
    text: codeName,
  }));

  const formik = useFormik<ContactFormData>({
    initialValues: initialFormData,
    onSubmit: (formValues) => {
      const selectedType = find(selectOptions, { id: formValues.type });
      const contactBody = {
        name: formValues.name,
        type: formValues.type,
        typeDesc: selectedType?.text,
        phone: formValues.phone?.replace(/\D/g, ''),
        email: formValues.email,
        isMainContact: formValues.isMainContact ? 'Y' : 'N',
        sendInvite: formValues.sendInvite,
      };
      if (hasSequenceNo) {
        updateContact(contactBody);
      } else {
        addContact(contactBody);
      }
    },
    validationSchema,
    validateOnChange: false,
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    setValues,
    setSubmitting,
    setErrors,
    validateField,
    isValid,
    isSubmitting,
  } = formik;

  useEffect(() => {
    if (isOpen) {
      void setValues(initialFormData);
      void setErrors({});
    } else {
      setSubmitting(false);
    }
  }, [isOpen, initialFormData, setValues, setErrors, setSubmitting]);

  useEffect(() => {
    if (status === 'success') {
      setIsOpen?.(false);
      onSuccess?.({
        ...(newContact as Contact),
        typeDesc: find(selectOptions, { id: values.type })?.text ?? '',
      });
    }
    if (status === 'error') {
      setSubmitting(false);
      onError?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newContact, status]);

  // TODO: fix usage of formik.dirty to get this status
  // TODO translation on checkboxes
  const allFieldsValues = values.name && values.type;

  return (
    <SheetModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialBreakpoint={1}
      className={classes.modal}
      headerClassName={classes.modalHeader}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={title}
      withRightCloseButton
      footerButtons={[
        {
          variant: 'secondary',
          text: t('common:cancel'),
          onClick: () => setIsOpen?.(false),
          disabled: isSubmitting,
          testid: 'cancel-button',
        },
        {
          className: classes.saveButton,
          variant: 'action',
          onClick: () => handleSubmit(),
          text: t('common:save'),
          disabled: !isValid || isSubmitting || !allFieldsValues,
          testid: `${testid}-save-button`,
        },
      ]}
      testid={testid}
    >
      <FormikProvider value={formik}>
        <form noValidate onSubmit={handleSubmit} data-testid={`${testid}-form`}>
          <FormikInput
            className={classes.formInput}
            inputmode="text"
            label={t('common:name')}
            name="name"
            maxlength={25}
            disabled={isSubmitting}
            testid={`${testid}-name-input`}
            placeholder={t('common:enterContactTitle')}
          />
          <Select
            placeholder={t('costSavings:enterContactTitle')}
            className={classes.formSelect}
            label={t('common:title')}
            name="type"
            options={selectOptions}
            value={values.type}
            setValue={async (e) => {
              await setFieldValue('type', e);
              setTimeout(() => {
                void validateField('type');
              }, 0);
            }}
            disabled={isSubmitting}
            testid={`${testid}-title-input`}
          />
          <FormikInput
            className={classes.formInput}
            label={t('common:phoneNumber')}
            name="phone"
            inputMode="tel"
            type="tel"
            mask="(000) 000-0000"
            disabled={isSubmitting}
            testid={`${testid}-phone-input`}
            placeholder={t('common:enterContactPhoneNumber')}
          />
          <FormikInput
            className={classes.formInput}
            placeholder={t('costSavings:enterContactEmail')}
            inputmode="email"
            type="email"
            label={t('common:email')}
            name="email"
            disabled={isSubmitting}
            testid={`${testid}-email-input`}
          />
          <IonItem className={classes.formItem}>
            <CheckBox
              ariaLabel="is main contact"
              name="isMainContact"
              checked={values.isMainContact}
              onChange={(checked) => setFieldValue('isMainContact', checked)}
              testid="make-main-contact-checbox"
              label={t('makeMainContact')}
              disabled={isSubmitting}
              className={classes.checkbox}
            />
          </IonItem>
          {showInvite && (
            <IonItem className={classes.formItem}>
              <CheckBox
                ariaLabel="send invite"
                className={classes.checkbox}
                name="sendInvite"
                checked={values.sendInvite}
                onChange={(checked) => setFieldValue('sendInvite', checked)}
                disabled={isSubmitting || !inviteMiCom}
                testid={`${testid}-invite-contact-input`}
                label={t('inviteMotion')}
              />
            </IonItem>
          )}
        </form>
      </FormikProvider>
    </SheetModal>
  );
};

export default ContactModalV2;
