import { toString } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationStatus } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type { MiProFile } from 'models/Attachment';
import { ToastType } from 'models/Toast';
import { findAttachmentsQueryKey } from './useFindAttachments';

interface UseRemoveAttachmentProps {
  domain: string;
  miLoc: string;
  ctlNo?: string;
}

interface UseRemoveAttachmentResponse {
  status: MutationStatus;
  removeAttachment: (image: MiProFile) => void;
}

const useRemoveAttachment = ({
  domain,
  miLoc,
  ctlNo,
}: UseRemoveAttachmentProps): UseRemoveAttachmentResponse => {
  const { axios } = useAxios();
  const { removeAttachmentAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const doRemoveNotebookAttachment = (file: MiProFile) => {
    return doPromiseAPI(async (cancelToken) => {
      let seqNumber = toString(file.seqNo);
      if (!seqNumber && seqNumber !== '0') {
        seqNumber = toString(file.SEQ_NO);
      }
      await axios.delete(
        removeAttachmentAPI(
          domain,
          // TODO: API is hardcoding values for this props, only the image knows them
          toString(file.miLoc || file.MI_LOC),
          toString(file.ctlNo || file.CTL_NO),
          toString(file.LINE_NO || file.lineNo),
          seqNumber
        ),
        { cancelToken, data: { fileName: file.USER_FILE || file.userFile } }
      );
    });
  };

  const { mutate, status } = useMutation(doRemoveNotebookAttachment, {
    onMutate: async (vars) =>
      onMutateUpdate<MiProFile>({
        queryClient,
        queryKey: findAttachmentsQueryKey,
        queryKeyParams: { domain, miLoc, ctlNo },
        removedFindPredicates: [{ ...vars }],
        isArrayQuery: true,
      }),
    onSuccess: () => {
      void onSuccessMutation(queryClient, findAttachmentsQueryKey, {
        domain,
        miLoc,
        ctlNo,
      });
    },
    onError: (error, vars, context) => {
      onErrorUpdate<MiProFile>({
        queryClient,
        context: context as UpdateMutationContext<MiProFile>[],
        isArrayQuery: true,
      });
      addToast({
        type: ToastType.error,
        text: 'Remove attachment operation failed.',
        testid: 'remove-attachments-error-toast',
      });
    },
  });

  return {
    status,
    removeAttachment: (image: MiProFile) => mutate(image),
  };
};

export default useRemoveAttachment;
