import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { forEach, get, isBoolean, isObject } from 'lodash';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { isPlatform } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type Text from 'components/Text/Text';
import classes from './renderHelpers.module.scss';

export type OptionalRenderProp<
  ComponentType = React.ComponentProps<typeof Text>,
  PropType = string
> =
  | PropType
  | ({
      wrapperProps?: IonicReactProps;
      customContent?: React.ReactNode;
    } & Partial<ComponentType>);

export type OptionalRenderFlags<Type> = {
  [PropName in keyof Type]?:
    | boolean
    | ({ [RenderFlag in PropName]?: boolean } & Type[PropName]);
};

export type SetStateProp<Type> = React.Dispatch<React.SetStateAction<Type>>;

export interface BaseComponentProps extends IonicReactProps {
  testid?: string;
}

export interface BaseInputProps {
  // TUDU should be OptionalRenderProp
  name: string;
  label?: string;
  required?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  viewonly?: boolean;
}

export const getOptionalRenderFlag = (
  name: string,
  props?: boolean | object
) => {
  return isBoolean(props) ? props : !!get(props, name);
};

export const getOptionalRenderProp = (props?: OptionalRenderProp) => {
  return {
    text: isObject(props) ? props.text : props,
    props: isObject(props) ? props : undefined,
  };
};

export const useNodeRef = <ElementType = HTMLElement | null>(
  paramNode?: ElementType
) => {
  const [node, setNode] = useState<ElementType>(paramNode as ElementType);

  const nodeRef = useCallback((ref: ElementType) => {
    if (ref !== null) {
      setNode(ref as ElementType);
    }
  }, []);

  return { node, nodeRef };
};

export const useIonContentRef = () => {
  const nodeRef = useRef<HTMLIonContentElement>(null);
  const node = nodeRef.current?.shadowRoot?.querySelector(
    'main'
  ) as HTMLElement;
  const [contentRef, setContentRef] = useState<HTMLElement>();

  useEffect(() => {
    setTimeout(() => {
      setContentRef(
        nodeRef.current?.shadowRoot?.querySelector('main') as HTMLElement
      );
    }, 100);
  }, [node]);

  return { node: node || contentRef, nodeRef };
};

export const useStikcyElement = <ElementType = HTMLElement | null>(
  offset = 0
) => {
  const { node, nodeRef } = useNodeRef<ElementType>();

  const stickyObserver = useMemo(() => {
    return new IntersectionObserver(
      ([e]) =>
        e?.target.classList.toggle(classes.stuck, e.intersectionRatio < 1),
      { rootMargin: `-${offset + 1}px 0px 0px 0px`, threshold: [1] }
    );
  }, [offset]);

  useEffect(() => {
    const element = node as HTMLElement;
    if (element) {
      element.classList.add(classes.sticky);
      stickyObserver.observe(element);
    }
    return () => {
      if (element) {
        stickyObserver.unobserve(element);
      }
    };
  }, [node, stickyObserver]);

  return { node, nodeRef };
};

export const useClassNameObserver = <ElementType = HTMLElement | null>(
  className: string,
  paramNode?: ElementType
) => {
  const { node, nodeRef } = useNodeRef<ElementType>(paramNode);
  const [hasClassName, setHasClassName] = useState(false);

  const classNameObserver = useMemo(() => {
    return new MutationObserver((mutations) => {
      forEach(mutations, (e) => {
        if (e.attributeName !== 'class') {
          return;
        }
        setHasClassName(
          (e.target as HTMLElement).classList.contains(className)
        );
      });
    });
  }, [className]);

  useEffect(() => {
    const element = node as HTMLElement;
    if (element) {
      classNameObserver.observe(element, { attributes: true });
    }
    return () => {
      if (element) {
        classNameObserver.disconnect();
      }
    };
  }, [node, classNameObserver]);

  return { hasClassName, node, nodeRef };
};

const getScrollParent = (node?: HTMLElement): HTMLElement => {
  if (!node) {
    return document.body;
  }
  const { overflowY } = window.getComputedStyle(node);
  const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

  if (isScrollable && node.scrollHeight >= node.clientHeight) {
    return node;
  }
  const parentNode =
    node.assignedSlot?.parentElement ||
    (node.parentNode as ShadowRoot)?.host ||
    node.parentElement;
  return getScrollParent(parentNode as HTMLElement);
};

export const onKeypadScroll = (node: HTMLElement) => {
  if (isPlatform('mobile')) {
    const parentRef = getScrollParent(node);
    if (parentRef.getElementsByClassName('mipro-scroll-pad').length === 0) {
      const paddingDiv = document.createElement('div');
      paddingDiv.setAttribute('class', 'mipro-scroll-pad');
      paddingDiv.setAttribute('style', 'height: 200px; min-height: 200px;');
      parentRef?.appendChild(paddingDiv);
    }
    let counter = 0;
    node?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    const interval = setInterval(() => {
      node?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      if (counter > 3) {
        clearInterval(interval);
      } else {
        counter += 1;
      }
    }, 200);
  }
};

export const removeKeypadScroll = (node: HTMLElement) => {
  const parentRef = getScrollParent(node);
  forEach(parentRef.getElementsByClassName('mipro-scroll-pad'), (item) => {
    item.remove();
  });
};

export const toggleKeyboard = async (show = false) => {
  if (Capacitor.isPluginAvailable('Keyboard')) {
    if (show) {
      await Keyboard.show();
    } else {
      await Keyboard.hide();
    }
  }
};

export const addKeyboardListener = async (event: string, fn: () => void) => {
  if (Capacitor.isPluginAvailable('Keyboard')) {
    return Keyboard.addListener(
      event as Parameters<typeof Keyboard.addListener>[0],
      fn
    );
  }
  return undefined;
};

export const getIntervalRender = ({
  fn = () => {},
  limit = 3,
  timeout = 100,
}) => {
  let counter = 0;
  const interval = setInterval(() => {
    fn?.();
    if (counter > limit) {
      clearInterval(interval);
    } else {
      counter += 1;
    }
  }, timeout);
  return interval;
};
