import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { filter, findIndex, isEmpty, map, size, toString } from 'lodash';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import Alert, { AlertVariantEnum } from 'common/components/Alert/Alert';
import { and, or } from 'common/utils/logicHelpers';
import { useFormik } from 'formik';
import { namespaces } from 'i18n/i18n.constants';
import * as yup from 'yup';
import useSendEmail from 'api/activities/useSendEmail';
import type { MiProFile } from 'models/Attachment';
import type { Recipient } from 'models/Contact';
import type { SearchItemType } from 'models/Search';
import { updateFileSizeFlag } from 'utils/filesUpload';
import { findIcon } from 'utils/icons';
import Attachments from 'components/Attachments/Attachments';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import Text from 'components/Text/Text';
import TextArea from 'components/TextArea/TextArea';
import ContactsList from './ContactsList';
import classes from './SendEmailModal.module.scss';

interface SendEmailModalProps {
  searchType?: SearchItemType;
  id?: string;
  miLoc?: string;
  subject?: string;
  body?: string;
  defaultRecipients?: Recipient[];
  canRemoveRecipients?: boolean;
  defaultFiles?: MiProFile[];
  cc?: string[];
}

const SendEmailModal = ({
  searchType,
  id = '',
  miLoc = '',
  subject = '',
  body = '',
  isOpen,
  setIsOpen,
  defaultRecipients,
  title,
  onClose,
  testid,
  canRemoveRecipients = true,
  defaultFiles,
  cc,
}: SendEmailModalProps &
  React.ComponentProps<typeof Modal> &
  IonicReactProps): JSX.Element => {
  const initialFormData = useMemo(
    () => ({ subject, message: body, recipients: defaultRecipients }),
    [body, subject, defaultRecipients]
  );
  const { t } = useTranslation(namespaces.email);
  const [listIsOpen, setListIsOpen] = useState(false);
  const [discardConfirmDialogIsOpen, setDiscardConfirmDialogIsOpen] =
    useState(false);
  const [filesToUpload, setFilesToUpload] = useState<MiProFile[]>([]);
  const [fileSizeExceeded, setFileSizeExceeded] = useState<boolean>(false);
  const [maxSizeSubmitToggle, setMaxSizeSubmitToggle] =
    useState<boolean>(false);
  const [formDirty, setFormDirty] = useState<boolean>(false);
  // DOC: trigger children change on Modal so that it can be resized
  const [triggerModalResize, setTriggerModalResize] = useState(0);

  const { onSendEmail, status } = useSendEmail();

  const validationSchema = yup.object().shape({
    subject: yup.string().required(t('subjectRequired')),
    message: yup.string().required(t('messageRequired')),
    recipients: yup.array().min(1, t('recipientRequired')),
  });

  const {
    values,
    errors,
    handleSubmit,
    setFieldValue,
    setValues,
    setSubmitting,
    setErrors,
    validateField,
    isSubmitting,
  } = useFormik({
    initialValues: initialFormData,
    onSubmit: (formValues) => {
      if (!fileSizeExceeded) {
        onSendEmail({
          subject: formValues.subject,
          body: formValues.message,
          recipients: map(formValues.recipients, ({ email }) =>
            toString(email)
          ),
          filesToUpload,
          cc,
        });
      } else {
        setMaxSizeSubmitToggle(!maxSizeSubmitToggle);
      }
    },
    validationSchema,
    validateOnChange: false,
  });

  // DOC: keep using index because Customer Contacts don't have location
  const removeRecipient = (
    index: number,
    sequenceNo?: number,
    itemMiLoc?: string
  ) => {
    let { recipients: recipientList } = values;
    if (recipientList) {
      const seqIndex = findIndex(values.recipients, {
        sequenceNo,
        ...(searchType === 'supplier' ? { miLoc: itemMiLoc } : undefined),
      });
      if (seqIndex !== -1) {
        recipientList = [
          ...recipientList.slice(0, seqIndex),
          ...recipientList.slice(seqIndex + 1),
        ];
      } else if (index !== -1) {
        recipientList = [
          ...recipientList.slice(0, index),
          ...recipientList.slice(index + 1),
        ];
      }
      void setFieldValue('recipients', recipientList);
    }
    setFormDirty(true);
  };

  const addRecipientClick = () => {
    setFormDirty(true);
    setListIsOpen(true);
  };

  const canAddCustomerContacts =
    searchType === 'customer' && !isEmpty(id) && !isEmpty(miLoc);
  const canAddSupplierContacts = searchType === 'supplier' && !isEmpty(id);

  const closeModel = () => {
    setIsOpen?.(false);
    onClose?.();
    setFormDirty(false);
  };

  const onFilesChanged = (fu: MiProFile[]) => {
    updateFileSizeFlag(fu, setFileSizeExceeded);
    setFilesToUpload(fu);
    setFormDirty(true);
    setTriggerModalResize(Date.now());
  };

  useEffect(() => {
    if (status === 'success') {
      setIsOpen?.(false);
      setFormDirty(false);
      setSubmitting(false);
    } else if (status === 'error' || fileSizeExceeded) {
      setSubmitting(false);
    }
  }, [setIsOpen, setSubmitting, status, maxSizeSubmitToggle, fileSizeExceeded]);

  useEffect(() => {
    if (isOpen && !formDirty) {
      setSubmitting(false);
      void setValues(initialFormData);
      void setErrors({});
      setFileSizeExceeded(false);
    }
  }, [
    isOpen,
    defaultRecipients,
    initialFormData,
    formDirty,
    setValues,
    setErrors,
    setSubmitting,
  ]);

  useEffect(() => {
    if (size(defaultRecipients) === 0 && isOpen) {
      setListIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (size(defaultFiles) > 0) {
      setFilesToUpload(defaultFiles as MiProFile[]);
    }
  }, [defaultFiles]);

  const canAddRecepients = or(canAddCustomerContacts, canAddSupplierContacts);

  return (
    <Modal
      modalClassName={classes.modalWrapper}
      className={classes.modal}
      headerClassName={classes.header}
      isOpen={isOpen}
      withTitleLine={false}
      title={title}
      setIsOpen={isSubmitting ? () => {} : setIsOpen}
      triggerModalResize={triggerModalResize}
      onClose={isSubmitting ? () => {} : closeModel}
      forceFullHeight
      footer={
        <IonRow className={classes.buttonsWrapper}>
          <Button
            variant="action"
            text={isSubmitting ? '' : t('common:send')}
            onClick={() => handleSubmit()}
            disabled={isSubmitting}
            testid="send-email-button"
          >
            <Loader
              className={classes.sendLoader}
              type="inline"
              isOpen={isSubmitting}
            />
          </Button>
          <Button
            variant="secondary"
            text={t('common:cancel')}
            onClick={() => setDiscardConfirmDialogIsOpen(true)}
            disabled={isSubmitting}
            testid="cancel-button"
          />
          <ConfirmDialog
            isOpen={discardConfirmDialogIsOpen}
            setIsOpen={setDiscardConfirmDialogIsOpen}
            title={t('cancelEmailTitle')}
            text={t('cancelEmailMessage')}
            primaryText={t('cancelEmailNo')}
            secondaryText={t('cancelEmailYes')}
            onPrimaryClick={() => setDiscardConfirmDialogIsOpen(false)}
            onSecondaryClick={() => closeModel()}
            testid="discard-changes-modal"
          />
        </IonRow>
      }
      testid={testid}
    >
      <div className={classNames(classes.content)}>
        <IonRow className={classes.titleRow}>
          <Text
            className={classNames({
              [classes.errWarning]: errors.recipients,
            })}
            variant="content-heavy"
            text={t('recipients')}
          />
          {canAddRecepients && (
            <>
              <Button
                className={classes.addRecipientButton}
                variant="link"
                icon={findIcon('plus-circle')}
                iconClassName={classes.plusCircle}
                onClick={() => addRecipientClick()}
                testid="add-recipient-button"
              />
              <ContactsList
                canRemoveRecipients={canRemoveRecipients}
                allowAddContact={canAddRecepients}
                searchType={searchType as SearchItemType}
                id={id}
                miLoc={miLoc}
                isOpen={listIsOpen}
                setIsOpen={setListIsOpen}
                title={title}
                values={values.recipients}
                onDone={(v) => {
                  void setFieldValue('recipients', v);
                  setTimeout(() => {
                    void validateField('recipients');
                  });
                }}
                testid="contact-list-modal"
              />
            </>
          )}
        </IonRow>
        <IonRow className={classes.recipientsWrapper} id="recipients">
          {map(
            values.recipients,
            ({ sequenceNo, miLoc: itemMiLoc = '', name, email }, index) => (
              <IonRow
                data-testid="recipient-name"
                key={`${toString(sequenceNo)}${itemMiLoc}${index}`}
                className={classNames(classes.recipientButton, {
                  [classes.withoutButton]: !canRemoveRecipients,
                })}
              >
                <div>
                  <div>
                    <Text
                      className={classes.contactName}
                      variant="content-heavy"
                      text={toString(name || email)}
                    />
                    {searchType === 'supplier' && (
                      <Text
                        className={classes.contactLocation}
                        variant="content-small"
                        text={itemMiLoc}
                        testid="card-location"
                      />
                    )}
                  </div>
                  {name && <Text text={toString(email)} />}
                </div>
                {and(canRemoveRecipients, canAddRecepients) && (
                  <Button
                    icon={findIcon('times-circle', 'fas')}
                    onClick={() => {
                      removeRecipient(index, sequenceNo, itemMiLoc);
                      setTimeout(() => {
                        void validateField('recipients');
                      });
                    }}
                    testid={`remove-recipient-${toString(sequenceNo)}-button`}
                  />
                )}
              </IonRow>
            )
          )}
        </IonRow>
        {errors.recipients && (
          <Alert variant={AlertVariantEnum.danger} text={errors.recipients} />
        )}
        <Input
          className={classes.subjectInput}
          label={t('subject')}
          name="subject"
          value={values.subject}
          error={errors.subject}
          setValue={(e) => {
            setFormDirty(true);
            void setFieldValue('subject', e);
          }}
          onBlur={(e) => validateField(e.target.name)}
          disabled={isSubmitting}
          testid={`${testid}-subject-input`}
          autocapitalize="sentences"
          spellcheck
        />
        <TextArea
          className={classes.subjectInput}
          label={t('message')}
          name="message"
          value={values.message}
          error={errors.message}
          setValue={(e) => {
            setFormDirty(true);
            void setFieldValue('message', e);
          }}
          onBlur={(e) => validateField(e.target.name)}
          disabled={isSubmitting}
          testid={`${testid}-message-input`}
        />
        <Attachments
          name="customer"
          files={filesToUpload}
          label={t('attachments')}
          onAdd={(files) => {
            onFilesChanged([...(filesToUpload || []), ...files]);
          }}
          onRemove={(file) => {
            const filesFilteredToUpload = filter(
              [...filesToUpload],
              (i) => i.fileName !== file.fileName
            );
            onFilesChanged(filesFilteredToUpload);
          }}
          disabled={isSubmitting}
          enableSmallPreview
          testid="attachments"
        />
      </div>
    </Modal>
  );
};

export default SendEmailModal;
