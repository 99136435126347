import { last, split } from 'lodash';
import type { NotificationTypeEnum } from 'HomeApp/Notifications/models/Notifications';
import { DateFormatEnum, formatDate } from 'utils/date';

export const getNotificationType = (className: string) =>
  last(split(className, '.')) as NotificationTypeEnum;

export const isCommentTypeMentioned = (notificationType: string) =>
  notificationType === 'Mentioned';

export const notificationLastSeenTime = () =>
  formatDate(Date.now(), DateFormatEnum.ISO);
