import React from 'react';
import classNames from 'classnames';
import { get, isNaN, toNumber, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { choose, or } from 'common/utils/logicHelpers';
import { scaleNumber } from 'common/utils/numberHelper';
import { emptyString } from 'common/utils/valueFormatter';
import i18n from 'i18n/i18n';
import type { ReportField } from 'ReportsApp/models';
import { DateFormatEnum, formatDate } from 'utils/date';
import Text from 'components/Text/Text';
import classes from './ReportCell.module.scss';

interface ReporCellProps {
  field: ReportField;
  data: unknown;
  currencyType?: string;
}

const ReportCell = ({
  field,
  data,
  currencyType,
}: ReporCellProps): JSX.Element => {
  const value = or(
    toNumber(field.getValue?.(data)),
    toNumber(get(data, field.id))
  );
  const defaultProps = {
    number: value,
    useGrouping: true,
  };
  const currency = or(toString(get(data, 'currency')), currencyType);
  let text = scaleNumber(defaultProps);

  switch (field.type) {
    case 'currency':
      text = scaleNumber({
        ...defaultProps,
        padFractionalZeros: false,
        currencyType: currency,
        scale: 2,
      });
      break;
    case 'yoy':
    case 'percentage':
      text = toString(
        choose(
          isNaN(defaultProps.number),
          emptyString,
          `${scaleNumber({
            ...defaultProps,
            scale: 1,
          })}%`
        )
      );
      break;
    case 'bp-yoy':
      text = toString(
        choose(
          isNaN(defaultProps.number),
          emptyString,
          `${scaleNumber({
            ...defaultProps,
            scale: 0,
          })}`
        )
      );
      text = (value >= 0 ? '+' : '') + text;
      break;
    case 'text':
      text = or(toString(get(data, field.id)), emptyString);
      break;
    case 'number':
      text = scaleNumber({
        ...defaultProps,
        scale: choose(isNaN(field.scale), 2, field.scale),
      });
      break;
    case 'date':
      text = choose(
        toString(get(data, field.id)),
        formatDate(
          toString(get(data, field.id)),
          DateFormatEnum.fullDate,
          i18n.language
        ),
        emptyString
      ) as string;
      break;

    default:
  }
  return (
    <div
      className={classNames({
        [classes.reportCell]: or(
          field.type === 'yoy',
          field.id === 'overage',
          field.type === 'bp-yoy'
        ),
        [classes.positive]: value > 0,
        [classes.negative]: value < 0,
        [classes.text]: field.type === 'text',
      })}
      data-testid="report-cell"
    >
      {value !== 0 && field.type === 'yoy' && (
        <FontAwesomeIcon
          icon={['fas', value > 0 ? 'caret-up' : 'caret-down']}
          data-testid="icon"
        />
      )}
      <Text
        variant="content-small"
        text={choose(field.showEmptyString, emptyString, text)}
        testid="value"
      />
    </div>
  );
};

export default ReportCell;
