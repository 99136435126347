import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { findIcon } from 'utils/icons';

const AppIcons = {
  snoozedIcon: (isSnoozed: boolean): IconProp =>
    findIcon('clock', isSnoozed ? 'fas' : 'far'),
  doneIcon: (isDone: boolean): IconProp =>
    findIcon('trash-alt', isDone ? 'fas' : 'far'),
  readIcon: (): IconProp => findIcon('check-double', 'fal'),
  followUpIcon: (isFollowedUp: boolean): IconProp =>
    findIcon('pennant', isFollowedUp ? 'fas' : 'far'),
  emailIcon: (): IconProp => findIcon('envelope'),
  phoneIcon: (): IconProp => findIcon('phone'),
  mobileIcon: (): IconProp => findIcon('mobile-alt'),
  smsIcon: (): IconProp => findIcon('comment'),
};

export default AppIcons;
