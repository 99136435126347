import { useTranslation } from 'react-i18next';
import { omit, size, toNumber, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  EBCDICSort,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type { Contact, UpdateContactBody } from 'models/Contact';
import type { SearchItemType } from 'models/Search';
import { ToastType } from 'models/Toast';
import { findContactsQueryKey } from './useFindContacts';
import { getContactQueryKey } from './useGetContact';

interface UpdateContactMutationProps {
  searchType: SearchItemType;
  miLoc: string;
  id: string;
  sequenceNo: number;
}

interface UseUpdateContactResponse {
  status: MutationStatus;
  updateContact: (body: UpdateContactBody) => void;
}
interface UpdateContactMutationContext {
  listContext: UpdateMutationContext<Contact>[];
  itemContext: UpdateMutationContext<Contact>[];
}

const useUpdateContact = ({
  searchType,
  miLoc,
  id,
  sequenceNo,
}: UpdateContactMutationProps): UseUpdateContactResponse => {
  const { axios } = useAxios();
  const { contactAPI } = useAPIUrl();
  const { t } = useTranslation(namespaces.contact);
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const doUpdateContact = (body: UpdateContactBody) => {
    return doPromiseAPI(async (cancelToken) => {
      const contactBody = { ...body };
      if (searchType === 'supplier') {
        contactBody.phone1 = contactBody.phone;
        delete contactBody.phone;
      }
      return axios.put(
        contactAPI(searchType, miLoc, id, toString(sequenceNo)),
        contactBody,
        { cancelToken }
      );
    });
  };

  const updateQueryKeyParams = {
    searchType,
    id,
    // DOC: update supplier queries for all locations
    ...(searchType === 'customer' ? { miLoc } : {}),
  };

  const { mutate, status } = useMutation(doUpdateContact, {
    onMutate: async (vars) => {
      const listContext = await onMutateUpdate<Contact>({
        queryClient,
        queryKey: findContactsQueryKey,
        queryKeyParams: updateQueryKeyParams,
        updatedItems: [{ ...vars, sequenceNo }],
        findPredicates: [
          {
            sequenceNo,
            // DOC: supplier contacts have miLoc
            ...(searchType === 'supplier' ? { miLoc } : {}),
          },
        ],
        // TODO fix optimisticUI updates with new react version
        sortPredicate: { sortBy: EBCDICSort },
        isInfiniteQuery: true,
      });
      const itemContext = await onMutateUpdate<Contact>({
        queryClient,
        queryKey: getContactQueryKey,
        queryKeyParams: {
          ...updateQueryKeyParams,
          sequenceNo,
        },
        updatedItems: [
          { ...omit(vars, ['resend']), sequenceNo: toNumber(sequenceNo) },
        ],
        findPredicates: [
          {
            sequenceNo,
            ...(searchType === 'supplier' ? { miLoc } : {}),
          },
        ],
        isInfiniteQuery: false,
        isSingleQuery: true,
      });
      return { listContext, itemContext };
    },
    onSuccess: (_data, vars, context) => {
      const { itemContext } = context as UpdateContactMutationContext;
      addToast({
        text: t('editSuccessToast', {
          name: vars?.name || t('editDefaultNameToast'),
        }),
        // TODO undo operation
        testid: 'edit-contact-toast',
      });
      void onSuccessMutation(queryClient, findContactsQueryKey, {
        searchType,
        id,
      });
      if (size(itemContext) > 0) {
        void onSuccessMutation(queryClient, getContactQueryKey, {
          searchType,
          miLoc,
          id,
          sequenceNo: toNumber(sequenceNo),
        });
      }
    },
    onError: (_error, _vars, context) => {
      const { itemContext, listContext } =
        context as UpdateContactMutationContext;
      addToast({
        type: ToastType.error,
        text: t('editContactSaveFailure'),
        testid: 'edit-contact-error-toast',
      });
      onErrorUpdate<Contact>({
        queryClient,
        context: itemContext,
        isSingleQuery: true,
      });
      onErrorUpdate<Contact>({
        queryClient,
        context: listContext,
        // sortPredicate: { sortBy: EBCDICSort },
        isInfiniteQuery: true,
      });
    },
  });

  return { status, updateContact: (body: UpdateContactBody) => mutate(body) };
};

export default useUpdateContact;
