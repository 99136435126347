import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  filter,
  find,
  findIndex,
  isEmpty,
  isNil,
  map,
  size,
  toString,
  escapeRegExp,
  get,
} from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { IonCol, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import Footer from 'common/components/Footer/Footer';
import Input from 'common/components/Forms/Input/Input';
import List from 'common/components/List/List';
import { useNodeRef } from 'common/components/utils/renderHelpers';
import { and, choose, ifRender, or } from 'common/utils/logicHelpers';
import { DataTypeEnum, formatKeyValue } from 'common/utils/valueFormatter';
import { namespaces } from 'i18n/i18n.constants';
import { useDebounce } from 'use-debounce';
import useFindContacts from 'api/contacts/useFindContacts';
import type { Recipient } from 'models/Contact';
import type { Customer } from 'models/Customer';
import {
  SearchSuggestionTypeEnum,
  type SearchItemType,
  type SelectModalItem,
  type SearchCustomerContactItem,
  type ContactItem,
} from 'models/Search';
import { getErrorMessage } from 'utils/helpers';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import ContactModalV2 from 'components/Contacts/ContactModalV2/ContactModalV2';
import Modal from 'components/Modal/Modal';
import Searchbar from 'components/Searchbar/Searchbar';
import Text from 'components/Text/Text';
import classes from './ContactsList.module.scss';

interface ContactsListProps {
  searchType: SearchItemType;
  id: string;
  miLoc: string;
  showSelectedContacts?: boolean;
  values?: Recipient[];
  allowAddContact?: boolean;
  canRemoveRecipients?: boolean;
  showInput?: boolean;
  inputName?: string;
  placeholder?: string;
  isReadOnly?: boolean;
  label?: string;
  value?: string;
  searchLink?: string;
  iconClassName?: string;
  disabled?: boolean;
  maxlength?: number;
  allowInputEdit?: boolean;
  inputError?: string;
  required?: boolean;
  icon?: string;
  onDone?: (v: Recipient[]) => void;
  onClick?: VoidFunction;
  onBlur?: VoidFunction;
  setSelItem?: (o: SelectModalItem) => void;
}

const ContactsList = ({
  searchType,
  id,
  miLoc,
  isOpen,
  values,
  allowInputEdit,
  title = 'Add Recipients',
  testid,
  showSelectedContacts = true,
  allowAddContact,
  canRemoveRecipients,
  showInput,
  disabled,
  inputName,
  placeholder,
  className,
  label,
  isReadOnly,
  searchLink,
  iconClassName,
  value,
  maxlength,
  inputError,
  required,
  icon,
  setIsOpen,
  onClose,
  onDone,
  onClick,
  onBlur,
  setSelItem,
}: ContactsListProps &
  React.ComponentProps<typeof Modal> &
  IonicReactProps): JSX.Element => {
  const { node, nodeRef } = useNodeRef();
  const [triggerFocus, setTriggerFocus] = useState(0);
  const [isAddContactOpen, setIsAddContactOpen] = useState(false);
  const [contactModalData, setContactModalData] =
    useState<
      Omit<
        React.ComponentProps<typeof ContactModalV2>,
        'isOpen' | 'setIsOpen' | 'searchType' | 'testid'
      >
    >();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);
  const withSearchQuery = !isEmpty(searchQueryValue);
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const { t } = useTranslation(namespaces.contact);

  useEffect(() => {
    if (isOpen) {
      setRecipients(or(values, []));
      setSearchQuery('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const {
    data,
    contacts,
    error,
    isEmptyResponse,
    showLoader: isLoadingContacts,
    noMoreData,
    fetchNextPage,
    enableInfiniteScroll,
  } = useFindContacts({
    query: searchQuery,
    searchType,
    miLoc,
    id,
    enabled: isOpen,
  });

  const sendInviteOnAdd = searchType === 'customer';
  const inviteMiCom = choose(
    searchType === 'customer',
    (data as Customer)?.inviteMiCom,
    false
  );

  const contactsItems: ContactItem[] = map(contacts, (item) => {
    const { name, typeDesc, phone, sequenceNo, isMainContact } =
      item as SearchCustomerContactItem;
    return {
      item,
      key: toString(sequenceNo),
      title: toString(name),
      description: typeDesc,
      contactItem: {
        address: phone,
        text: '',
        searchSuggestionType: SearchSuggestionTypeEnum.search,
        testid: 'contactitem',
        isMainCard: isMainContact === 'Y',
      },
    };
  });

  const filteredContacts = useMemo(
    () =>
      filter(
        contactsItems,
        ({ title: contactName }) =>
          !withSearchQuery ||
          !isNil(
            new RegExp(escapeRegExp(searchQueryValue), 'i').exec(
              toString(contactName)
            )
          )
      ),
    [contactsItems, searchQueryValue, withSearchQuery]
  );

  useEffect(() => {
    if (and(isOpen, !searchQueryValue, !values?.length)) {
      setTimeout(() => setTriggerFocus(Date.now()), 150);
    }
  }, [isOpen, values?.length, searchQueryValue]);

  // DOC: keep using index because Customer Contacts don't have location
  const removeRecipient = (
    index: number,
    sequenceNo?: number,
    itemMiLoc?: string
  ) => {
    setRecipients((prev) => {
      const seqIndex = findIndex(prev, {
        sequenceNo,
        ...(searchType === 'supplier' ? { miLoc: itemMiLoc } : undefined),
      });
      if (seqIndex !== -1) {
        return [...prev.slice(0, seqIndex), ...prev.slice(seqIndex + 1)];
      }
      if (index !== -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return prev;
    });
    if (showInput) {
      setSelItem?.({
        key: '',
        title: '',
      });
    }
  };

  const getItemName = (item: Recipient) => {
    return or(get(item, 'name'), get(item, 'email'));
  };

  const handleSingleSelection = (listItem: ContactItem) => {
    setRecipients(() => [
      {
        sequenceNo: listItem.item?.sequenceNo,
        miLoc: listItem.item?.miLoc,
        name: listItem.item?.name,
        email: listItem.item?.email,
      },
    ]);
    setIsAddContactOpen(false);
    setSelItem?.(listItem);
    setIsOpen?.(false);
    onDone?.(recipients);
  };

  const renderListItem = (listItem: ContactItem) => {
    const sequenceNo = listItem.item?.sequenceNo;
    const itemMiLoc = toString(listItem.item?.miLoc);
    const itemName = toString(listItem.item?.name);
    const itemEmail = toString(listItem.item?.email);
    const itemTypeDesc = listItem.item?.typeDesc;
    const phoneNumber = listItem.item?.phone;
    const isMainContact = listItem?.item?.isMainContact;

    const isSelected = find(recipients, (i) => {
      if (searchType === 'supplier') {
        return or(
          toString(get(i, 'sequenceNo')) === get(listItem, 'key'),
          toString(get(i, 'sequenceNo')) === get(listItem, 'key')
        );
      }
      return toString(get(i, 'sequenceNo')) === get(listItem, 'key');
    });
    const indexAdded = findIndex(recipients, {
      sequenceNo,
      ...(searchType === 'supplier' ? { miLoc: itemMiLoc } : undefined),
    });

    return (
      <ActionRow
        key={`${toString(sequenceNo)}${itemMiLoc}`}
        className={classNames(classes.itemCard, {
          [classes.selected]: isSelected,
        })}
        leftButton={choose(!showInput, {
          variant: 'link',
          icon: choose(
            isSelected,
            ['fas', 'check-circle'],
            ['far', 'plus-circle']
          ),
          testid: `contact-${toString(sequenceNo)}-add-button`,
          wrapperProps: {
            className: classes.iconWrapper,
          },
        })}
        onClick={() => {
          if (showInput) {
            handleSingleSelection(listItem);
            return;
          }
          if (!isSelected) {
            setRecipients((prev) => [
              ...prev,
              {
                sequenceNo,
                miLoc: itemMiLoc,
                name: itemName,
                email: itemEmail,
              },
            ]);
            return;
          }
          removeRecipient(indexAdded, sequenceNo, itemMiLoc);
        }}
        testid={`contact-${toString(sequenceNo)}`}
      >
        <IonRow className={classes.itemRow}>
          <div>
            {!isEmpty(itemName) && (
              <Text
                className={classes.itemName}
                text={itemName}
                textQuery={searchQueryValue}
              />
            )}
            {searchType === 'supplier' && (
              <Text
                variant="content-small"
                text={itemMiLoc}
                testid="card-location"
              />
            )}
          </div>
          <Text variant="content-small" text={itemEmail} />
          <IonRow>
            <Text
              className={classes.description}
              variant="content-small"
              text={itemTypeDesc}
            />
            {ifRender(
              isMainContact === 'Y',
              <Text
                className={classes.mainContact}
                variant="content-small"
                text={t('snapshot:mainContact')}
                testid="contact-main-id"
              />
            )}
          </IonRow>
          {ifRender(
            phoneNumber,
            <Text
              className={classes.phone}
              variant="content-small"
              text={formatKeyValue({
                value: phoneNumber,
                type: DataTypeEnum.phone,
              })}
            />
          )}
        </IonRow>
      </ActionRow>
    );
  };

  const renderSelectedPill = (item: Recipient, index: number) => (
    <IonRow
      key={`pill-${index}-${toString(item?.miLoc)}`}
      className={classes.selectedItem}
    >
      <Text className={classes.itemName} text={getItemName(item)} />
      {ifRender(
        canRemoveRecipients,
        <Button
          icon={['fas', 'times']}
          onClick={() => {
            removeRecipient(index, item?.sequenceNo, item?.miLoc);
          }}
          testid={`remove-recipient-${toString(item?.sequenceNo)}-button`}
        />
      )}
    </IonRow>
  );

  return (
    <>
      {showInput && (
        <IonRow className={className}>
          <IonCol size="12">
            <Input
              className={classes.input}
              readonly={isReadOnly}
              label={label}
              name={toString(inputName)}
              value={value}
              placeholder={placeholder}
              testid={`input-${testid}`}
              rightButton={
                !searchLink
                  ? {
                      icon: icon as IconName,
                      testid: 'mag-button',
                      className: iconClassName,
                      onClick: () =>
                        choose(!disabled && !isReadOnly, () => onClick?.())?.(),
                    }
                  : undefined
              }
              required={required}
              disabled={disabled}
              error={inputError}
              onFocus={() =>
                choose(!allowInputEdit, () => setIsOpen?.(true))?.()
              }
              onBlur={onBlur}
              setValue={(v) =>
                choose(allowInputEdit, () =>
                  setSelItem?.({ title: v, key: v })
                )?.()
              }
              maxlength={maxlength}
              focusable={allowInputEdit}
            />
          </IonCol>
          {searchLink && (
            <Button
              className={classes.searchLink}
              variant="link"
              onClick={() => choose(!disabled && !isReadOnly, onClick?.())}
              textVariant="mipro-report-info"
              testid={`${testid}-search-link`}
              text={searchLink}
            />
          )}
        </IonRow>
      )}
      <Modal
        modalClassName={classes.modalWrapper}
        className={classes.modal}
        headerClassName={classes.modalHeader}
        isOpen={isOpen}
        withTitleLine={false}
        title={title}
        setIsOpen={setIsOpen}
        onClose={onClose}
        forceFullHeight
        header={
          <div className={classes.searchWrapper}>
            <Searchbar
              className={classNames(classes.searchbar, {
                [classes.withMargin]: !allowAddContact,
              })}
              value={searchQuery}
              setValue={setSearchQuery}
              testid="search-input"
              triggerInputSelect={triggerFocus}
            />
            {ifRender(
              allowAddContact,
              <ActionRow
                className={classNames(classes.actionRow, 'ion-no-padding')}
                leftButton={{
                  variant: 'link',
                  text: t('contact:addNewContact'),
                }}
                onClick={() => {
                  setIsAddContactOpen(true);
                  setContactModalData({
                    title: t('contact:addNewContact'),
                    showInvite: sendInviteOnAdd,
                    inviteMiCom,
                    customerId: id,
                    miLoc,
                  });
                }}
                icon={['far', 'user-plus']}
                testid="add-contact-button"
              />
            )}
            {ifRender(
              and(showSelectedContacts, size(recipients) > 0),
              <div className={classes.selectedItems}>
                <div className={classes.itemsWrapper}>
                  {map(recipients, renderSelectedPill)}
                  <div style={{ minWidth: `1px` }} />
                </div>
              </div>
            )}
          </div>
        }
        footer={ifRender(
          !showInput,
          <Footer
            buttons={[
              {
                text: t('common:clear'),
                onClick: () => {
                  setRecipients([]);
                },
                variant: 'secondary',
                testid: 'clear-button',
              },
              {
                text: t('common:apply'),
                onClick: () => {
                  onDone?.(recipients);
                  setIsOpen?.(false);
                },
                variant: 'mipro-action',
                testid: 'done-button',
              },
            ]}
          />
        )}
        testid={testid}
      >
        <div className={classes.contactsList} ref={nodeRef}>
          <List
            testid="results-list"
            data={filteredContacts}
            itemContent={(_, item) => renderListItem(item)}
            isLoading={and(isLoadingContacts, withSearchQuery)}
            scrollParent={node}
            isEmptyList={or(isEmptyResponse, !filteredContacts?.length)}
            isError={{
              isError: !!error,
              title: t('common:errorLoading'),
              body: getErrorMessage(error),
            }}
            endReached={choose(enableInfiniteScroll, fetchNextPage)}
            isEndOfList={noMoreData}
          />
        </div>
      </Modal>
      <ContactModalV2
        onSuccess={(newContact) => {
          if (!newContact) return;

          const { name, email, sequenceNo, typeDesc } = newContact;
          if (showInput) {
            handleSingleSelection({
              key: toString(sequenceNo),
              title: toString(name ?? email),
              item: newContact,
              description: typeDesc,
            });
            return;
          }
          setRecipients((prev) => [
            ...prev,
            {
              name,
              email,
              sequenceNo,
            },
          ]);
        }}
        searchType="customer"
        isOpen={isAddContactOpen}
        setIsOpen={setIsAddContactOpen}
        testid="contact-modal"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...contactModalData}
      />
    </>
  );
};

export default ContactsList;
