import React, { useRef, useEffect, useCallback } from 'react';
import type ColorVariant from 'constants/colorVariants';
import { getLongPressDuration } from 'constants/platformSpecificConstants';
import { toString } from 'lodash';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Gesture, GestureDetail, Animation } from '@ionic/react';
import { createAnimation, createGesture } from '@ionic/react';
import { LongPressDetectEvents, useLongPress } from 'use-long-press';
import useHapticFeedback from 'hooks/useHapticFeedback';
import classes from './Slider.module.scss';

export type SwipeOption = {
  icon?: IconProp;
  onSwipeComplete?: () => void;
  variant?: ColorVariant;
  text?: string;
  className?: string;
  willPlayAnimation?: boolean;
  autoClose?: boolean;
};

const windowWidth = window.innerWidth;
const ANIMATION_DURATION = 250;
const SWIPE_THRESHOLD = 100;
const POP_ANIMATION = 'cubic-bezier(.34,1.42,.87,1.15)';

const getSliderAnimatiion = (el: Element | Node, height: string) => {
  return createAnimation()
    .addElement(el)
    .duration(ANIMATION_DURATION)
    .easing('ease-in-out')
    .fromTo('height', height, '0');
};

const getIconPopAnimation = (el: Element | Node): Animation => {
  return createAnimation()
    .addElement(el)
    .easing(POP_ANIMATION)
    .duration(ANIMATION_DURATION)
    .fromTo('transform', 'scale(1)', 'scale(1.5)');
};

const getIconResetAnimation = (el: Element | Node): Animation => {
  return createAnimation()
    .addElement(el)
    .duration(ANIMATION_DURATION * 0.6)
    .to('transform', 'scale(1)');
};

interface Props {
  children?: JSX.Element;
  swipeRightOption?: SwipeOption;
  swipeLeftOption?: SwipeOption;
  onLongPress?: () => void;
}

const Slider = ({
  children = <div>slider_test</div>,
  swipeRightOption,
  swipeLeftOption,
  onLongPress,
}: Props): JSX.Element => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const innerItemRef = useRef<HTMLDivElement>(null);
  const leftIconRef = useRef<HTMLDivElement>(null);
  const rightIconRef = useRef<HTMLDivElement>(null);

  const { hapticsImpactLight } = useHapticFeedback();

  const getBackgroundColor = useCallback((variant: string): string => {
    switch (variant) {
      case 'primary':
      default:
        return 'var(--ion-color-primary)';
      case 'secondary':
        return 'var(--ion-color-secondary)';
      case 'danger':
        return 'var(--ion-color-danger)';
      case 'success':
        return 'var(--ion-color-success)';
    }
  }, []);

  const callback = useCallback(() => {
    if (onLongPress) {
      void hapticsImpactLight();
      onLongPress();
    }
  }, [hapticsImpactLight, onLongPress]);

  const bind = useLongPress(callback, {
    captureEvent: true,
    threshold: getLongPressDuration(),
    cancelOnMovement: true,
    detect: LongPressDetectEvents.BOTH,
  });

  // TODO: decouple leftOption and rightOption animations to allow for
  // sliders with only swipe left or only swipe right
  // e.g., leftIconRef || rightIconRef

  useEffect(() => {
    if (
      sliderRef.current &&
      innerItemRef.current &&
      leftIconRef.current &&
      rightIconRef.current
    ) {
      let canAnimateLeftIcon = true;
      let canAnimateRightIcon = true;
      const { style } = innerItemRef.current;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const cs = getComputedStyle(innerItemRef.current.firstElementChild!);

      const swipeAnimation = getSliderAnimatiion(sliderRef.current, cs.height);

      const leftIconAnimation = getIconPopAnimation(leftIconRef.current);
      const resetLeftIconAnimation = getIconResetAnimation(leftIconRef.current);

      const rightIconAnimation = getIconPopAnimation(rightIconRef.current);
      const resetRightIconAnimation = getIconResetAnimation(
        rightIconRef.current
      );

      const resetIcons = () => {
        void resetLeftIconAnimation.play();
        void resetRightIconAnimation.play();
      };

      const resetSlider = () => {
        style.transform = '';
        style.pointerEvents = 'all';
        style.transition = '';
      };

      const gesture: Gesture = createGesture({
        el: sliderRef.current,
        gestureName: 'swipe',
        threshold: 5,
        gesturePriority: 100,
        onStart: () => {
          style.transition = ``;
          style.pointerEvents = ' none';
          resetIcons();
        },
        onMove: (ev: GestureDetail) => {
          if (ev.deltaX > 0) {
            style.transform = `translate3d(${ev.deltaX}px, 0, 0)`;
            if (sliderRef.current) {
              sliderRef.current.style.backgroundColor = getBackgroundColor(
                toString(swipeRightOption?.variant)
              );
            }
          }
          if (ev.deltaX < 0) {
            style.transform = `translate3d(${ev.deltaX}px, 0, 0)`;
            if (sliderRef.current) {
              sliderRef.current.style.backgroundColor = getBackgroundColor(
                toString(swipeLeftOption?.variant)
              );
            }
          }
          if (ev.deltaX > SWIPE_THRESHOLD) {
            if (canAnimateLeftIcon) {
              void leftIconAnimation.play();
              void hapticsImpactLight();
              canAnimateLeftIcon = false;
            }
          }
          if (ev.deltaX < -SWIPE_THRESHOLD) {
            if (canAnimateRightIcon) {
              void rightIconAnimation.play();
              void hapticsImpactLight();
              canAnimateRightIcon = false;
            }
          }
          if (Math.abs(ev.deltaX) < SWIPE_THRESHOLD) {
            if (!canAnimateLeftIcon || !canAnimateRightIcon) {
              void hapticsImpactLight();
              void leftIconAnimation.stop();
              void rightIconAnimation.stop();
              canAnimateLeftIcon = true;
              canAnimateRightIcon = true;
            }
          }
        },
        onEnd: (ev: GestureDetail) => {
          style.transition = '0.2s ease-out';
          // Positive deltax indicates the inner item has moved to the right
          // A completed gesture with a positive deltax should be a right swipe
          if (ev.deltaX > SWIPE_THRESHOLD) {
            swipeRightOption?.onSwipeComplete?.call(null);
            if (swipeRightOption?.willPlayAnimation) {
              style.transform = `translate3d(${windowWidth}px, 0, 0)`;
              void swipeAnimation.play();
              void hapticsImpactLight();
            } else {
              resetSlider();
            }
            resetIcons();
          }
          // Negative deltax indicates the inner item has moved to the left
          // A completed gesture with a negative deltax should be a left swipe
          if (ev.deltaX < -SWIPE_THRESHOLD) {
            swipeLeftOption?.onSwipeComplete?.call(null);
            if (swipeLeftOption?.willPlayAnimation) {
              style.transform = `translate3d(${-windowWidth}px, 0, 0)`;
              void swipeAnimation.play();
              void hapticsImpactLight();
            } else {
              resetSlider();
            }
            resetIcons();
          }
          // An absolute value of deltax < SWIPE_THRESHOLD
          // means the user did not complete the gesture
          // The slider returns to the 0 position
          if (Math.abs(ev.deltaX) < SWIPE_THRESHOLD) {
            resetSlider();
          }
        },
      });
      gesture.enable();

      return () => {
        if (gesture) {
          gesture.destroy();
        }
      };
    }
    return () => undefined;
  }, [
    getBackgroundColor,
    hapticsImpactLight,
    swipeLeftOption?.onSwipeComplete,
    swipeLeftOption?.variant,
    swipeLeftOption?.willPlayAnimation,
    swipeRightOption?.onSwipeComplete,
    swipeRightOption?.variant,
    swipeRightOption?.willPlayAnimation,
  ]);

  return (
    <div ref={sliderRef} className={classes.slider}>
      {/* ICON DIV */}
      <div className={classes.iconContainer}>
        <div>
          {swipeRightOption && (
            <div ref={leftIconRef} className={classes.leftIcon}>
              <FontAwesomeIcon
                icon={swipeRightOption.icon as IconProp}
                className={classes.icon}
              />
            </div>
          )}
          {swipeLeftOption && (
            <div ref={rightIconRef} className={classes.rightIcon}>
              <FontAwesomeIcon
                icon={swipeLeftOption.icon as IconProp}
                className={classes.icon}
              />
            </div>
          )}
        </div>
      </div>
      <div
        ref={innerItemRef}
        className={classes.innerItem}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...bind}
      >
        {children}
      </div>
    </div>
  );
};

export default Slider;
