import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isEmpty, toString } from 'lodash';
import type { ShopQuoteReady } from 'ActivitiesApp/models/ShopQuote';
import InsetList from 'common/components/InsetList/InsetList';
import ShopModal from 'common/components/ShopModal/ShopModal';
import { and } from 'common/utils/logicHelpers';
import { DataTypeEnum } from 'common/utils/valueFormatter';
import { parseISO, isBefore } from 'date-fns';
import useTransmitDetails from 'ProductSearchApp/hooks/useTransmitLogDetails';
import type { ActionCardActivity } from 'models/ActivityModels';
import { formatCardDate } from 'utils/date';
import { removeLeadingZeros } from 'utils/number';
import {
  goToReviewCart,
  goToTransmissionLog,
} from 'navigation/navigationHelpers';
import Button from 'components/Button/Button';
import NavigationRow, {
  NavigationRowVariants,
} from 'components/NavigationRow/NavigationRow';
import Text from 'components/Text/Text';
import classes from './ShopQuoteForm.module.scss';

interface ShopQuoteFormProps {
  activity: ActionCardActivity;
}

const ShopQuoteForm = ({ activity }: ShopQuoteFormProps): JSX.Element => {
  const {
    shopMiLoc,
    itemDescription,
    workOrderCtlNo,
    orderCtlNo,
    shopName,
    cost,
    shopAddr1,
    shopAddr2,
    shopAddr3,
    shopCity,
    shopState,
    shopZip,
    country,
    shopPhone,
    shopEmail,
    workOrderQuoteComplete,
    lastDocumentTransmission,
  } = activity.extendedInfo as unknown as ShopQuoteReady;

  const { transmitMessage } = useTransmitDetails({
    transmissionMethod: lastDocumentTransmission?.transmissionMethod,
    transmissionStatus: lastDocumentTransmission?.transmissionStatus,
    creationUserId: lastDocumentTransmission?.creationUserId,
    creationUsername: lastDocumentTransmission?.creationUsername,
  });
  const [repairShopModalOpen, setRepairShopModalOpen] = useState(false);
  const { t } = useTranslation('ActivitiesApp-ShopQuote');
  const ocnReviewHref = goToReviewCart({
    miLoc: activity?.custMiLoc,
    shipToCustNo: activity?.custNo,
    orderCtlNo,
    ocnType: 'order',
    ocnMode: 'review',
  });

  const tranmissionLogHref = goToTransmissionLog({
    miLoc: activity?.custMiLoc,
    shipToCustNo: activity?.custNo,
    orderCtlNo,
    ocnType: 'order',
    ocnMode: 'review',
  });

  const insetData = [
    {
      label: t('itemDesc'),
      type: DataTypeEnum.string,
      value: itemDescription,
    },
    {
      label: t('workOrder'),
      type: DataTypeEnum.string,
      value: workOrderCtlNo,
    },
    {
      label: t('ocn'),
      type: DataTypeEnum.string,
      customValue: (
        <Button
          variant={activity?.userLocationMatch ? 'link' : 'clear'}
          testid="inset-order-ctl-no"
          text={removeLeadingZeros(orderCtlNo)}
          textVariant="list-item-title"
          href={activity?.userLocationMatch ? ocnReviewHref : undefined}
          className={classes.insetCustomValue}
        />
      ),
    },
    {
      label: t('motionShop'),
      type: DataTypeEnum.string,
      customValue: (
        <Button
          variant="link"
          testid="inset-shop-name"
          text={`${toString(shopMiLoc)} - ${toString(shopName)}`}
          textVariant="list-item-title"
          className={classes.insetCustomValue}
          onClick={() => setRepairShopModalOpen(true)}
        />
      ),
    },
    {
      label: t('dateQuoted'),
      type: DataTypeEnum.string,
      value: formatCardDate(activity?.creationTimestamp, false, false),
    },
    {
      label: t('cost'),
      type: DataTypeEnum.currency,
      value: cost,
    },
  ];

  const showOcnIndicator = () => {
    if (
      and(
        !isEmpty(lastDocumentTransmission),
        isBefore(
          parseISO(workOrderQuoteComplete),
          parseISO(lastDocumentTransmission?.transmissionTmstmp)
        )
      )
    ) {
      return (
        <NavigationRow
          text={transmitMessage}
          endCustomContent={
            <Button
              testid="view-transmission-log"
              variant="link"
              text={t('ActivitiesApp-ShopQuote:view')}
              disabled={!activity?.userLocationMatch}
            />
          }
          variant={NavigationRowVariants.iconLink}
          startCustomContent={
            <Button testid="mail-icon" icon={['fas', 'envelope']} />
          }
          className={classes.ocnIndicator}
          href={activity?.userLocationMatch ? tranmissionLogHref : undefined}
        />
      );
    }
    return (
      <Text
        variant="mipro-body-copy"
        text={t('shopQuoteDescription', {
          item: itemDescription,
          location: shopMiLoc,
          shop: shopName,
        })}
        className={classNames(classes.textBlock, classes.lineBreak)}
      />
    );
  };

  return (
    <div className={classes.detailCard}>
      {showOcnIndicator()}
      <InsetList
        testid="inset-list"
        data={insetData}
        className={classes.insetList}
      />
      {activity?.userLocationMatch && (
        <Button
          className={classes.linkButton}
          variant="secondary"
          text={t('viewOcn')}
          testid="view-ocn-button"
          href={ocnReviewHref}
        />
      )}
      <ShopModal
        shop={{
          shopAddr1,
          shopAddr2,
          shopAddr3,
          shopCity,
          shopState,
          shopZip,
          country,
          shopPhone,
          shopEmail,
          shopMiLoc,
          shopName,
        }}
        repairShopModalOpen={repairShopModalOpen}
        setRepairShopModalOpen={setRepairShopModalOpen}
      />
    </div>
  );
};

export default ShopQuoteForm;
