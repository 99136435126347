import React from 'react';
import { toString } from 'lodash';
import { FormikSelectModal } from 'common/components/SelectModal/SelectModal';
import { or } from 'common/utils/logicHelpers';
import type { IndustrySegment } from 'HomeApp/ProTips/api/useFindIndustrySegments';
import useFindIndustrySegments from 'HomeApp/ProTips/api/useFindIndustrySegments';

interface IndustrySegmentsSelectProps
  extends Partial<
    React.ComponentProps<typeof FormikSelectModal<IndustrySegment>>
  > {
  name: string;
}

const IndustrySegmentsSelect = ({ ...props }: IndustrySegmentsSelectProps) => {
  const testid = or(props.testid, 'industry-segments-input');
  const modalTestid = or(props.modalTestid, 'industry-segments-modal');

  const { industrySegments, ...queryProps } = useFindIndustrySegments();

  return (
    <FormikSelectModal<IndustrySegment>
      items={industrySegments}
      getId={(i) => toString(i.id)}
      getName={(i) => i.industryName}
      getDescription={(i) => i.sicCode}
      getSearchMatch={(i, q) =>
        or(
          new RegExp(q, 'i').test(i.industryName),
          new RegExp(q, 'i').test(i.sicCode)
        )
      }
      queryProps={queryProps}
      testid={testid}
      modalTestid={modalTestid}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default IndustrySegmentsSelect;
