import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { get, isEmpty, isNil, map, reduce, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import useFindDataCodes from 'api/data/useFindDataCodes';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type {
  ActionCardActivity,
  ActivityFieldData,
} from 'models/ActivityModels';
import { ActivityType } from 'models/ActivityModels';
import type { InfiniteQueryFlags } from 'models/Search';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';
import { DateFormatEnum, formatDate } from 'utils/date';
import { getIsCorpAccount } from 'utils/search';
import type { CodeListOptionProps } from 'pages/Activities/ActivityActionCard/ActivityCardConfig';

export const findActivitiesQueryKey = 'user-activities';

export const reduceFieldsData = (
  fields: ActivityFieldData[] = []
): Dictionary<unknown> =>
  reduce(fields, (prev, field) => ({ ...prev, [field.name]: field.value }), {});

interface UseFindActivitiesProps {
  miLoc?: string;
  id?: string;
  natlAccNo?: string;
  filter?: Record<string, unknown>;
  fetchOtherUsers?: boolean;
  visitContact?: string;
  endDate?: number;
  startDate?: number;
  sortField?: string;
  sortDir?: string;
  enabled?: boolean;
  groupSortField?: string;
  limit?: number;
  enabledDataCodesQuery?: boolean;
  hideVisits?: boolean;
  includeOthers?: boolean;
}

interface UseFindActivitiesResponse {
  activities: ActionCardActivity[];
  codeList?: CodeListOptionProps[];
}

const useFindActivities = ({
  miLoc,
  id,
  natlAccNo,
  filter,
  fetchOtherUsers = false,
  visitContact,
  endDate,
  startDate,
  sortField = 'showAfter',
  sortDir = 'DESCENDING',
  groupSortField,
  enabled = true,
  limit = pageSize(),
  enabledDataCodesQuery,
  includeOthers = false,
  hideVisits = false,
}: UseFindActivitiesProps): InfiniteQueryFlags & UseFindActivitiesResponse => {
  const { axios } = useAxios();
  const { findActivitiesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const params: Record<string, string> = {
    sortField,
    sortDir,
    limit: toString(limit),
    ...filter,
  };

  if (miLoc) {
    params.custMiLoc = miLoc;
  }
  if (id) {
    if (natlAccNo && getIsCorpAccount(id, natlAccNo)) {
      params.nationalAcctNo = natlAccNo;
    } else {
      params.custNo = id;
    }
  }
  if (includeOthers) {
    params.distinctHistoryId = toString(true);
  } else {
    params.userId = userId;
  }
  if (fetchOtherUsers) {
    delete params.userId;
  }
  if (isNil(params.isDone)) {
    delete params.isDone;
  }
  if (visitContact) {
    params.visitContact = visitContact;
  }
  if (endDate) {
    params.endDate = formatDate(endDate, DateFormatEnum.activitiesDateRangeAPI);
  }
  if (startDate) {
    params.startDate = formatDate(
      startDate,
      DateFormatEnum.activitiesDateRangeAPI
    );
  }
  if (groupSortField) {
    params.groupSortField = groupSortField;
  }

  params.useExtendedInfo = toString(false);
  params.extendedInfoEventTagName = toString(
    `${ActivityType.customerVisit},${ActivityType.customerVisitedByNonRep},${ActivityType.crmTask},${ActivityType.crmOpportunity},${ActivityType.salesOpportunity}`
  );

  if (hideVisits) {
    params.excludedEventTagName = toString(`${ActivityType.customerVisit}`);
  }

  const doFindActivities = ({ pageParam = 1 }) => {
    return doPromiseAPI<ActionCardActivity[]>(async (cancelToken) => {
      const paramsGET = new URLSearchParams({
        ...params,
        page: toString(pageParam),
      });
      const { data } = await axios.get<ActionCardActivity[]>(
        findActivitiesAPI(toString(paramsGET)),
        { cancelToken }
      );
      return map(data, (activity) => ({
        ...activity,
        fieldsData: reduceFieldsData(activity.fields),
        children: map(activity.children, (ev) => ({
          ...ev,
          fieldsData: reduceFieldsData(ev.fields),
        })),
      }));
    });
  };

  const response = useInfiniteQuery<ActionCardActivity[], AxiosError>(
    createQueryKey(findActivitiesQueryKey, params),
    doFindActivities,
    {
      enabled: enabled && !isEmpty(userId),
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const { data: codeList, isLoading: isCodesLoading } = useFindDataCodes({
    codeType: 'CUCONTYP',
    enabled: enabledDataCodesQuery,
  });

  const codeListOptions = map(codeList, ({ id: codeId, codeName }) => ({
    id: codeId,
    text: codeName,
  }));

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
  } = response;

  const activities = useMemo(
    () => doConcatDataPages<ActionCardActivity>(data),
    [data]
  );

  const hasItems = size(activities) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader =
    status === 'loading' || isCodesLoading || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    activities,
    error,
    hasError,
    isFetching,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch();
    },
    codeList: codeListOptions,
  };
};

export default useFindActivities;
