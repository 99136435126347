import { toString } from 'lodash';
import { Preferences } from '@capacitor/preferences';

export enum StorageKey {
  USER_ROLE_KEY = 'UserRole',
  USER_NAME_KEY = 'username',
}

const Storage = {
  async get<T>(key: StorageKey, parse = false): Promise<T | null> {
    const { value } = await Preferences.get({ key });
    if (!value) return null;
    return parse ? (JSON.parse(value) as T) : (value as T);
  },
  async set<T>(key: StorageKey, value: T, stringify = false): Promise<void> {
    await Preferences.set({
      key,
      value: stringify ? JSON.stringify(value) : toString(value),
    });
  },
  async remove(key: StorageKey, cb?: (key: StorageKey) => void): Promise<void> {
    await Preferences.remove({ key });
    cb?.(key);
  },
  async clear(cb?: VoidFunction): Promise<void> {
    await Preferences.clear();
    cb?.();
  },
};

export default Storage;
