import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  filter,
  find,
  isEmpty,
  kebabCase,
  map,
  size,
  toString,
  xorBy,
} from 'lodash';
import type {
  IconProp,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';
import { choose } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import classes from './ProTipFilter.module.scss';

export interface ProTipFilterOption {
  key: string | number;
  name: string;
  isDefault?: boolean;
  id?: string;
  icon?: IconName;
  status?: string;
  type?: string;
  iconPrefix?: IconPrefix;
  customContent?: React.ReactNode;
}

interface ProTipFilterProps {
  customButton?: React.ComponentProps<typeof Button>;
  customContent?: React.ReactNode;
  selectedItems?: ProTipFilterOption[];
  filterOptions: {
    title: string;
    options: ProTipFilterOption[];
    hidden?: boolean;
    multipleSelection?: boolean;
  };
  setFilterData?: (option?: ProTipFilterOption[]) => void;
  testid: string;
  toggleOnSelection?: boolean;
  modalTitle?: string;
  allowMultiple?: boolean;
}

const ProTipFilter = ({
  isOpen,
  setIsOpen,
  initialBreakpoint = 0.65,
  customButton,
  customContent: pCustomContent,
  className,
  selectedItems,
  filterOptions,
  setFilterData,
  testid,
  toggleOnSelection = false,
  modalTitle,
  allowMultiple = false,
}: ProTipFilterProps &
  Omit<React.ComponentProps<typeof SheetModal>, 'title'>): JSX.Element => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] =
    useState<Array<ProTipFilterOption | undefined>>();

  useEffect(() => {
    if (isOpen) {
      setSelectedOptions(selectedItems);
    }
  }, [isOpen, selectedItems]);

  const hasSelectedItems = size(filter(selectedItems, (o) => !!o)) > 0;
  const filterText = choose(
    hasSelectedItems,
    map(selectedItems, (o) => toString(o?.name)).join(' '),
    t('filter')
  );

  const disabledFilter = isEmpty(
    choose(!filterOptions.hidden, filterOptions.options)
  );

  const handleSelection = (option: ProTipFilterOption) => {
    if (allowMultiple) {
      if (option.key === 'All') {
        // doc: set the selected option and remove other previous selected options.
        setSelectedOptions([find(filterOptions.options, { key: 'All' })]);
        return;
      }
      // doc: set the selected option and unselect the All value.
      setSelectedOptions((prev = []) => {
        const listWithoutTheAllValue = filter(prev, (op) => op?.key !== 'All');
        return xorBy(listWithoutTheAllValue, [option], 'key');
      });
      return;
    }
    setIsOpen?.(false);
    setSelectedOptions([option]);
  };

  return (
    <div className={className}>
      <Button
        className={classNames(classes.button, {
          [classes.filterSelected]: hasSelectedItems,
        })}
        onClick={() => {
          choose(
            toggleOnSelection && hasSelectedItems,
            () => setFilterData?.([]),
            () => setIsOpen?.(true)
          )?.();
        }}
        icon={
          choose(
            hasSelectedItems,
            ['far', 'times'],
            ['far', 'filter']
          ) as IconProp
        }
        text={filterText}
        testid={`${testid}-open-filter`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...customButton}
      />
      <SheetModal
        title={modalTitle ?? t('filter')}
        className={classes.filterSheet}
        isOpen={isOpen}
        withRightCloseButton
        setIsOpen={setIsOpen}
        initialBreakpoint={initialBreakpoint}
        testid={`${testid}-modal`}
      >
        <div className={classes.content}>
          {pCustomContent}
          <div
            className={classNames({
              [classes.hideOption]: filterOptions.hidden,
            })}
          >
            <Text
              className={classes.sectionTitle}
              variant="list-item-subtitle"
              text={filterOptions.title}
              testid={`${filterOptions.title}-section-title`}
            />
            <div>
              {map(
                filterOptions.options,
                (
                  { key, name, icon, customContent, iconPrefix },
                  optionIndex
                ) => {
                  const isSelected = choose(
                    allowMultiple,
                    selectedOptions?.some((item) => item?.key === key),
                    key === selectedOptions?.[0]?.key
                  );
                  const validIconType = iconPrefix ?? 'far';

                  return (
                    <Button
                      key={`${kebabCase(name)}-${optionIndex}`}
                      className={classNames(classes.filterRow, {
                        [classes.optionSelected]: isSelected,
                      })}
                      variant={choose(isSelected, 'action', 'clear')}
                      icon={choose<IconProp>(icon, [
                        validIconType,
                        icon as IconName,
                      ])}
                      text={name}
                      textVariant="mipro-h6-headline"
                      onClick={() =>
                        handleSelection(filterOptions.options[optionIndex])
                      }
                      testid={`${testid}-${kebabCase(name)}`}
                    >
                      {customContent}
                    </Button>
                  );
                }
              )}
              {disabledFilter && (
                <Loader
                  isOpen={disabledFilter}
                  testid="filter-loader"
                  text={t(`${namespaces.common}:filterLoader`)}
                />
              )}
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.buttonBar}>
            <Button
              variant="secondary"
              text={t('common:clear')}
              testid="pro-tip-clear-filter"
              className={classes.fullWidth}
              onClick={() => {
                setSelectedOptions([]);
              }}
            />
            <Button
              variant="action"
              text={t('common:apply')}
              testid="pro-tip-apply-filter"
              className={classes.fullWidth}
              onClick={() => {
                setIsOpen?.(false);
                setFilterData?.(selectedOptions as ProTipFilterOption[]);
              }}
            />
          </div>
        </div>
      </SheetModal>
    </div>
  );
};

export default ProTipFilter;
