import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { toString } from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { IonItem, IonRow } from '@ionic/react';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import useGetNotificationCardConfig from 'HomeApp/Notifications/api/useGetNotificationActionCardConfig';
import type { NotificationItem } from 'HomeApp/Notifications/models/Notifications';
import useMarkInAppNotificationAsRead from 'api/notifications/useMarkInAppNotificationsAsRead';
import { formatCardDate } from 'utils/date';
import CardAvatar from 'components/CardAvatar/CardAvatar';
import Text from 'components/Text/Text';
import classes from './NotificationActionCard.module.scss';

interface NotificationCardProps extends BaseComponentProps {
  notificationItem: NotificationItem;
}

const NotificationActionCard = ({
  notificationItem,
  testid,
}: NotificationCardProps) => {
  const { title, description, icon, iconColor, href } =
    useGetNotificationCardConfig({
      notificationItem,
    });
  const { read, creationTimestamp, id, isHighlighted } = notificationItem;
  const history = useHistory();
  const { onMarkAsRead } = useMarkInAppNotificationAsRead();

  return (
    <IonItem
      className={classNames(classes.notificationCard, {
        [classes.highlighted]: isHighlighted,
      })}
      onClick={() => {
        history.push(toString(href));
        onMarkAsRead({ notificationId: toString(id) });
      }}
      data-testid={testid}
    >
      <div className={classes.circleWrapper}>
        <div className={classNames(classes.dot, { [classes.unread]: !read })} />
        <CardAvatar
          icon={icon as IconName}
          name={icon}
          color={toString(iconColor)}
          transform="shrink-3"
          testid="icon"
        />
      </div>
      <IonRow className={classes.row}>
        <div className={classes.titleWrapper}>
          {title}
          <Text
            text={formatCardDate(creationTimestamp, false, true)}
            className={classes.aging}
            variant="label-micro"
          />
        </div>
        {description}
      </IonRow>
    </IonItem>
  );
};

export default NotificationActionCard;
