import React, { useEffect, useMemo, useState } from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import { orderBy, find, filter, includes, isEmpty, map, get } from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonContent, IonPage, IonRow } from '@ionic/react';
import Header from 'common/components/Header/Header';
import { and, choose, or } from 'common/utils/logicHelpers';
import { isAccountRep, useGetAvpUser } from 'common/utils/userInfo';
import { namespaces } from 'i18n/i18n.constants';
import { reportsURL, reportsDrillDownURL } from 'navigation';
import useDisablePick12 from 'ReportsApp/hooks/useDisablePick12';
import {
  goToCostSavingsReport,
  goToEndOfMonthReport,
  goToOpenQuotesReport,
  goToProTipsReport,
  goToSalesPerformanceReport,
  goToUnbilledReport,
} from 'ReportsApp/navigation/navigationHelpers';
import useGetUserConfig from 'api/user/useGetUserConfig';
import useUpdateUserConfig from 'api/user/useUpdateUserConfig';
import {
  AccessControlType,
  useHasAccessControls,
} from 'hooks/useAccessControls';
import {
  ReportTypesWithIcon,
  ReportUserConfigTypeEnum,
} from 'models/UserConfig';
import type { UserConfigs } from 'models/UserConfig';
import type { RootState } from 'store/reducers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import ReportTile from './components/ReportTile/ReportTile';
import classes from './NewReports.module.scss';

interface ReportGridProp {
  icon?: string;
  label: string;
  id: ReportUserConfigTypeEnum;
  sortOrder: number;
  accessControl?: AccessControlType;
  hidden?: boolean;
}

interface GridProp {
  left: ReportGridProp[];
}

const reportsIconNames: Dictionary<string> = {
  [ReportUserConfigTypeEnum.sales]: 'chart-pie-alt',
  [ReportUserConfigTypeEnum.pick12Leaderboard]: 'trophy-alt',
  /* [ReportUserConfigTypeEnum.salesPlays]: 'badge-dollar',
  [ReportUserConfigTypeEnum.opsReports]: 'chart-bar',
  [ReportUserConfigTypeEnum.webActivity]: 'browser', */
};

const NewReports = (): JSX.Element => {
  const { isLoading, data } = useGetUserConfig({
    configType: 'reports',
    enabled: true,
  });
  const { onUpdateUserConfig } = useUpdateUserConfig({ configType: 'reports' });
  const { t } = useTranslation(namespaces.reports);
  const { userInfo, isCamUser } = useSelector((state: RootState) => state.user);
  const jobCode = get(userInfo, 'jobCode');
  const { isAvpUserWithExecView } = useGetAvpUser();
  const isPick12Disabled = useDisablePick12();

  const reportsAccessControl = (reportId: string) => {
    if (reportId === ReportUserConfigTypeEnum.costSavings) {
      return AccessControlType.CostSavingsAccessControls;
    }
    if (reportId === ReportUserConfigTypeEnum.unbilled) {
      return AccessControlType.ViewUnbilledReport;
    }
    if (
      and(
        or(isCamUser, isAvpUserWithExecView),
        includes(
          [
            ReportUserConfigTypeEnum.sales,
            ReportUserConfigTypeEnum.endOfMonth,
            ReportUserConfigTypeEnum.webPerformance,
          ],
          reportId
        )
      )
    ) {
      return AccessControlType.camUserReports;
    }
    return AccessControlType.viewReports;
  };

  const disablePick12AndLeaderBoard = (reportId: ReportUserConfigTypeEnum) => {
    return and(
      isPick12Disabled,
      or(
        reportId === ReportUserConfigTypeEnum.pick12Detail,
        reportId === ReportUserConfigTypeEnum.pick12Leaderboard
      )
    );
  };

  const defaultGridItems: ReportGridProp[] = useMemo(
    () =>
      map(
        [
          ReportUserConfigTypeEnum.sales,
          ReportUserConfigTypeEnum.webPerformance,
          ReportUserConfigTypeEnum.pick12Detail,
          ReportUserConfigTypeEnum.pick12Leaderboard,
          ReportUserConfigTypeEnum.endOfMonth,
          ReportUserConfigTypeEnum.costSavings,
          ReportUserConfigTypeEnum.unbilled,
          ReportUserConfigTypeEnum.openQuotes,
          ReportUserConfigTypeEnum.proTips,
        ],
        (reportId, index) => ({
          icon: reportsIconNames[reportId],
          label: t(`userConfigs:${reportId}`),
          id: reportId,
          sortOrder: index,
          accessControl: reportsAccessControl(reportId),
          hidden: or(
            and(
              reportId === ReportUserConfigTypeEnum.unbilled,
              isAccountRep(jobCode)
            ),
            disablePick12AndLeaderBoard(reportId)
          ),
        })
      ),
    // TODO should we keep it or remove it permanently
    //   {
    //   icon: 'badge-dollar',
    //   label: t(`userConfigs:${ReportUserConfigTypeEnum.salesPlays}`),
    //   id: ReportUserConfigTypeEnum.salesPlays,
    //   sortOrder: 5,
    // },
    // {
    //   icon: 'chart-bar',
    //   label: t(`userConfigs:${ReportUserConfigTypeEnum.opsReports}`),
    //   id: ReportUserConfigTypeEnum.opsReports,
    //   sortOrder: 6,
    // },
    // {
    //   icon: 'browser',
    //   label: t(`userConfigs:${ReportUserConfigTypeEnum.webActivity}`),
    //   id: ReportUserConfigTypeEnum.webActivity,
    //   sortOrder: 7,
    // },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobCode, t]
  );
  const { hasAccessControl } = useHasAccessControls();
  const [allDraggable, setAllDraggable] = useState<
    'Start' | 'Customize' | 'Save' | 'Cancel'
  >('Start');
  const { miLoc: stateMiLoc = '', locationTree } = useSelector(
    (state: RootState) => state.user
  );

  const stateLocation = locationTree?.[stateMiLoc];
  const fromVirtualTeam = stateMiLoc === 'VT';
  const team = stateLocation?.locationType === 'T';
  const pick12 = fromVirtualTeam || team;
  const pick12Url = concatRoutes(
    reportsURL(),
    reportsDrillDownURL('pick12', '', '', '', '')
  );

  const loctionPick12Url = concatRoutes(
    reportsURL(),
    reportsDrillDownURL('locationPick12', '', '', '', '')
  );

  const [warningPlaceHolder, setWarningPlaceHolder] = useState<boolean>(false);

  const [items, setItems] = useState<GridProp>({ left: [] });
  const [defaultItems, setDefaultItems] = useState<GridProp>({ left: [] });

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const reportsGridItems: ReportGridProp[] = map(
      data?.report,
      ({ reportId, sortOrder }) => ({
        id: reportId,
        sortOrder,
        accessControl: reportsAccessControl(reportId),
        label: t(`userConfigs:${reportId}`),
        icon: reportsIconNames[reportId],
        hidden: or(
          and(
            reportId === ReportUserConfigTypeEnum.unbilled,
            isAccountRep(jobCode)
          ),
          disablePick12AndLeaderBoard(reportId)
        ),
      })
    );
    const newItems = filter(
      defaultGridItems,
      (item) => !find(reportsGridItems, { id: item.id })
    );

    const gridItems: GridProp = {
      left: orderBy(
        filter(
          isEmpty(reportsGridItems)
            ? defaultGridItems
            : [...reportsGridItems, ...newItems],
          ({ accessControl, hidden }) =>
            hasAccessControl(accessControl) && !hidden
        ),
        'sortOrder'
      ),
    };
    setItems(gridItems);
    setDefaultItems(gridItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (allDraggable === 'Save' || allDraggable === 'Cancel') {
      setAllDraggable('Start');
    }
  }, [allDraggable]);

  const title = t('common:reports');

  const onChange = (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number
  ) => {
    if (allDraggable === 'Customize') {
      const result = swap(items?.left, sourceIndex, targetIndex);
      setItems({ ...items, [sourceId]: result });
    }
    return {};
  };

  const onSave = () => {
    setAllDraggable('Save');
    const updatedGridItems = items?.left.map(({ id }, index) => ({
      sortOrder: index,
      reportId: id,
    }));
    const report: UserConfigs = { report: updatedGridItems };
    onUpdateUserConfig(report);
  };

  const history = useHistory();

  const onGridClick = (id: string) => {
    if (allDraggable === 'Customize') {
      return;
    }
    let path = '';
    switch (id) {
      case ReportUserConfigTypeEnum.sales:
        path = goToSalesPerformanceReport({});
        break;
      case ReportUserConfigTypeEnum.pick12Detail:
        path = pick12 ? pick12Url : loctionPick12Url;
        break;
      case ReportUserConfigTypeEnum.pick12Leaderboard:
        path = concatRoutes(
          reportsURL(),
          reportsDrillDownURL('pick12LeaderBoard', '', '', '', '')
        );
        break;
      case ReportUserConfigTypeEnum.webPerformance:
        path = concatRoutes(
          reportsURL(),
          reportsDrillDownURL('webSales', '', '', '', '')
        );
        break;
      case ReportUserConfigTypeEnum.endOfMonth:
        path = goToEndOfMonthReport();
        break;
      case ReportUserConfigTypeEnum.costSavings:
        path = goToCostSavingsReport({});
        break;
      case ReportUserConfigTypeEnum.unbilled:
        path = goToUnbilledReport({});
        break;
      case ReportUserConfigTypeEnum.openQuotes:
        path = goToOpenQuotesReport();
        break;
      case ReportUserConfigTypeEnum.proTips:
        path = goToProTipsReport();
        break;
      default:
        path = '';
    }
    if (path) {
      history.push(path);
    } else {
      setWarningPlaceHolder(true);
    }
  };

  return (
    <IonPage className={classes.reports} data-testid="Reports">
      <Header
        hideBackButton
        className={classes.header}
        title={title}
        testid="grid-reports"
        showLocationText
      />
      <IonContent className={classNames(classes.content)}>
        <GridContextProvider onChange={onChange}>
          <div className={classes.container}>
            <Loader className={classes.loader} isOpen={isLoading} />
            {!isLoading && (
              <GridDropZone
                className={classNames(classes.dropzone, classes.left)}
                id="left"
                boxesPerRow={3}
                rowHeight={120}
                disableDrag={allDraggable === 'Start'}
              >
                {items?.left.map(({ label, icon, id }) => (
                  <GridItem className={classes.gridCont} key={id}>
                    {allDraggable === 'Customize' && (
                      <div className={classes.dragCircle}>
                        <FontAwesomeIcon
                          icon={findIcon('arrows' as IconName, 'far')}
                          className={classes.dragIcon}
                        />
                      </div>
                    )}
                    <ReportTile
                      id={id}
                      label={label}
                      icon={choose(includes(ReportTypesWithIcon, id), icon)}
                      onClick={onGridClick}
                      className={classes.gridItem}
                    />
                  </GridItem>
                ))}
              </GridDropZone>
            )}
          </div>
        </GridContextProvider>
        {!isLoading && allDraggable === 'Start' && (
          <Button
            text={t('common:customize')}
            variant="secondary"
            className={classNames(classes.customize, classes.button)}
            testid="customize-button"
            onClick={() => {
              setAllDraggable('Customize');
            }}
          />
        )}
        {!isLoading && allDraggable !== 'Start' && (
          <IonRow>
            <Button
              text={t('common:save')}
              variant="action"
              className={classNames(classes.save, classes.button)}
              testid="save-button"
              onClick={onSave}
            />
            <Button
              text={t('common:cancel')}
              variant="secondary"
              className={classNames(classes.customize, classes.button)}
              testid="cancel-button"
              onClick={() => {
                setAllDraggable('Start');
                setItems(defaultItems);
              }}
            />
          </IonRow>
        )}
        {warningPlaceHolder && (
          <Modal
            isOpen={warningPlaceHolder}
            setIsOpen={setWarningPlaceHolder}
            title="Upgrade App"
            testid="warning-holder"
            withCloseButton={false}
            modalClassName={classes.warningModal}
            className={classes.placeholder}
            headerClassName={classes.placelhoderHeader}
          >
            <WarningMessage
              className={classes.warningMessage}
              icon={['far', 'info-circle']}
              title="Please upgrade your application version."
            />
          </Modal>
        )}
      </IonContent>
    </IonPage>
  );
};

export default NewReports;
