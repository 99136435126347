import React from 'react';
import classNames from 'classnames';
import { debounce, isArray, isNil, map } from 'lodash';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonButton, IonCol, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { or } from 'common/utils/logicHelpers';
import type { FirebaseButtonClickParams } from 'utils/firebaseAnalytics';
import { logFirebaseEvent } from 'utils/firebaseAnalytics';
import { withStringProp } from 'utils/helpers';
import type { TextVariantType } from 'components/Text/Text';
import Text from 'components/Text/Text';
import classes from './Button.module.scss';

export enum ButtonVariantEnum {
  clear = 'clear',
  'icon-action' = 'icon-action',
  action = 'action',
  secondary = 'secondary',
  underlined = 'underlined',
  link = 'link',
  filter = 'filter',
  'mipro-text-button' = 'mipro-text-button',
  'mipro-owner-link' = 'mipro-owner-link',
  'mipro-danger-default' = 'mipro-danger-default',
  'mipro-action' = 'mipro-action',
}

export type ButtonVariantType = keyof typeof ButtonVariantEnum;

interface ButtonProps {
  variant?: ButtonVariantEnum | ButtonVariantType | ButtonVariantType[];
  textVariant?: TextVariantType;
  text?: string;
  icon?: IconProp; // defaults to leftIcon
  leftIcon?: IconProp;
  rightIcon?: IconProp;
  onClick?: (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
  isExternalLink?: boolean;
  testid: string;
  debounceClick?: boolean;
  iconClassName?: string;
  textClassName?: string;
  track?: boolean;
  id?: string;
  googleAnalyticsId?: string;
}

type WrapperProps = Pick<ButtonProps, 'variant'>;

const Wrapper = ({
  variant,
  children,
}: React.PropsWithChildren<WrapperProps>): JSX.Element => {
  if (variant === 'icon-action') {
    return (
      <IonRow className={classes.wrapper}>
        <IonCol>{children}</IonCol>
      </IonRow>
    );
  }
  return <>{children}</>;
};

const Button = ({
  slot,
  className,
  iconClassName,
  textClassName,
  variant = 'clear',
  textVariant,
  type = 'button',
  href: propsHref,
  routerDirection,
  disabled,
  text = '',
  icon,
  leftIcon,
  rightIcon,
  onClick,
  children,
  tabIndex = undefined,
  isExternalLink = false,
  testid,
  track = true,
  // TODO: should be false by default and only set to true when needed
  debounceClick = false,
  color,
  id,
  googleAnalyticsId,
}: ButtonProps &
  React.ComponentProps<typeof IonButton> &
  IonicReactProps): JSX.Element => {
  const withText = withStringProp(text);
  const withLeftIcon = !isNil(icon) || !isNil(leftIcon);
  const withRightIcon = !isNil(rightIcon);
  const withTabIndex = !isNil(tabIndex);
  let buttonLeftIcon = icon;
  const href = window.location.pathname !== propsHref ? propsHref : undefined;

  if (leftIcon) {
    buttonLeftIcon = leftIcon;
  }

  let variants;
  if (isArray(variant)) {
    variants = variant;
  } else {
    variants = [variant];
  }

  const onClickWithTracking = (
    e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) => {
    if (track) {
      const params: FirebaseButtonClickParams = {
        href,
        routerDirection,
        variant,
        type,
        testid: or(googleAnalyticsId, testid),
      };
      logFirebaseEvent('button_click', params);
    }
    onClick?.(e);
  };

  let buttonClick = onClickWithTracking;
  if (debounceClick) {
    buttonClick = debounce(onClickWithTracking, 300);
  }

  return (
    <IonButton
      className={classNames(
        classes.button,
        className,
        map(variants, (v) => classes[v]),
        { [classes.disabled]: disabled }
      )}
      type={type}
      href={href}
      routerLink={!isExternalLink ? href : undefined}
      routerDirection={routerDirection}
      disabled={disabled}
      onClick={buttonClick}
      data-testid={testid}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...{
        ...(withTabIndex ? { tabIndex } : null),
        ...(slot ? { slot } : null),
        ...(id ? { id } : null),
      }}
      color={color}
    >
      <Wrapper variant={variant}>
        {withLeftIcon && (
          <FontAwesomeIcon
            className={classNames(classes.icon, iconClassName, {
              [classes.leftIconWithText]: withText || !isNil(children),
            })}
            icon={buttonLeftIcon as IconProp}
          />
        )}
        {children}
        {withText && (
          <Text
            className={classNames(classes.text, textClassName)}
            variant={
              textVariant ||
              (variant === 'icon-action' ? 'label-micro' : 'content-heavy')
            }
            text={text}
          />
        )}
        {withRightIcon && (
          <FontAwesomeIcon
            className={classNames(classes.icon, iconClassName, {
              [classes.rightIconWithText]: withText || !isNil(children),
            })}
            icon={rightIcon}
          />
        )}
      </Wrapper>
    </IonButton>
  );
};

export default Button;
