import { flatMap, split, trim } from 'lodash';
import type { BaseQueryProps } from 'common/api/utils/apiHelpers';
import { getURLParams } from 'common/api/utils/apiHelpers';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { useAxios } from 'providers/AxiosProvider';
import { pageSize } from 'utils/constants';
import { findProTipsAPI } from '.';

export const findProTipsQueryKey = 'find-protips';

export interface ProTip {
  details: string;
  industryId: number[];
  likeCount: number;
  mfrCtlNo: string;
  reqEmployeeName: string;
  revenue: number;
  searchScore: number;
  subject: string;
  tipId: number;
  viewCount: number;
}

interface UseFindProTipProps {
  custNo: string;
  custMiLoc: string;
  industryIds?: string[];
}

const useFindProTips = ({
  query,
  custMiLoc,
  custNo,
  limit = pageSize(),
  industryIds,
  enabled,
}: UseFindProTipProps & BaseQueryProps) => {
  const { axios } = useAxios();
  const params = {
    searchQuery: query,
    custMiLoc,
    custNo,
    limit,
    industryId: flatMap(industryIds, (segment) =>
      split(segment, ',').map(trim)
    ),
  };

  const response = useQuerySetup<ProTip, ProTip[]>({
    queryKey: findProTipsQueryKey,
    queryParams: params,
    isInfiniteQuery: true,
    dataPath: 'rows',
    totalDataPath: 'totalRows',
    enabled,
    onAPIRequest: async ({ pageParam = 1, signal }) => {
      const { data } = await axios.get<ProTip[]>(
        findProTipsAPI(getURLParams({ ...params, page: pageParam })),
        { signal }
      );
      return data;
    },
  });

  return { proTips: response.items, ...response };
};

export default useFindProTips;
