interface ReportConfig {
  reportId: ReportUserConfigTypeEnum;
  sortOrder: number;
}

export interface UserConfigs {
  report?: ReportConfig[];
  currency?: string;
}

export enum ReportUserConfigTypeEnum {
  sales = 'sales',
  pick12Detail = 'pick12Detail',
  pick12Leaderboard = 'pick12Leaderboard',
  webPerformance = 'webPerformance',
  endOfMonth = 'endOfMonth',
  costSavings = 'costSavings',
  unbilled = 'unbilled',
  openQuotes = 'openQuotes',
  proTips = 'proTips',
  // salesPlays = 'salesPlays',
  // webActivity = 'webActivity',
}

export const ReportTypesWithIcon = [
  ReportUserConfigTypeEnum.sales,
  ReportUserConfigTypeEnum.pick12Leaderboard,
  /* ReportUserConfigTypeEnum.salesPlays,
  ReportUserConfigTypeEnum.opsReports,
  ReportUserConfigTypeEnum.webActivity, */
];
