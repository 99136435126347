import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { toNumber, type Dictionary } from 'lodash';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { IconName } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  NavContext,
} from '@ionic/react';
import { formatNotificationCount } from 'common/utils/numberHelper';
import {
  homeURL,
  homeURLDefinition,
  reportsURL,
  reportsURLDefinition,
  searchURL,
  searchURLDefinition,
  tabsURL,
  activitiesURL,
  activitiesURLDefinition,
  documentsURL,
  documentsURLDefinition,
  replenishmentViewURL,
  replenishmentViewURLDefinition,
  workOrdersURL,
  workOrdersURLDefinition,
  issueViewURL,
  issueViewURLDefinition,
} from 'navigation';
import { useTabReset } from 'providers/TabResetProvider';
import useGetUnreadNotificationsCount from 'api/user/useGetUnreadNotificationsCount';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import useChangeAppMode from 'hooks/useChangeAppMode';
import useFeatureFlags, { FeatureFlagType } from 'hooks/useFeatureFlags';
import type { RootState } from 'store/reducers';
import {
  ACTIVITY,
  DOCUMENTS,
  HOME,
  ISSUE_PROCESSING,
  REPLENISHMENTS,
  REPORTS,
  SEARCH,
  WORKORDERS,
} from 'utils/constants';
import defaultAppUrl from 'utils/defaultAppUrl';
import { findIcon } from 'utils/icons';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import classes from './TabNavigator.module.scss';
import {
  activitiesRouter,
  getRoutes,
  homeRouter,
  reportsRouter,
  searchRouter,
  documentsRouter,
  replenishmentViewRouter,
  workOrdersRouter,
  issueViewRouter,
} from './TabRouterConfig';

const getTabIcon = (url: string, icon: IconName) => (pathname: string) =>
  findIcon(icon, pathname.indexOf(url) !== -1 ? 'fas' : 'far');

export interface TabProps {
  name: string;
  href: string;
  label: string;
  icon: (pathname: string) => IconProp;
  tab: string;
  hidden?: boolean;
  skipReset?: boolean;
  badge?: number;
}

const TabNavigator = (): JSX.Element => {
  const history = useHistory();
  const { resetTab } = useContext(NavContext);
  const { deeplink } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (deeplink) {
        history.push(deeplink.url);
      }
      // DOC: timeout to give login time to close loader
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deeplink]);

  const documentsAccessControl = useAccessControls(
    AccessControlType.viewDocuments
  );
  const replenishmentsAccessControl = useAccessControls(
    AccessControlType.viewItemReplenishment
  );
  const issueProcessingAccessControl = useAccessControls(
    AccessControlType.viewIssueProcessing
  );
  const workOrderFeatureFlag = useFeatureFlags(FeatureFlagType.workOrders);
  const workOrdersAccessControl = useAccessControls(
    AccessControlType.workOrders
  );

  const { t } = useTranslation();
  const { data: unreadNotifications } = useGetUnreadNotificationsCount();
  const { isMiProApp } = useChangeAppMode();

  const miProTabs: TabProps[] = useMemo(
    () => [
      {
        name: 'home',
        href: homeURL(),
        label: t('home'),
        icon: getTabIcon(homeURL(), 'home-alt'),
        tab: HOME,
      },
      {
        name: 'activity',
        href: activitiesURL(),
        label: t('activity'),
        icon: getTabIcon(activitiesURL(), 'calendar-lines'),
        tab: ACTIVITY,
        badge: unreadNotifications?.unreadCount,
      },
      {
        name: 'search',
        href: searchURL(),
        label: t('search'),
        icon: getTabIcon(searchURL(), 'search'),
        tab: SEARCH,
      },
      {
        name: 'reports',
        href: reportsURL(),
        label: t('reports'),
        icon: getTabIcon(reportsURL(), 'chart-line'),
        skipReset: true,
        tab: REPORTS,
      },
    ],
    [t, unreadNotifications?.unreadCount]
  );

  const miProServTabs: TabProps[] = useMemo(
    () => [
      {
        name: 'workOrders',
        href: workOrdersURL(),
        label: t('workOrders'),
        icon: getTabIcon(workOrdersURL(), 'hard-hat'),
        tab: WORKORDERS,
        hidden: !(workOrderFeatureFlag && workOrdersAccessControl),
      },
      {
        name: 'documents',
        href: documentsURL(),
        label: t('documents'),
        icon: getTabIcon(documentsURL(), 'file-spreadsheet'),
        tab: DOCUMENTS,
        hidden: !documentsAccessControl,
      },
      {
        name: 'replenishments',
        href: replenishmentViewURL(),
        label: t('replenishments'),
        icon: getTabIcon(replenishmentViewURL(), 'warehouse-alt'),
        tab: REPLENISHMENTS,
        hidden: !replenishmentsAccessControl,
      },
      {
        name: 'issueProcessing',
        href: issueViewURL(),
        label: t('issueProcessing'),
        icon: getTabIcon(issueViewURL(), 'file-exclamation'),
        tab: ISSUE_PROCESSING,
        hidden: !issueProcessingAccessControl,
      },
    ],
    [
      t,
      documentsAccessControl,
      issueProcessingAccessControl,
      replenishmentsAccessControl,
      workOrderFeatureFlag,
      workOrdersAccessControl,
    ]
  );

  const bottomTabs: TabProps[] = isMiProApp ? miProTabs : miProServTabs;

  const { pathname } = useLocation();
  const { resetTabMap, activityTabAnimating } = useTabReset();
  const [allowResetMap, setAllowResetMap] = useState<Dictionary<boolean>>({
    [HOME]: true,
    [ACTIVITY]: true,
    [SEARCH]: true,
    [REPORTS]: true,
  });
  const updateAllowResetByKey = (k: string, v: boolean) => {
    setAllowResetMap((prev) => ({ ...prev, [k]: v }));
  };

  useEffect(() => {
    setAllowResetMap({
      [HOME]: pathname === homeURL(),
      [ACTIVITY]: pathname === activitiesURL(),
      [SEARCH]: pathname === searchURL(),
      [REPORTS]: pathname === reportsURL(),
    });
  }, [pathname]);

  const url = defaultAppUrl({
    isMiProApp,
    workOrderFeatureFlag,
    workOrdersAccessControl,
    documentsAccessControl,
    replenishmentsAccessControl,
    issueProcessingAccessControl,
  });

  return (
    <IonTabs className={classes.mainTabBar} data-testid="main-tab-bar">
      <IonRouterOutlet>
        {/* mipro tabs */}
        <Redirect exact path={tabsURL()} to={url} />
        {getRoutes(homeURLDefinition(), homeRouter)}
        {getRoutes(activitiesURLDefinition(), activitiesRouter)}
        {getRoutes(searchURLDefinition(), searchRouter)}
        {getRoutes(reportsURLDefinition(), reportsRouter)}
        {/* miproservices tabs */}
        {getRoutes(workOrdersURLDefinition(), workOrdersRouter)}
        {getRoutes(documentsURLDefinition(), documentsRouter)}
        {getRoutes(replenishmentViewURLDefinition(), replenishmentViewRouter)}
        {getRoutes(issueViewURLDefinition(), issueViewRouter)}
      </IonRouterOutlet>
      <IonTabBar slot="bottom" translucent>
        {bottomTabs
          .filter((tab) => !tab.hidden)
          .map((tab) => (
            <IonTabButton
              key={tab.name}
              tab={tab.name}
              href={tab.href}
              data-testid={`${tab.name}-tab`}
            >
              <Button
                className={classes.tabButton}
                onClick={() => {
                  if (tab.skipReset) {
                    // DOC: timeout needed so ionic doesn't break tab navigation
                    setTimeout(() => resetTab(tab.name, tab.href), 100);
                  }
                  if (pathname === tab.href) {
                    if (allowResetMap[tab.tab]) {
                      resetTabMap(tab.tab);
                    } else {
                      updateAllowResetByKey(tab.tab, true);
                    }
                  }
                }}
                testid={`${tab.name}-tab-button`}
              >
                <div
                  className={classNames(classes.tabWrapper, {
                    [classes.activeTab]: pathname.startsWith(tab.href),
                  })}
                >
                  <FontAwesomeIcon
                    icon={tab.icon(pathname)}
                    className={classNames(classes.icon, {
                      [classes.bell]:
                        activityTabAnimating && tab.name === 'activity',
                    })}
                  />
                  <IonLabel>{tab.label}</IonLabel>
                </div>
                {toNumber(tab.badge) > 0 && (
                  <Badge
                    text={formatNotificationCount(toNumber(tab.badge))}
                    type="notification"
                    testid="acitivity--notification"
                    className={classes.notification}
                  />
                )}
              </Button>
            </IonTabButton>
          ))}
      </IonTabBar>
    </IonTabs>
  );
};

export default TabNavigator;
