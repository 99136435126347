import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isBoolean, isNumber, map, size, toNumber, toString } from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { IonCol, IonRow, useIonViewWillEnter } from '@ionic/react';
import useFindActivityNotesV2 from 'ActivitiesApp/api/notes/useFindActivityNotesV2';
import useUpdateSubscription from 'ActivitiesApp/api/useUpdateSubscription';
import FollowerList from 'ActivitiesApp/components/FollowerList/FollowerList';
import type { ActivityDetailURLParams } from 'ActivitiesApp/models/ActivityDetail';
import { goToActivity } from 'ActivitiesApp/navigation/navigationHelpers';
import List from 'common/components/List/List';
import {
  getIntervalRender,
  type BaseComponentProps,
} from 'common/components/utils/renderHelpers';
import { and, choose, ifFunction, ifRender } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import EmployeeModal from 'SearchApp/components/EmployeeModal/EmployeeModal';
import type { ActivityNote, UpdateActivityNoteBody } from 'models/Notebook';
import { getErrorMessage } from 'utils/helpers';
import Button from 'components/Button/Button';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import Comment from './Comment';
import classes from './CommentList.module.scss';

interface CommentListProps extends BaseComponentProps {
  /* eslint-disable react/require-default-props */
  historyId?: number;
  eventUserId?: string;
  isLoading?: boolean;
  scrollParent?: HTMLElement | null;
  onEditComment?: (n: UpdateActivityNoteBody) => void;
  disableComments?: boolean;
  activityTitle?: string;
  showSubscribeModal?: boolean;
  setShowSubscribeModal?: (b: boolean) => void;
  /* eslint-enable react/require-default-props */
}

export interface CommentListRef {
  refetchV2?: () => void;
}

export const onScroll = (commentNode: Element) => {
  ifFunction(!!commentNode, () => {
    commentNode.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    commentNode.setAttribute(
      'style',
      '--background:var(--mipro-color-background)'
    );
    setTimeout(() => commentNode.setAttribute('style', ''), 3000);
  });
};

const CommentList = React.forwardRef(
  (props: CommentListProps, outerRef: React.ForwardedRef<CommentListRef>) => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const routeCommentId = params.get('commentId');
    const { t } = useTranslation(namespaces.comments);
    const routeParams = useParams<ActivityDetailURLParams>();
    const [scrollCommentId, setScrollCommentId] = useState('');
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>();
    const [isFollowerListOpen, setIsFollowerListOpen] = useState(false);

    const {
      className,
      eventUserId = '',
      isLoading,
      scrollParent,
      onEditComment,
      disableComments = false,
      historyId,
      setShowSubscribeModal,
      showSubscribeModal = false,
      activityTitle,
    } = props;

    const {
      activityNotes,
      commentsCount,
      subscribed,
      subscriptionCount,
      error: errorV2,
      isLoading: showLoaderV2,
      isEmptyResponse: isEmptyResponseV2,
      fetchNextPage: fetchNextPageV2,
      refetch: refetchV2,
    } = useFindActivityNotesV2({
      historyId,
      eventUserId,
      sortDir: 'ASC',
      sortCol: 'creationTimestamp',
      limit: 100,
      enabled: !!historyId,
    });
    const hasNotes = size(activityNotes) > 0;

    useIonViewWillEnter(() => {
      ifFunction(!!routeCommentId, () => {
        setScrollCommentId(toString(routeCommentId));
        history.replace(
          goToActivity({ ...routeParams, customerId: routeParams.id })
        );
      });
    }, [routeCommentId]);

    useEffect(() => {
      let interval: NodeJS.Timer;
      ifFunction(
        and(!!scrollCommentId, !showLoaderV2, hasNotes, !isLoading),
        () => {
          const scrollToComment = () => {
            const commentNode = document.querySelector(
              `[data-testid=comment-${scrollCommentId}]`
            );
            ifFunction(!!commentNode, () => setScrollCommentId(''));
            onScroll(commentNode as Element);
          };
          interval = getIntervalRender({
            timeout: 150,
            fn: scrollToComment,
          });
        }
      );
      return () => {
        clearInterval(interval);
      };
    }, [scrollCommentId, showLoaderV2, hasNotes, isLoading]);

    const { onUpdateSubscription, status } = useUpdateSubscription({
      historyId: toNumber(historyId),
    });

    const renderComment = (item: ActivityNote) => (
      <Comment
        className={classes.commentContainer}
        key={item.eventNoteId}
        notebook={item}
        onEditComment={onEditComment}
        disabledComment={disableComments}
        setSelectedEmployeeId={(e) => {
          setSelectedEmployeeId(e);
          setIsContactModalOpen(true);
        }}
      />
    );

    useImperativeHandle(outerRef, () => ({ refetchV2 }), [refetchV2]);

    const subscriptionCountValue = toNumber(subscriptionCount);
    const isNoFollowers = subscriptionCountValue === 0;
    const subscribeCommentsNameSpace =
      'ActivitiesApp-Comments:subscribeComments';

    return (
      <div>
        <div className={classes.commentsHeader}>
          <div className={classes.titleWrapper}>
            <Text
              text={t('comments', {
                count: choose(toNumber(commentsCount) > 0, commentsCount, 0),
              })}
              variant="mipro-h3-headline"
            />
            {isBoolean(subscribed) && (
              <Button
                slot="end"
                icon={choose(subscribed, ['fas', 'bell']) as IconName}
                testid="follow-button"
                text={choose(
                  subscribed,
                  t('ActivitiesApp-Comments:subscribed'),
                  t('ActivitiesApp-Comments:subscribe')
                )}
                variant={choose(subscribed, 'secondary', 'mipro-action')}
                textVariant="mipro-body-copy-bold"
                className={classNames(classes.updateSubBtn, {
                  [classes.subscribe]: !subscribed,
                  [classes.subscribed]: subscribed,
                })}
                onClick={() =>
                  onUpdateSubscription({ subscribed, subscriptionCount })
                }
                disabled={status === 'loading'}
              />
            )}
          </div>
          {isNumber(subscriptionCount) && (
            <Button
              icon={['far', 'users']}
              testid="followers-list-button"
              text={t('ActivitiesApp-Comments:members', {
                members: subscriptionCountValue,
                count: subscriptionCountValue,
              })}
              variant={choose(!isNoFollowers, 'link')}
              textVariant="mipro-body-copy-bold"
              className={classNames(classes.followersButton, {
                [classes.textLabel]: isNoFollowers,
              })}
              onClick={() => setIsFollowerListOpen(!isNoFollowers)}
            />
          )}
        </div>

        {/* TUDU has to render the whole list, otherise can't scroll...
      need to look for a scroll util inside virtuoso */}
        <div className={classes.commentsWrapper}>
          {map(activityNotes, renderComment)}
        </div>
        {ifRender(
          size(activityNotes) === 0,
          <List
            className={classNames(classes.list, className)}
            data={activityNotes}
            scrollParent={scrollParent}
            isLoading={{
              isLoading: showLoaderV2,
              text: t('comments:loading'),
            }}
            isEmptyList={{
              className: classes.emptyList,
              isEmptyList: isEmptyResponseV2,
              title: t('noComments'),
            }}
            isError={{
              isError: !!errorV2,
              title: t('errorLoadingComments'),
              body: getErrorMessage(errorV2),
            }}
            endReached={fetchNextPageV2}
            testid="comment-list"
          />
        )}
        <div
          style={{ height: 1, width: '100%' }}
          data-testid="comments-scroll-end"
        />
        <EmployeeModal
          isOpen={isContactModalOpen}
          setIsOpen={setIsContactModalOpen}
          testid="comment-contact-modal"
          id={selectedEmployeeId}
          onClose={() =>
            document
              .querySelector('.mention .active')
              ?.classList.remove('active')
          }
        />
        <SheetModal
          title={t(`${subscribeCommentsNameSpace}:subscribeTo`, {
            activityType: activityTitle,
          })}
          isOpen={showSubscribeModal}
          withRightCloseButton
          initialBreakpoint={0.4}
          testid="subscribe-comments-modal"
          className={classes.subscribeModal}
          setIsOpen={setShowSubscribeModal}
        >
          <div className={classes.subscribeText}>
            <Text
              text={t(`${subscribeCommentsNameSpace}:subscribeCommentsText`, {
                activityType: activityTitle,
              })}
              variant="mipro-body-copy"
            />
          </div>
          <IonRow className={classes.subscribeBtnRow}>
            <IonCol>
              <Button
                testid="subscribe-yes"
                variant="mipro-action"
                text={t(`${subscribeCommentsNameSpace}:yes`)}
                onClick={() => {
                  onUpdateSubscription({ subscribed });
                  setShowSubscribeModal?.(false);
                }}
                className={classes.subscribeBtn}
              />
            </IonCol>
          </IonRow>
        </SheetModal>
        <FollowerList
          historyId={toString(historyId)}
          isOpen={isFollowerListOpen}
          setIsOpen={setIsFollowerListOpen}
          testid="follower-list"
        />
      </div>
    );
  }
);

export default CommentList;
