import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { map, size } from 'lodash';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import useChangeAppMode from 'hooks/useChangeAppMode';
import useFeatureFlags, { FeatureFlagType } from 'hooks/useFeatureFlags';
import defaultAppUrl from 'utils/defaultAppUrl';
import type Button from 'components/Button/Button';
import type HeaderActions from 'components/HeaderActions/HeaderActions';

interface UseInventoryHeaderActionProps {
  customOptions?: Array<
    React.ComponentProps<typeof Button> & { autoClose?: boolean }
  >;
  withAddItem?: boolean;
  onAddItem?: () => void;
  withAddCountZero?: boolean;
  onAddCountZero?: () => void;
  withDeleteCount?: boolean;
  onDeleteGroupCount?: () => void;
}

interface UseInventoryHeaderActionResponse {
  exitUrl: string;
  headerActions?: Partial<React.ComponentProps<typeof HeaderActions>>;
}

const useInventoryHeaderActions = ({
  customOptions,
  withAddItem,
  onAddItem,
  withAddCountZero,
  onAddCountZero,
  withDeleteCount,
  onDeleteGroupCount,
}: UseInventoryHeaderActionProps): UseInventoryHeaderActionResponse => {
  const { t } = useTranslation();
  const { isMiProApp } = useChangeAppMode();
  const workOrderFeatureFlag = useFeatureFlags(FeatureFlagType.workOrders);
  const workOrdersAccessControl = useAccessControls(
    AccessControlType.workOrders
  );
  const documentsAccessControl = useAccessControls(
    AccessControlType.viewDocuments
  );
  const replenishmentsAccessControl = useAccessControls(
    AccessControlType.viewItemReplenishment
  );
  const issueProcessingAccessControl = useAccessControls(
    AccessControlType.viewIssueProcessing
  );
  const history = useHistory();
  const headerOptions: Array<
    React.ComponentProps<typeof Button> & { autoClose?: boolean }
  > = [];

  headerOptions.push(
    ...map(customOptions, (option) => ({
      ...option,
      onClick: (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
        option.onClick?.(e);
      },
    }))
  );

  if (withAddItem) {
    headerOptions.push({
      testid: 'add-manual-item',
      text: t('inventory:addItem'),
      onClick: () => {
        onAddItem?.();
      },
    });
  }

  if (withAddCountZero) {
    headerOptions.push({
      testid: 'add-zero-count',
      text: t('inventory:addZeroCount'),
      onClick: () => {
        onAddCountZero?.();
      },
    });
  }

  if (withDeleteCount) {
    headerOptions.push({
      testid: 'delete-count-group',
      text: t('inventory:deleteCountGroup'),
      onClick: () => {
        onDeleteGroupCount?.();
      },
    });
  }

  const exitUrl = defaultAppUrl({
    isMiProApp,
    workOrderFeatureFlag,
    workOrdersAccessControl,
    documentsAccessControl,
    replenishmentsAccessControl,
    issueProcessingAccessControl,
  });

  headerOptions.push({
    testid: 'exit-inventory',
    text: t('inventory:exitInventory'),
    onClick: () => {
      history.replace(exitUrl);
    },
  });

  const initialBreakpoint =
    size(headerOptions) === 1 ? 0.2 : size(headerOptions) * 0.11;

  return {
    exitUrl,
    headerActions: {
      title: t('inventory:manage'),
      options: headerOptions,
      initialBreakpoint,
    },
  };
};

export default useInventoryHeaderActions;
