import { useTranslation } from 'react-i18next';
import { useToasts } from 'providers/ToastProvider';
import useUpdateActivity from 'api/activities/useUpdateActivity';
import type { UpdateActivityFlags } from 'models/ActivityModels';

interface UseUpdateActivityFalgsProps {
  userId: string;
  historyId: number;
}

const useUpdateActivityFlags = ({
  userId,
  historyId,
}: UseUpdateActivityFalgsProps) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { onUpdateActivity } = useUpdateActivity({
    userId,
    historyId,
  });

  const onUpdateActivityFlags = ({
    undoData,
    activityUpdate,
  }: UpdateActivityFlags) => {
    onUpdateActivity(activityUpdate);

    if (activityUpdate.done) {
      addToast({
        text: t('activities:removeActivity'),
        button: {
          role: 'undo',
          text: t('common:undo'),
          handler: () => onUpdateActivity(undoData),
        },
        testid: 'update-activity-toast',
      });
    }
  };

  return { onUpdateActivityFlags };
};

export default useUpdateActivityFlags;
