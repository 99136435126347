import type { Dictionary } from 'lodash';
import type { FileAttachmentProp } from 'models/Attachment';
import type { SelectModalItem, WithOptimisticUI } from 'models/Search';

export enum OcnTypeEnum {
  'unfinished' = 'unfinished',
  'quote' = 'quote',
  'order' = 'order',
  'closed' = 'closed',
}

export interface ItemSourcing {
  requestTo?: string;
  requestToName: string;
  requestToAddr1?: string;
  requestToAddr2?: string;
  requestToAddr3?: string;
  requestToCity?: string;
  requestToState?: string;
  requestToZip?: string;
  requestToPhone?: string;
  requestToEmail?: string;
  countryCd?: string;
  shopFlag: 'Y' | 'N';
  ctlNo?: string;
  reqCtlNo?: string;
  orderLineNo?: string;
  qtyRequested?: string;
  workOrderCancelRequestedFlag?: string;
  requestType?: string;
  lineStatus?: string;
}

export interface CartOrderItem {
  itemNo: string;
  miLoc: string;
  orderLineNo: string;
  mfrName: string;
  mfrCtlNo: string;
  mfgPartNo: string;
  groupSerial: string;
  custStockNo: string;
  priceSourceDesc: string;
  opQtyOrdered: number;
  listPrice: string;
  customerCost: string;
  unitSellPrice: string;
  salesUOM: string;
  grossProfitPct: string;
  costSource: string;
  hasOpportunities: boolean;
  itemImageName: string;
  custComment: string;
  recommendedGP: string;
  promsdDelivDt: string;
  errorList: string[];
  unitCost: string;
  affectUsageFlag: string;
  deleteOption: string;
  priceLocked: boolean;
  promotionNo: string[];
  isIncidentalPricing: boolean;
  paCalcPrice: string;
  priceCompFlag: string;
  isEditable: boolean;
  itemStatus: string;
  freightAdded: string;
  deleteFlag: string;
  scheduleSeqNo: string;
  paType: string;
  hasTransmittedLine: boolean;
  hasScheduledLines: boolean;
  priceOverridePrice: string;
  priceOverrideGPPerc: string;
  priceOverride: string;
  priceOverrideRequestUserid: string;
  priceOverrideReason: string;
  priceOverrideReasonDesc: string;
  priceOverrideNotebookText: string;
  priceOverrideSendOrderConfirmation: string;
  priceOverrideNotebookLastModified: string;
  addToAgreement: string;
  extPrice?: number;
  editUnitCost?: boolean;
  governmentPricing?: boolean;
  itemSourcing?: Dictionary<ItemSourcing>;
  minimumOrderQty?: string;
  orderIncrement?: string;
  brDefaultFlag?: string;
  transferMinQty?: string;
  dcDefaultFlag?: string;
  itemStatusDetailDesc?: string;
  governmentAccountMfrFlag?: boolean;
}

export interface UpdatedCartOrderItem {
  itemNo: string;
  mino: string;
  groupSerial: string;
  custStockNo: string;
  mfgPartNo: string;
  unitSellPrice?: string;
  unitCost?: string;
  affectUsageFlag?: string;
  orderLineNo?: string;
  salesUOM?: string;
  quantity: number;
  promsdDelivDt?: string;
  dirty?: string;
}

export interface LastDocumentTransmission {
  transmissionMethod?: string;
  creationUserId?: string;
  creationUsername?: string;
  transmissionTmstmp?: string;
  transmissionStatus?: 'T' | 'F';
}

export interface ProTipItem {
  commentCount: number;
  creationTimestamp: string;
  creationUserId: string;
  creationUserName: string;
  details: string;
  dislikeCount: number;
  historyId: number;
  industryDesc: string;
  industryId: number[];
  lastUpdateTimestamp: string;
  lastUpdatedUserId: string;
  likeCount: number;
  memberCount: number;
  mfrCtlNo: string;
  miCorpLoc: string;
  reqEmployeeId: string;
  reqEmployeeName: string;
  revenue: number;
  subject: string;
  tipId: number;
  viewCount: number;
}

export interface Order extends WithOptimisticUI {
  miLoc: string;
  creationTmstmp: string;
  shipToCustNo: string;
  billToCustNo: string;
  custPONo: string;
  custReleaseNo: string;
  dueDate: string;
  enteredById: string;
  userName: string;
  linesOrdered: string;
  minPrmsdDelivDt: string;
  nextStep: string;
  orderCtlNo: string;
  orderDate: string;
  orderSourceCd: string;
  processStatus: string;
  takenBy: string;
  totalOrderAmt: string;
  orderTypeCd: string;
  deleteOptions: string;
  fcFlag: string;
  items: Dictionary<CartOrderItem>;
  linesUpdating: string[];
  headerErrorList: string[];
  shipChargesCd: string;
  carrierCd: string;
  carrierDesc: string;
  shipMethodCd: string;
  carrierAccount: string;
  handlingInst: string;
  shipComplete: string;
  shipMethodDesc: string;
  shipChargesDesc: string;
  mdseOrderedPrice: number;
  totalFrt?: number;
  salesTaxTotal?: number;
  totalVas?: number;
  grossProfitDollars?: number;
  grossProfitPct?: number;
  custPoNo: string;
  reOrderReminder: string;
  autoRepriceZPI?: string;
  excludeFromWeb?: string;
  custContactName: string;
  customerName: string;
  custContactPhone: string;
  custContactEmail: string;
  storeroomNo: string;
  isSourced: boolean;
  isEditable: boolean;
  pendingOverrideCount: number;
  approvedAfterLastTransmission: boolean;
  lastDocumentTransmission?: LastDocumentTransmission;
  lastOrderTransmissionLog?: LastDocumentTransmission;
  orderSourceDesc?: string;
  creationUsername?: string;
  governmentAccountFlag?: boolean;
  commercialToFunctionalExchange: string;
  locationProfile?: LocationProfile;
  proTips?: ProTipItem[];
}

export interface TransmissionLog {
  controlNo: string;
  creationUserId: string;
  creationUsername: string;
  docCtlNo: string;
  docType: string;
  formType: string;
  miLoc: string;
  retransmitFlag: string;
  sendTo: string;
  transmissionMethod: string;
  transmissionStatus: string;
  transmissionTmstmp: string;
}

export const ecosClassRed = 'highlightTextRed';
export const ecosClassOrange = 'highlightTextOrange';

export interface LostSalesCode {
  codeDesc: string;
  codeValue: string;
  statusFlag: string;
}

export interface OrderNotesURLParams {
  mode?: 'add' | 'edit' | 'view';
  date?: string;
  time?: string;
  micro?: string;
}

export interface OrderNote extends OrderNotesURLParams {
  notebookFirstLine?: string;
  lastUpdName?: string;
  creationName?: string;
  statusFlag?: string;
  lastUpdTmstmp?: string;
  lastUpdUserid?: string;
  text: string;
  printFaxFlag: string;
  quotePrintFlag: string;
  printOnInvoice: string;
  printOnPick: string;
  templateCD: string;
  orderLineNo: string;
  source?: string;
  creationUserid?: string;
}

export interface OcnLineStatusItemDetail {
  componentLineNo?: string;
  fcLocPO?: string;
  freight?: string;
  hasAttachmentSW?: string;
  locType?: string;
  prHasCancelItem?: string;
  prNBFlag?: string;
  proofOfDeliveryAttachments?: FileAttachmentProp;
  rowPRSW?: string;
  seqGroup?: string;
  seqNo?: string;
  sqlType?: string;
  tranAttachmentFlag?: string;
  tranCancelDesc?: string;
  tranCarrier?: string;
  tranCarrierAcct?: string;
  tranCtlNo?: string;
  tranDate?: string;
  tranDirectShipDesc?: string;
  tranLineNo?: string;
  tranPO?: string;
  tranPOConfirmation?: string;
  tranPOLine?: string;
  tranQuantity?: string;
  tranRFQQuoteNo?: string;
  tranSourceLoc?: string;
  tranSourceName?: string;
  tranSourceTypeCd?: string;
  tranStatus?: string;
  tranStatusDesc?: string;
  tranTrackingCtlNo?: string;
  tranTrackingNo: string;
  tranType: string;
  tranTypeDesc?: string;
}

export interface OcnLineStatusItem {
  directShip: boolean;
  showResults: boolean;
  fcdcShip?: boolean;
  fcdc?: string;
  sourceLoc: string;
  sourceLocType: string;
  sourceName: string;
  shipmentStatusDesc: string;
  details: OcnLineStatusItemDetail[];
}

export interface OcnLineStatus {
  lineStatus: {
    tranQuantity?: string;
  };
  showResults?: boolean;
  statuses: OcnLineStatusItem[];
  warningCode?: string;
  warningDescription?: string;
  miLoc: string;
}

export interface Carrier {
  codeDesc: string;
  codeValue: string;
  carrierType?: string;
  emergencyFlag?: string;
  billFlag?: string;
  serviceIndicator?: string;
  serviceIcon?: string;
  codIndicator?: string;
  holdForPickup?: string;
  acctRequired?: string;
  preferredCarrier?: string;
  allowHazardous?: string;
  motionDelivery?: string;
  trackingUrlId?: string;
  upsAPI?: string;
  upsServiceCode?: string;
  rushFlag?: string;
  motionFreightClass?: string;
  shippingMethodDesc?: string;
}

export interface ShippingCharges {
  codeValue: string;
  codeDesc: string;
  shipChargeFrchD?: string;
  shipChargeType?: string;
  acctRequired?: string;
  validIntercoms?: string;
  shipChargeSpanD?: string;
  taxCategoryCode?: string;
  zeroTouchAutoInvoice?: string;
}

export interface ShippingMethod {
  codeDesc: string;
  codeValue: string;
  pickImmedValid?: string;
  carrierFlag?: string;
  validFor?: string;
  shipChargeFrchD?: string;
  allowHazardous?: string;
  shipChargeSpanD?: string;
  premium?: string;
  mxTrasladoSw?: string;
}

export interface CheckoutOrderBaseForm {
  emailCustomer?: boolean;
  typedRecipients?: string;
  emailMsg?: string;
  includeAttachments?: boolean;
  emailAckType?: string;
}

export interface CheckoutOrderForm extends CheckoutOrderBaseForm {
  customerContact?: SelectModalItem;
  shipComplete?: string;
  handlingInst?: string;
  shipChargesCd?: ShippingCharges;
  carrierCd?: Carrier;
  shipMethodCd?: ShippingMethod;
  poNumber?: string;
  custReleaseNo?: string;
  carrierAccount?: string;
  excludeFromWeb?: string;
  contactName?: string;
  contactNumber?: string;
  contactEmail?: string;
  dueDate?: string;
  priceOverridePending?: boolean;
  sendUnprocessed: boolean;
  miLoc?: string;
  orderCtlNo?: string;
  shipToCustNo?: string;
  billToCustNo?: string;
  processStatus?: string;
  orderSourceDesc?: string;
  addCustomer?: boolean;
  customerName?: string;
}

export interface ShareGovOcnURLParams {
  miLoc: string;
  id: string;
  ocn: string;
}

export interface LocationProfile {
  OAP_ENABLED?: string;
}
