import { isNil, toLower, truncate } from 'lodash';
import DOMPurify from 'dompurify';
import { decode } from 'he';

export const UNSUPPORTED_FILE_TYPE = 'Unsupported File Type';

export const sanitizeContent = (
  dirty: string,
  allowedTags?: string[]
): string => {
  if (isNil(allowedTags)) {
    return decode(
      DOMPurify.sanitize(dirty, {
        USE_PROFILES: { html: true },
      })
    );
  }
  return decode(
    DOMPurify.sanitize(dirty, {
      ALLOWED_TAGS: allowedTags,
    })
  );
};

export const sanitizeExcerpt = (dirty: string): string => {
  const sanitizedContent = DOMPurify.sanitize(dirty, { ALLOWED_TAGS: [] });

  return truncate(decode(sanitizedContent), {
    length: 43, // Docs: the 3 extra characters are for the ellipsis.
    separator: /,? +/,
  });
};

export const sanitizeTitle = (dirty: string): string => {
  const sanitizedContent = DOMPurify.sanitize(dirty, { ALLOWED_TAGS: [] });

  return decode(sanitizedContent);
};

// https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc
export const mimetypeFromExtension = (extension: string): string => {
  const lowerCaseExtension = toLower(extension);
  switch (lowerCaseExtension) {
    case 'pdf': {
      return 'application/pdf';
    }
    case 'xls': {
      return 'application/vnd.ms-excel';
    }
    case 'xlsx': {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    case 'doc': {
      return 'application/msword';
    }
    case 'docx': {
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
    case 'ppt': {
      return 'application/vnd.ms-powerpoint';
    }
    case 'pptx': {
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    }
    case 'apng':
    case 'png':
    case 'jpeg':
    case 'jpg':
    case 'avif':
    case 'bmp':
    case 'gif':
    case 'webp':
    case 'tiff': {
      return `image/${lowerCaseExtension}`;
    }
    case 'tif': {
      return 'image/tiff';
    }
    case 'svg': {
      return 'image/svg+xml';
    }
    case 'mp4':
      return `video/mp4`;
    case 'mov': {
      return `video/quicktime`;
    }
    case 'msg':
    case 'eml': {
      return `message/rfc822`;
    }
    default: {
      return UNSUPPORTED_FILE_TYPE;
    }
  }
};
