import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { map, size, toString } from 'lodash';
import SelectModal from 'common/components/SelectModal/SelectModal';
import { and, choose, or } from 'common/utils/logicHelpers';
import useFindIndustrySegments from 'HomeApp/ProTips/api/useFindIndustrySegments';
import type { ProTip } from 'HomeApp/ProTips/api/useFindProTips';
import useFindProTips from 'HomeApp/ProTips/api/useFindProTips';
import ProTipFilter from 'pages/Reports/components/ProTipFilter/ProTipFilter';
import type { ProTipFilterOption } from 'pages/Reports/components/ProTipFilter/ProTipFilter';
import ProTipsReportGrid from 'assets/ProTipsReportGrid.svg';
import classes from './ProTipSelect.module.scss';

interface ProTipSelectProps {
  miLoc: string;
  customerNo: string;
  onDone?: (proTips: ProTip[]) => void;
}

const ProTipSelect = ({
  ...props
}: Partial<React.ComponentProps<typeof SelectModal<ProTip>>> &
  ProTipSelectProps) => {
  const { t } = useTranslation('HomeApp/ProTips:proTip');
  const [searchQuery, setSearchQuery] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilterIndustrySegments, setSelectedFilterIndustrySegments] =
    useState<ProTipFilterOption[] | undefined>([]);

  const { testid, customerNo, miLoc, isOpen, onDone } = props;

  const { proTips, totalItems, ...queryProps } = useFindProTips({
    query: searchQuery,
    custMiLoc: miLoc,
    custNo: customerNo,
    industryIds: map(
      selectedFilterIndustrySegments,
      'key'
    ) as unknown as string[],
    enabled: isOpen,
  });

  const filterNone = size(selectedFilterIndustrySegments) === 0;

  const { industrySegments } = useFindIndustrySegments();

  const industrySegmentsOptions: ProTipFilterOption[] = map(
    industrySegments,
    ({ sicCode: key, industryName: name }) => ({
      key,
      name,
    })
  );

  return (
    <SelectModal<ProTip>
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isOpen={!!isOpen}
      belowSearchBarSlot={
        <ProTipFilter
          className={classes.filter}
          allowMultiple
          setFilterData={(option) => {
            setSelectedFilterIndustrySegments(option);
          }}
          selectedItems={selectedFilterIndustrySegments}
          filterOptions={{
            title: t('HomeApp/ProTips:proTip:industrySegments'),
            options: industrySegmentsOptions,
          }}
          setIsOpen={setIsFilterOpen}
          isOpen={isFilterOpen}
          modalTitle={t('common:filter')}
          testid="protips-filter"
          customButton={{
            className: classNames(classes.filterButton, {
              [classes.filterNone]: filterNone,
              [classes.hasFilters]: !filterNone,
            }),
            testid: 'pro-tip-select-filter-button',
            text: choose(filterNone, t('common:filter'), toString(1)),
            rightIcon: undefined,
            icon: ['far', 'sliders'],
            children: undefined,
          }}
        />
      }
      items={proTips}
      onClose={() => setSelectedFilterIndustrySegments([])}
      testid={or(testid, 'pro-tips-modal')}
      setSearchQuery={setSearchQuery}
      getName={(i) => i.subject}
      getId={(i) => toString(i.tipId)}
      onDone={(v) => {
        onDone?.(v);
      }}
      getDescription={(i) => i.reqEmployeeName}
      searchPlaceholder={t('HomeApp/ProTips:proTip:searchPlaceholder')}
      queryProps={queryProps}
      listProps={{
        title: {
          className: classes.listHeader,
          text: choose(
            and(!searchQuery, filterNone),
            t('common:suggested'),
            t('common:results', {
              count: totalItems,
            })
          ),
          variant: 'title-screen-section',
          wrapperProps: { className: classes.listHeader },
        },
        isEmptyList: {
          hideIcon: true,
          imgSrc: ProTipsReportGrid,
          iconClassName: classes.emptyResult,
          title: t('common:noResultsTitle'),
          body: t('HomeApp/ProTips:proTip:emptyResultMsg'),
        },
      }}
    />
  );
};

export default ProTipSelect;
