import { flatMap, head, split, trim } from 'lodash';
import type { InfiniteData } from '@tanstack/react-query';
import { getURLParams, type BaseAPIHook } from 'common/api/utils/apiHelpers';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { useAxios } from 'providers/AxiosProvider';
import type { SortDirEnum } from 'models/Sort';
import { pageSize } from 'utils/constants';
import { getProTipsAPI } from './apiRoutes';

export const getProTipsReportQuerykey = 'pro-tips-report';

export interface ProTipsRow {
  subject: string;
  salesCount: string;
  salesAmount: string;
  openOppsAmount: string;
  reqEmployeeName: string;
  reqEmployeeId: string;
  creationTmstmp: string;
  viewCount: string;
  commentCount: string;
  likeCount: string;
  dislikeCount: string;
  industrySegments: string;
  creationUsername: string;
  historyId: string;
}

interface UseGetProTipsReportProps extends BaseAPIHook {
  sortField?: string;
  sortDir?: SortDirEnum;
  industrySegment?: string[];
}

interface UseGetProTipsReportResponse {
  summary?: ProTipsRow;
  rows: ProTipsRow[];
}

const useGetProTipsReport = ({
  enabled,
  sortField,
  sortDir,
  limit = pageSize(),
  industrySegment,
}: UseGetProTipsReportProps) => {
  const { axios } = useAxios();

  const params = {
    sortField,
    sortDir,
    limit,
    industrySegment: flatMap(industrySegment, (segment) =>
      split(segment, ',').map(trim)
    ),
  };

  const response = useQuerySetup<ProTipsRow, UseGetProTipsReportResponse>({
    queryKey: getProTipsReportQuerykey,
    queryParams: params,
    onAPIRequest: async ({ pageParam = 1, signal }) => {
      const { data } = await axios.get<UseGetProTipsReportResponse>(
        getProTipsAPI(getURLParams({ ...params, page: pageParam })),
        {
          signal,
        }
      );
      return data;
    },
    dataPath: 'rows',
    totalDataPath: 'summary',
    limit,
    enabled,
    isInfiniteQuery: true,
  });

  const { items, data: responseData, ...queryFlags } = response;

  const proTipsResponseData =
    responseData as InfiniteData<UseGetProTipsReportResponse>;
  const totals = head(proTipsResponseData?.pages)?.summary;

  return {
    ...queryFlags,
    rows: items as ProTipsRow[],
    totals,
  };
};

export default useGetProTipsReport;
