import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { choose } from 'common/utils/logicHelpers';
import { type Recipient } from 'models/Contact';
import type { SelectModalItem } from 'models/Search';
import ContactsList from 'components/Modals/SendEmailModal/ContactsList';

interface ContactSelectProps {
  miLoc: string;
  customerNo: string;
  selItem?: SelectModalItem;
  className?: string;
  label: string;
  required?: boolean;
  iconClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  setContact: (o: SelectModalItem) => void;
  showInput?: boolean;
  openModal?: boolean;
  setOpenModal?: (b: boolean) => void;
  onBlur?: () => void;
  inputError?: string;
  maxlength?: number;
  searchLink?: string;
}

const ContactSelect = ({
  miLoc,
  customerNo,
  selItem,
  className,
  label,
  required,
  iconClassName,
  placeholder,
  disabled,
  setContact,
  showInput = true,
  openModal = false,
  setOpenModal,
  onBlur,
  inputError,
  maxlength,
  searchLink,
}: ContactSelectProps & IonicReactProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <ContactsList
      allowAddContact
      allowInputEdit
      canRemoveRecipients
      id={customerNo}
      miLoc={miLoc}
      searchType="customer"
      isOpen={setOpenModal ? openModal : isOpen}
      setIsOpen={setOpenModal || setIsOpen}
      setSelItem={setContact}
      testid="select-contact-modal"
      onClick={() => setIsOpen(true)}
      onBlur={onBlur}
      inputError={inputError}
      label={label}
      required={required}
      value={choose(!isEmpty(selItem?.key), selItem?.title)}
      values={choose(!isEmpty(selItem?.key), [
        {
          sequenceNo: selItem?.key,
          name: selItem?.title,
          email: selItem?.title,
        } as Recipient,
      ])}
      placeholder={placeholder}
      disabled={disabled}
      iconClassName={iconClassName}
      showInput={showInput}
      className={className}
      maxlength={maxlength}
      icon="caret-down"
      searchLink={searchLink}
      title={t('snapshot:selectAlternateTitle', {
        type: t(`snapshot:contact`),
      })}
    />
  );
};

export default ContactSelect;
