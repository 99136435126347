import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import useFindUomTypes from 'ProductSearchApp/api/fastfind/useFindUomTypes';
import type { SelectModalItem } from 'models/Search';
import SelectModal from 'components/SelectModal/SelectModal';

interface UomTypeSelectProps extends BaseComponentProps {
  selItem?: SelectModalItem;
  setUomTypeCode: (o: SelectModalItem) => void;
}

const UomTypeSelect = ({
  selItem,
  setUomTypeCode,
  testid = 'uom-type-select',
  ...props
}: UomTypeSelectProps &
  Partial<React.ComponentProps<typeof SelectModal>>): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { uomTypes, error, isLoading, isEmptyResponse, fetchNextPage } =
    useFindUomTypes({ query: searchQuery });

  const inputDisplay = selItem?.key ? `(${selItem.key}) ${selItem.title}` : '';

  const items = map(uomTypes, (item) => ({
    key: item.codeValue,
    title: item.codeDesc,
    description: item.codeValue,
  }));

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('');
    }
  }, [isOpen, searchQuery, setSearchQuery]);

  return (
    <SelectModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      selItem={selItem || { title: '', description: '', key: '' }}
      setSelItem={(o) => setUomTypeCode(o)}
      items={items}
      error={error}
      isEmptyResponse={!!isEmptyResponse}
      showLoader={!!isLoading}
      fetchNextPage={fetchNextPage}
      enableInfiniteScroll
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      value={inputDisplay}
      icon="caret-down"
      testid={testid}
    />
  );
};

export default UomTypeSelect;
