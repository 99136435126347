import { API_URL } from 'api';

export const getCostSavingsAPI = (paramsString: string) =>
  `${API_URL}/costSavings/report?${paramsString}`;

export const getUnbilledAPI = (paramsString: string) =>
  `${API_URL}/reporting/sales/unbilled?${paramsString}`;

export const getProTipsAPI = (paramsString: string) =>
  `${API_URL}/reporting/protips?${paramsString}`;
