import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, toString } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import ActivitiesGroupContent from 'ActivitiesApp/components/common/ActivitiesGroupContent';
import Header from 'common/components/Header/Header';
import List from 'common/components/List/List';
import { useIonContentRef } from 'common/components/utils/renderHelpers';
import useFindNotifications, {
  inAppNotificationsQueryKey,
} from 'HomeApp/Notifications/api/useFindNotifications';
import NotificationActionCard from 'HomeApp/Notifications/components/NotificationActionCard/NotificationActionCard';
import useNotificationGroupCounts from 'HomeApp/Notifications/hooks/useNotificationGroupCounts';
import { homeURL, notificationSettingsURL, settingsURL } from 'navigation';
import { onSuccessMutation } from 'api/helpers';
import useMarkInAppNotificationAsRead from 'api/notifications/useMarkInAppNotificationsAsRead';
import { getErrorMessage } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import Refresher from 'components/Refresher/Refresher';
import classes from './NotificationList.module.scss';

const NotificationList = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { node, nodeRef } = useIonContentRef();

  const { t } = useTranslation('HomeApp-Notifications');

  const { onMarkAsRead } = useMarkInAppNotificationAsRead();

  const headerActionsArray = [
    {
      text: t('markAllAsRead'),
      onClick: () => onMarkAsRead({}),
      testid: 'mark-items-read-button',
    },
    {
      text: t('managePusNotification'),
      href: concatRoutes(homeURL(), settingsURL(), notificationSettingsURL()),
      testid: 'manage-push-button',
    },
  ];
  const headerActions = {
    title: t('manageNotification'),
    initialBreakpoint: 0.35,
    disabled: false,
    options: headerActionsArray,
  };
  const {
    items = [],
    error,
    isLoading,
    noMoreData,
    fetchNextPage,
  } = useFindNotifications({ enabled: true });

  const { groupCounts } = useNotificationGroupCounts(items);

  const renderGroupContent = (index: number) => (
    <ActivitiesGroupContent index={index} groupCount={groupCounts[index]} />
  );

  const renderItemContent = (index: number) => (
    <NotificationActionCard
      key={items[index].notification.notificationCommentId}
      notificationItem={items[index]}
      testid={toString(items[index].id)}
    />
  );

  return (
    <IonPage
      className={classes.notificationListPage}
      data-testid="notices-page"
    >
      <Header
        className={classes.header}
        subTitle=""
        hideBackButton={false}
        hideMenuButton={false}
        hideLocationSelector={false}
        title={t('pageTitle')}
        testid="notices-header"
        headerActions={headerActions}
      />
      <IonContent ref={nodeRef}>
        <Refresher
          slot="fixed"
          hidden
          onRefresh={async () => {
            await onSuccessMutation(queryClient, inAppNotificationsQueryKey);
          }}
          testid="notifications-refresher"
          disabled={isLoading}
        />
        <List
          className={classes.list}
          title=""
          isGrouped
          data={items}
          groupItemContent={renderItemContent}
          groupContent={renderGroupContent}
          groupCounts={groupCounts}
          endReached={fetchNextPage}
          scrollParent={node}
          isLoading={isLoading}
          isEmptyList={{
            isEmptyList: isEmpty(items),
            title: t('emptyList'),
            className: classes.warningMessage,
          }}
          isError={{
            isError: !!error,
            title: t('errorLoading'),
            body: getErrorMessage(error),
          }}
          isEndOfList={{
            isEndOfList: noMoreData,
            text: t('noMoreData'),
            testid: 'no-more-data',
          }}
          testid="notifications-list"
        />
      </IonContent>
    </IonPage>
  );
};

export default NotificationList;
