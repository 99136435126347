import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { FormikInput } from 'common/components/Forms/Input/Input';
import { useFormikContext } from 'formik';
import { OcnTypeEnum } from 'ProductSearchApp/models/Order';
import type { CheckoutOrderForm } from 'ProductSearchApp/models/Order';
import { mergeEmailRecipients } from 'ProductSearchApp/util/ocnHelpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import ContactsList from 'components/Modals/SendEmailModal/ContactsList';
import RadioButton from 'components/RadioButton/RadioButton';
import Text from 'components/Text/Text';
import classes from './CheckoutForm.module.scss';

interface EmailInfoProps {
  miLoc: string;
  customerNo: string;
  disabled?: boolean;
  ocnType: OcnTypeEnum;
}

const EmailInfo = ({
  miLoc,
  customerNo,
  disabled,
  ocnType,
}: EmailInfoProps): JSX.Element => {
  const { t } = useTranslation();
  const namespace = 'productSearch:checkout';
  const [listIsOpen, setListIsOpen] = useState(false);
  const isOrder = ocnType === OcnTypeEnum.order;

  const {
    values: {
      emailCustomer,
      emailAckType,
      priceOverridePending,
      excludeFromWeb,
      sendUnprocessed,
      includeAttachments,
      typedRecipients,
    },
    setFieldValue,
  } = useFormikContext<CheckoutOrderForm>();

  const emailDisabled =
    priceOverridePending || excludeFromWeb === 'Y' || sendUnprocessed;
  const showEmailFields = !emailDisabled && emailCustomer;

  const emailAckOptions = [
    { value: 'CO', displayValue: t(`${namespace}:confirmation`) },
    { value: 'CH', displayValue: t(`${namespace}:changeOrder`) },
  ];

  if (!showEmailFields) {
    return <></>;
  }

  return (
    <IonGrid className={classes.cardWrapper}>
      <Text text={t(`${namespace}:customerMail`)} variant="mipro-h3-headline" />
      <FormikInput
        className={classes.input}
        label={t(`${namespace}:recipients`)}
        customLabel={
          <>
            <Button
              className={classes.addRecipientButton}
              variant="link"
              icon={findIcon('plus-circle')}
              iconClassName={classes.plusCircle}
              onClick={() => setListIsOpen(true)}
              testid="add-recipient-button"
            />
            <ContactsList
              allowAddContact
              searchType="customer"
              id={customerNo}
              miLoc={miLoc}
              isOpen={listIsOpen}
              setIsOpen={setListIsOpen}
              title={t(`${namespace}:recipients`)}
              onDone={(v) => {
                setFieldValue(
                  'typedRecipients',
                  mergeEmailRecipients(
                    typedRecipients,
                    map(v, (item) => item.email).join('; ')
                  ),
                  true
                );
              }}
              testid="contact-list-modal"
            />
          </>
        }
        inputmode="email"
        type="email"
        name="typedRecipients"
        disabled={disabled}
        onBlur={() =>
          setFieldValue(
            'typedRecipients',
            mergeEmailRecipients('', typedRecipients)
          )
        }
        required={emailCustomer}
        testid="typed-recipients-email-input"
      />
      {isOrder && (
        <IonRow className={classes.contactSel}>
          <IonCol size="12">
            <Text
              variant="list-item-subtitle"
              text={t(`${namespace}:emailType`)}
            />
          </IonCol>
          <IonCol>
            <RadioButton
              testid="emailacktype--radio"
              options={emailAckOptions}
              value={emailAckType}
              onChange={(val) => {
                setFieldValue('emailAckType', val);
              }}
              disabled={disabled}
              isVerticalAlign
            />
          </IonCol>
        </IonRow>
      )}
      <IonRow className={classes.emailRow}>
        <CheckBox
          name="includeAttachments"
          ariaLabel="includeAttachments"
          label={t(`${namespace}:includeAttachments`)}
          checked={includeAttachments}
          disabled={disabled}
          onChange={(checked) => setFieldValue?.('includeAttachments', checked)}
          testid="include--attachments"
        />
      </IonRow>
      <FormikInput
        className={classes.input}
        label={t(`${namespace}:emailMsg`)}
        name="emailMsg"
        textarea
        toUpperCase
        disabled={disabled}
        testid="email-message"
      />
    </IonGrid>
  );
};

export default EmailInfo;
