import type { AxiosError } from 'axios';
import { size, type Dictionary, isArray } from 'lodash';
import type {
  QueryFunctionContext,
  QueryKey,
  UseInfiniteQueryResult,
  UseQueryResult,
} from '@tanstack/react-query';
import { and, or } from 'common/utils/logicHelpers';
import { getUnixTime } from 'date-fns';

export type QueryParamsType = Dictionary<
  string | number | boolean | unknown[] | undefined
>;
export type QueryFnProps = QueryFunctionContext<QueryKey, number>;

export type QueryFlags<DataType = unknown> = {
  error?: AxiosError;
  status?: 'error' | 'loading' | 'success';
  isLoading?: boolean;
  isEmptyResponse?: boolean;
  enableInfiniteScroll?: boolean;
  noMoreData?: boolean;
  dataUpdatedAt?: number;
  refetch?: () => Promise<DataType | void>;
  fetchNextPage?: () => Promise<void>;
};

export type UseGetQueryFlagsProps<DataType = unknown> = Partial<
  Omit<UseInfiniteQueryResult | UseQueryResult, 'data'>
> & {
  data?: DataType | DataType[];
  enabled?: boolean;
};

function useGetQueryFlags<DataType>(
  props: UseGetQueryFlagsProps<DataType>
): QueryFlags<DataType> {
  const {
    data,
    error,
    status,
    fetchStatus,
    dataUpdatedAt,
    enabled = true,
    refetch,
  } = props;
  const { hasNextPage, isFetchingNextPage, fetchNextPage } =
    props as UseInfiniteQueryResult;

  return {
    ...props,
    isLoading: fetchStatus === 'fetching',
    error: error as AxiosError,
    // TUDU: noMoreData page size validation should be inside LIST component
    noMoreData: and(status === 'success', !hasNextPage),
    dataUpdatedAt: getUnixTime(new Date(or(dataUpdatedAt, 0))),
    isEmptyResponse: isArray(data) && size(data) === 0,
    enableInfiniteScroll: !(!hasNextPage || isFetchingNextPage),
    refetch: async () => {
      if (enabled) {
        const response = await refetch?.();
        return response?.data as DataType;
      }
      return undefined;
    },
    fetchNextPage: async () => {
      if (and(enabled, hasNextPage, !isFetchingNextPage)) {
        await fetchNextPage?.();
      }
    },
  };
}

export default useGetQueryFlags;
