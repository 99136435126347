import React, { useEffect, useImperativeHandle, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  find,
  head,
  includes,
  isEmpty,
  map,
  size,
  toNumber,
  toString,
  trim,
} from 'lodash';
import { IonItem, IonRow } from '@ionic/react';
import useUpdatePriceOverride from 'ActivitiesApp/api/useUpdatePriceOverride';
import type { ActivityPageBaseProps } from 'ActivitiesApp/models/ActivityDetail';
import type { PriceOverrideInfo } from 'ActivitiesApp/models/PriceOverride';
import type { ActivityComponentRef } from 'ActivitiesApp/pages/ActivityDetail';
import { getActivityDataValue } from 'ActivitiesApp/utils/helpers';
import Input from 'common/components/Forms/Input/Input';
import { or } from 'common/utils/logicHelpers';
import { formatNumber, scaleNumber } from 'common/utils/numberHelper';
import { DataTypeEnum } from 'common/utils/valueFormatter';
import { useFormik } from 'formik';
import { namespaces } from 'i18n/i18n.constants';
import { feedbackURL, homeURL } from 'navigation';
import { useToasts } from 'providers/ToastProvider';
import useUpdateActivity from 'api/activities/useUpdateActivity';
import useFindDataCodes from 'api/data/useFindDataCodes';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { ActionCardActivity } from 'models/ActivityModels';
import { RatingActivityEnum } from 'models/ActivityModels';
import { ToastType } from 'models/Toast';
import { maxNumericValue } from 'utils/constants';
import { DateFormatEnum, formatCardDate, formatDate } from 'utils/date';
import { FirebaseEventNameEnum } from 'utils/firebaseAnalytics';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import AccountsReceivableCard from 'pages/Snapshots/AccountsReceivableCard';
import type { ActivityModalData } from 'components/Activities/ActionCardModal/ActionCardModalData';
import ActionCardModalData, {
  OcnTypeEnum,
} from 'components/Activities/ActionCardModal/ActionCardModalData';
import RatingButtons from 'components/Activities/RatingButtons/RatingButtons';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import Loader from 'components/Loader/Loader';
import OptionsListModal from 'components/Modals/OptionsListModal/OptionsListModal';
import Text from 'components/Text/Text';
import WarningMessage, {
  WarningMessageVarianEnum,
} from 'components/WarningMessage/WarningMessage';
import classes from './PriceOverrideForm.module.scss';
import usePriceOverrideFormValidations from './usePriceOverrideFormValidations';

export interface IPriceOverrideForm {
  action?: 'A' | 'R';
  overrideReason?: string;
  overridePrice?: number | string;
  overrideGP?: number | string;
  finalGP?: number | string;
  createOverrideException?: boolean;
  createManufacturerMargin?: boolean;
  updateUploadOverride?: boolean;
  updateManufacturerMargin?: boolean;
  marginGP?: number | string;
  contractGP?: number | string;
}

const priceOverrideDefinition: ActivityModalData = {
  orderNumberOrder: { label: 'ocn (order)', type: DataTypeEnum.ocn },
  orderNumberQuote: { label: 'ocn (quote)', type: DataTypeEnum.ocn },
  originalPrice: { label: 'originalPrice', type: DataTypeEnum.number },
  overridePrice: { label: 'overridePrice', type: DataTypeEnum.number },
  quantity: { label: 'common:quantity', type: DataTypeEnum.string },
  originalGP: { label: 'orignalGPPercent', type: DataTypeEnum.number },
  overrideGP: { label: 'overrideGPPercent', type: DataTypeEnum.number },
  finalGP: { label: 'finalGPPercent', type: DataTypeEnum.number },
  overrideReason: { label: 'overrideReason', type: DataTypeEnum.string },
  line: { label: 'common:line', type: DataTypeEnum.string },
  mino: { label: 'common:mino', type: DataTypeEnum.string },
  mfrPartNo: { label: 'manufacturerPartNo', type: DataTypeEnum.string },
  overrideUser: { label: 'overrideUser', type: DataTypeEnum.employee },
  agreementlastUpdated: {
    label: 'agreementLastUpdated',
    type: DataTypeEnum.string,
  },
  manufacturer: { label: 'common:manufacturer', type: DataTypeEnum.string },
  productGroup: { label: 'productGroup', type: DataTypeEnum.string },
  mfrCurrentGP: { label: 'currentGPPercent', type: DataTypeEnum.string },
  mfrLastUpdated: { label: 'common:lastUpdated', type: DataTypeEnum.string },
};

const PriceOverrideForm = React.forwardRef<
  ActivityComponentRef,
  ActivityPageBaseProps
>((props, outerRef): JSX.Element => {
  const ac = useAccessControls(AccessControlType.approvePriceOverride);
  const { t, i18n } = useTranslation(namespaces.activities);
  const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);

  const {
    historyId,
    userId = '',
    activity,
    isLoading: propsIsLoading,
    updateActivityData,
    didChange,
    footerRef,
    shouldRenderSaveButton,
    hasPendingComment,
    setIsDiscardCommentOpen,
    onContactClick,
    onEmployeeClick,
    disableActivityFlags,
    onCompleteActivityFlag,
    onFollowUpActivityFlag,
    onUnSnooze,
    onContentChange,
    onDone,
    triggerResize,
    contentBeforeDetailsRef,
  } = props;

  const { fieldsData } = activity as ActionCardActivity;
  const extendedInfo = (activity?.extendedInfo ||
    {}) as unknown as PriceOverrideInfo;
  const orderNumber = getActivityDataValue(fieldsData?.['Order Ctl No']);
  const miLoc = toString(fieldsData?.['MI Loc'] || activity?.custMiLoc);
  const customerId = toString(activity?.custNo);
  const priceOverrideRequestor = getActivityDataValue(
    fieldsData?.['Requestor Name'] || fieldsData?.['Requestor Userid']
  );
  const {
    priceOverrideAgreement,
    overrideStatus,
    overrideReason,
    orderTypeCd,
    priceSource,
    addToAgreement,
    priceIncreasePts,
    salesUomQty,
    uomDescription,
    agreementLastUpdated,
    approveRejectUsername,
    approveRejectEmployeeId,
    rebateFlag,
    mfrCtlNo,
    prodGroupNo,
    pgcMfrGPPct,
    pgcMfrLastUpdated,
    mino,
    mfgPartNo,
    notebooks,
    notAbleToEmailReason,
    lastDocumentTransmission,
    requestorEmpid,
  } = extendedInfo;
  const isDone = overrideStatus === 'A' || overrideStatus === 'R';
  const isApproved = overrideStatus === 'A';
  const isClosed = overrideStatus === 'CL';
  const isDeleted = overrideStatus === 'DL';
  const isOrder = orderTypeCd === 'O';
  const failedToSendEmail = !isEmpty(notAbleToEmailReason);
  const hasSentEmails = size(lastDocumentTransmission?.sendToList) > 0;

  const { data: overrideReasons, isLoading: overrideReasonsLoading } =
    useFindDataCodes({ codeType: 'CRPPRCOV' });

  const isLoading = propsIsLoading || overrideReasonsLoading;
  const lineNo = getActivityDataValue(fieldsData?.['Order Line No']);
  const overridePrice = scaleNumber({
    number: extendedInfo.overridePrice,
    scale: 3,
  });
  const cost = toNumber(extendedInfo.cost) || 0;
  const calculateOverrideGP = (price: number): string => {
    const newGP = ((price - cost) / price) * 100;
    return scaleNumber({ number: newGP, scale: 5 });
  };
  const calculateOverridePrice = (gp: number): string => {
    const newPrice = cost / (1 - gp / 100);
    return scaleNumber({ number: newPrice, scale: 3 });
  };
  const overrideGP = calculateOverrideGP(toNumber(overridePrice));
  let recommendedGP = toNumber(extendedInfo.recommendedGP) || 0;
  if (recommendedGP <= 0) {
    recommendedGP = toNumber(overrideGP) || 0;
  }
  const isRebate = rebateFlag === 'Y';
  const finalCost = toNumber(extendedInfo.finalCost) || 0;
  const calculateFinalGP = (price: number): string => {
    const newGP = ((price - finalCost) / price) * 100;
    return scaleNumber({ number: newGP, scale: 5 });
  };
  const finalGP = calculateFinalGP(toNumber(overridePrice));
  const marginGP = scaleNumber({
    number: recommendedGP + (toNumber(priceIncreasePts) || 0),
    scale: 5,
  });
  const contractGP = scaleNumber({ number: recommendedGP, scale: 5 });
  const agreementType = head(trim(priceOverrideAgreement));

  useEffect(() => {
    triggerResize?.(Date.now());
  }, [isLoading, triggerResize]);

  // #region form
  const { addToast } = useToasts();
  const { onUpdateActivity } = useUpdateActivity({
    userId,
    historyId: toNumber(historyId),
  });

  const {
    data: updatePriceOverrideData,
    status: updatePriceOverrideStatus,
    onUpdatePriceOverride,
  } = useUpdatePriceOverride({
    miLoc,
    ocn: orderNumber,
    lineNo,
  });

  const onSuccessSave = () => {
    onDone?.();
  };

  const {
    values,
    errors,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setSubmitting,
    isSubmitting,
    isValid,
  } = useFormik<IPriceOverrideForm>({
    initialValues: {
      overridePrice,
      overrideGP,
      overrideReason,
      finalGP,
      createOverrideException: false,
      createManufacturerMargin: false,
      updateUploadOverride: addToAgreement === 'Y',
      updateManufacturerMargin: false,
      marginGP,
      contractGP,
    },
    onSubmit: (formValues) => {
      onUpdateActivity({
        ...updateActivityData,
        activity_type: FirebaseEventNameEnum.price_override_action,
        disableRefetchActivity: true,
      });
      if (formValues.action) {
        let actionContract = '';
        let actionMargin = '';
        if (formValues.action === 'A') {
          if (formValues.createOverrideException) {
            actionContract = 'C';
          }
          if (formValues.updateUploadOverride) {
            actionContract = 'U';
          }
          if (formValues.createManufacturerMargin) {
            actionMargin = 'C';
          }
          if (formValues.updateManufacturerMargin) {
            actionMargin = 'U';
          }
        }
        onUpdatePriceOverride({
          historyId: toNumber(historyId),
          userId,
          action: toString(formValues.action),
          overridePrice: toString(formValues.overridePrice),
          overrideGp: toString(formValues.overrideGP),
          finalGp: toString(formValues.finalGP),
          overrideReason: formValues.overrideReason,
          actionContract,
          actionMargin,
          marginGp: toString(formValues.marginGP),
          contractGp: toString(formValues.contractGP),
        });
        return;
      }
      onSuccessSave();
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  useImperativeHandle(
    outerRef,
    () => ({
      onSubmit: handleSubmit,
    }),
    [handleSubmit]
  );

  useEffect(() => {
    if (updatePriceOverrideStatus === 'success') {
      onSuccessSave();
    }
    if (updatePriceOverrideData?.errorMsg) {
      addToast({
        duration: 0,
        type: ToastType.warn,
        text: updatePriceOverrideData?.errorMsg,
        testid: 'price-override-warning-toast',
      });
    }
    if (updatePriceOverrideStatus === 'error') {
      setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addToast,
    setSubmitting,
    updatePriceOverrideData?.errorMsg,
    updatePriceOverrideStatus,
  ]);

  const {
    validateOverridePrice,
    validateOverrideGP,
    validateMarginGP,
    validateContractGP,
    resetMarginGPError,
    resetContractGPError,
  } = usePriceOverrideFormValidations({
    overridePrice,
    overrideGP,
    finalGP,
    marginGP,
    contractGP,
    calculateOverrideGP,
    calculateOverridePrice,
    calculateFinalGP,
    values,
    overrideReasons,
    extendedInfo,
    setFieldValue,
    setFieldError,
  });

  const isApproving = values.action === 'A';
  const isRejecting = values.action === 'R';
  const isReadOnly = activity?.readOnly;
  const reasonText = getActivityDataValue(
    find(overrideReasons, {
      id: values.overrideReason,
    })?.codeName
  );
  const ReasonDropdown = (
    <Button
      rightIcon={isApproving ? findIcon('caret-down', 'fas') : undefined}
      onClick={() => {
        if (isApproving) {
          setIsReasonModalOpen(true);
        }
      }}
      disabled={isSubmitting}
      testid="override-reason-button"
    >
      <Text text={reasonText} />
    </Button>
  );
  const priceOverrideData = {
    ...(isOrder
      ? { orderNumberOrder: getActivityDataValue(orderNumber) }
      : { orderNumberQuote: getActivityDataValue(orderNumber) }),
    originalPrice: `${formatNumber({
      number: toNumber(fieldsData?.['Existing Price'] || 0),
      scale: 3,
    })}  ${priceSource ? `${toString(priceSource)}` : ''}`,
    overridePrice: {
      value: formatNumber({ number: toNumber(overridePrice), scale: 3 }),
      customComponent: isApproving ? (
        <Input
          className={classes.dataInput}
          name="overridePrice"
          value={toString(values.overridePrice)}
          error={errors.overridePrice}
          disabled={isSubmitting}
          currencyMask={{ scale: 3 }}
          setValue={(e) => {
            void setFieldValue('overridePrice', e);
            void validateOverridePrice(toNumber(e));
          }}
          onBlur={() =>
            validateOverridePrice(toNumber(values.overridePrice), true)
          }
          testid="override-price-input"
        />
      ) : undefined,
    },
    quantity: `${toNumber(salesUomQty) || 0} ${toString(uomDescription)}`,
    originalGP: `${toNumber(fieldsData?.['Existing GP %'] || 0)} %`,
    overrideGP: {
      value: `${overrideGP} %`,
      customComponent: isApproving ? (
        <Input
          className={classes.dataInput}
          name="overrideGP"
          value={toString(values.overrideGP)}
          error={errors.overrideGP}
          disabled={isSubmitting}
          numberMask={{ min: -1 * maxNumericValue, max: 100, scale: 5 }}
          setValue={(e) => {
            void setFieldValue('overrideGP', e);
            void validateOverrideGP(toNumber(e));
          }}
          onBlur={() => validateOverrideGP(toNumber(values.overrideGP), true)}
          testid="override-gp-input"
        />
      ) : undefined,
    },
    finalGP: {
      customComponent: (
        <Text
          className={classes.dataInput}
          text={`${toString(values.finalGP)} %${isRebate ? ' SSS' : ''}`}
          textQuery={[{ query: 'SSS', className: classes.pricingLabel }]}
        />
      ),
    },
    ...{
      overrideReason:
        isDone || isClosed ? reasonText : { customComponent: ReasonDropdown },
    },
  };
  const marginGPData = {
    manufacturer: getActivityDataValue(mfrCtlNo),
    productGroup: getActivityDataValue(prodGroupNo),
    mfrCurrentGP: getActivityDataValue(pgcMfrGPPct),
    mfrLastUpdated: getActivityDataValue(pgcMfrLastUpdated),
  };
  const additionalData = {
    line: lineNo,
    mino: getActivityDataValue(mino),
    mfrPartNo: getActivityDataValue(mfgPartNo),
    overrideUser: {
      value: getActivityDataValue(priceOverrideRequestor),
      employeeId: requestorEmpid,
    },
    agreementlastUpdated: getActivityDataValue(agreementLastUpdated),
  };
  const rateValue = isApproving
    ? RatingActivityEnum.positive
    : RatingActivityEnum.negative;

  // DOC: set first reason as initial value if not in extendedInfo
  useEffect(() => {
    if (!overrideReason) {
      void setFieldValue('overrideReason', head(overrideReasons)?.id);
    }
  }, [overrideReason, overrideReasons, setFieldValue]);
  // #endregion

  return (
    <div className={classes.content}>
      <Loader
        testid="loader"
        className={classes.loader}
        text={t('common:loading')}
        isOpen={!!isLoading}
      />
      {!isLoading && (
        <>
          {contentBeforeDetailsRef?.current &&
            createPortal(
              <AccountsReceivableCard
                miLoc={miLoc}
                id={toString(activity?.custNo)}
                className={classes.arWidgetCard}
                clickDisabled={!activity?.userLocationMatch}
              />,
              contentBeforeDetailsRef.current
            )}
          {!ac && !isDone && !isClosed && !isDeleted && (
            <WarningMessage
              className={classes.message}
              title={t('priceOverrideAccessErrorTitle')}
              body={t('priceOverrideAccessErrorBody')}
            >
              <div>
                <Button
                  variant="action"
                  text={t('feedback:feedback')}
                  className={classes.feedbackButton}
                  href={concatRoutes(homeURL(), feedbackURL())}
                  testid="price-override-approval-feedback-button"
                />
              </div>
            </WarningMessage>
          )}
          {isClosed && (
            <WarningMessage
              className={classes.message}
              title={t('priceOverridePendingTitle')}
              body={t('priceOverridePendingBody')}
            />
          )}
          {isDeleted && (
            <WarningMessage
              className={classes.message}
              title={t('priceOverrideDeletedTitle')}
              body={t('priceOverrideDeletedBody')}
            />
          )}
          {isDone && isApproved && (
            <div>
              <Text
                testid="price-override-approved-by"
                className={classes.approvedBy}
                text={t('priceOverrideApprovedMessage')}
              />
              <Button
                variant={isEmpty(approveRejectEmployeeId) ? 'clear' : 'link'}
                text={approveRejectUsername}
                className={classes.approvedBy}
                onClick={() =>
                  onEmployeeClick?.(toString(approveRejectEmployeeId))
                }
                testid="price-override-approval-employee-button"
                disabled={isEmpty(approveRejectEmployeeId)}
              />
            </div>
          )}
          {isDone && hasSentEmails && (
            <div className={classes.message}>
              <Text
                testid="price-override-emails-sent"
                text={`${t('confirmationSucceedToEmailBody', {
                  formatHour: formatDate(
                    lastDocumentTransmission?.transmissionTmstmp,
                    DateFormatEnum.ampmTime,
                    i18n.language
                  ),
                  formatDate: formatCardDate(
                    lastDocumentTransmission?.transmissionTmstmp,
                    false,
                    false,
                    i18n.language
                  ),
                })}`}
              />
              {map(lastDocumentTransmission?.sendToList, (email, idx) => (
                <Text key={idx} className={classes.listStyle} text={email} />
              ))}
            </div>
          )}
          {isDone && !hasSentEmails && failedToSendEmail && (
            <WarningMessage
              testid="price-override-emails-not-sent"
              variant={WarningMessageVarianEnum.warning}
              hideIcon
              className={classes.message}
              title={`${t('confirmationFailedToEmailTitle')}.`}
              body={`${t('confirmationFailedToEmailBody')}`}
            />
          )}
          {isDone && !isApproved && (
            <WarningMessage
              hideIcon
              className={classes.message}
              variant={WarningMessageVarianEnum.alert}
              title=""
            >
              <div>
                <Text
                  testid="price-override-rejected-by"
                  className={classes.rejectedBy}
                  variant="title-info-card"
                  text={t('priceOverrideRejectedMessage')}
                />
                <Button
                  variant={isEmpty(approveRejectEmployeeId) ? 'clear' : 'link'}
                  text={approveRejectUsername}
                  className={classes.rejectedBy}
                  onClick={() =>
                    onEmployeeClick?.(toString(approveRejectEmployeeId))
                  }
                  testid="price-override-rejection-employee-button"
                  disabled={isEmpty(approveRejectEmployeeId)}
                />
              </div>
            </WarningMessage>
          )}
          {!isDeleted && (
            <ActionCardModalData
              dataDefinition={priceOverrideDefinition}
              activityData={priceOverrideData}
              activity={activity}
              className={classNames({
                [classes.priceOverrideData]: !isReadOnly,
                [classes.priceOverrideDataReadOnly]: isReadOnly,
              })}
              labelClassName={classes.moreInfoLabel}
              ocn={orderNumber}
              ocnType={isOrder ? OcnTypeEnum.Order : OcnTypeEnum.Quote}
              miLoc={miLoc}
              customerId={customerId}
              showProductLines={false}
            />
          )}
          {ac && !isDone && !isClosed && !isDeleted && (
            <>
              {!isReadOnly && (
                <div className={classes.form}>
                  <div>
                    {!includes(['I'], agreementType) &&
                      addToAgreement === 'Y' &&
                      !isRejecting && (
                        <Text
                          className={classes.lastSoldMessage}
                          text={`${t('priceOverrideRequestorSuggests')}`}
                        />
                      )}
                    {includes(['A', 'D', 'J', 'F'], agreementType) && (
                      <Text
                        className={classes.sectionTitle}
                        variant="content-heavy"
                        text={t('createAgreement')}
                      />
                    )}
                    {includes(
                      ['B', 'C', 'E', 'K', 'G', 'H', 'N', 'Y'],
                      agreementType
                    ) && (
                      <Text
                        className={classes.sectionTitle}
                        variant="content-heavy"
                        text={t('updateAgreement')}
                      />
                    )}
                    {includes(['I', 'J', 'K'], agreementType) && (
                      <Text
                        className={classes.lastSoldMessage}
                        text={t('lastPriceMessage')}
                      />
                    )}
                    {includes(['A', 'F'], agreementType) && (
                      <IonItem
                        className={classes.formItem}
                        data-testid="override-exception-checkbox"
                      >
                        <CheckBox
                          ariaLabel="create-override-exception"
                          checked={values.createOverrideException}
                          disabled={isSubmitting || isRejecting}
                          name="createOverrideException"
                          testid="create-override-expection-checkbox"
                          label={t('overrideException')}
                          onChange={(checked) => {
                            void setFieldValue(
                              'createOverrideException',
                              checked
                            );
                            resetContractGPError(Boolean(checked));
                            triggerResize?.(Date.now());
                          }}
                        />
                      </IonItem>
                    )}
                    {includes(['D', 'J', 'F'], agreementType) && (
                      <IonItem
                        className={classes.formItem}
                        data-testid="create-margin-checkbox"
                      >
                        <CheckBox
                          ariaLabel="create manufacturer margin"
                          name="createManufacturerMargin"
                          checked={values.createManufacturerMargin}
                          disabled={isSubmitting || isRejecting}
                          onChange={(checked) => {
                            void setFieldValue(
                              'createManufacturerMargin',
                              checked
                            );
                            resetMarginGPError(Boolean(checked));
                            triggerResize?.(Date.now());
                          }}
                          label={t('pgcException')}
                          testid="create-manufacturer-margin-checkbox"
                        />
                      </IonItem>
                    )}
                    {includes(
                      ['B', 'C', 'G', 'H', 'N', 'Y'],
                      agreementType
                    ) && (
                      <IonItem
                        className={classes.formItem}
                        data-testid="upload-override-checkbox"
                      >
                        <CheckBox
                          ariaLabel="update upload override"
                          name="updateUploadOverride"
                          checked={values.updateUploadOverride}
                          testid="update-upload-override-checkbox"
                          disabled={isSubmitting || isRejecting}
                          onChange={(checked) => {
                            void setFieldValue('updateUploadOverride', checked);
                            resetContractGPError(Boolean(checked));
                            triggerResize?.(Date.now());
                          }}
                          label={t('uploadAgreement')}
                        />
                      </IonItem>
                    )}
                    {(values.updateUploadOverride ||
                      values.createOverrideException) && (
                      <IonRow className={classes.marginRow}>
                        <Text
                          className={classes.marginLabel}
                          variant="content-heavy"
                          text={t('common:gpPercentage')}
                        />
                        <Input
                          className={classes.marginInput}
                          name="contractGP"
                          numberMask={{
                            min: -1 * maxNumericValue,
                            max: 100,
                            scale: 5,
                          }}
                          value={toString(values.contractGP)}
                          error={errors.contractGP}
                          setValue={(e) => {
                            void setFieldValue('contractGP', e);
                          }}
                          onBlur={() =>
                            validateContractGP(
                              or(
                                !!values.createOverrideException,
                                !!values.updateUploadOverride
                              )
                            )
                          }
                          disabled={isSubmitting || isRejecting}
                          testid="contract-gp-input"
                        />
                      </IonRow>
                    )}
                    {includes(
                      ['E', 'K', 'G', 'H', 'N', 'Y'],
                      agreementType
                    ) && (
                      <IonItem
                        className={classes.formItem}
                        data-testid="update-margin-checkbox"
                      >
                        <CheckBox
                          ariaLabel="update manufacturer margin"
                          name="updateManufacturerMargin"
                          checked={values.updateManufacturerMargin}
                          disabled={isSubmitting || isRejecting}
                          label={t('updatePgcMargin')}
                          onChange={(checked) => {
                            void setFieldValue(
                              'updateManufacturerMargin',
                              checked
                            );
                            resetMarginGPError(Boolean(checked));
                            triggerResize?.(Date.now());
                          }}
                          testid="update-manufacturer-margin"
                        />
                      </IonItem>
                    )}
                    {(values.createManufacturerMargin ||
                      values.updateManufacturerMargin) && (
                      <>
                        <IonRow className={classes.marginRow}>
                          <Text
                            className={classes.marginLabel}
                            variant="content-heavy"
                            text={t('common:gpPercentage')}
                          />
                          <Input
                            className={classes.marginInput}
                            name="marginGP"
                            numberMask={{
                              min: -1 * maxNumericValue,
                              max: 100,
                              scale: 5,
                            }}
                            value={toString(values.marginGP)}
                            error={errors.marginGP}
                            setValue={(e) => {
                              void setFieldValue('marginGP', e);
                            }}
                            onBlur={() =>
                              validateMarginGP(
                                or(
                                  !!values.createManufacturerMargin,
                                  !!values.updateManufacturerMargin
                                )
                              )
                            }
                            disabled={isSubmitting || isRejecting}
                            testid="margin-gp-input"
                          />
                        </IonRow>
                        <ActionCardModalData
                          dataDefinition={priceOverrideDefinition}
                          activityData={marginGPData}
                          activity={activity}
                          className={classes.marginGPData}
                          labelClassName={classes.moreInfoLabel}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}
              <div
                className={classNames({
                  [classes.moreInfoWrapper]: !isReadOnly,
                  [classes.moreInfoWrapperReadOnly]: isReadOnly,
                })}
              >
                <ActionCardModalData
                  dataDefinition={priceOverrideDefinition}
                  activityData={additionalData}
                  activity={activity}
                  labelClassName={classes.moreInfoLabel}
                  onContactClick={onContactClick}
                  className={classNames({
                    [classes.lowerActionCardReadOnly]: isReadOnly,
                  })}
                  onEmployeeClick={onEmployeeClick}
                />
                {size(notebooks) > 0 && (
                  <div className={classes.notebook}>
                    <div className={classes.notebookRow}>
                      <Text
                        className={classes.notebookLabel}
                        variant="content-heavy"
                        text={t('notebook')}
                      />
                    </div>
                    {map(notebooks, ({ text }, index) => (
                      <Text
                        key={index}
                        className={classes.notebookText}
                        text={text}
                      />
                    ))}
                  </div>
                )}
              </div>
              {!isReadOnly && (
                <RatingButtons
                  className={classNames({
                    [classes.rateButtons]: isApproving,
                  })}
                  rating={values.action ? rateValue : undefined}
                  positiveText={t('common:approve')}
                  negativeText={t('common:reject')}
                  setRating={(r) => {
                    if (r === RatingActivityEnum.positive) {
                      void validateContractGP(
                        or(
                          !!values.createOverrideException,
                          !!values.updateUploadOverride
                        )
                      );
                      void validateMarginGP(
                        or(
                          !!values.createManufacturerMargin,
                          !!values.updateManufacturerMargin
                        )
                      );
                    } else {
                      setFieldError('contractGP', undefined);
                      setFieldError('marginGP', undefined);
                    }

                    void setFieldValue(
                      'action',
                      r === RatingActivityEnum.positive ? 'A' : 'R'
                    );
                    onCompleteActivityFlag?.(true);
                    onFollowUpActivityFlag?.(false);
                    onUnSnooze?.();
                    disableActivityFlags?.(true);
                    onContentChange?.();
                    triggerResize?.(Date.now());
                  }}
                  disabled={isSubmitting}
                />
              )}
            </>
          )}
        </>
      )}
      {footerRef?.current &&
        shouldRenderSaveButton &&
        createPortal(
          <Button
            className={classes.button}
            variant="action"
            text={t('common:save')}
            onClick={() => {
              if (hasPendingComment) {
                setIsDiscardCommentOpen?.(true);
                return;
              }
              handleSubmit();
            }}
            disabled={!didChange || !isValid || isSubmitting}
            testid="save-button"
          />,
          footerRef?.current
        )}
      <OptionsListModal
        isOpen={isReasonModalOpen}
        setIsOpen={setIsReasonModalOpen}
        title={t('activities:sourcingOverride:reason')}
        selectedItem={values.overrideReason}
        filterOptions={map(overrideReasons, ({ id, codeName }) => ({
          key: id,
          name: codeName,
        }))}
        onOptionClick={(option) => {
          void setFieldValue('overrideReason', option.key);
          setIsReasonModalOpen(false);
          triggerResize?.(Date.now());
        }}
        testid="override-reason-modal"
      />
    </div>
  );
});

export default PriceOverrideForm;
