import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import {
  filter,
  forEach,
  get,
  groupBy,
  isEmpty,
  join,
  last,
  map,
  size,
  split,
  toNumber,
  toString,
} from 'lodash';
import type { ScrollDetail } from '@ionic/core/components';
import { IonContent, IonPage, IonRow } from '@ionic/react';
import {
  differenceInDays,
  getUnixTime,
  isSameDay,
  startOfDay,
  startOfToday,
} from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import { useDebouncedCallback } from 'use-debounce';
import useFindActivities from 'api/activities/useFindActivities';
import useFindAttachments from 'api/attachments/useFindAttachments';
import useGetCustomer from 'api/customer/useGetCustomer';
import { ActivityType } from 'models/ActivityModels';
import type { Contact } from 'models/Contact';
import type { DateSlideIcon, DateSlideIconList } from 'models/DateSlider';
import type { FilterSection } from 'models/Filters';
import { FilterSectionEnum } from 'models/Filters';
import { IconColor } from 'models/Legend';
import type { SnapshotsURLParams } from 'models/Search';
import type { RootState } from 'store/reducers';
import { formatCardDate, parseDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { getIsCorpAccount, getSnapshotEyebrow } from 'utils/search';
import ActionRow from 'components/ActionRow/ActionRow';
import VisitCard from 'components/Activities/VisitCard/VisitCard';
import Button from 'components/Button/Button';
import DateSlider from 'components/DateSlider/DateSlider';
import Header from 'components/Header/Header';
import HelpModal from 'components/HelpModal/HelpModal';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import FiltersModal from 'components/Modals/FiltersModal/FiltersModal';
import Refresher from 'components/Refresher/Refresher';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './VisitHistory.module.scss';

const VisitHistory = (): JSX.Element => {
  const { miLoc, id } = useParams<SnapshotsURLParams>();
  const searchType = 'customer';
  const { t } = useTranslation(namespaces.snapshot);

  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const today = getUnixTime(startOfToday());

  const { data: customerData } = useGetCustomer({ searchType, miLoc, id });

  const listRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const scrollTopRef = useRef<number>(0);
  const currentScrollDate = useRef<number>(0);
  const [visitContact, setVisitContact] = useState('');
  const [dateRange, setDateRange] = useState<number[]>([0, 0]);
  const [startDate, setStartDate] = useState<number>(0);
  const [endDate, setEndDate] = useState<number>(today);
  const [sliderDate, setSliderDate] = useState<number>(today);
  const [scrollTrigger, setScrollTrigger] = useState<number>();
  const [isOpenHelpModal, setIsOpenHelpModal] = useState<boolean>(false);

  const filters: FilterSection[] = useMemo(
    () => [
      {
        key: 'visitContact',
        label: t('customerContact'),
        type: FilterSectionEnum.search,
        selectedValue: visitContact,
      },
      {
        key: 'dateRange',
        label: t('dateRange'),
        type: FilterSectionEnum.dateRange,
        selectedValue: join(dateRange, '#'),
      },
    ],
    [dateRange, visitContact, t]
  );

  const onFiltersDone = (iFilters: FilterSection[]) => {
    forEach(iFilters, ({ key, selectedValue = '' }) => {
      const selectedRange = split(selectedValue, '#');
      const startRange = toNumber(selectedRange[0] || 0);
      const endRange = toNumber(selectedRange[1] || 0);
      switch (key) {
        case 'visitContact':
          setVisitContact(selectedValue);
          break;
        case 'dateRange':
          setDateRange([startRange, endRange]);
          setStartDate(startRange > 0 ? startRange : 0);
          setEndDate(endRange > 0 ? endRange : today);
          break;
        default:
      }
    });
  };

  // DOC: get all attachments
  useFindAttachments({
    domain: 'mprovisi',
    // TODO: api is hardcoding these values
    miLoc: 'EXEC',
    ctlNo: userId,
  });

  const {
    activities,
    error,
    fetchNextPage,
    hasError,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    refetch,
  } = useFindActivities({
    miLoc,
    id,
    natlAccNo: customerData?.natlAcctNo,
    fetchOtherUsers: true,
    visitContact,
    startDate: startDate > 0 ? startDate : undefined,
    endDate: endDate > 0 ? endDate : today,
    filter: {
      showSnoozed: true,
      eventTagName: ActivityType.customerVisit,
      extendedInfoEventTagName: ActivityType.customerVisit,
    },
    sortField: 'visitDate',
    enabled: true,
  });

  const sliderIcons: DateSlideIcon[] = useMemo(
    () =>
      map(
        groupBy(activities, ({ extendedInfo }) =>
          formatCardDate(
            toString(extendedInfo?.visitDateWithZimeZoneCreated),
            true,
            false
          )
        ),
        (evs) => {
          const icons: DateSlideIconList[] = [];
          const active = false;
          const currentUserEvs = filter(evs, { userId });
          if (size(currentUserEvs) > 0) {
            icons.push({
              icon: findIcon('check'),
              active:
                size(
                  filter(
                    currentUserEvs,
                    (ev) =>
                      size(ev.extendedInfo?.contacts as Contact[]) > 0 ||
                      !isEmpty(ev.extendedInfo?.notebookText) ||
                      size(filter(ev.children, { done: 'Y' })) > 0
                  )
                ) > 0,
            });
          }
          const otherUserEvs = filter(evs, (ev) => ev.userId !== userId);
          if (size(otherUserEvs) > 0) {
            icons.push({
              icon:
                size(
                  filter(evs, (ev) => ev.fieldsData?.['Is Specialist'] === 'Y')
                ) > 0
                  ? findIcon('wrench', 'fas')
                  : findIcon('user', 'fas'),
            });
          }
          return {
            date: getUnixTime(
              startOfDay(
                parseDate(
                  toString(evs[0].extendedInfo?.visitDateWithZimeZoneCreated)
                )
              )
            ),
            icons,
            active,
          };
        }
      ),
    [activities, userId]
  );

  const onDateChange = useDebouncedCallback(
    (date: number) => setSliderDate(date),
    300
  );

  const scrollToPoint = useCallback(
    (scrollTop: number) => {
      const items = listRef.current?.children;
      const listScrollTop = scrollTop;
      for (let i = 0; i < size(items); i += 1) {
        const item = items?.[i] as HTMLElement;
        const itemScrollTop = item.offsetTop;
        const itemHeight = item.offsetHeight;
        if (itemHeight + itemScrollTop > listScrollTop) {
          const scrollDate = getUnixTime(
            startOfDay(
              parseDate(
                toString(
                  activities[i]?.extendedInfo?.visitDateWithZimeZoneCreated
                )
              )
            )
          );
          currentScrollDate.current = scrollDate;
          setSliderDate(scrollDate);
          setScrollTrigger(Date.now());
          break;
        }
      }
    },
    [activities]
  );

  const onContentScroll = useDebouncedCallback(
    (activity: CustomEvent<ScrollDetail>) => {
      const listScrollTop = activity.detail.scrollTop || 0;
      scrollTopRef.current = listScrollTop;
      scrollToPoint(listScrollTop);
    },
    300
  );

  useEffect(() => {
    if (differenceInDays(parseDate(sliderDate), parseDate(endDate)) > 0) {
      setSliderDate(endDate);
    }
  }, [endDate, sliderDate]);

  const scrollListBySlider = useCallback(() => {
    const items = listRef.current?.children;
    for (let i = 0; i < size(items); i += 1) {
      const item = items?.[i] as HTMLElement;
      // DOC: don't scroll directly to the offsetTop
      // to avoid scrolling into the previous date
      const itemScrollTop = item.offsetTop + 4;
      const scrollDate = getUnixTime(
        startOfDay(
          parseDate(
            toString(activities[i].extendedInfo?.visitDateWithZimeZoneCreated)
          )
        )
      );
      if (isSameDay(parseDate(sliderDate), parseDate(scrollDate))) {
        currentScrollDate.current = scrollDate;
        void contentRef.current?.scrollToPoint(null, itemScrollTop, 300);
        break;
      }
    }
  }, [activities, sliderDate]);

  useEffect(() => {
    if (
      size(activities) > 0 &&
      differenceInDays(
        parseDate(currentScrollDate.current),
        parseDate(sliderDate)
      ) !== 0
    ) {
      scrollListBySlider();
    }
  }, [activities, sliderDate, scrollListBySlider]);

  useEffect(() => {
    if (size(activities) > 0 && scrollTopRef.current === 0) {
      scrollToPoint(0);
    }
  }, [scrollToPoint, activities]);

  const lastItemHeight = (last(listRef.current?.children) as HTMLElement)
    ?.offsetHeight;

  const helpModalItemsList = [
    {
      icon: findIcon('check'),
      color: IconColor.green,
      text: t('visitLegendCheckOff'),
    },
    {
      icon: findIcon('user', 'fas'),
      text: t('visitLegendAnotherRep'),
    },
    {
      icon: findIcon('wrench', 'fas'),
      text: t('visitLegendSpecialist'),
    },
  ];

  return (
    <IonPage className={classes.VisitHistory} data-testid="VisitHistory-page">
      <Header
        title={t('visitHistory')}
        eyebrow={getSnapshotEyebrow({ name: customerData?.name, miLoc, id })}
        withBackButton
        testid="visitHistory"
      >
        <IonRow className={classes.headerRow}>
          <FiltersModal
            className={classes.filters}
            title={t('filterVisitHistory')}
            filters={filters}
            onDone={onFiltersDone}
          />
          <div className={classes.spaceFiller} />
          <Button
            variant="icon-action"
            testid="help-icon-button"
            icon={findIcon('info-circle', 'far')}
            className={classes.helpIcon}
            onClick={() => setIsOpenHelpModal(true)}
          />
        </IonRow>
        <DateSlider
          icons={sliderIcons}
          date={sliderDate}
          onDateChange={onDateChange}
          scrollTrigger={scrollTrigger}
          startDate={startDate}
          endDate={endDate}
          isLoading={showLoader}
        />
      </Header>
      <IonContent
        ref={contentRef}
        className={classes.content}
        scrollEvents
        onIonScroll={onContentScroll}
      >
        <Refresher
          slot="fixed"
          hidden
          onRefresh={refetch}
          disabled={showLoader}
          testid="VisitHistory"
        />

        <div ref={listRef}>
          {!hasError &&
            map(activities, (ev) => (
              <VisitCard
                key={`${ev.historyId}${ev.userId}`}
                className={classes.activityCard}
                historyId={ev.historyId}
                activity={{
                  ...ev,
                  isForCorpAcct: getIsCorpAccount(
                    customerData?.customerNo,
                    customerData?.natlAcctNo
                  ),
                }}
                testid="visit-activity"
              />
            ))}
        </div>

        {isEmptyResponse && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title={t('activities:noActivitiesMessage')}
          />
        )}
        {hasError && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('activities:errorLoading')}
            body={getErrorMessage(error)}
          />
        )}
        <Loader
          className={classes.loader}
          text={t('activities:loadingActivities')}
          isOpen={showLoader}
          testid="loading-activities"
        />
        <InfiniteScroll
          disabled={!enableInfiniteScroll}
          onLoadMore={fetchNextPage}
          testid="infinite-scroll"
        />
        <div
          style={{ height: `calc(100% - ${(lastItemHeight || 0) - 4}px)` }}
        />

        <HelpModal
          title={t('iconKey')}
          isOpen={isOpenHelpModal}
          closeHelpModal={() => setIsOpenHelpModal(false)}
          testid="help-modal"
          initialBreakpoint={0.35}
          className={classes.modelContent}
          variant="key"
        >
          <div className={classes.helpModalWrapper}>
            {map(helpModalItemsList, ({ icon, text, color }, index) => (
              <ActionRow
                key={index}
                leftButton={{
                  className: classNames(color ? classes[color] : undefined),
                  icon,
                  testid: 'help-modal-icon',
                }}
                text={text}
                testid="visit-history-help-modal-row"
                className={classes.actionRow}
              />
            ))}
          </div>
        </HelpModal>
      </IonContent>
    </IonPage>
  );
};

export default VisitHistory;
