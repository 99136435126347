import { includes, round, upperCase, toString, split } from 'lodash';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { MiProFile } from 'models/Attachment';
import { findIcon } from 'utils/icons';

export const updateFileSizeFlag = (
  fileArray: MiProFile[],
  callback?: (fileSizeExceed: boolean) => void
): boolean => {
  const totalSize: number = fileArray.reduce((acc, fileElem: File) => {
    return acc + fileElem.size;
  }, 0);
  callback?.(totalSize / 1000000 > 25);
  return totalSize / 1000000 > 25;
};

export const isImageFile = (fileType: string) => {
  const fileExtension = upperCase(fileType);
  return (
    fileType.includes('image') ||
    includes(
      [
        'APNG',
        'AVIF',
        'GIF',
        'JPEG',
        'JPG',
        'JFIF',
        'PJPEG',
        'PJP',
        'PNG',
        'SVG',
        'WEBP',
        'BMP',
        'ICO',
        'TIF',
        'TIFF',
        'GIFV',
      ],
      fileExtension
    )
  );
};

export const iconFinder = (fileType: string): IconProp => {
  const fileExtension = fileType.toUpperCase();
  if (
    fileType.includes('video') ||
    includes(['AVI', 'MOV', 'QT', 'MP4', 'WMV', 'FLV'], fileExtension)
  ) {
    return findIcon('file-video');
  }
  if (isImageFile(fileType)) {
    return findIcon('file-image');
  }
  if (fileType.includes('pdf')) {
    return findIcon('file-pdf');
  }
  if (
    fileType.includes('spreadsheetml') ||
    includes(['XLSX', 'XLSM', 'XLSB', 'XLTX', 'XLS', 'XLT'], fileExtension)
  ) {
    return findIcon('file-spreadsheet');
  }
  if (
    fileType.includes('text/csv') ||
    includes(['TXT', 'CSV'], fileExtension)
  ) {
    return findIcon('file-csv');
  }
  if (
    fileType.includes('presentationml') ||
    fileType.includes('powerpoint') ||
    includes(['PPTX', 'PPTM', 'PPT'], fileExtension)
  ) {
    return findIcon('file-powerpoint');
  }
  return findIcon('file-alt');
};

export const attachmentAcceptedFiles =
  'image/*,.ppt,.pptx,.xls,.xlsx,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,video/*,.pdf,.eml,.msg';

export const fileSizeSuffix = (size: number): string => {
  const fileSize = size / 1000000;
  if (fileSize >= 1) {
    return `${round(fileSize, 2)} MB`;
  }
  if (fileSize * 1000 >= 1) {
    return `${round(fileSize * 1000, 2)} KB`;
  }
  return `${size} B`;
};

export const getFileName = (file?: MiProFile): string => {
  let fileName = file?.USER_FILE;
  fileName ||= file?.userFile;
  fileName ||= file?.FILE_NAME;
  fileName ||= file?.fileName;
  return toString(fileName);
};

export const getFileExtension = ({
  file,
  fileName,
}: {
  file?: MiProFile;
  fileName?: string;
}) => {
  let name = fileName;
  name ||= getFileName(file);
  return toString(split(name, '.').pop());
};

export const getAttachmentProps = ({
  file,
  domain,
}: {
  file?: MiProFile;
  domain?: string;
}) => {
  let entity = file?.ENTITY;
  entity ||= file?.entity;
  entity ||= domain;

  let miLoc = file?.MI_LOC;
  miLoc ||= file?.miLoc;

  let ctlNo = file?.CTL_NO;
  ctlNo ||= file?.ctlNo;

  let lineNo = file?.LINE_NO;
  lineNo ||= file?.lineNo;

  let seqNo = file?.SEQ_NO;
  seqNo ||= toString(file?.seqNo);
  const fileName = getFileName(file as unknown as MiProFile);

  return {
    domain: toString(entity),
    miLoc: toString(miLoc),
    ctlNo: toString(ctlNo),
    lineNo: toString(lineNo),
    seqNo: toString(seqNo),
    fileName,
  };
};

export const isPreviewDisabled = ({ file }: { file?: MiProFile }) => {
  const { miLoc, ctlNo, lineNo, seqNo, fileName } = getAttachmentProps({
    file,
  });
  return !miLoc || !ctlNo || !lineNo || !seqNo || !fileName;
};
