import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  get,
  kebabCase,
  findIndex,
  forEach,
  isEmpty,
  map,
  size,
  toString,
  trim,
  isNil,
  filter,
} from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonItem, IonLabel, IonRow } from '@ionic/react';
import useCustomerVisit from 'ActivitiesApp/api/useCustomerVisit';
import type { ActivityPageBaseProps } from 'ActivitiesApp/models/ActivityDetail';
import {
  VisitTypes,
  type RateVisitContact,
  type VisitContact,
} from 'ActivitiesApp/models/CustomerVisit';
import type { ActivityComponentRef } from 'ActivitiesApp/pages/ActivityDetail';
import { getVisitAttachmentsIds } from 'ActivitiesApp/utils/helpers';
import Alert, { AlertVariantEnum } from 'common/components/Alert/Alert';
import Input from 'common/components/Forms/Input/Input';
import { and, choose, ifRender, or } from 'common/utils/logicHelpers';
import { endOfDay, getUnixTime } from 'date-fns';
import { useFormik } from 'formik';
import { namespaces } from 'i18n/i18n.constants';
import { useToasts } from 'providers/ToastProvider';
import * as yup from 'yup';
import useFindAttachments from 'api/attachments/useFindAttachments';
import useRemoveAttachment from 'api/attachments/useRemoveAttachment';
import useUpdateAttachmentDetails from 'api/attachments/useUpdateAttachmentDetails';
import useUploadFileAttachment from 'api/attachments/useUploadFileAttachment';
import useGetCustomer from 'api/customer/useGetCustomer';
import type { ActionCardActivity } from 'models/ActivityModels';
import { ActivityType, RatingActivityEnum } from 'models/ActivityModels';
import { AttachmentSize, type MiProFile } from 'models/Attachment';
import type { Contact } from 'models/Contact';
import type { RootState } from 'store/reducers';
import {
  DateFormatEnum,
  formatDate,
  formatSnoozeDate,
  parseDate,
} from 'utils/date';
import { findIcon } from 'utils/icons';
import {
  getActivityConfig,
  getActivityType,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';
import Attachments from 'components/Attachments/Attachments';
import FileAttachments from 'components/Attachments/FileAttachments';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import DateInput from 'components/DateInput/DateInput';
import DatePickerModal from 'components/DatePickerModal/DatePickerModal';
import ContactsList from 'components/Modals/SendEmailModal/ContactsList';
import Text from 'components/Text/Text';
import ShareWithTeamHelpButton from './ShareWithTeamHelpButton/ShareWithTeamHelpButton';
import classes from './VisitForm.module.scss';
import VisitFormLine from './VisitFormLine';

export type VisitFormData = {
  title?: string;
  text: string;
  metWithContact: boolean;
  files: MiProFile[];
  visitContactsValues?: Contact[];
  visitor?: Visitor;
  visitType: VisitTypes | string;
  newOpportunity: string;
  subject: string;
  estimatedValue: string;
  estimatedCloseDate: string;
};

interface Visitor {
  name: string;
}

const VisitForm = React.forwardRef<ActivityComponentRef, ActivityPageBaseProps>(
  (props, outerRef): JSX.Element => {
    const {
      historyId,
      userId = '',
      activity,
      activityData,
      updateActivityData,
      readonly = false,
      isSameUser = false,
      didChange,
      footerRef,
      customerVisitedByNonRep = false,
      customerVisitByOthers = false,
      shouldRenderSaveButton,
      hasPendingComment,
      setIsDiscardCommentOpen,
      onDone,
      onUpdatingForm,
      onContentChange,
      triggerResize,
    } = props;

    const custMiLoc = toString(activity?.custMiLoc);
    const custNo = toString(activity?.custNo);

    const { data: customerData } = useGetCustomer({
      searchType: 'customer',
      miLoc: custMiLoc,
      id: custNo,
    });
    const { userInfo } = useSelector((state: RootState) => state.user);
    const loggedInUserId = get(userInfo, 'userid', '');
    const loggedInUserEmployeeNumber = get(userInfo, 'employeenumber', '');
    const miLoc = toString(activity?.custMiLoc);
    const id = toString(activity?.custNo);
    const { t, i18n } = useTranslation(namespaces.activities);

    const { addToast } = useToasts();
    const { uploadAttachment } = useUploadFileAttachment({
      domain: 'mprovisi',
      // TODO: api is hardcoding these values
      miLoc: 'EXEC',
      ctlNo: userId,
    });

    const { removeAttachment } = useRemoveAttachment({
      domain: 'mprovisi',
      // TODO: api is hardcoding these values
      miLoc: 'EXEC',
      ctlNo: userId,
    });
    const {
      data: createdData,
      status,
      onCustomerVisit,
    } = useCustomerVisit({
      userId,
      miLoc,
      id,
      historyId,
    });
    const [isOpen, setIsOpen] = useState(false);
    const [visitDate, setVisitDate] = useState<Date>(new Date());
    const [listIsOpen, setListIsOpen] = useState(false);

    const showCreateOpportunity = or(
      toString(activityData?.newOpportunity) !== 'Y',
      isNil(historyId)
    );

    const updatedDate = formatSnoozeDate(
      toString(activity?.extendedInfo?.visitDateWithZimeZoneCreated),
      i18n.language
    );

    useEffect(() => {
      if (!isNil(activity?.extendedInfo?.visitDateWithZimeZoneCreated)) {
        const date = new Date(
          toString(activity?.extendedInfo?.visitDateWithZimeZoneCreated)
        );
        setVisitDate(date);
      }
    }, [activity?.extendedInfo?.visitDateWithZimeZoneCreated]);

    const { attachmentUserId, filteredHistoryId } =
      getVisitAttachmentsIds(activity);

    const { updateAttachmentDetails } = useUpdateAttachmentDetails({
      domain: 'mprovisi',
      miLoc: 'EXEC',
      ctlNo: attachmentUserId,
      lineNo: toString(historyId),
    });

    const { data: attachmentsData } = useFindAttachments({
      domain: 'mprovisi',
      // TODO: api is hardcoding these values
      miLoc: 'EXEC',
      ctlNo: attachmentUserId,
      lineNo: filteredHistoryId,
      enabled: and(!isEmpty(attachmentUserId), !isEmpty(filteredHistoryId)),
    });

    const initialVisitContacts: Contact[] = useMemo(
      () =>
        map(
          activityData?.contacts as VisitContact[],
          ({ contactSeqNo, contactName }) => ({
            sequenceNo: contactSeqNo,
            name: trim(contactName),
          })
        ),
      [activityData?.contacts]
    );

    const initialVistor = useMemo(() => {
      const visitor = activityData?.visitor as Visitor;
      return choose(visitor?.name, visitor, {
        name: activity?.userFullName,
      }) as Visitor;
    }, [activity?.userFullName, activityData]);

    const initialContacts = useMemo(
      () => choose(!isEmpty(initialVisitContacts), initialVisitContacts),
      [initialVisitContacts]
    );

    const [filesForUpload, setFilesForUpload] = useState<MiProFile[]>([]);
    const [filesForRemoval, setFilesForRemoval] = useState<MiProFile[]>([]);

    const validationSchema = yup.object().shape({
      text: yup.string().required(t('activities:customerVisitDetailsRequired')),
      subject: yup.string().when('newOpportunity', {
        is: (value: string) => value === 'Y' && showCreateOpportunity,
        then: (schema) =>
          schema.required(
            t('activities:visitRequiredField', {
              field: t('activities:visitSubject'),
            })
          ),
      }),
      estimatedValue: yup.string().when('newOpportunity', {
        is: (value: string) => value === 'Y' && showCreateOpportunity,
        then: (schema) =>
          schema.required(
            t('activities:visitRequiredField', {
              field: t('activities:visitEstimated'),
            })
          ),
      }),

      estimatedCloseDate: yup.string().when(['newOpportunity'], {
        is: (value: string) => value === 'Y' && showCreateOpportunity,
        then: (schema) =>
          schema.required(
            t('activities:visitRequiredField', {
              field: t('activities:visitCloseDate'),
            })
          ),
      }),
    });
    const {
      values,
      submitForm,
      isSubmitting,
      setFieldValue,
      errors,
      isValid,
      validateField,
    } = useFormik<VisitFormData>({
      enableReinitialize: true,
      initialValues: {
        text: toString(activityData?.notebookText),
        metWithContact: size(initialVisitContacts) > 0,
        visitContactsValues: initialContacts,
        visitor: initialVistor,
        files: map(attachmentsData, (x) => x),
        visitType: or(
          activity?.extendedInfo?.visitType as string,
          VisitTypes.Visit
        ),
        newOpportunity: or(toString(activityData?.newOpportunity), 'N'),
        subject: '',
        estimatedValue: '',
        estimatedCloseDate: '',
      },
      onSubmit: ({ text, metWithContact, visitContactsValues }) => {
        const visitContacts: RateVisitContact[] = [];
        if (metWithContact) {
          forEach(visitContactsValues, ({ sequenceNo, name }) => {
            visitContacts.push({
              miLoc: toString(miLoc),
              customerNo: toString(id),
              sequenceNo,
              name,
              delete: false,
            });
          });
          forEach(initialVisitContacts, ({ sequenceNo, name }) => {
            if (
              findIndex(visitContactsValues, {
                sequenceNo,
              }) === -1
            ) {
              visitContacts.push({
                miLoc: toString(miLoc),
                customerNo: toString(id),
                sequenceNo,
                name,
                delete: true,
              });
            }
          });
        } else {
          forEach(initialVisitContacts, ({ sequenceNo, name }) => {
            visitContacts.push({
              miLoc: toString(miLoc),
              customerNo: toString(id),
              sequenceNo,
              name,
              delete: true,
            });
          });
        }
        const notebookToSend = { text };
        choose(historyId, () => {
          map(filesForUpload, (file) => {
            uploadAttachment({
              file,
              lineNo: toString(historyId),
              src: file.fileURL,
            });
          });
          map(filesForRemoval, (file) => removeAttachment(file));
        })?.();
        onCustomerVisit({
          // TODO: hardcoding rating value so API doesn't fail
          rating: RatingActivityEnum.positive,
          contacts: visitContacts,
          notebook: notebookToSend,
          ...updateActivityData,
          filesForUpload,
          visitDate,
          visitType: values.visitType,
          visitDateWithZimeZoneCreated: visitDate,
          newOpportunity: values.newOpportunity,
          subject: values.subject,
          estimatedValue: values.estimatedValue,
          estimatedCloseDate: values.estimatedCloseDate,
          createOpportunity:
            values.newOpportunity === 'Y' &&
            (toString(activityData?.newOpportunity) !== 'Y' ||
              isNil(historyId)),
        });
      },
      validationSchema,
      validateOnBlur: true,
      validateOnChange: false,
    });

    const isLoggingAVisit = !historyId;

    useEffect(() => {
      choose(and(status === 'success', isLoggingAVisit), () => {
        addToast({
          text: t(
            choose(
              values.visitType === VisitTypes.Visit,
              'snapshot:visitToast',
              'snapshot:newCallToast'
            ) as string
          ),
          testid: 'rate-visit-toast',
        });
      })?.();

      choose(status === 'success', () => {
        onDone?.(createdData?.historyId);
      })?.();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useImperativeHandle(
      outerRef,
      () => ({
        onSubmit: submitForm,
      }),
      [submitForm]
    );

    const handleVisitContactChange = async (newValues?: unknown[]) => {
      onContentChange?.();
      triggerResize?.(Date.now());
      await setFieldValue('metWithContact', size(newValues) > 0);
      await setFieldValue('visitContactsValues', newValues);
    };

    const disableEdit = or(customerVisitedByNonRep, customerVisitByOthers);

    const visitOptions = [
      { displayValue: t('activities:visit'), value: VisitTypes.Visit },
      { displayValue: t('activities:call'), value: VisitTypes.Call },
    ];

    const {
      activityConfig: {
        card: {
          body: { lines: cardLines },
        },
      },
    } = getActivityConfig({
      cardType: getActivityType(toString(activity?.eventTagName), t).cardType,
      activityData: activity as ActionCardActivity,
      t,
      i18n,
      loggedInUserId,
    });

    const validateSingleField = async (field: string) => {
      await validateField(field);
    };

    const VisitDescription = (
      <IonItem lines="none" className={classes.visitDescription}>
        <Text
          variant="content-default"
          text={toString(cardLines?.[1].description)}
          testid="visit-description"
          textQuery={cardLines?.[1].highlight}
        />
      </IonItem>
    );

    const hasAttachments = !isEmpty(values.files);

    return (
      <div className={classes.visitForm}>
        {ifRender(
          and(!readonly, !customerVisitedByNonRep, !customerVisitByOthers),
          <>
            <div className={classes.visitDateSelect}>
              <Text
                text={formatSnoozeDate(visitDate, i18n.language)}
                variant="mipro-h2-headline"
                className={classes.text}
              />
              <Button
                className={classes.editButton}
                variant="link"
                text={t('common:edit')}
                rightIcon={findIcon('pen', 'far')}
                onClick={() => setIsOpen(true)}
                testid="edit-button"
              />
            </div>
            <VisitFormLine
              required
              label={t('activities:engagementType')}
              fieldName="visitType"
              value={values.visitType}
              radioOptions={visitOptions}
              onChange={async (e) => {
                onContentChange?.();
                await setFieldValue('visitType', e);
              }}
              isSameUser={isSameUser}
              disabled={!isNil(historyId)}
            />
            {ifRender(
              and(!isEmpty(customerData?.natlAcctNo), showCreateOpportunity),
              <>
                <IonRow>
                  <Text
                    text={t('activities:visitOpportunityMsg', {
                      visitType: visitOptions.find(
                        (item) => item.value === values.visitType
                      )?.displayValue,
                    })}
                    variant="mipro-body-copy"
                    className={classes.opportunityMessage}
                  />
                </IonRow>
                <IonRow className={classes.opportunityLine}>
                  <CheckBox
                    label={t('activities:visitOpportunityCheckBox')}
                    name="newOpportunity"
                    checked={values.newOpportunity === 'Y'}
                    onChange={(checked) => {
                      onContentChange?.();
                      if (!checked) {
                        setTimeout(() => {
                          void validateSingleField('subject');
                          void validateSingleField('estimatedValue');
                          void validateSingleField('estimatedCloseDate');
                        }, 100);
                      }
                      return setFieldValue(
                        'newOpportunity',
                        checked ? 'Y' : 'N'
                      );
                    }}
                    disabled={disableEdit}
                    testid="opporunity-checkbox"
                  />
                  <ShareWithTeamHelpButton
                    miLoc={miLoc}
                    custId={id}
                    loggedInUserEmpNo={loggedInUserEmployeeNumber}
                    testId="share-with-team"
                  />
                </IonRow>
                {ifRender(
                  customerData?.natlAcctNo && values.newOpportunity === 'Y',
                  <>
                    <Input
                      className={classes.formItem}
                      label={t('activities:visitSubject')}
                      name="subject"
                      required
                      value={values.subject}
                      error={errors.subject}
                      setValue={(e) => {
                        onContentChange?.();
                        setTimeout(() => {
                          void validateSingleField('subject');
                        }, 100);
                        return setFieldValue('subject', e);
                      }}
                      testid="visitNote-modal-subject-input"
                      spellcheck
                      readonly={disableEdit}
                    />
                    <Input
                      className={classes.formItem}
                      label={t('activities:visitEstimated')}
                      name="estimatedValue"
                      placeholder="$"
                      required
                      currencyMask={{ max: 99999999 }}
                      value={values.estimatedValue}
                      error={errors.estimatedValue}
                      setValue={(e) => {
                        onContentChange?.();
                        setTimeout(() => {
                          void validateSingleField('estimatedValue');
                        }, 100);
                        return setFieldValue('estimatedValue', e);
                      }}
                      testid="visitNote-modal-estimatedValue-input"
                      readonly={disableEdit}
                    />
                    <DateInput
                      className={classNames(classes.dateInput)}
                      inputRowClassName={classNames(classes.dateWrapper, {
                        [classes.errorBorder]: !isEmpty(
                          errors.estimatedCloseDate
                        ),
                        [classes.error]: isEmpty(errors.estimatedCloseDate),
                      })}
                      value={getUnixTime(parseDate(values.estimatedCloseDate))}
                      setValue={(date) => {
                        onContentChange?.();
                        setTimeout(() => {
                          void validateSingleField('estimatedCloseDate');
                        }, 100);
                        return setFieldValue(
                          'estimatedCloseDate',
                          formatDate(date, DateFormatEnum.standardDate)
                        );
                      }}
                      name="estimatedCloseDate"
                      label={t('activities:visitCloseDate')}
                      placeholder={t('activities:notSet')}
                      readonly
                      required
                      rightButton={{
                        testid: 'calendar-icon',
                        icon: ['far', 'calendar-days'],
                      }}
                      testid="expected-date-input"
                    />
                    {!isEmpty(errors.estimatedCloseDate) && (
                      <Alert
                        testid="estimated-close-date-required"
                        text={{
                          text: toString(errors.estimatedCloseDate),
                          testid: 'content-small',
                        }}
                        variant={AlertVariantEnum.danger}
                        className={classes.error}
                      />
                    )}
                  </>
                )}
              </>
            )}

            <Input
              readonly
              className={classes.formItem}
              label={t('activities:contacts')}
              textarea
              rows={2}
              onClick={() => setListIsOpen(true)}
              value={map(values.visitContactsValues, (v) => v.name).join(', ')}
              customLabel={
                <>
                  <Button
                    className={classes.addButton}
                    variant="link"
                    icon={findIcon('plus-circle')}
                    iconClassName={classes.plusCircle}
                    onClick={() => setListIsOpen(true)}
                    testid="add-recipient-button"
                  />
                  <ContactsList
                    allowAddContact
                    canRemoveRecipients
                    searchType="customer"
                    id={id}
                    miLoc={miLoc}
                    isOpen={listIsOpen}
                    setIsOpen={setListIsOpen}
                    title={t('activities:contacts')}
                    values={values.visitContactsValues as Contact[]}
                    onDone={async (v) => {
                      await handleVisitContactChange(v);
                    }}
                    testid="visit-form-contact-list-modal"
                  />
                </>
              }
              name="visitContactsValues"
              testid="visit-form-contact-input"
            />
          </>
        )}
        {ifRender(readonly, VisitDescription)}
        {ifRender(
          and(readonly, !isEmpty(values.text)),
          <IonItem
            className={classNames(classes.formItem, classes.smallItem)}
            data-testid="visit-checkbox"
          >
            <FontAwesomeIcon
              className={classNames(classes.checkIcon, classes.noteIcon)}
              icon={findIcon('edit')}
            />
            <IonLabel className={classes.label}>
              {!isEmpty(values.text) && (
                <IonRow className={classes.labelRow}>
                  <Text
                    className={classes.simplifiedNoteText}
                    text={values.text}
                  />
                </IonRow>
              )}
            </IonLabel>
          </IonItem>
        )}
        {ifRender(
          and(readonly, size(values.files) > 0),
          <Attachments
            domain="mprovisi"
            name="customer"
            files={values.files}
            size={AttachmentSize.SMALL}
            enableSmallPreview
            disabled={isSubmitting}
            editMode={false}
            testid={`gallery-readonly-${miLoc}-${id}`}
          />
        )}
        {ifRender(
          !readonly,
          <div className={classes.notesForm}>
            {disableEdit && VisitDescription}
            <Input
              textarea
              rows={6}
              className={classes.messageInput}
              label={t('common:details')}
              name="text"
              value={values.text}
              error={errors.text}
              setValue={(e) => {
                onContentChange?.();
                setTimeout(() => {
                  void validateSingleField('text');
                }, 100);
                return setFieldValue('text', e);
              }}
              testid="visitNote-modal-text-input"
              readonly={disableEdit}
              required
            />
            {!isEmpty(updatedDate) && (
              <div className={classes.visitDateWrapper}>
                <Text
                  text={`${t('common:updated')} ${toString(updatedDate)}`}
                  className={classes.text}
                />
              </div>
            )}
            <div className={classes.attachmentWrapper}>
              <Text
                text={choose(
                  !isLoggingAVisit && !hasAttachments && !isSameUser,
                  t('email:noAttachments'),
                  t('email:attachments')
                )}
                variant="content-heavy"
              />
              {ifRender(
                isLoggingAVisit || hasAttachments || isSameUser,
                <Text
                  text={` ${t('email:attachmentsOnly')}`}
                  className={classes.attachmentsOnly}
                  variant="content-heavy"
                />
              )}
            </div>
            <FileAttachments
              className={classes.fileAttachments}
              domain="mprovisi"
              name="customer"
              files={values.files}
              onUpdate={updateAttachmentDetails}
              hidePreviewInputs
              onAdd={(files) => {
                if (historyId) {
                  map(files, (file) => {
                    uploadAttachment({
                      file,
                      src: file.fileURL,
                      lineNo: toString(historyId),
                      miOnly: file.miOnly,
                      description: file.description,
                    });
                  });
                  return;
                }
                onContentChange?.();
                setFilesForUpload([...(filesForUpload ?? []), ...files]);
              }}
              onRemove={(file) => {
                if (historyId) {
                  removeAttachment(file);
                  return;
                }
                onContentChange?.();
                const filesFiltered = filter([...filesForUpload], (i) => {
                  // FILE_NAME | fileName is used as the unique identifier for images
                  if ('fileName' in file) {
                    return 'fileName' in file
                      ? i.fileName !== file.fileName
                      : true;
                  }
                  return true;
                });
                choose(
                  or(file.userFile, file.USER_FILE),
                  setFilesForRemoval([...(filesForRemoval || []), file])
                );
                setFilesForUpload(filesFiltered);
              }}
              disabled={isSubmitting}
              enableSmallPreview
              editMode={!customerVisitedByNonRep && !customerVisitByOthers}
              testid={`gallery-${miLoc}-${id}`}
              prefetchAll
            />
          </div>
        )}
        {footerRef?.current &&
          !customerVisitedByNonRep &&
          !customerVisitByOthers &&
          shouldRenderSaveButton &&
          createPortal(
            <Button
              className={classes.button}
              variant="action"
              text="Save"
              onClick={async () => {
                if (hasPendingComment) {
                  setIsDiscardCommentOpen?.(true);
                  return;
                }
                onUpdatingForm?.(true);
                await submitForm();
              }}
              disabled={!didChange || isSubmitting || !isValid}
              testid={kebabCase(`${ActivityType.customerVisit}-save-button`)}
            />,
            footerRef?.current
          )}
        <DatePickerModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onDone={(date) => {
            setVisitDate(date);
            onContentChange?.();
          }}
          showTimeSelect
          date={visitDate}
          maxDate={endOfDay(new Date())}
          testid="visit-history-date-picker"
        />
      </div>
    );
  }
);

export default VisitForm;
