import CostSavingReportEN from 'ReportsApp/pages/CostSavings/en.json';
import Pick12ReportEN from 'ReportsApp/pages/Pick12Report/en.json';
import Pick12ReportFR from 'ReportsApp/pages/Pick12Report/fr.json';
import ProTipsEN from 'ReportsApp/pages/ProTips/en.json';
import SalesPerformanceEN from 'ReportsApp/pages/SalesPerformance/en.json';
import UnbilledReportEN from 'ReportsApp/pages/Unbilled/en.json';

export default {
  en: {
    ...CostSavingReportEN,
    ...UnbilledReportEN,
    ...Pick12ReportEN,
    ...SalesPerformanceEN,
    ...ProTipsEN,
  },
  fr: { ...Pick12ReportFR },
};
