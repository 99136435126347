import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { kebabCase, map, toString } from 'lodash';
import { IonPage, IonContent, IonRow } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import { searchURL } from 'navigation';
import useFindContacts from 'api/contacts/useFindContacts';
import type { Contact } from 'models/Contact';
import type { Customer } from 'models/Customer';
import type { SnapshotsURLParams, SearchItemType } from 'models/Search';
import { SearchSuggestionTypeEnum } from 'models/Search';
import { SortContactFieldEnum, SortDirEnum } from 'models/Sort';
import type { Supplier } from 'models/Supplier';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import {
  getSearchItemKey,
  getSnapshotEyebrow,
  handleSearchNavigation,
} from 'utils/search';
import ActionRow from 'components/ActionRow/ActionRow';
import ContactModalV2 from 'components/Contacts/ContactModalV2/ContactModalV2';
import ContactRow from 'components/Contacts/ContactRow/ContactRow';
import type { FilterOption } from 'components/Filter/Filter';
import Filter from 'components/Filter/Filter';
import Header from 'components/Header/Header';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import ContactActionsModal from 'components/Modals/ContactActionsModal/ContactActionsModal';
import Refresher from 'components/Refresher/Refresher';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './Contacts.module.scss';

const TITLE_TEXT = 'snapshot:contacts';
interface ContactsProps {
  searchType: SearchItemType;
}

const Contacts = ({ searchType }: ContactsProps): JSX.Element => {
  const { miLoc: routeMiLoc, id } = useParams<SnapshotsURLParams>();
  const { t } = useTranslation(namespaces.contact);

  const [isOpenContactModal, setIsOpenContactModal] = useState(false);
  const [isOpenSort, setIsOpenSort] = useState(false);

  const [isOpenContactActionsModal, setIsOpenContactActionsModal] =
    useState(false);
  const [longPressedItem, setLongPressedItem] = useState<Contact>();
  const [contactModalData, setContactModalData] =
    useState<
      Omit<
        React.ComponentProps<typeof ContactModalV2>,
        'isOpen' | 'setIsOpen' | 'searchType' | 'testid'
      >
    >();

  const sendInviteOnAdd = searchType === 'customer';
  const contactSearchType =
    searchType === 'customer' ? 'customerContact' : 'supplierContact';

  const sortFieldOptions = [
    { key: SortContactFieldEnum.name, name: t('common:name') },
  ];
  const sortDirOptions: FilterOption[] = [
    {
      key: SortDirEnum.ASCENDING,
      name: t('sort:alphaAscending'),
      icon: 'arrow-up',
    },
    {
      key: SortDirEnum.DESCENDING,
      name: t('sort:alphaDescending'),
      icon: 'arrow-down',
    },
  ];

  const [sortField, setSortField] = useState<FilterOption | undefined>(
    sortFieldOptions[0]
  );
  const [sortDir, setSortDir] = useState<FilterOption | undefined>(
    sortDirOptions[0]
  );

  const {
    data,
    contacts,
    error,
    fetchNextPage,
    hasError,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    refetch,
  } = useFindContacts({
    searchType,
    miLoc: routeMiLoc,
    id,
    sortField: sortField?.key as SortContactFieldEnum,
    sortDir: sortDir?.key as SortDirEnum,
  });

  const headerName =
    searchType === 'customer'
      ? (data as Customer)?.name
      : (data as Supplier)?.supLocName;

  const inviteMiCom =
    searchType === 'customer' ? (data as Customer)?.inviteMiCom : false;

  const selectedFilters = useMemo(
    () => [sortField, sortDir],
    [sortField, sortDir]
  );

  return (
    <IonPage data-testid="contacts-page">
      <Header
        title={t(TITLE_TEXT)}
        eyebrow={getSnapshotEyebrow({
          name: headerName,
          miLoc: searchType === 'customer' ? routeMiLoc : '',
          id,
        })}
        withBackButton
        testid="contacts"
      >
        <IonRow className={classes.headerRow} />
      </Header>
      <IonContent className={classNames(classes.content, classes.contactCards)}>
        <div className={classes.filterWrapper}>
          <ActionRow
            className={classes.actionRow}
            text={t('addContact')}
            icon={findIcon('user-plus')}
            onClick={() => {
              setIsOpenContactModal(true);
              setContactModalData({
                title: t('addNewContact'),
                showInvite: sendInviteOnAdd,
                inviteMiCom,
              });
            }}
            testid="add-contact-button"
          />
        </div>
        <ContactModalV2
          searchType={searchType}
          isOpen={isOpenContactModal}
          setIsOpen={setIsOpenContactModal}
          testid="contact-modal"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...contactModalData}
        />
        <Refresher
          slot="fixed"
          hidden
          onRefresh={refetch}
          testid="contacts-refresher"
          disabled={showLoader}
        />
        <IonRow className={classes.titleHeader}>
          <Filter
            variant="sort"
            selectedItems={selectedFilters}
            setFilterData={[setSortField, setSortDir]}
            filterOptions={[
              {
                title: t('common:sortByCategory'),
                options: sortFieldOptions,
              },
              {
                title: t('common:sortBy'),
                options: sortDirOptions,
              },
            ]}
            isOpen={isOpenSort}
            setIsOpen={setIsOpenSort}
            testid="contact-sorter"
          />
        </IonRow>
        <div>
          {!hasError &&
            map(contacts, (contact, index) => {
              const {
                miLoc: iMiLoc,
                name = '',
                typeDesc,
                isMainContact,
                isWebRegistered,
                webRegistered,
                lastUpdTmstmp,
                registrationCreationDate,
                sequenceNo,
                registrationStatus,
              } = contact;
              const miLoc = iMiLoc || routeMiLoc;
              const key = getSearchItemKey({
                type: contactSearchType,
                miLoc,
                sequenceNo: toString(sequenceNo),
                customerId: id,
                supplierId: id,
                index,
              });
              return (
                <ContactRow
                  key={key}
                  className={classNames(classes.contactCard, {
                    [classes.updatingCard]: contact.isOptimisticallyUpdating,
                  })}
                  searchSuggestionType={SearchSuggestionTypeEnum.result}
                  text={name}
                  description={typeDesc}
                  href={concatRoutes(
                    searchURL(),
                    handleSearchNavigation({
                      type: contactSearchType,
                      miLoc,
                      sequenceNo: toString(sequenceNo),
                      customerId: id,
                      supplierId: id,
                    })
                  )}
                  testid={`${searchType}-${kebabCase(name)}`}
                  searchType={searchType}
                  miLoc={miLoc}
                  isMainContact={isMainContact}
                  isWebRegistered={
                    searchType === 'customer'
                      ? webRegistered || isWebRegistered
                      : undefined
                  }
                  registrationStatus={registrationStatus}
                  reigsterOrInviteDate={
                    registrationCreationDate || lastUpdTmstmp
                  }
                  onLongPress={() => {
                    setIsOpenContactActionsModal(true);
                    setLongPressedItem({ ...contact, miLoc });
                  }}
                />
              );
            })}
        </div>
        <ContactActionsModal
          // TODO improve the passing of props for this
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...longPressedItem}
          title={t('contactActions')}
          isOpen={isOpenContactActionsModal}
          setIsOpen={setIsOpenContactActionsModal}
          testid="contact-actions-modal"
          searchType={searchType}
          contact={longPressedItem}
          miLoc={longPressedItem?.miLoc || routeMiLoc}
          id={id}
          inviteMiCom={inviteMiCom}
        />
        {isEmptyResponse && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title={t('noContacts')}
          />
        )}
        {hasError && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('errorContacts')}
            body={getErrorMessage(error)}
          />
        )}
        <Loader
          className={classes.loader}
          text={t('loadingContacts')}
          isOpen={showLoader}
        />
        {/* DOC: we need to force a rerender of the infiniteScroll component when changing filters */}
        {!showLoader && (
          <InfiniteScroll
            disabled={!enableInfiniteScroll}
            onLoadMore={fetchNextPage}
            testid="infinite-scroll"
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Contacts;
