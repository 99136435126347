import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import type { CostSavings } from 'CostSavingsApp/models/CostSavings';
import { addYears, subYears } from 'date-fns';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';
import { DateFormatEnum, formatDate } from 'utils/date';

export const findCostSavingsQueryKey = 'cost-savings-list';

interface UseFindCostSavingsProps {
  miLoc: string;
  enabled?: boolean;
  id: string;
  isCorpAccount?: boolean;
  limit?: number;
  csStatus: string[];
}

interface FindCostSavingsData {
  items: CostSavings[];
  totals: number;
}

interface UseFindCostSavingsAPIResponse {
  costSavingsList: CostSavings[];
  rowCount: number;
}

interface UseFindCostSavingsResponse {
  costSavingsList?: CostSavings[];
}

const useFindCostSavings = ({
  miLoc,
  enabled,
  id,
  limit = pageSize(),
  csStatus,
  isCorpAccount,
}: UseFindCostSavingsProps): UseFindCostSavingsResponse &
  InfiniteQueryFlags => {
  const { findCostSavingsAPI } = useAPIUrl();
  const { axios } = useAxios();
  const { createQueryKey } = useKeyUserId();
  const { getURLParams } = useMiLocOrTeamId({});
  const miLocAndId = `${miLoc}${id}`;
  const params: QueryParamsType = {
    miLoc,
    custCostSavings: 'A',
    summaryLevel: 'BRCH',
    limit,
    status: csStatus,
    serviceEndDate: formatDate(
      addYears(new Date(), 1),
      DateFormatEnum.standardDate
    ),
    serviceStartDate: formatDate(
      subYears(new Date(), 1),
      DateFormatEnum.standardDate
    ),
  };

  if (isCorpAccount) {
    params.natlAcctFastFind = miLocAndId;
  } else {
    params.custFastFind = miLocAndId;
  }

  const doGetCostSavingsList = ({ pageParam = 1, signal }: QueryFnProps) => {
    return doPromiseAPI<FindCostSavingsData>(async () => {
      const { data } = await axios.get<UseFindCostSavingsAPIResponse>(
        findCostSavingsAPI(getURLParams({ ...params, page: pageParam })),
        { signal }
      );

      return {
        items: data.costSavingsList,
        totals: data.rowCount,
      };
    });
  };

  const response = useInfiniteQuery<FindCostSavingsData, AxiosError>(
    createQueryKey(findCostSavingsQueryKey, { ...params }),
    doGetCostSavingsList,
    {
      enabled,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      retry: (failureCount: number, err: AxiosError<any>) =>
        err.response?.status === 403 ? false : !(failureCount < 3),
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const costSavingsList = useMemo(
    () => doConcatDataPages<CostSavings, FindCostSavingsData>(data, 'items'),
    [data]
  );

  const hasItems = size(costSavingsList) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    error,
    showLoader,
    refetch: async () => {
      await refetch();
    },
    enableInfiniteScroll,
    noMoreData,
    hasError,
    isEmptyResponse,
    costSavingsList,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
  };
};

export default useFindCostSavings;
