import React from 'react';
import { useTranslation } from 'react-i18next';
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso';
import classNames from 'classnames';
import { isBoolean } from 'lodash';
import { IonItem, IonRow } from '@ionic/react';
import type {
  BaseComponentProps,
  OptionalRenderFlags,
  OptionalRenderProp,
} from 'common/components/utils/renderHelpers';
import {
  getOptionalRenderFlag,
  getOptionalRenderProp,
  toggleKeyboard,
} from 'common/components/utils/renderHelpers';
import { and, choose, ifRender, or } from 'common/utils/logicHelpers';
import Loader from 'components/Loader/Loader';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './List.module.scss';

export const isScrolling = async (isScroll = false) => {
  if (isScroll) {
    await toggleKeyboard(false);
  }
};

type ListProps<ItemType> = {
  isGrouped?: boolean;
  data?: ItemType[];
  title?: OptionalRenderProp;
  itemContent?: (itemIndex: number, item: ItemType) => React.ReactNode;
  groupItemContent?: (
    itemIndex: number,
    groupIndex: number,
    item: ItemType
  ) => React.ReactNode;
  groupCounts?: number[];
  groupContent?: (index: number) => React.ReactNode;
  endReached?: () => void;
  scrollParent?: HTMLElement | null;
  hideKeyboardOnScroll?: boolean;
  testid: string;
  outerRef?: React.Ref<HTMLDivElement>;
} & OptionalRenderFlags<{
  isEndOfList: Partial<React.ComponentProps<typeof Text>>;
  isLoading: Partial<React.ComponentProps<typeof Loader>>;
  isError: Partial<React.ComponentProps<typeof WarningMessage>>;
  isEmptyList: Partial<React.ComponentProps<typeof WarningMessage>>;
}> &
  BaseComponentProps;

function List<ItemType>({
  isGrouped = false,
  groupCounts,
  groupContent,
  className,
  data,
  itemContent,
  groupItemContent,
  endReached,
  scrollParent,
  hideKeyboardOnScroll = true,
  testid,
  outerRef,
  ...props
}: ListProps<ItemType>): JSX.Element {
  const { t } = useTranslation();

  const isLoading = getOptionalRenderFlag('isLoading', props.isLoading);
  const loadingProps = isBoolean(props.isLoading) ? undefined : props.isLoading;

  const isError = getOptionalRenderFlag('isError', props.isError);
  const errorProps = isBoolean(props.isError) ? undefined : props.isError;

  const isEmptyList = getOptionalRenderFlag('isEmptyList', props.isEmptyList);
  const emptyListProps = isBoolean(props.isEmptyList)
    ? undefined
    : props.isEmptyList;

  const isEndOfList = getOptionalRenderFlag('isEndOfList', props.isEndOfList);
  const endOfListProps = isBoolean(props.isEndOfList)
    ? undefined
    : props.isEndOfList;

  const listProps = {
    className: classNames(classes.list, className),
    'data-testid': testid,
  };

  if (isEmptyList && isLoading) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...listProps}>
        <Loader
          isOpen={isLoading}
          text={t('common:loading')}
          testid="loader"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...loadingProps}
          className={classNames(classes.loader, loadingProps?.className)}
        />
      </div>
    );
  }

  if (isError && !isLoading) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...listProps}>
        <WarningMessage
          title={t('common:errorTitle')}
          testid="error-message"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...errorProps}
          className={classNames(classes.message, errorProps?.className)}
        />
      </div>
    );
  }

  if (isEmptyList && !isLoading) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...listProps}>
        <WarningMessage
          icon={['far', 'info-circle']}
          title={t('common:noResultsTitle')}
          testid="empty-message"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...emptyListProps}
          className={classNames(classes.message, emptyListProps?.className)}
        />
      </div>
    );
  }
  const { text: title, props: titleProps } = getOptionalRenderProp(props.title);

  const renderFooter = () => (
    <>
      <Loader
        isOpen={isLoading}
        text={t('common:loading')}
        testid="next-page-loader"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...loadingProps}
        className={classNames(classes.loader, loadingProps?.className)}
      />
      {ifRender(
        isEndOfList,
        <IonRow className={classes.endOfList}>
          <Text
            variant="list-item-secondaryText"
            text={t('common:endOfList')}
            testid="end-of-list-message"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...endOfListProps}
          />
        </IonRow>
      )}
    </>
  );

  const emptyRender = () => <></>;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...listProps}>
      {ifRender(
        or(title, titleProps?.customContent),
        <IonItem
          detail={false}
          lines="none"
          data-testid="header"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...titleProps?.wrapperProps}
          className={classNames(
            classes.header,
            titleProps?.wrapperProps?.className
          )}
        >
          {ifRender(
            title,
            <Text
              text={title}
              variant="mipro-h3-headline"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...titleProps}
            />
          )}
          {titleProps?.customContent}
        </IonItem>
      )}
      {choose(
        isGrouped,
        <GroupedVirtuoso<ItemType>
          groupCounts={groupCounts}
          groupContent={groupContent}
          itemContent={or(groupItemContent, emptyRender)}
          isScrolling={async (isScroll) => {
            await isScrolling(and(hideKeyboardOnScroll, isScroll));
          }}
          endReached={endReached}
          customScrollParent={or(scrollParent, undefined)}
          increaseViewportBy={{ top: 500, bottom: 500 }}
          components={{ Footer: renderFooter }}
          data-testid="virtuoso-wrapper"
        />,
        <Virtuoso<ItemType>
          data={data}
          itemContent={or(itemContent, emptyRender)}
          isScrolling={async (isScroll) => {
            await isScrolling(and(hideKeyboardOnScroll, isScroll));
          }}
          endReached={endReached}
          customScrollParent={or(scrollParent, undefined)}
          increaseViewportBy={{ top: 500, bottom: 500 }}
          components={{ Footer: renderFooter }}
          data-testid="virtuoso-wrapper"
        />
      )}
    </div>
  );
}

export default List;
