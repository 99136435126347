export enum SourcingOverrideTypeEnum {
  sourcingOverrideCreated = 'SourcingOverrideCreated',
  sourcingOverrideAccepted = 'SourcingOverrideAccepted',
  sourcingOverrideRejected = 'SourcingOverrideRejected',
}

export enum SourcingOverrideIconEnum {
  sourcingOverrideCreated = 'shipping-fast',
  sourcingOverrideRejected = 'times',
  sourcingOverrideAccepted = 'check',
}

export enum SourcingOverrideColorEnum {
  sourcingOverrideCreated = 'color-sourcingOverride',
  sourcingOverrideRejected = 'color-sourcingOverrideRejected',
  sourcingOverrideAccepted = 'color-sourcingOverrideAccepted',
}

export enum SourcingOverrideFilterEnum {
  sourceOverride = 'sourceOverride',
}

export interface SourcingOverrideExtendedInfo {
  requestCreationTimestamp: string;
  dcAvailabilityQuantity: string;
  overrideStatus: string;
  approveRejectTmstmp: string;
  approveRejectUsername: string;
  mfgPartNo: string;
  mfrName: string;
  mino: string;
  orderTypeCode: string;
  overrideReasonCode: string;
  overrideReasonDesc: string;
  purReqCtlNo: string;
  sourceQty: string;
  empId: string;
}
