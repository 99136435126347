import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, toString } from 'lodash';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { and, choose, ifRender, or } from 'common/utils/logicHelpers';
import { differenceInCalendarDays } from 'date-fns';
import EmployeeStatus from 'SearchApp/components/EmployeeStatus/EmployeeStatus';
import { LoggedInStatusEnum } from 'SearchApp/utils/helpers';
import { parseDate } from 'utils/date';
import Text from 'components/Text/Text';
import classes from './EmployeeTitle.module.scss';

interface EmployeeTitleProps extends BaseComponentProps {
  miLoc?: string;
  title?: string;
  lastLoggedIn?: string;
  status: LoggedInStatusEnum;
  currentEmployee: boolean;
}

const EmployeeTitle = ({
  miLoc,
  title,
  lastLoggedIn,
  status,
  currentEmployee,
  testid,
}: EmployeeTitleProps): JSX.Element => {
  const { t } = useTranslation();

  const loggedInStatus = () => {
    let statusText = t('SearchApp-EmployeeStatus:onlineToday');
    const aging = differenceInCalendarDays(new Date(), parseDate(lastLoggedIn));

    if (status === LoggedInStatusEnum.deactivated) {
      statusText = t('SearchApp-EmployeeStatus:noLongerAnEmployee');
    } else if (status === LoggedInStatusEnum.away) {
      statusText = t('SearchApp-EmployeeStatus:lastLoginDays', {
        days: aging,
        count: choose(aging === 1, 0, 1),
      });
    } else if (or(status === LoggedInStatusEnum.inactive, isEmpty(status))) {
      statusText = t('SearchApp-EmployeeStatus:inactive');
    }

    return (
      <>
        <div className={classes.loggedInStatus}>
          <EmployeeStatus status={status} testid="employee-status" />
          <Text
            text={statusText}
            variant="mipro-body-copy"
            testid="statusText"
          />
        </div>
        {ifRender(
          !currentEmployee,
          <div className={classes.deactivated}>
            <Text
              variant="mipro-body-copy"
              testid="terminatedEmployeeText"
              text={t('SearchApp-EmployeeStatus:noLongerAnEmployeeDescription')}
            />
          </div>
        )}
      </>
    );
  };

  let employeePosition = '';
  if (and(!isEmpty(title), !isEmpty(miLoc), currentEmployee)) {
    employeePosition = `${toString(title)} • ${toString(miLoc)}`;
  }

  return (
    <div className={classes.employeeSpecification} data-testid={testid}>
      <div className={classes.employeePosition}>
        <Text
          text={employeePosition}
          variant="mipro-body-copy"
          testid="title"
        />
      </div>
      {loggedInStatus()}
    </div>
  );
};

export default EmployeeTitle;
