import React, { useEffect, useImperativeHandle, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { find, map, split, toNumber, toString } from 'lodash';
import { IonRow } from '@ionic/react';
import useUpdateSourcingOverride from 'ActivitiesApp/api/useUpdateSourcingOverride';
import type { ActivityPageBaseProps } from 'ActivitiesApp/models/ActivityDetail';
import type { SourcingOverrideExtendedInfo } from 'ActivitiesApp/models/SourcingOverride';
import type { ActivityComponentRef } from 'ActivitiesApp/pages/ActivityDetail';
import { getActivityDataValue } from 'ActivitiesApp/utils/helpers';
import { DataTypeEnum } from 'common/utils/valueFormatter';
import { useFormik } from 'formik';
import { feedbackURL, homeURL, searchSupplierURL, searchURL } from 'navigation';
import { useToasts } from 'providers/ToastProvider';
import * as yup from 'yup';
import useUpdateActivity from 'api/activities/useUpdateActivity';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { ActionCardActivity } from 'models/ActivityModels';
import { ActivityType, RatingActivityEnum } from 'models/ActivityModels';
import { ToastType } from 'models/Toast';
import { DateFormatEnum, formatDate } from 'utils/date';
import { FirebaseEventNameEnum } from 'utils/firebaseAnalytics';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import { getActivityType } from 'pages/Activities/ActivityActionCard/ActivityCardConfig';
import type { ActivityModalData } from 'components/Activities/ActionCardModal/ActionCardModalData';
import ActionCardModalData, {
  OcnTypeEnum,
} from 'components/Activities/ActionCardModal/ActionCardModalData';
import RatingButtons from 'components/Activities/RatingButtons/RatingButtons';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import OptionsListModal from 'components/Modals/OptionsListModal/OptionsListModal';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './SourcingOverrideForm.module.scss';

interface ISourcingOverrideForm {
  action?: 'A' | 'R';
  overrideReason?: string;
}

const sourcingOverrideDefinition: ActivityModalData = {
  miLoc: {
    label: 'activities:sourcingOverride:miLoc',
    type: DataTypeEnum.string,
  },
  orderNumberOrder: { label: 'ocn (order)', type: DataTypeEnum.ocn },
  orderNumberQuote: { label: 'ocn (quote)', type: DataTypeEnum.ocn },
  line: {
    label: 'activities:sourcingOverride:line',
    type: DataTypeEnum.string,
  },
  sourceQty: {
    label: 'activities:sourcingOverride:sourceQty',
    type: DataTypeEnum.string,
  },
  supplier: { label: 'common:supplier', type: DataTypeEnum.link },
  user: {
    label: 'activities:sourcingOverride:user',
    type: DataTypeEnum.employee,
  },
  requestTime: {
    label: 'activities:sourcingOverride:requestTime',
    type: DataTypeEnum.datetime,
  },
  reason: {
    label: 'activities:sourcingOverride:reason',
    type: DataTypeEnum.string,
  },
  mino: { label: 'common:mino', type: DataTypeEnum.string },
  mfrPartNo: {
    label: 'activities:manufacturerPartNo',
    type: DataTypeEnum.string,
  },
  purReqCtlNo: {
    label: 'activities:sourcingOverride:purReqCtlNo',
    type: DataTypeEnum.string,
  },
  dcAvailability: {
    label: 'activities:sourcingOverride:dcAvailability',
    type: DataTypeEnum.string,
  },
};

const overrideReasons = [
  {
    id: 'A',
    name: 'DC unable to ship complete order. One source loc allowed for this OCN',
  },
  { id: 'B', name: 'Pick up at supplier location' },
];

const SourcingOverrideForm = React.forwardRef<
  ActivityComponentRef,
  ActivityPageBaseProps
>((props, outerRef): JSX.Element => {
  const ac = useAccessControls(AccessControlType.ApproveSourcingOverride);
  const { t, i18n } = useTranslation();
  const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);

  const {
    historyId,
    userId = '',
    activity,
    isLoading,
    updateActivityData,
    didChange,
    footerRef,
    shouldRenderSaveButton,
    hasPendingComment,
    setIsDiscardCommentOpen,
    onContactClick,
    onEmployeeClick,
    disableActivityFlags,
    onCompleteActivityFlag,
    onFollowUpActivityFlag,
    onUnSnooze,
    onContentChange,
    onDone,
    triggerResize,
  } = props;

  const { fieldsData } = activity as ActionCardActivity;
  const extendedInfo = (activity?.extendedInfo ||
    {}) as unknown as SourcingOverrideExtendedInfo;
  const activityCreationTmstmp = activity?.creationTimestamp;
  const orderNumber = getActivityDataValue(
    fieldsData?.['Order Control Number']
  );
  const lineNo = getActivityDataValue(fieldsData?.['Order Line Number']);
  const miLoc = toString(fieldsData?.['MI Loc'] || activity?.custMiLoc);
  const customerId = toString(activity?.custNo);
  const supplierId = toString(fieldsData?.['Supplier No']);
  const purReqCtlNo = toString(
    fieldsData?.['Purch Req Ctl No'] || extendedInfo.purReqCtlNo
  );
  const sourceQty = getActivityDataValue(extendedInfo.sourceQty);

  const {
    orderTypeCode,
    overrideReasonCode,
    requestCreationTimestamp,
    mino,
    mfgPartNo,
    dcAvailabilityQuantity,
    empId,
  } = extendedInfo;
  const { cardType } = getActivityType(toString(activity?.eventTagName), t);
  const isApproved = cardType === ActivityType.sourcingOverrideAccepted;
  const isDone =
    isApproved || cardType === ActivityType.sourcingOverrideRejected;
  const isOrder = orderTypeCode === 'O';

  useEffect(() => {
    triggerResize?.(Date.now());
  }, [isLoading, triggerResize]);

  const { addToast } = useToasts();
  const { onUpdateActivity } = useUpdateActivity({
    userId,
    historyId: toNumber(historyId),
  });

  const {
    data: updateSourcingOverrideData,
    status: updateSourcingOverrideStatus,
    onUpdateSourcingOverride,
  } = useUpdateSourcingOverride({
    miLoc,
    ocn: orderNumber,
    lineNo,
  });

  const onSuccessSave = () => {
    onDone?.();
  };

  const {
    values,
    errors,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setSubmitting,
    isSubmitting,
    isValid,
  } = useFormik<ISourcingOverrideForm>({
    initialValues: {
      overrideReason: overrideReasonCode,
    },
    onSubmit: (formValues) => {
      onUpdateActivity({
        ...updateActivityData,
        activity_type: FirebaseEventNameEnum.sourcing_override_action,
      });
      const action = toString(formValues.action);
      if (action) {
        onUpdateSourcingOverride({
          historyId: toNumber(historyId),
          userId,
          action,
          purReqCtlNo,
          statusCd: action === 'A' ? 'AC' : 'RJ',
          reasonCd: toString(formValues.overrideReason),
        });
      } else {
        onSuccessSave();
      }
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  useImperativeHandle(
    outerRef,
    () => ({
      onSubmit: handleSubmit,
    }),
    [handleSubmit]
  );

  useEffect(() => {
    if (updateSourcingOverrideStatus === 'success') {
      onSuccessSave();
    }
    if (updateSourcingOverrideData?.errorMsg) {
      addToast({
        duration: 0,
        type: ToastType.warn,
        text: updateSourcingOverrideData?.errorMsg,
        testid: 'sourcing-override-warning-toast',
      });
    }
    if (updateSourcingOverrideStatus === 'error') {
      setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addToast,
    setSubmitting,
    updateSourcingOverrideData?.errorMsg,
    updateSourcingOverrideStatus,
  ]);

  const isApproving = values.action === 'A';
  const reasonText = getActivityDataValue(
    find(overrideReasons, {
      id: values.overrideReason,
    })?.name
  );
  const ReasonDropdown = (
    <Button
      rightIcon={isApproving ? findIcon('caret-down', 'fas') : undefined}
      onClick={() => {
        if (isApproving) {
          setIsReasonModalOpen(true);
        }
      }}
      disabled={isSubmitting}
      testid="override-reason-button"
    >
      <div className={classes.reasonWrapper}>
        <Text
          className={classes.reasonText}
          variant="content-heavy"
          text={reasonText}
        />
        {errors.overrideReason && (
          <Text
            className={classes.reasonError}
            variant="content-small"
            text={errors.overrideReason}
          />
        )}
      </div>
    </Button>
  );
  const sourcingOverrideData = {
    miLoc,
    ...(isOrder
      ? { orderNumberOrder: getActivityDataValue(orderNumber) }
      : { orderNumberQuote: getActivityDataValue(orderNumber) }),
    line: lineNo,
    sourceQty,
    supplier: {
      value: getActivityDataValue(fieldsData?.['Supplier Name']),
      href: concatRoutes(searchURL(), searchSupplierURL(miLoc, supplierId)),
    },
    user: {
      value: getActivityDataValue(fieldsData?.['Requestor Name']),
      employeeId: empId,
    },
    requestTime: getActivityDataValue(requestCreationTimestamp),
    ...(isApproving || isApproved
      ? {
          reason: isDone ? reasonText : { customComponent: ReasonDropdown },
        }
      : undefined),
  };
  const additionalData = {
    mino: getActivityDataValue(mino),
    mfrPartNo: getActivityDataValue(mfgPartNo),
    purReqCtlNo: getActivityDataValue(purReqCtlNo),
    dcAvailability: {
      customComponent: (
        <div className={classes.dcAvailability}>
          {map(split(dcAvailabilityQuantity, ','), (row, ridx) => (
            <IonRow key={ridx}>
              {map(split(row, ':'), (col, cidx) => (
                <Text key={cidx} text={col} />
              ))}
            </IonRow>
          ))}
        </div>
      ),
    },
  };
  const rateValue = isApproving
    ? RatingActivityEnum.positive
    : RatingActivityEnum.negative;

  const validateOverrideReason = async (
    overrideReason = values.overrideReason
  ) => {
    try {
      await yup
        .string()
        .required(t('activities:sourcingOverride:reasonRequired'))
        .validate(overrideReason);
      setFieldError('overrideReason', undefined);
    } catch (error) {
      const message = (error as Error)?.message;
      if (message) {
        setFieldError('overrideReason', message);
      }
    }
  };

  useEffect(() => {
    if (isApproving) {
      setIsReasonModalOpen(true);
    }
  }, [isApproving]);

  return (
    <div className={classes.content}>
      <Loader
        testid="loader"
        className={classes.loader}
        text={t('common:loading')}
        isOpen={!!isLoading}
      />
      {!isLoading && (
        <>
          {!ac && !isDone && (
            <WarningMessage
              className={classes.message}
              title={t('activities:sourcingOverride:accessErrorTitle')}
              body={t('activities:sourcingOverride:accessErrorBody')}
            >
              <div>
                <Button
                  variant="action"
                  text={t('feedback:feedback')}
                  className={classes.feedbackButton}
                  href={concatRoutes(homeURL(), feedbackURL())}
                  testid="feedback-button"
                />
              </div>
            </WarningMessage>
          )}
          {isDone && (
            <WarningMessage
              className={classes.message}
              iconClassName={classNames({
                [classes.thumbsUp]: isApproved,
                [classes.thumbsDown]: !isApproved,
              })}
              icon={isApproved ? ['far', 'check'] : ['far', 'times']}
              title={
                isApproved
                  ? t('activities:sourcingOverride:approvedMessage')
                  : t('activities:sourcingOverride:rejectedMessage')
              }
              body={t('activities:sourcingOverride:dateMessage', {
                formatDate: formatDate(
                  activityCreationTmstmp,
                  DateFormatEnum.priceOverrideTime,
                  i18n.language
                ),
              })}
            />
          )}
          <ActionCardModalData
            activityData={sourcingOverrideData}
            activity={activity}
            dataDefinition={sourcingOverrideDefinition}
            className={classes.priceOverrideData}
            labelClassName={classes.moreInfoLabel}
            ocn={orderNumber}
            ocnType={isOrder ? OcnTypeEnum.Order : OcnTypeEnum.Quote}
            miLoc={miLoc}
            customerId={customerId}
            onContactClick={onContactClick}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            onEmployeeClick={onEmployeeClick}
            showProductLines={false}
          />
          {ac && !isDone && !activity?.readOnly && (
            <RatingButtons
              rating={values.action ? rateValue : undefined}
              positiveText={t('common:approve')}
              negativeText={t('common:reject')}
              setRating={(r) => {
                void setFieldValue(
                  'action',
                  r === RatingActivityEnum.positive ? 'A' : 'R'
                );
                if (r === RatingActivityEnum.positive) {
                  void validateOverrideReason();
                } else {
                  void setFieldValue('overrideReason', undefined);
                  setFieldError('overrideReason', undefined);
                }
                onCompleteActivityFlag?.(true);
                onFollowUpActivityFlag?.(false);
                onUnSnooze?.();
                disableActivityFlags?.(true);
                onContentChange?.();
                triggerResize?.(Date.now());
              }}
              disabled={isSubmitting}
            />
          )}
          {ac && !isDone && (
            <ActionCardModalData
              className={classNames(classes.additionalData, {
                [classes.additionalDataReadOnly]: activity?.readOnly,
              })}
              activityData={additionalData}
              activity={activity}
              dataDefinition={sourcingOverrideDefinition}
              labelClassName={classes.moreInfoLabel}
            />
          )}
        </>
      )}
      {footerRef?.current &&
        shouldRenderSaveButton &&
        createPortal(
          <Button
            className={classes.saveButton}
            variant="action"
            text={t('common:save')}
            onClick={() => {
              if (hasPendingComment) {
                setIsDiscardCommentOpen?.(true);
                return;
              }
              handleSubmit();
            }}
            disabled={!didChange || !isValid || isSubmitting}
            testid="save-button"
          />,
          footerRef?.current
        )}
      <OptionsListModal
        isOpen={isReasonModalOpen}
        setIsOpen={setIsReasonModalOpen}
        title={t('activities:sourcingOverride:reason')}
        selectedItem={values.overrideReason}
        filterOptions={map(overrideReasons, ({ id, name }) => ({
          key: id,
          name,
        }))}
        onOptionClick={(option) => {
          void setFieldValue('overrideReason', option.key);
          void validateOverrideReason(option.key);
          setIsReasonModalOpen(false);
          triggerResize?.(Date.now());
        }}
        testid="override-reason-modal"
      />
    </div>
  );
});

export default SourcingOverrideForm;
