/* istanbul ignore file */

import type { Dictionary } from 'lodash';
import type { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import type {
  CrmActivityColorEnum,
  CrmActivityIconEnum,
} from 'ActivitiesApp/models/CrmActivity';
import { CrmActivityTypeEnum } from 'ActivitiesApp/models/CrmActivity';
import {
  CustomerAccountColorEnum,
  CustomerAccountFilterEnum,
  CustomerAccountIconEnum,
  CustomerAccountTypeEnum,
} from 'ActivitiesApp/models/CustomerAccount';
import {
  CustomerVisitColorEnum,
  CustomerVisitFilterEnum,
  CustomerVisitIconEnum,
  CustomerVisitTypeEnum,
} from 'ActivitiesApp/models/CustomerVisit';
import {
  OrderQuoteColorEnum,
  OrderQuoteFilterEnum,
  OrderQuoteIconEnum,
  OrderQuoteTypeEnum,
} from 'ActivitiesApp/models/OrderQuote';
import {
  PriceOverrideColorEnum,
  PriceOverrideFilterEnum,
  PriceOverrideIconEnum,
  PriceOverrideTypeEnum,
} from 'ActivitiesApp/models/PriceOverride';
import { ProTipEnum } from 'ActivitiesApp/models/ProTip';
import { SalesOpportunityEnum } from 'ActivitiesApp/models/SalesOpportunity';
import type {
  SalesOpportunityColorEnum,
  SalesOpportunityIconEnum,
} from 'ActivitiesApp/models/SalesOpportunity';
import type {
  ShopQuoteTypeEnum,
  ShopQuoteIconEnum,
  ShopQuoteColorEnum,
} from 'ActivitiesApp/models/ShopQuote';
import {
  SourcingOverrideTypeEnum,
  SourcingOverrideIconEnum,
  SourcingOverrideColorEnum,
  SourcingOverrideFilterEnum,
} from 'ActivitiesApp/models/SourcingOverride';
import {
  WebActivityColorEnum,
  WebActivityFilterEnum,
  WebActivityIconEnum,
  WebActivityTypeEnum,
} from 'ActivitiesApp/models/WebActivity';
import type { FirebaseEventNameEnum } from 'utils/firebaseAnalytics';
import type { TextQueryType } from 'components/Text/Text';
import type { WithOptimisticUI } from './Search';

export interface ActivityFieldData {
  name: string;
  value: unknown;
}

export interface ActionCardActivity extends WithOptimisticUI {
  historyId: number;
  userId: string;
  userFullName: string;
  showAfter: string;
  showAfterISO: string;
  showSnoozed: boolean;
  done: 'Y' | 'N';
  followUp: 'Y' | 'N';
  custMiLoc: string;
  custNo: string;
  isForCorpAcct?: boolean;
  natlAcctNo: string;
  customerName: string;
  eventTagName: string;
  creationTimestamp: string;
  creationTimestampISO: string;
  extendedInfo?: Dictionary<unknown>;
  fields?: ActivityFieldData[];
  fieldsData?: Dictionary<unknown>;
  children?: ActionCardActivity[];
  readOnly?: boolean;
  userLocationMatch?: boolean;
  isSnoozed?: 'Y' | 'N';
  eventRead: string;
  eventConfigVersion: string;
  baseConfigVersion: string;
  customerPick12?: boolean;
  currency?: string;
  ocn?: string;
  totalOrderAmount?: string;
  employeeId: string;
  commentCount: string;
  subscribed?: boolean;
  subscriptionCount?: number;
  thumbChoice: string;
  thumbCount: ThumbVote[];
  canDelete: boolean;
  totalRows?: number;
}

enum BaseActivityTypeEnum {
  unknown = 'unknown',
}
export const ActivityType = {
  ...BaseActivityTypeEnum,
  ...OrderQuoteTypeEnum,
  ...CustomerVisitTypeEnum,
  ...WebActivityTypeEnum,
  ...CustomerAccountTypeEnum,
  ...PriceOverrideTypeEnum,
  ...SourcingOverrideTypeEnum,
  ...CrmActivityTypeEnum,
  ...SalesOpportunityEnum,
  ...ProTipEnum,
};
export type ActivityTypeEnum =
  | BaseActivityTypeEnum
  | OrderQuoteTypeEnum
  | CustomerVisitTypeEnum
  | WebActivityTypeEnum
  | CustomerAccountTypeEnum
  | PriceOverrideTypeEnum
  | SourcingOverrideTypeEnum
  | CrmActivityTypeEnum
  | ShopQuoteTypeEnum
  | SalesOpportunityEnum
  | ProTipEnum;

export enum BaseActivityIconEnum {
  important = 'exclamation-square',
  unknown = 'question-square',
  followUp = 'pennant',
  unread = 'envelope',
  snoozed = 'clock',
  done = 'trash',
}
export const ActivityIcon = {
  ...BaseActivityIconEnum,
  ...OrderQuoteIconEnum,
  ...CustomerVisitIconEnum,
  ...WebActivityIconEnum,
  ...CustomerAccountIconEnum,
  ...PriceOverrideIconEnum,
  ...SourcingOverrideIconEnum,
};
export type ActivityIconEnum =
  | BaseActivityIconEnum
  | OrderQuoteIconEnum
  | CustomerVisitIconEnum
  | WebActivityIconEnum
  | CustomerAccountIconEnum
  | PriceOverrideIconEnum
  | SourcingOverrideIconEnum
  | CrmActivityIconEnum
  | ShopQuoteIconEnum
  | SalesOpportunityIconEnum;

export enum BaseActivityColorEnum {
  snoozed = 'color-snoozed',
  followUp = 'color-followUp',
  delete = 'color-priceOverride',
  snoozedSelected = 'color-snoozed-active',
  unknownSelected = 'color-delete-active',
  unreadSelected = 'color-priceOverride',
  default = 'white',
}
export const ActivityColor = {
  ...BaseActivityColorEnum,
  ...OrderQuoteColorEnum,
  ...CustomerVisitColorEnum,
  ...WebActivityColorEnum,
  ...CustomerAccountColorEnum,
  ...PriceOverrideColorEnum,
  ...SourcingOverrideColorEnum,
};
export type ActivityColorEnum =
  | BaseActivityColorEnum
  | OrderQuoteColorEnum
  | CustomerVisitColorEnum
  | WebActivityColorEnum
  | CustomerAccountColorEnum
  | PriceOverrideColorEnum
  | SourcingOverrideColorEnum
  | CrmActivityColorEnum
  | ShopQuoteColorEnum
  | SalesOpportunityColorEnum;

export enum BaseActivityFilterEnum {
  important = 'IMPORTANT',
  followUp = 'FOLLOW_UP',
  snoozed = 'SHOW_AFTER',
  unread = 'UNREAD',
  done = 'DONE',
}
export const ActivityFilter = {
  ...BaseActivityFilterEnum,
  ...OrderQuoteFilterEnum,
  ...CustomerVisitFilterEnum,
  ...WebActivityFilterEnum,
  ...CustomerAccountFilterEnum,
  ...PriceOverrideFilterEnum,
  ...SourcingOverrideFilterEnum,
};
export type ActivityFilterEnum =
  | BaseActivityFilterEnum
  | OrderQuoteFilterEnum
  | CustomerVisitFilterEnum
  | WebActivityFilterEnum
  | CustomerAccountFilterEnum
  | PriceOverrideFilterEnum
  | SourcingOverrideIconEnum;

export interface ActivityTypeFilter {
  id: string;
  name: string;
  count: number;
  type?: 'event' | 'property' | 'category';
  status?: string;
  filter?: Record<string, unknown>;
  eventTagName?: string;
}

enum ActionOperationStatusEnum {
  none,
  pending,
  completed,
}

export type ActionOperationStatus = keyof typeof ActionOperationStatusEnum;

export enum RatingActivityEnum {
  positive = '1',
  negative = '-1',
}

export interface ActivityFiltersButton {
  id: string;
  name: string;
  count?: number;
  disabled: boolean;
  showCounter: boolean;
  icon?: IconName;
}

export interface UpdateActivityBody {
  showAfter?: string;
  done?: boolean;
  followUp?: boolean;
  subscribe?: boolean;
  activity_type?: FirebaseEventNameEnum;
  historyId?: number;
  historyIds?: number[];
  disableRefetchActivity?: boolean;
}

export type UpdateActivityFlags = {
  undoData: UpdateActivityBody;
  activityUpdate: UpdateActivityBody;
};

export interface ActivityCardDescription {
  description: string;
  lines?: ActivityCardLines[];
}

export interface ActivityCardLines {
  description: string;
  highlight?: TextQueryType;
  followUpDescription?: string;
  followUpHighlight?: TextQueryType;
  followupIcon?: IconProp;
  pick12Icon?: boolean;
  isCorpAccount?: boolean;
  badge?: JSX.Element;
  className?: string;
}

export interface ThumbVote {
  count: number;
  vote: string;
}
