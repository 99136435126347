import { isNil, isString, toNumber, toString, isEmpty, isNaN } from 'lodash';
import type { Address } from 'models/Address';
import { formatPhone, getAddress } from 'utils/address';
import { formatDate, DateFormatEnum } from 'utils/date';
import { removeLeadingZeros } from 'utils/number';
import { formatNumber } from './numberHelper';

export enum DataTypeEnum {
  string,
  currency,
  number,
  datetime,
  date,
  link,
  address,
  phone,
  email,
  contact,
  ocn,
  percent,
  employee,
}

interface FormatKeyValueProps {
  value: unknown;
  type?: DataTypeEnum;
  language?: string;
  currency?: string;
}

export const emptyString = '--';

export const formatKeyValue = ({
  value,
  type = DataTypeEnum.string,
  language,
  currency,
}: FormatKeyValueProps): string => {
  if (isNil(value) || (isString(value) && isEmpty(value))) {
    return emptyString;
  }
  if (isNaN(value)) {
    return 'N/A';
  }
  switch (type) {
    case DataTypeEnum.ocn:
      return removeLeadingZeros(toString(value));
    case DataTypeEnum.currency:
      return formatNumber({
        number: toNumber(value),
        scale: 2,
        currencyType: currency,
      });
    case DataTypeEnum.date:
      return formatDate(toString(value), DateFormatEnum.fullDate, language);
    case DataTypeEnum.datetime:
      return formatDate(
        toString(value),
        DateFormatEnum.priceOverrideTime,
        language
      );
    case DataTypeEnum.address:
      return getAddress(value as Address);
    case DataTypeEnum.phone:
      return formatPhone(toString(value));
    case DataTypeEnum.percent:
      return toString(value).concat('%');
    default:
      return toString(value);
  }
};
