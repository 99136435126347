import React from 'react';
import { useTranslation } from 'react-i18next';
import type ColorVariant from 'constants/colorVariants';
import classNames from 'classnames';
import { head, isEmpty, isNil, map, startCase, toString } from 'lodash';
import type { IconProp, IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonCol,
  IonRow,
  IonItem,
  IonItemOptions,
  IonItemOption,
  IonItemSliding,
} from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import CorpAccIcon from 'common/components/CorpAccIcon/CorpAccIcon';
import { useNodeRef } from 'common/components/utils/renderHelpers';
import { and, ifRender, or } from 'common/utils/logicHelpers';
import useDisablePick12 from 'ReportsApp/hooks/useDisablePick12';
import type { ActivityCardDescription } from 'models/ActivityModels';
import type { IconColor } from 'models/Legend';
import type { SnoozedData } from 'models/SnoozedData';
import { formatCardDate } from 'utils/date';
import { colorHash } from 'utils/helpers';
import MIIcon from 'assets/MIIcon.svg';
import Pick12 from 'assets/Pick12.svg';
import OwnerTitle from 'components/Activities/OwnerTitle/OwnerTitle';
import SnoozeTitle from 'components/Activities/SnoozeTitle/SnoozeTitle';
import Button from 'components/Button/Button';
import CardAvatar from 'components/CardAvatar/CardAvatar';
import type { SwipeOption } from 'components/Slider/Slider';
import Slider from 'components/Slider/Slider';
import type { TextQueryType } from 'components/Text/Text';
import Text from 'components/Text/Text';
import classes from './ActionCard.module.scss';
import ActionCardDecoration from './ActionCardDecoration';

export interface ActionCardOption {
  icon: IconProp;
  text: string;
  variant: ColorVariant;
  isActive?: boolean;
  activeClass?: string;
  inActiveClass?: string;
  disabled?: boolean;
  onClick?: () => void;
  onMultiSelectAction?: () => void;
  testid: string;
}

interface ActionCardProps {
  text: string;
  body: ActivityCardDescription;
  aging: Date | number | string;
  withAvatar?: boolean;
  icon?: IconName;
  svgIcon?: string;
  iconColor?: IconColor | string;
  backgroundColor?: IconColor | string;
  withDefaultAvatar?: boolean;
  highlight?: TextQueryType;
  snoozedData?: SnoozedData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customContent?: React.FC<any>;
  onSlideLeft?: (SwipeOption | undefined)[];
  onLongPress?: () => void;
  onMultiSelectAction?: () => void;
  statusIcon?: IconProp;
  statusColor?: IconColor | string;
  decoratorIcon?: IconProp;
  decoratorVariant?: ColorVariant;
  isRead?: boolean;
  testid: string;
  avatarContainerClass?: string;
  nonLoggedInOwnerEmpId?: string;
  ownerName?: string;
  hideReadIndicator?: boolean;
  onDisabledClick?: () => void;
}

const ActionCard = ({
  className,
  text,
  body,
  aging,
  withAvatar = true,
  icon,
  svgIcon,
  iconColor,
  backgroundColor,
  withDefaultAvatar = false,
  highlight,
  onClick,
  snoozedData,
  customContent: CustomContent,
  onSlideLeft,
  onLongPress,
  onMultiSelectAction,
  testid,
  decoratorIcon,
  decoratorVariant,
  statusIcon,
  statusColor,
  isRead = true,
  avatarContainerClass,
  nonLoggedInOwnerEmpId,
  ownerName,
  disabled,
  hideReadIndicator = false,
  onDisabledClick,
}: ActionCardProps &
  React.ComponentProps<typeof IonItem> &
  IonicReactProps): JSX.Element => {
  const { i18n } = useTranslation();
  const { node: slidingRef, nodeRef } = useNodeRef<HTMLIonItemSlidingElement>();
  const isPick12Disabled = useDisablePick12();

  return (
    <Slider onLongPress={onLongPress}>
      <IonItemSliding ref={nodeRef} disabled={disabled}>
        <IonItem
          className={classNames(classes.card, className)}
          lines="none"
          button
          detail={false}
          data-testid={testid}
          onClick={onClick}
        >
          <IonRow className={classes.container}>
            {withAvatar &&
              !onMultiSelectAction &&
              isNil(icon) &&
              isEmpty(svgIcon) && (
                <div
                  className={classes.avatar}
                  style={{
                    backgroundColor: colorHash.hex(startCase(text)),
                  }}
                  aria-hidden="true"
                >
                  {withDefaultAvatar ? (
                    <img src={MIIcon} alt="" />
                  ) : (
                    <Text
                      className={classes.title}
                      variant="title-screen-section"
                      text={toString(head(text))}
                    />
                  )}
                </div>
              )}
            {(!isNil(icon) || onMultiSelectAction) && (
              <div className={classNames(classes.avatarDiv)} aria-hidden="true">
                <IonCol
                  className={classNames(classes.readCol, {
                    [classes.hideReadCol]: hideReadIndicator,
                  })}
                >
                  <div
                    className={
                      isRead
                        ? classNames(classes.readSpacing)
                        : classNames(classes.readDot)
                    }
                  />
                </IonCol>
                <IonCol>
                  <div
                    className={classNames(classes.avatar, avatarContainerClass)}
                    aria-hidden="true"
                  >
                    <CardAvatar
                      icon={icon}
                      svgIcon={svgIcon}
                      testid={`${testid}-icon`}
                      name={icon || ''}
                      color={iconColor}
                      backgroundColor={backgroundColor}
                      transform="shrink-3"
                      onClick={onMultiSelectAction}
                    />
                  </div>
                </IonCol>
              </div>
            )}
            <IonRow className={classes.wrapper}>
              <IonCol>
                <IonRow className={classes.title}>
                  <Text
                    className={classes.text}
                    variant="title-info-card-activity"
                    text={text}
                    testid={`${testid}-text`}
                  />
                  <Text
                    className={classes.aging}
                    variant="label-micro"
                    text={formatCardDate(aging, false, true, i18n.language)}
                    testid={`${testid}-aging`}
                  />
                </IonRow>
                {/* //TODO should be removed once all descriptions have changed to new way */}
                {!isEmpty(body.description) &&
                  (!body.lines || body.lines?.length === 0) && (
                    <IonRow className={classes.contentRow}>
                      <Text
                        className={classes.description}
                        variant="content-smaller"
                        text={toString(body.description)}
                        textQuery={highlight}
                        testid={`${testid}-description`}
                      />
                      {!isNil(CustomContent) && <CustomContent />}
                      {statusIcon && (
                        <FontAwesomeIcon
                          icon={statusIcon}
                          className={classNames(classes.statusIcon, {
                            [classes.statusPrimary]: statusColor === 'primary',
                          })}
                        />
                      )}
                    </IonRow>
                  )}
                {map(body.lines, (line, index) => (
                  <IonRow key={index} className={classes.contentRow}>
                    <div
                      className={classNames({
                        [classes.badgeRow]: !!line.badge,
                        [classes.descriptions]:
                          !!line.description && !!line.followUpDescription,
                      })}
                    >
                      {line.isCorpAccount && (
                        <div className={classes.corpIconWrapper}>
                          <CorpAccIcon
                            showIcon={line.isCorpAccount}
                            testid={testid}
                            className={classes.corpIcon}
                          />
                        </div>
                      )}
                      {and(line.pick12Icon, !isPick12Disabled) && (
                        <div className={classes.pick12Wrapper}>
                          <img
                            src={Pick12}
                            alt=""
                            className={classes.pick12Icon}
                          />
                        </div>
                      )}
                      {line.badge}
                      <Text
                        className={classNames(
                          classes.description,
                          line.className
                        )}
                        variant="content-smaller"
                        text={toString(line.description)}
                        textQuery={line.highlight}
                        testid={`${testid}-description`}
                      />
                      {line.followUpDescription && (
                        <Text
                          className={classNames(
                            classes.description,
                            line.className
                          )}
                          variant="content-smaller"
                          text={toString(line.followUpDescription)}
                          textQuery={line.followUpHighlight}
                          testid={`${testid}-follow-up-description`}
                        />
                      )}
                    </div>
                    {line.followupIcon && (
                      <FontAwesomeIcon
                        icon={line.followupIcon}
                        className={classNames(classes.statusIcon, {
                          [classes.statusPrimary]: statusColor === 'primary',
                        })}
                      />
                    )}
                  </IonRow>
                ))}
                <SnoozeTitle snoozedData={snoozedData} testid={testid} />
                <OwnerTitle
                  nonLoggedInOwnerEmpId={nonLoggedInOwnerEmpId}
                  ownerName={ownerName}
                  testid="non-logged-in-employee"
                />
              </IonCol>
            </IonRow>
          </IonRow>
          {decoratorIcon && (
            <ActionCardDecoration
              icon={decoratorIcon}
              variant={decoratorVariant}
            />
          )}
        </IonItem>
        {ifRender(
          and(!isNil(onSlideLeft), !!onSlideLeft?.length),
          <IonItemOptions
            side="end"
            onClick={async () => {
              if (disabled) {
                onDisabledClick?.();
                await slidingRef?.close();
              }
            }}
          >
            {map(onSlideLeft, (opt, idx) => (
              <IonItemOption
                key={`action-option-${idx}`}
                disabled={disabled}
                className={classNames(classes[or(opt?.className, '')], {
                  [classes.disabled]: disabled,
                })}
                onClick={async () => {
                  if (opt?.onSwipeComplete) {
                    opt?.onSwipeComplete();
                  }
                  if (opt?.autoClose) {
                    await slidingRef?.close();
                  }
                }}
              >
                <Button
                  icon={opt?.icon as IconProp}
                  text={opt?.text}
                  testid={`slide-left-${idx}`}
                />
              </IonItemOption>
            ))}
          </IonItemOptions>
        )}
      </IonItemSliding>
    </Slider>
  );
};

export default ActionCard;
