import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty, trim } from 'lodash';
import { IonContent, IonPage, IonGrid, IonRow } from '@ionic/react';
import useFindActiveEmployees from 'ActivitiesApp/api/useFindActiveEmployees';
import Header from 'common/components/Header/Header';
import { choose, or } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import EmployeeTitle from 'SearchApp/components/EmployeeTitle/EmployeeTitle';
import { isCurrentEmployee, LoggedInStatusEnum } from 'SearchApp/utils/helpers';
import useGetEmployee from 'api/employee/useGetEmployee';
import useGetAvatarById from 'api/user/useGetAvatarById';
import type { SnapshotsURLParams, SearchItemType } from 'models/Search';
import AvatarCard from 'components/Avatar/AvatarCard';
import {
  PhoneCallRow,
  PhoneMobileRow,
  PhoneSMSRow,
} from 'components/Contacts/PhoneNumber/PhoneNumber';
import { EmailRow } from 'components/Email/Email';
import Loader from 'components/Loader/Loader';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import Text from 'components/Text/Text';
import classes from './Employee.module.scss';

interface SnapshotsProps {
  searchType: SearchItemType;
}

const Employee = ({ searchType }: SnapshotsProps): JSX.Element => {
  const { id } = useParams<SnapshotsURLParams>();
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const { t } = useTranslation();

  const { activeEmployeeList } = useFindActiveEmployees({});
  const employeeFromCache = activeEmployeeList?.[id];
  const status = choose(
    !isEmpty(employeeFromCache),
    employeeFromCache?.status,
    LoggedInStatusEnum.inactive
  );

  const { data: avatarData } = useGetAvatarById(id);
  const { data: employee, isLoading } = useGetEmployee({ id });
  const {
    name = '',
    email = '',
    title = '',
    miLoc = '',
    mobilePhone = '',
    workPhone = '',
    firstName = '',
    lastName = '',
    name_empDisplayName: empDisplayName = '',
    phoneCountryCode,
  } = employee || {};
  const headerText: string = name || 'Loading';

  return (
    <IonPage className={classes.container} data-testid="search-detail-page">
      <Header
        className={classes.header}
        eyebrow={t(`${namespaces.snapshot}:employee`)}
        title={headerText}
        testid="search-detail"
      />
      <IonContent className={classes.content}>
        <Loader
          className={classes.loader}
          text={t(`${namespaces.snapshot}:loadingSearchType`, { searchType })}
          isOpen={isLoading}
          testid="loadingIndicator"
        />
        {!isLoading && (
          <IonGrid
            className={classes.detailsContainer}
            data-testid="employee-grid"
          >
            <IonRow
              className={classNames(
                classes.firstRowWrapper,
                classes.rowWrapper
              )}
            >
              <AvatarCard
                data={avatarData}
                displayName={trim(`${firstName} ${lastName}`)}
                className={classes.employeeAvatar}
                backgroundColor={classes.employeeFallback}
              />
              <Text
                className={classNames(classes.empInfo, classes.titleUpper)}
                variant="title-info-card"
                text={or(name, empDisplayName)}
                testid="employee-name"
              />
              <EmployeeTitle
                title={title}
                status={
                  isCurrentEmployee(employee)
                    ? (status as LoggedInStatusEnum)
                    : LoggedInStatusEnum.deactivated
                }
                miLoc={miLoc}
                lastLoggedIn={employeeFromCache?.lastLoggedIn}
                currentEmployee={isCurrentEmployee(employee)}
              />
            </IonRow>
            {workPhone && isCurrentEmployee(employee) && (
              <PhoneCallRow
                className={classNames(classes.rowWrapper, classes.itemWrapper)}
                phoneNumber={workPhone}
                testid="contact-employee-contact-phone-call"
                phoneCountryCode={phoneCountryCode}
              />
            )}
            {mobilePhone && isCurrentEmployee(employee) && (
              <PhoneMobileRow
                className={classNames(classes.rowWrapper, classes.itemWrapper)}
                iconClassName={classes.mobilePhone}
                phoneNumber={mobilePhone}
                testid="contact-employee-contact-mobile-call"
                phoneCountryCode={phoneCountryCode}
              />
            )}
            {mobilePhone && isCurrentEmployee(employee) && (
              <PhoneSMSRow
                className={classNames(classes.rowWrapper, classes.itemWrapper)}
                phoneNumber={mobilePhone}
                testid="contact-employee-contact-phone-sms"
                phoneCountryCode={phoneCountryCode}
              />
            )}
            {email && isCurrentEmployee(employee) && (
              <>
                <EmailRow
                  emailAddress={email}
                  className={classNames(
                    classes.rowWrapper,
                    classes.itemWrapper
                  )}
                  onClick={() => setSendEmailModal(true)}
                  testid="contact-action-contact-email"
                />
                <SendEmailModal
                  searchType={searchType}
                  id={id}
                  miLoc={miLoc}
                  defaultRecipients={[{ name, email }]}
                  isOpen={sendEmailModal}
                  setIsOpen={setSendEmailModal}
                  title={t('common:sendEmail')}
                  testid="employee-send-email-modal"
                  canRemoveRecipients={false}
                />
              </>
            )}
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Employee;
