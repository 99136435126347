import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Column, Row } from 'react-table';
import type { Dictionary } from 'lodash';
import { get, map, toString } from 'lodash';
import { IonRow } from '@ionic/react';
import { ProTipEnum } from 'ActivitiesApp/models/ProTip';
import { goToActivity } from 'ActivitiesApp/navigation/navigationHelpers';
import { choose } from 'common/utils/logicHelpers';
import type { ProTipsRow } from 'ReportsApp/api/useGetProTipsReport';
import ReportCell from 'ReportsApp/components/ReportCell/ReportCell';
import { useGetTableColumns } from 'ReportsApp/hooks/useGetTableColumns';
import type { ReportField } from 'ReportsApp/models';
import { CUSTOMERLESS } from 'utils/constants';
import Button from 'components/Button/Button';
import classes from './ProTips.module.scss';

interface UseOpenQuotesTableDataProps {
  totals?: ProTipsRow;
  openEmployeeModal?: React.Dispatch<string>;
}

const renderProTipCell = (
  field: ReportField,
  data: ProTipsRow,
  openEmployeeModal?: React.Dispatch<string>
) => {
  const value = toString(get(data, field.id));

  switch (field.id) {
    case 'reqEmployeeName':
    case 'creationUserName':
      return (
        <Button
          text={value}
          variant="link"
          testid={`${field.id}-modal`}
          className={classes.employeeName}
          onClick={() =>
            openEmployeeModal?.(
              toString(
                choose(
                  field.id === 'reqEmployeeName',
                  get(data, 'reqEmployeeId'),
                  get(data, 'creationEmpId')
                )
              )
            )
          }
        />
      );
    default:
      return <ReportCell field={field} data={data} />;
  }
};

// eslint-disable-next-line import/prefer-default-export
export const useGetProTipTableData = ({
  totals,
  openEmployeeModal,
}: UseOpenQuotesTableDataProps) => {
  const { t } = useTranslation();
  const namespace = 'ReportApp-ProTips:reportFields';

  const reportFields: ReportField[] = [
    /* Re-enable when after linking is done
    {
      key: t(`${namespace}:linkedOCNs`),
      id: 'salesCount',
    },
    {
      key: t(`${namespace}:totalSales`),
      id: 'salesAmount',
      type: 'currency',
    },
    {
      key: t(`${namespace}:openOpportunities`),
      id: 'openOppsAmount',
      type: 'currency',
    },
    */
    {
      key: t(`${namespace}:pro`),
      id: 'reqEmployeeName',
      type: 'text',
    },
    {
      key: t(`${namespace}:publishedDate`),
      id: 'creationTmstmp',
      type: 'date',
    },
    {
      key: t(`${namespace}:views`),
      id: 'viewCount',
      type: 'text',
    },
    {
      key: t(`${namespace}:comments`),
      id: 'commentCount',
      type: 'text',
    },
    {
      key: t(`${namespace}:likes`),
      id: 'likeCount',
      type: 'text',
    },
    {
      key: t(`${namespace}:disLikes`),
      id: 'dislikeCount',
      type: 'text',
    },
    {
      key: t(`${namespace}:industrySegment`),
      id: 'industrySegmentsDesc',
      type: 'text',
    },
    {
      key: t(`${namespace}:publisher`),
      id: 'creationUserName',
      type: 'text',
    },
  ];

  const tableColumns: Column<Dictionary<unknown>>[] = useMemo(
    () => [
      {
        Header: toString(t(`${namespace}:proTip`)),
        id: 'subject',
        accessor: 'data',
        sortType: 'basic',
        Cell: ({ row }: Dictionary<unknown>) => {
          const { original } = row as Row;
          const { subject, historyId } = original as ProTipsRow;
          return (
            <IonRow className={classes.rowLink}>
              <Button
                text={subject}
                href={goToActivity({
                  historyId,
                  miLoc: 'EXEC',
                  customerId: CUSTOMERLESS,
                  activityType: ProTipEnum.proTip,
                })}
                testid="pro-tip"
                variant="link"
                textVariant="list-item-title"
              />
            </IonRow>
          );
        },
      },
      ...map(reportFields, (field) => ({
        Header: field.key,
        id: field.id,
        sortType: 'basic',
        accessor: field.id,
        Cell: ({ row }: Dictionary<unknown>) => {
          const { original } = row as Row;
          return renderProTipCell(
            field,
            original as ProTipsRow,
            openEmployeeModal
          );
        },
      })),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportFields]
  );

  const { totalsRow } = useGetTableColumns({
    summaryData: totals,
    namespace,
    reportFields,
  });

  return { tableColumns, totalsRow };
};
