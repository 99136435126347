import { useSelector } from 'react-redux';
import SentryCategories from 'constants/sentryCategories';
import { get } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationStatus } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import { unreadQueryKey } from 'api/user/useGetUnreadNotificationsCount';
import type { ActionCardActivity } from 'models/ActivityModels';
import type { RootState } from 'store/reducers';

interface UseMarkActivityAsUnreadResponse {
  status: MutationStatus;
  onMarkActivityAsUnread: (body: MarkActivityAsUnreadBody) => void;
}

interface MarkActivityAsUnreadBody {
  historyId: number;
}

const useMarkActivityAsUnread = (): UseMarkActivityAsUnreadResponse => {
  const { axios } = useAxios();
  const { markActivityUnreadAPI } = useAPIUrl();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const queryClient = useQueryClient();

  const doMarkActivityAsUnread = (body: MarkActivityAsUnreadBody) => {
    return doPromiseAPI(async (cancelToken) => {
      await axios.post(
        markActivityUnreadAPI(),
        { historyId: body.historyId },
        { cancelToken }
      );
      return { ...body, success: true };
    });
  };

  const { status, mutate } = useMutation(doMarkActivityAsUnread, {
    onMutate: async (body) => {
      return onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        updatedItems: [
          { eventRead: 'N', historyId: body.historyId } as ActionCardActivity,
        ],
        findPredicates: [{ historyId: body.historyId }],
        isInfiniteQuery: true,
        markAsUpdated: false,
      });
    },
    onSuccess: (vars) => {
      // invalidate unread since the activity's last read status has been updated
      void onSuccessMutation(queryClient, unreadQueryKey);

      Sentry.addBreadcrumb({
        level: 'info',
        data: { userId, ...vars },
        category: SentryCategories.ACTIVITIES,
        message: 'Succeeded in marking activity as unread.',
      });
    },
    onError: (error, vars, context) => {
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: context as UpdateMutationContext<ActionCardActivity>[],
        isInfiniteQuery: true,
      });
      Sentry.addBreadcrumb({
        level: 'error',
        data: { userId },
        category: SentryCategories.ACTIVITIES,
        message: 'Failed to mark activity as unread.',
      });
    },
  });

  return {
    status,
    onMarkActivityAsUnread: (body: MarkActivityAsUnreadBody) => mutate(body),
  };
};

export default useMarkActivityAsUnread;
