import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { findIndex, map, toString, find, filter, get } from 'lodash';
import { IonRow } from '@ionic/react';
import type { QueryFlags } from 'common/api/utils/useGetQueryFlags';
import Footer from 'common/components/Footer/Footer';
import { FormikInput } from 'common/components/Forms/Input/Input';
import List from 'common/components/List/List';
import type {
  BaseComponentProps,
  BaseInputProps,
  OptionalRenderProp,
  SetStateProp,
} from 'common/components/utils/renderHelpers';
import {
  getOptionalRenderProp,
  useNodeRef,
} from 'common/components/utils/renderHelpers';
import { and, choose, ifRender, or } from 'common/utils/logicHelpers';
import { emptyString } from 'common/utils/valueFormatter';
import { useField, useFormikContext } from 'formik';
import { useDebounce } from 'use-debounce';
import { getErrorMessage } from 'utils/helpers';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Searchbar from 'components/Searchbar/Searchbar';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './SelectModal.module.scss';

interface SelectModalProps<ItemType> extends BaseComponentProps {
  title?: OptionalRenderProp;
  searchPlaceholder?: string;
  showSelected?: boolean;
  multiple?: boolean;
  values?: ItemType[];
  onDone?: (v: ItemType[]) => void;
  items?: ItemType[];
  getId?: (i: ItemType) => string;
  getName?: (i: ItemType) => string;
  getDescription?: (i: ItemType) => string;
  getSearchMatch?: (i: ItemType, q: string) => boolean;
  renderItem?: (i: ItemType) => React.ReactNode;
  renderViewOnly?: (v: ItemType[]) => React.ReactNode;
  belowSearchBarSlot?: React.ReactNode;
  setSearchQuery?: SetStateProp<string>;
  queryProps?: QueryFlags;
  listProps?: Partial<React.ComponentProps<typeof List>>;
}

function SelectModal<ItemType>({
  title,
  searchPlaceholder,
  showSelected = true,
  multiple = false,
  values,
  onDone,
  items,
  getId,
  getName,
  getDescription,
  getSearchMatch,
  renderItem,
  queryProps,
  listProps,
  testid,
  isOpen,
  setIsOpen,
  onClose,
  belowSearchBarSlot,
  ...props
}: SelectModalProps<ItemType> & React.ComponentProps<typeof Modal>) {
  const [selectedItems, setSelectedItems] = useState<ItemType[]>([]);
  const [triggerFocus, setTriggerFocus] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);
  const { t } = useTranslation();
  const { node, nodeRef } = useNodeRef();

  const { error, fetchNextPage, isLoading, isEmptyResponse, noMoreData } = or(
    queryProps,
    {}
  );

  const addItem = (item: ItemType) => {
    setSelectedItems((prev) => [...prev, item]);
  };

  const getItemId = (item: ItemType) => {
    return toString(or(get(item, 'id'), getId?.(item)));
  };

  const getItemName = (item: ItemType) => {
    return toString(or(get(item, 'name'), getName?.(item)));
  };

  const getItemDescription = (item: ItemType) => {
    return toString(or(get(item, 'description'), getDescription?.(item)));
  };

  const removeItem = (item: ItemType) => {
    setSelectedItems((prev) => {
      const seqIndex = findIndex(prev, (i) => getItemId(item) === getItemId(i));
      return [...prev.slice(0, seqIndex), ...prev.slice(seqIndex + 1)];
    });
  };

  const filteredItems = useMemo(() => {
    if (and(!!getSearchMatch, !!searchQueryValue)) {
      return filter(items, (i) =>
        getSearchMatch?.(i, searchQueryValue)
      ) as ItemType[];
    }
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQueryValue, items]);

  useEffect(() => {
    if (isOpen) {
      setSelectedItems(or(values, []));
    }
  }, [values, isOpen]);

  useEffect(() => {
    setSearchQuery('');
  }, [isOpen]);

  useEffect(() => {
    if (and(isOpen, !searchQueryValue, !items?.length)) {
      setTimeout(() => setTriggerFocus(Date.now()), 150);
    }
  }, [isOpen, items?.length, searchQueryValue]);

  const { text: titleText } = getOptionalRenderProp(title);

  const renderListItem = (item: ItemType) => {
    const isSelected = find(
      selectedItems,
      (i) => getItemId(i) === getItemId(item)
    );
    return (
      <ActionRow
        className={classNames(classes.itemCard, {
          [classes.selected]: isSelected,
        })}
        leftButton={choose(multiple, {
          variant: 'link',
          icon: choose(
            isSelected,
            ['fas', 'check-circle'],
            ['far', 'plus-circle']
          ),
          testid: `${getItemId(item)}-add-button`,
          wrapperProps: {
            className: classes.iconWrapper,
          },
        })}
        onClick={() => {
          if (!isSelected) {
            if (!multiple) {
              setIsOpen?.(false);
              onDone?.([item]);
              return;
            }
            addItem(item);
            return;
          }
          removeItem(item);
        }}
        testid={`item-${getItemId(item)}`}
      >
        {choose(
          renderItem,
          renderItem?.(item),
          <IonRow className={classes.itemRow}>
            <Text
              className={classes.itemName}
              text={getItemName(item)}
              variant="mipro-product-headline"
            />
            <Text variant="content-small" text={getItemDescription(item)} />
          </IonRow>
        )}
      </ActionRow>
    );
  };

  const renderSelectedItem = (item: ItemType, index: number) => (
    <IonRow
      key={`pill-${index}-${getItemId(item)}`}
      className={classes.selectedItem}
    >
      <Text className={classes.itemName} text={getItemName(item)} />
      <Button
        icon={['fas', 'times']}
        onClick={() => {
          removeItem(item);
          if (!multiple) {
            onDone?.([]);
          }
        }}
        testid={`remove-${getItemId(item)}-button`}
      />
    </IonRow>
  );

  useEffect(() => {
    props.setSearchQuery?.(searchQueryValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQueryValue]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={onClose}
      forceFullHeight
      title={titleText}
      withTitleLine={false}
      className={classes.modal}
      modalClassName={classes.modalWrapper}
      headerClassName={classes.modalHeader}
      testid={testid}
      header={
        <div className={classes.searchWrapper}>
          <Searchbar
            className={classes.searchbar}
            value={searchQuery}
            setValue={setSearchQuery}
            testid="search-input"
            placeholder={searchPlaceholder}
            triggerInputSelect={triggerFocus}
          />
          {ifRender(belowSearchBarSlot, belowSearchBarSlot)}
          {ifRender(
            and(showSelected, selectedItems?.length > 0),
            <div className={classes.selectedItems}>
              <div className={classes.itemsWrapper}>
                {map(selectedItems, renderSelectedItem)}
                <div style={{ minWidth: `1px` }} />
              </div>
            </div>
          )}
        </div>
      }
      footer={ifRender(
        multiple,
        <Footer
          buttons={[
            {
              text: t('common:clear'),
              onClick: () => {
                onDone?.([]);
                setIsOpen?.(false);
              },
              variant: 'secondary',
              testid: 'clear-button',
            },
            {
              text: t('common:apply'),
              onClick: () => {
                onDone?.(selectedItems);
                setIsOpen?.(false);
              },
              variant: 'mipro-action',
              testid: 'done-button',
            },
          ]}
        />
      )}
    >
      <div className={classes.list} ref={nodeRef}>
        {choose(
          and(!searchQueryValue, !items?.length, !isLoading),
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title={t('snapshot:startSearching')}
          />,
          <List
            testid="results-list"
            title={listProps?.title}
            data={filteredItems}
            itemContent={(_, item) => renderListItem(item)}
            isLoading={isLoading}
            scrollParent={node}
            isEmptyList={{
              isEmptyList: or(isEmptyResponse, !filteredItems?.length),
              ...(choose(
                typeof listProps?.isEmptyList === 'object',
                listProps?.isEmptyList
              ) as object),
            }}
            isError={{
              isError: !!error,
              title: t('common:errorLoading'),
              body: getErrorMessage(error),
            }}
            endReached={fetchNextPage}
            isEndOfList={noMoreData}
          />
        )}
      </div>
    </Modal>
  );
}

export default SelectModal;

interface FormikSelectModalProps<ItemType>
  extends Partial<React.ComponentProps<typeof SelectModal<ItemType>>>,
    BaseInputProps {
  modalTitle?: string;
  searchPlaceholder?: string;
  modalTestid: string;
}

export function FormikSelectModal<ItemType>({
  className,
  label,
  name,
  multiple,
  required,
  testid,
  modalTitle,
  modalTestid,
  renderViewOnly,
  ...props
}: Readonly<FormikSelectModalProps<ItemType>>) {
  const [isOpen, setIsOpen] = useState(false);
  const valueName = `${name}_valueText`;
  const { viewonly } = props;

  const [field, meta, helpers] = useField<ItemType[]>({ name });
  const [, , helpersValueText] = useField<string>({
    name: valueName,
  });
  const { validateField } = useFormikContext();

  useEffect(() => {
    helpersValueText.setValue(
      or(
        map(field.value, (i) => {
          return toString(or(get(i, 'name'), props.getName?.(i)));
        }).join(', '),
        choose(viewonly, emptyString)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, viewonly]);

  return (
    <>
      <FormikInput
        className={classNames(classes.input, className, {
          [classes.viewonly]: and(viewonly, !!renderViewOnly),
        })}
        label={label}
        name={valueName}
        error={choose(meta.touched, meta.error, '')}
        testid={testid}
        onClick={() => setIsOpen(true)}
        rightButton={{
          icon: ['fas', 'caret-down'],
          onClick: () => setIsOpen(true),
        }}
        readonly
        viewonly={viewonly}
        required={required}
        textarea
        autogrow
        rows={1}
        customLabel={ifRender(
          !viewonly,
          <SelectModal<ItemType>
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={modalTitle}
            values={field.value}
            multiple={multiple}
            testid={modalTestid}
            onDone={(v) => {
              helpers.setTouched(true);
              helpers.setValue(v);
              setTimeout(() => validateField(name));
            }}
            onClose={() => {
              setTimeout(() => validateField(name));
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )}
      />
      {ifRender(viewonly, renderViewOnly?.(field.value))}
    </>
  );
}
