import type { Ref } from 'react';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { isEmpty, isNil, kebabCase, toString, toNumber } from 'lodash';
import { Style as StatusBarStyle } from '@capacitor/status-bar';
import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonMenuToggle,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { and, choose, or } from 'common/utils/logicHelpers';
import { goToNotificationList } from 'HomeApp/Notifications/navigation/navigationHelpers';
import { notificationLastSeenTime } from 'HomeApp/Notifications/utils/helpers';
import useGetOrder from 'ProductSearchApp/api/useGetOrder';
import useGetUnreadNoticesCount from 'api/user/useGetUnreadNoticesCount';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import useChangeAppMode from 'hooks/useChangeAppMode';
import useChangeLocation from 'hooks/useChangeLocation';
import useGoBack from 'hooks/useGoBack';
import type { RootState } from 'store/reducers';
import { setNoticesLastSeenTime, setPrevNoticesLastSeenTime } from 'store/user';
import { setStatusBarTheme } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { goToOrderCart } from 'navigation/navigationHelpers';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './Header.module.scss';

enum HeaderThemeTypeEnum {
  light,
  dark,
}

type HeaderThemeType = keyof typeof HeaderThemeTypeEnum;

interface HeaderProps {
  title?: string;
  titleButton?: React.ReactNode;
  eyebrow?: string;
  theme?: HeaderThemeType;
  toolbar?: React.ReactNode;
  toolbarChildren?: React.ReactNode;
  href?: string;
  onDismiss?: (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
  customBackButtonClick?: () => void;
  withBackButton?: boolean;
  hideAvatar?: boolean;
  testid: string;
  hideHomeMenu?: boolean;
  showOptionsMenu?: boolean;
  eyebrowClassName?: string;
  setIsOpen?: (isOpen: boolean) => void;
  headerRef?: Ref<HTMLIonHeaderElement>;
  backButtonClass?: string;
  isCondensedHeader?: boolean;
  showLocationText?: boolean;
  showCartIcon?: boolean;
  showNoticesBellIcon?: boolean;
  showLocationSelector?: boolean;
}

const Header = ({
  className,
  backButtonClass,
  title,
  titleButton,
  eyebrow = '',
  theme = 'dark',
  children,
  toolbar,
  toolbarChildren,
  href,
  onDismiss,
  withBackButton,
  customBackButtonClick,
  hideAvatar,
  testid,
  hideHomeMenu = false,
  showOptionsMenu = false,
  eyebrowClassName,
  setIsOpen,
  headerRef,
  isCondensedHeader,
  showLocationText = false,
  showCartIcon = true,
  showNoticesBellIcon = true,
  showLocationSelector = !hideHomeMenu,
}: HeaderProps &
  React.ComponentProps<typeof IonHeader> &
  IonicReactProps): JSX.Element => {
  const { goBack } = useGoBack();
  const dispatch = useDispatch();
  const { currentCartCustomer, noticesLastSeenTime } = useSelector(
    (state: RootState) => state.user
  );
  const { isLocatorAvailable, locatorURL, locationText } = useChangeLocation();
  const { isMiProApp } = useChangeAppMode();
  const { data: unreadCountData } = useGetUnreadNoticesCount({
    enabled: false,
  });

  const unreadCount = toNumber(unreadCountData?.unreadCount);

  const isCartAvailable = useAccessControls(
    AccessControlType.EditOrdersAccessControls
  );
  const { numberOfCartItems } = useGetOrder({
    miLoc: currentCartCustomer?.miLoc,
    orderCtlNo: currentCartCustomer?.orderCtlNo,
  });

  const replaceToolbar =
    isNil(title) &&
    isNil(titleButton) &&
    isNil(onDismiss) &&
    isNil(withBackButton);

  useIonViewWillEnter(() => {
    void setStatusBarTheme(
      theme === 'light' ? StatusBarStyle.Light : StatusBarStyle.Dark
    );
  }, [theme]);

  const showCart = isCartAvailable && showCartIcon && isMiProApp;
  const showNoticesBell = showNoticesBellIcon && isMiProApp;
  const showLocation = showLocationSelector && isLocatorAvailable;

  const onBellButtonClick = () => {
    dispatch(setPrevNoticesLastSeenTime(noticesLastSeenTime));
    dispatch(setNoticesLastSeenTime(notificationLastSeenTime()));
  };

  return (
    <IonHeader
      translucent
      className={classNames(classes.header, className, classes[theme])}
      data-testid={`header-${testid}`}
      ref={headerRef}
      mode="ios"
    >
      <IonToolbar color={theme}>
        {withBackButton && (
          <IonButtons
            slot="start"
            className={classNames({
              [classes.condendsedHeaderIcons]: isCondensedHeader,
            })}
          >
            <Button
              className={classNames(classes.backButton, backButtonClass)}
              variant="clear"
              icon={findIcon('chevron-left')}
              testid="header-back-button"
              href={href}
              routerLink={href}
              routerDirection="back"
              onClick={() => {
                if (!isNil(customBackButtonClick)) {
                  customBackButtonClick?.();
                } else if (isEmpty(href)) {
                  goBack();
                }
              }}
            />
          </IonButtons>
        )}
        {onDismiss && (
          <IonButtons slot="end">
            <Button
              className={classes.closeBtn}
              variant="clear"
              onClick={onDismiss}
              icon={findIcon('times')}
              testid="header-dismiss"
            />
          </IonButtons>
        )}
        {(title || titleButton) && (
          <>
            <Text
              className={classNames(classes.eyebrow, eyebrowClassName)}
              variant="label-micro"
              text={eyebrow}
              testid="header-eyebrow"
            />
            <IonTitle className={classes.title}>
              {title && (
                <Text
                  className={classes.titleText}
                  variant="title-screen-section"
                  text={title}
                  testid={`${kebabCase(title)}-header-title`}
                />
              )}
              {!isNil(titleButton) && titleButton}
            </IonTitle>
          </>
        )}
        {(showCart || showLocation || showNoticesBell) && (
          <IonButtons
            slot="end"
            className={classNames({
              [classes.condendsedHeaderIcons]: isCondensedHeader,
              [classes.nonCondensedIcons]: !isCondensedHeader,
            })}
          >
            {showNoticesBell && (
              <Button
                icon={['far', 'bell']}
                href={goToNotificationList()}
                onClick={onBellButtonClick}
                testid="notices-button"
                className={classNames(classes.cartIcon, {
                  [classes.condensedCartIcon]: isCondensedHeader,
                })}
              >
                {unreadCount > 0 && (
                  <Badge
                    text={toString(unreadCount > 99 ? '99+' : unreadCount)}
                    type="error"
                    testid="notices-badge"
                    className={classes.badge}
                  />
                )}
              </Button>
            )}
            {/* Todo: Add href once the Cart UI is ready */}
            {showCart && (
              <Button
                icon={findIcon('cart-shopping', 'far')}
                href={goToOrderCart(
                  currentCartCustomer?.miLoc,
                  currentCartCustomer?.shipToCustNo,
                  currentCartCustomer?.orderCtlNo
                )}
                testid="carticon--button"
                className={classNames(classes.cartIcon, {
                  [classes.condensedCartIcon]: isCondensedHeader,
                })}
              >
                {/* Todo: Pass the value from the API */}
                {toNumber(numberOfCartItems) > 0 && (
                  <Badge
                    text={toString(numberOfCartItems)}
                    type="error"
                    testid="carticon--badge"
                    className={classes.badge}
                  />
                )}
              </Button>
            )}
            {showLocation && (
              <Button
                variant="link"
                icon={['fal', 'location-dot']}
                testid="location-selector"
                text={choose(
                  or(
                    showLocationText,
                    and(!title, !eyebrow, isNil(titleButton))
                  ),
                  locationText
                )}
                href={locatorURL}
                textVariant="label-header"
                iconClassName={classes.locationIcon}
                className={classNames(
                  classes.cartIcon,
                  classes.locationButton,
                  { [classes.condensedCartIcon]: isCondensedHeader }
                )}
              />
            )}
          </IonButtons>
        )}
        {!isNil(toolbarChildren) && toolbarChildren}
        {hideHomeMenu || replaceToolbar ? (
          toolbar
        ) : (
          <IonMenuToggle
            slot="end"
            className={classNames(classes.openMenuToggle, {
              [classes.condendsedHeaderIcons]: isCondensedHeader,
            })}
            menu="home-menu"
            data-testid="home-header-avatar-toggle"
          >
            {!hideAvatar && (
              <IonMenuButton className={classes.menuButton} autoHide={false} />
            )}
          </IonMenuToggle>
        )}
        {showOptionsMenu && !!hideHomeMenu && (
          <IonButtons slot="end">
            <Button
              className={classes.closeBtn}
              variant="clear"
              onClick={() => setIsOpen?.(true)}
              icon={findIcon('ellipsis-v')}
              testid="notes-overflow-menu"
            />
          </IonButtons>
        )}
      </IonToolbar>
      {children}
    </IonHeader>
  );
};

export default Header;
