import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import {
  useMiLocOrTeamId,
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type { Contact, UpdateContactBody } from 'models/Contact';
import type { SearchItemType } from 'models/Search';
import { ToastType } from 'models/Toast';
import { findContactsQueryKey } from './useFindContacts';

interface AddContactMutationProps {
  searchType: SearchItemType;
  miLoc: string;
  id: string;
  sendInvite?: boolean;
}

interface UseAddContactResponse {
  status: MutationStatus;
  addContact: (body: UpdateContactBody) => void;
  data?: Contact;
}

const useAddContact = ({
  searchType,
  miLoc: itemMiLoc,
  id,
}: AddContactMutationProps): UseAddContactResponse => {
  const { axios } = useAxios();
  const { findContactsAPI } = useAPIUrl();
  const { t } = useTranslation(namespaces.contact);
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { createParams } = useMiLocOrTeamId({
    miLoc: itemMiLoc,
    sendTeamId: false,
  });
  const params = { ...createParams() };
  const { miLoc } = params;

  const doAddContact = (body: UpdateContactBody) => {
    return doPromiseAPI<Contact>(async (cancelToken) => {
      let contactBody = { ...body };
      if (searchType === 'supplier') {
        contactBody.phone1 = contactBody.phone;
        contactBody = omit(contactBody, ['phone', 'sendInvite']);
      }
      const response = await axios.post<Contact>(
        findContactsAPI(searchType, miLoc, id),
        contactBody,
        {
          cancelToken,
        }
      );

      return response.data;
    });
  };

  const updateQueryKeyParams = {
    searchType,
    id,
    // DOC: update supplier queries for all locations
    ...(searchType === 'customer' ? { miLoc } : {}),
  };

  const { mutate, status, data } = useMutation(doAddContact, {
    onMutate: async (vars) =>
      onMutateUpdate<Contact>({
        queryClient,
        queryKey: findContactsQueryKey,
        queryKeyParams: updateQueryKeyParams,
        newItems: [{ ...vars, sequenceNo: Date.now() }],
        isInfiniteQuery: true,
      }),
    onSuccess: (_, { name = t('defaultNameToast') }) => {
      addToast({
        text: t('addedSuccessToast', {
          name,
        }),
        // TODO undo operation
        testid: 'add-contact-toast',
      });
      void onSuccessMutation(
        queryClient,
        findContactsQueryKey,
        updateQueryKeyParams
      );
    },
    onError: (errors, vars, context) => {
      addToast({
        type: ToastType.error,
        text: t('contactSaveFailure'),
        testid: 'add-contact-error-toast',
      });
      onErrorUpdate<Contact>({
        queryClient,
        context: context as UpdateMutationContext<Contact>[],
        isInfiniteQuery: true,
      });
    },
  });

  return {
    status,
    addContact: (body: UpdateContactBody) => mutate(body),
    data,
  };
};

export default useAddContact;
