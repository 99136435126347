import React, { useState } from 'react';
import { toString } from 'lodash';
import { FormikSelectModal } from 'common/components/SelectModal/SelectModal';
import { or } from 'common/utils/logicHelpers';
import type { Manufacturer } from 'HomeApp/ProTips/api/useFindManufacturers';
import useFindManufacturers from 'HomeApp/ProTips/api/useFindManufacturers';

interface ManufacturerSelectProps
  extends Partial<
    React.ComponentProps<typeof FormikSelectModal<Manufacturer>>
  > {
  name: string;
}

const ManufacturerSelect = ({ ...props }: ManufacturerSelectProps) => {
  const testid = or(props.testid, 'manufacturer-input');
  const modalTestid = or(props.modalTestid, 'manufacturer-modal');

  const [searchQuery, setSearchQuery] = useState('');

  const { manufacturers, ...queryProps } = useFindManufacturers({
    query: searchQuery,
  });

  return (
    <FormikSelectModal<Manufacturer>
      items={manufacturers}
      getId={(i) => toString(i.mfrCtlNo)}
      getName={(i) => i.mfrName}
      getDescription={(i) => i.mfrCtlNo}
      setSearchQuery={setSearchQuery}
      queryProps={queryProps}
      testid={testid}
      modalTestid={modalTestid}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default ManufacturerSelect;
