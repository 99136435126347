import React, { useEffect, useMemo, useRef } from 'react';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import type { Dictionary } from 'lodash';
import {
  filter,
  find,
  findIndex,
  forEach,
  get,
  includes,
  map,
  toArray,
  toString,
} from 'lodash';
import { IonCol, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useGetCostTamplate from 'CostSavingsApp/api/useGetCostTemplate';
import VasSelect from 'CostSavingsApp/components/FastFind/VasSelect';
import type {
  CostSavingsEntryForm,
  CostSavingsField,
} from 'CostSavingsApp/models/CostSavings';
import {
  CostSavingsDataTypeEnum,
  FastFindTypeEnum,
} from 'CostSavingsApp/models/CostSavings';
import classes from 'CostSavingsApp/pages/CostSavingsEntry.module.scss';
import { namespaces } from 'i18n/i18n.constants';
import type { Contact } from 'models/Contact';
import type { SelectModalItem } from 'models/Search';
import FileAttachments from 'components/Attachments/FileAttachments';
import Loader from 'components/Loader/Loader';
import Text from 'components/Text/Text';
import CostSavingsInput from './CostSavingsInput';
import VasCodeLine from './VasCodeLine';

export const getGenericFields = (t: TFunction): CostSavingsField[] => [
  {
    dataType: CostSavingsDataTypeEnum.date,
    description: t('costSavings:selectDateOfService'),
    displayName: t('costSavings:dateOfService'),
    required: 'Y',
    columnName: 'dateOfService',
  },
  {
    dataType: CostSavingsDataTypeEnum.fastfind,
    description: t('costSavings:enterContactName'),
    displayName: t('costSavings:customerContact'),
    required: 'Y',
    columnName: 'custContactName',
    maxlength: 30,
    fastFindType: FastFindTypeEnum.contact,
  },
  {
    dataType: CostSavingsDataTypeEnum.email,
    description: t('costSavings:enterContactEmail'),
    displayName: t('costSavings:customerEmail'),
    isExpression: 'Y',
    required: 'Y',
    columnName: 'custContactEmail',
  },
  {
    dataType: CostSavingsDataTypeEnum.text,
    description: t('costSavings:enterContactTitle'),
    displayName: t('costSavings:customerTitle'),
    isExpression: 'Y',
    required: 'Y',
    maxlength: 30,
    columnName: 'custContactTitle',
  },
  {
    dataType: CostSavingsDataTypeEnum.radio,
    description: t('costSavings:costSavingsBlitz'),
    displayName: t('costSavings:costSavingsBlitz'),
    required: 'Y',
    defaultValue: 'N',
    columnName: 'costSavingsBlitz',
  },
  {
    dataType: CostSavingsDataTypeEnum.radio,
    description: t('costSavings:supplierAssistance'),
    displayName: t('costSavings:supplierAssistance'),
    required: 'Y',
    defaultValue: 'N',
    columnName: 'supplierAssistance',
  },
  {
    dataType: CostSavingsDataTypeEnum.fastfind,
    description: t('costSavings:selectSupplierAssistanceMFR'),
    displayName: t('costSavings:supplierAssistanceMFR'),
    columnName: 'supplierMfrCtlNo',
    fastFindType: FastFindTypeEnum.manufacturer,
    show: 'N',
  },
  {
    dataType: CostSavingsDataTypeEnum.textarea,
    description: t('costSavings:helpCustomer'),
    displayName: t('costSavings:problemSolution'),
    required: 'Y',
    columnName: 'problemSolution',
  },
];

interface CostSavingsFormProps {
  miLoc: string;
  customerNo: string;
  formData: CostSavingsEntryForm;
  formErrors: Dictionary<unknown>;
  setFieldValue: (n: string, v: unknown) => void;
  setFieldError: (n: string, v: string) => void;
  disabled?: boolean;
  formInputs: CostSavingsField[];
  setFormInputs: React.Dispatch<React.SetStateAction<CostSavingsField[]>>;
  triggerVasInput?: number;
  triggerRefetch?: number;
}

const CostSavingsForm = ({
  miLoc,
  customerNo,
  formData,
  formErrors,
  setFieldValue,
  setFieldError,
  disabled,
  formInputs,
  setFormInputs,
  triggerVasInput,
  triggerRefetch,
}: CostSavingsFormProps & IonicReactProps): JSX.Element => {
  const { t } = useTranslation(namespaces.costSavings);
  const vasCode = toString(formData.VAS_CODE?.key);
  const divRef = useRef<HTMLDivElement>(null);

  const {
    error,
    data: templateData,
    isLoading,
  } = useGetCostTamplate({ miLoc, customerNo, vasCode });

  useEffect(() => {
    setFieldValue('VAS_TEMPLATE', templateData);
  }, [setFieldValue, templateData, triggerRefetch]);

  useEffect(() => {
    setFormInputs([
      ...getGenericFields(t),
      ...toArray(templateData?.fieldsList),
    ]);
  }, [setFormInputs, t, templateData?.fieldsList]);

  useEffect(() => {
    if (vasCode) {
      forEach(templateData?.fieldsList, (field) => {
        const fieldName = field.columnName;
        // DOC: when vasCode changes, reset date and expression fields
        if (
          includes(
            [CostSavingsDataTypeEnum.date, CostSavingsDataTypeEnum.expression],
            field.dataType
          )
        ) {
          setFieldValue(fieldName, '');
        }
        // DOC: set default values to template inputs
        if (field.defaultValue) {
          setFieldValue(fieldName, field.defaultValue);
        }
      });
    }
  }, [setFieldValue, vasCode, templateData?.fieldsList, miLoc, customerNo]);

  useEffect(() => {
    const contact = get(formData.custContactName_DROPDOWN, 'item') as Contact;
    if (contact) {
      setFieldValue('custContactName', contact.name);
      setFieldValue('custContactEmail', contact.email);
      setFieldError('custContactEmail', '');
      setFieldValue('custContactTitle', contact.typeDesc);
      setFieldError('custContactTitle', '');
    }
  }, [setFieldValue, setFieldError, formData.custContactName_DROPDOWN]);

  useEffect(() => {
    setFieldValue('custContactName', '');
    setFieldValue('custContactEmail', '');
    setFieldValue('custContactTitle', '');
    setFieldValue('custContactName_DROPDOWN', '');
  }, [setFieldValue, miLoc, customerNo]);

  useEffect(() => {
    // DOC: if supplier assintance is Y, Supplier Assistance MFR is required
    const radioValue = toString(formData.supplierAssistance);
    if (radioValue) {
      setFormInputs((prev) => {
        const index = findIndex(prev, { columnName: 'supplierMfrCtlNo' });
        setFieldValue(
          'supplierMfrCtlNo',
          radioValue === 'Y'
            ? get(formData.supplierMfrCtlNo_DROPDOWN, 'key')
            : ''
        );
        return [
          ...prev.slice(0, index),
          {
            ...prev[index],
            required: radioValue,
            show: radioValue,
            value:
              radioValue === 'Y' ? toString(formData.supplierMfrCtlNo) : '',
          },
          ...prev.slice(index + 1),
        ];
      });
      setFieldError('supplierMfrCtlNo', '');
    }
  }, [
    setFieldError,
    setFormInputs,
    formData.supplierAssistance,
    templateData?.fieldsList,
    formData.supplierMfrCtlNo,
    setFieldValue,
    formData.supplierMfrCtlNo_DROPDOWN,
  ]);

  const attachments = useMemo(
    () => formData.VAS_ATTACHMENTS || [],
    [formData.VAS_ATTACHMENTS]
  );

  return (
    <div className={classes.costSavingsForm} ref={divRef}>
      <IonRow className={classes.rowHeading}>
        <IonCol>
          <Text
            text={t('costSavingsCode')}
            variant="title-card-red"
            className={classes.textColor}
            testid="cost-savings-title"
          />
        </IonCol>
      </IonRow>
      <IonRow className={classes.rowHeading}>
        <IonCol>
          <VasSelect
            className={classes.vasHeading}
            label={t('costSavingsCodeInput')}
            placeholder={t('costSavingsCodePlaceholder')}
            iconClassName={classes.magnifier}
            setCostSavingsCode={(item) => setFieldValue('VAS_CODE', item)}
            disabled={disabled}
            selItem={formData.VAS_CODE as SelectModalItem}
            triggerInput={triggerVasInput}
          />
          <VasCodeLine templateData={templateData} />
        </IonCol>
      </IonRow>
      <Loader
        testid="loader"
        text={t('common:loading')}
        className={classes.loader}
        isOpen={isLoading}
      />
      {!isLoading && !error && vasCode && (
        <>
          <IonRow className={classes.rowHeading}>
            <IonCol>
              <Text
                text={t('Details')}
                variant="title-card-red"
                className={classes.textColor}
                testid="cost-savings-title"
              />
            </IonCol>
          </IonRow>
          <IonRow className={classes.rowHeading}>
            <IonCol>
              <Text
                text={toString(templateData?.instructions)}
                variant="content-small"
                testid="cost-savings-instuctions"
              />
            </IonCol>
          </IonRow>
          <div className={classes.formWrapper}>
            {map(formInputs, (item, index) => (
              <CostSavingsInput
                key={`${item.columnName}-${index}`}
                miLoc={miLoc}
                customerNo={customerNo}
                formData={formData}
                formErrors={formErrors}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                field={item}
                disabled={disabled}
                disableAutoScroll={false}
                parentRef={divRef}
                testid={`${item.columnName}-cs-input`}
              />
            ))}
          </div>
          <IonRow className={classes.rowHeading} key="attachments">
            <IonCol>
              <FileAttachments
                domain="vas"
                hidePreviewInputs
                name="VAS_ATTACHMENTS"
                files={attachments}
                label={t('common:addAttachments')}
                setFieldError={setFieldError}
                onAdd={(files) => {
                  setFieldValue('VAS_ATTACHMENTS_ADDED', [
                    ...(formData.VAS_ATTACHMENTS_ADDED || []),
                    ...files,
                  ]);
                }}
                onRemove={(file) => {
                  if (
                    !find(formData.VAS_ATTACHMENTS_ADDED, {
                      fileName: file.fileName,
                    })
                  ) {
                    setFieldValue('VAS_ATTACHMENTS_REMOVED', [
                      ...(formData.VAS_ATTACHMENTS_REMOVED || []),
                      file.fileName,
                    ]);
                  }
                  setFieldValue(
                    'VAS_ATTACHMENTS_ADDED',
                    filter(
                      formData.VAS_ATTACHMENTS_ADDED,
                      ({ fileName }) => fileName !== file.fileName
                    )
                  );
                }}
                disabled={disabled}
                enableSmallPreview
                testid="attachments"
              />
            </IonCol>
          </IonRow>
        </>
      )}
    </div>
  );
};

export default CostSavingsForm;
