import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { toString, toNumber, trim } from 'lodash';
import ActivityItemContent from 'ActivitiesApp/components/common/ActivityItemContent';
import { and, choose, ifRender, or } from 'common/utils/logicHelpers';
import type { ProTip } from 'HomeApp/ProTips/api/useFindProTips';
import type { ProTipLinkerType } from 'HomeApp/ProTips/api/useLinkProTip';
import useLinkProTip from 'HomeApp/ProTips/api/useLinkProTip';
import ProTipSelect from 'HomeApp/ProTips/components/ProTipSelect/ProTipSelect';
import { activitiesURL, activityDetailURL } from 'navigation';
import type { ProTipItem } from 'ProductSearchApp/models/Order';
import { isCustomerLess } from 'ProductSearchApp/util/productSearchUtil';
import useGetActivity from 'api/activities/useGetActivity';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import useFeatureFlags, { FeatureFlagType } from 'hooks/useFeatureFlags';
import type { ActionCardActivity } from 'models/ActivityModels';
import { CUSTOMERLESS } from 'utils/constants';
import { concatRoutes } from 'utils/navigations';
import Button from 'components/Button/Button';
import HeaderActions from 'components/HeaderActions/HeaderActions';
import Loader from 'components/Loader/Loader';
import type { SwipeOption } from 'components/Slider/Slider';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './ProTipLink.module.scss';

interface ProTipLinkProps {
  className?: string;
  isLoading?: boolean;
  miLoc: string;
  customerNo: string;
  linkerId: string;
  linkerType: ProTipLinkerType;
  proTip?: ProTipItem;
  historyId?: number;
  orderCtlNo?: string;
}

const ProTipLink = ({
  className,
  isLoading = false,
  miLoc,
  customerNo,
  linkerId,
  linkerType,
  proTip,
  historyId,
  orderCtlNo,
}: ProTipLinkProps) => {
  const { t } = useTranslation('ProductSearchApp-Search');
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [actionsIsOpen, setActionsIsOpen] = useState(false);
  const canLinkProTip = useFeatureFlags(FeatureFlagType.linkProTip);
  const canCreateEditOrder = useAccessControls(
    AccessControlType.EditOrdersAccessControls
  );
  const canUpdateOcnLinePrice = useAccessControls(
    AccessControlType.editOcnPrice
  );
  const enabled = and(
    !isCustomerLess(customerNo),
    or(canCreateEditOrder, canUpdateOcnLinePrice)
  );
  const { historyId: proTipHistoryId, reqEmployeeId = '' } = or(
    proTip,
    {} as ProTipItem
  );

  const { data: activityItem, isFetching } = useGetActivity({
    historyId: toNumber(proTipHistoryId),
    userId: toString(reqEmployeeId),
    enabled: and(!!proTipHistoryId, !!reqEmployeeId),
  });

  const { isLoading: isLinkLoading, onLinkProTip } = useLinkProTip();

  const onDetailClick = ({ activity }: { activity: ActionCardActivity }) => {
    const { userId, custMiLoc, custNo, eventTagName } = activity;
    history.push(
      concatRoutes(
        activitiesURL(),
        activityDetailURL(
          or(trim(custMiLoc), 'EXEC'),
          or(trim(custNo), CUSTOMERLESS),
          userId,
          toString(proTipHistoryId),
          eventTagName
        )
      )
    );
  };

  const linkProTip = (v: ProTip[]) => {
    onLinkProTip({
      tipId: v[0]?.tipId,
      linkerType,
      linkerId,
      miLoc,
      active: 'Y',
      historyId,
      orderCtlNo,
    });
  };

  const unLinkProTip = () => {
    onLinkProTip({
      tipId: toNumber(proTip?.tipId),
      linkerType,
      linkerId,
      miLoc,
      active: 'N',
      historyId,
      orderCtlNo,
    });
  };

  const onSlideLeft: SwipeOption = {
    icon: ['far', 'link-slash'],
    text: t('unLink'),
    className: 'unLinkSlider',
    onSwipeComplete: unLinkProTip,
  };

  return canLinkProTip ? (
    <div className={classNames(className, classes.proTipCard)}>
      <div className={classNames(classes.cardWrapper, classes.noMargin)}>
        <div className={classes.flex}>
          <Text variant="mipro-h3-headline" text={t('proTip')} />
          {proTip && (
            <HeaderActions
              className={classes.proTipHeaderActions}
              isOpen={actionsIsOpen}
              setIsOpen={setActionsIsOpen}
              title={t('manageProTips')}
              testid="manage-pro-tips-button"
              options={[
                {
                  text: t('unlinkProTip'),
                  icon: ['far', 'link-slash'],
                  onClick: () => unLinkProTip(),
                  disabled: or(isFetching, isLinkLoading, isLoading),
                  testid: 'unlink-pro-tip-button',
                },
              ]}
            />
          )}
        </div>
        <Loader
          className={classes.loader}
          text={t('loadingProTip')}
          isOpen={or(isFetching, isLinkLoading, isLoading)}
          testid="loading-proTip"
        />
        {ifRender(
          and(!isFetching, !isLinkLoading, !isLoading),
          choose(
            !!proTip,
            <ActivityItemContent
              className={classes.actionCard}
              testid="pro-tip-activity"
              activity={or(activityItem, {} as ActionCardActivity)}
              multiSelectEnabled={false}
              loggedInUserId={reqEmployeeId}
              onDetailClick={onDetailClick}
              isSnapshotActivities={false}
              onSlideLeft={[onSlideLeft]}
              enabled
              hideReadIndicator
            />,
            <WarningMessage
              className={classes.proTipMsg}
              title={t('proTipEmpty')}
              hideIcon
            >
              <Button
                variant="secondary"
                testid="link-pro-tip"
                icon={['far', 'link']}
                text={t('linkProTip')}
                className={classes.linkProTipBtn}
                onClick={() => setIsOpen(true)}
                disabled={!enabled}
              />
            </WarningMessage>
          )
        )}
      </div>

      <ProTipSelect
        className={classes.formField}
        isOpen={isOpen}
        miLoc={miLoc}
        customerNo={customerNo}
        onDone={(v) => linkProTip(v)}
        setIsOpen={setIsOpen}
        title={t('selProTip')}
        testid="pro-tip-input"
      />
    </div>
  ) : null;
};

export default ProTipLink;
