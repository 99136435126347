import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { map, size, toNumber, toString } from 'lodash';
import { IonSkeletonText } from '@ionic/react';
import { FormikInput } from 'common/components/Forms/Input/Input';
import { choose, or } from 'common/utils/logicHelpers';
import { useFormikContext } from 'formik';
import useGetIndustryUserCount from 'HomeApp/ProTips/api/useGetIndustryUserCount';
import EmployeeSelect from 'HomeApp/ProTips/components/EmployeeSelect.ts/EmployeeSelect';
import IndustrySegmentsSelect from 'HomeApp/ProTips/components/IndustrySegmentsSelect.ts/IndustrySegmentsSelect';
import ManufacturerSelect from 'HomeApp/ProTips/components/ManufacturerSelect/ManufacturerSelect';
import FileAttachments from 'components/Attachments/FileAttachments';
import Text from 'components/Text/Text';
import WarningMessage, {
  WarningMessageVarianEnum,
} from 'components/WarningMessage/WarningMessage';
import classes from './ShareProTip.module.scss';
import type { ShareProTipForm } from './ShareProTipSchema';

const ProTipReview = () => {
  const { t } = useTranslation();

  const { values } = useFormikContext<ShareProTipForm>();

  const { userCount, isLoading, error } = useGetIndustryUserCount({
    industries: map(values.industrySegments, (i) => toString(i.id)),
  });

  return (
    <div className={classes.reviewWrapper} data-testid="pro-tip-review">
      {choose(
        !!error,
        <WarningMessage
          title={t('common:errorTitle')}
          className={classes.message}
          testid="error-message"
        />,
        choose(
          isLoading,
          <IonSkeletonText className={classes.valueSkeleton} animated />,
          <>
            <WarningMessage
              variant={choose(
                toNumber(userCount) > 1,
                WarningMessageVarianEnum.warning,
                WarningMessageVarianEnum.alert
              )}
              title={t('HomeApp/ProTips:reviewWarningTitle')}
              hideIcon
              className={classes.message}
              testid="review-warning-message"
            >
              <span className={classes.body}>
                <Trans
                  i18nKey={t('HomeApp/ProTips:reviewWarningMsg', {
                    employee: values.employee?.[0]?.name,
                    count: or(userCount, 0),
                  })}
                />
              </span>
            </WarningMessage>
            <EmployeeSelect
              className={classes.formField}
              name="employee"
              label={t('HomeApp/ProTips:employee:reviewLabel')}
              viewonly
              testid="employee-input"
            />
            <IndustrySegmentsSelect
              className={classes.formField}
              name="industrySegments"
              label={t('HomeApp/ProTips:industrySegments:reviewLabel')}
              viewonly
              testid="industry-segments-input"
            />
            <FormikInput
              className={classes.formField}
              name="revenue"
              label={t(`HomeApp/ProTips:revenue:reviewLabel`)}
              viewonly
              currencyMask={{ scale: 0 }}
            />
            <ManufacturerSelect
              className={classes.formField}
              name="manufacturer"
              label={t('HomeApp/ProTips:manufacturer:reviewLabel')}
              viewonly
              testid="manufacturer-input"
            />
            <FormikInput
              className={classes.formField}
              label={t(`HomeApp/ProTips:headline:reviewLabel`)}
              name="headline"
              viewonly
            />
            <FormikInput
              className={classes.formField}
              label={t(`HomeApp/ProTips:descriptionLabel`)}
              name="description"
              viewonly
            />
            <div className={classes.attachmentWrapper}>
              <Text
                text={choose(
                  size(values.files) > 0,
                  t('email:attachments'),
                  t('email:noAttachments')
                )}
                variant="content-heavy"
              />
              <FileAttachments
                className={classes.fileAttachments}
                hidePreviewInputs
                domain=""
                name="files"
                files={values.files}
                enableSmallPreview
                editMode={false}
                prefetchAll
                testid="pro-tip-attachments"
              />
            </div>
          </>
        )
      )}
    </div>
  );
};

export default ProTipReview;
