import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { lowerCase, toString } from 'lodash';
import { IonItem, IonRow } from '@ionic/react';
import useDeleteActivityNote from 'ActivitiesApp/api/notes/useDeleteActivityNote';
import { and, choose, ifRender, or } from 'common/utils/logicHelpers';
import { useUserInfo } from 'common/utils/userInfo';
import { differenceInSeconds } from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import type { ActivityNote, UpdateActivityNoteBody } from 'models/Notebook';
import { formatCardDate, parseDate } from 'utils/date';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import classes from './Comment.module.scss';
import CommentInput from './CommentInput';

interface ActivityNotebookProps {
  notebook: ActivityNote;
  onEditComment?: (n: UpdateActivityNoteBody) => void;
  className?: string;
  disabledComment?: boolean;
  setSelectedEmployeeId?: (employeeId: string) => void;
}

const Comment = ({
  notebook,
  onEditComment,
  className,
  disabledComment,
  setSelectedEmployeeId,
}: ActivityNotebookProps): JSX.Element => {
  const {
    title,
    text,
    creationTimestamp,
    lastUpdatedTimestamp,
    userId,
    userFullName,
    historyId,
    eventNoteId,
    isOptimisticallyUpdating,
    employeeId,
  } = notebook;
  const { t, i18n } = useTranslation(namespaces.comments);
  const { isOwner } = useUserInfo({ userId });
  const { deleteActivityNote, status } = useDeleteActivityNote();

  const [isOpenEditDeleteModal, setIsOpenEditDeleteModal] = useState(false);

  let owner = choose(isOwner, t('common:you'), userFullName);
  owner = or(owner, userId);
  const isUpdating = or(status === 'loading', isOptimisticallyUpdating);

  const onDelete = () => {
    deleteActivityNote({
      deleteFlag: 'Y',
      historyId: toString(historyId),
      id: eventNoteId,
      successToastText: t('deleteCommentToast'),
    });
  };

  return (
    <div className={className}>
      <ActionRow
        disabled={or(isUpdating)}
        className={classNames(classes.commentCard, {
          [classes.isUpdating]: isUpdating,
        })}
        testid={`comment-${eventNoteId}`}
        withArrow={false}
        onClick={() => {
          if (and(isOwner, !disabledComment)) {
            setIsOpenEditDeleteModal(true);
          }
        }}
      >
        <div className={classes.commentItem}>
          <IonRow className={classes.commentInfo}>
            <IonRow>
              <Button
                className={classNames({
                  [classes.owner]: isOwner,
                  [classes.others]: !isOwner,
                })}
                variant={choose(!isOwner, 'link', 'clear')}
                textVariant="mipro-body-copy-bold"
                text={lowerCase(owner)}
                onClick={choose(!isOwner, () =>
                  setSelectedEmployeeId?.(employeeId)
                )}
                testid="comment-owner"
              />
              {ifRender(
                differenceInSeconds(
                  parseDate(lastUpdatedTimestamp),
                  parseDate(creationTimestamp)
                ) > 5,
                <Text
                  className={classes.editedLabel}
                  variant="label-micro"
                  text={t('editedComment')}
                />
              )}
            </IonRow>
            <div className={classes.timeStamp}>
              <Text
                className={classes.updateDate}
                variant="list-item-secondaryText"
                text={formatCardDate(
                  lastUpdatedTimestamp,
                  false,
                  true,
                  i18n.language
                )}
                testid="comments-updated-time"
              />
            </div>
          </IonRow>
          <IonRow className={classes.commentText}>
            <Text variant="mipro-body-copy-bold" text={title} />
          </IonRow>
          <IonRow className={classes.commentText}>
            <CommentInput
              key={eventNoteId}
              readOnly
              testid={`mention-input-${employeeId}`}
              value={text}
              onMentionClick={({ id: mentionEmployeeId }) => {
                setTimeout(() => {
                  setSelectedEmployeeId?.(mentionEmployeeId);
                }, 100);
              }}
            />
          </IonRow>
        </div>
      </ActionRow>
      <SheetModal
        title={t('editComment')}
        isOpen={isOpenEditDeleteModal}
        setIsOpen={setIsOpenEditDeleteModal}
        initialBreakpoint={0.2}
        testid="comment-actions-modal"
        withRightCloseButton
      >
        <div className={classes.content}>
          <IonItem
            key="edit-item"
            lines="none"
            className={classes.buttonWrapper}
          >
            <Button
              icon={['far', 'pencil']}
              className={classes.editButton}
              disabled={false}
              onClick={() => {
                setIsOpenEditDeleteModal?.(false);
                onEditComment?.({
                  ...notebook,
                  noteId: toString(notebook.eventNoteId),
                  text: notebook.text,
                });
              }}
              testid="edit-button"
              slot="start"
              text={t('common:edit')}
              variant="mipro-text-button"
            />
          </IonItem>
          <IonItem
            key="delete-item"
            lines="none"
            className={classes.buttonWrapper}
          >
            <Button
              icon={['far', 'trash-can']}
              className={classes.deleteButton}
              disabled={false}
              onClick={() => {
                setIsOpenEditDeleteModal?.(false);
                onDelete();
              }}
              testid="delete-button"
              slot="start"
              text={t('common:delete')}
              variant="mipro-text-button"
            />
          </IonItem>
        </div>
      </SheetModal>
    </div>
  );
};

export default Comment;
