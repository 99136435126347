import { isEmpty } from 'lodash';
import i18next from 'i18n/i18n';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18next.t('contact:nameRequired'))
    .max(25, i18next.t('nameMax')),
  type: yup.string().required(i18next.t('contact:typeRequired')),
  phone: yup
    .string()
    .test('isValidPhoneNumber', i18next.t('contact:phoneInvalid'), (value) => {
      let repValue = value ?? '';
      if (repValue) {
        repValue = repValue.replace(/\D/g, '');
      }
      return repValue.length === 10 || isEmpty(repValue);
    }),
  email: yup.string().email(i18next.t('contact:invalidEmail')),
});

export default validationSchema;
