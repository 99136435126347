import NotificationList from 'HomeApp/Notifications/pages/Notifications/NotificationList';

// #region ui-routes
export const notificationListURL = () => '/notifications';
// #endregion ui-routes

export const NotificationListRouter = [
  {
    path: notificationListURL(),
    routeName: 'Notices List',
    component: NotificationList,
  },
];
