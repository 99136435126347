import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { head, map, toNumber, toString } from 'lodash';
import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';
import Footer from 'common/components/Footer/Footer';
import Header from 'common/components/Header/Header';
import type { SetStateProp } from 'common/components/utils/renderHelpers';
import { scrollToFieldError } from 'common/utils/formHelpers';
import { and, choose, or } from 'common/utils/logicHelpers';
import { useGetUserInfo } from 'common/utils/userInfo';
import { Formik, useFormikContext } from 'formik';
import useSubmitProTip from 'HomeApp/ProTips/api/useSubmitProTip';
import { goToShareProTip } from 'HomeApp/ProTips/navigation/navigationHelpers';
import useGoBack from 'hooks/useGoBack';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import ProTipForm from './ProTipForm';
import ProTipReview from './ProTipReview';
import classes from './ShareProTip.module.scss';
import { ShareProTipSchema, type ShareProTipForm } from './ShareProTipSchema';

interface ShareProTipFormikProps {
  onSubmit: (values: ShareProTipForm) => void | Promise<void>;
}

export const ShareProTipFormik = ({
  children,
  onSubmit,
}: React.PropsWithChildren<ShareProTipFormikProps>) => {
  return (
    <Formik<ShareProTipForm>
      initialValues={{
        employee: [],
        industrySegments: [],
        revenue: '',
        manufacturer: [],
        headline: '',
        description: '',
      }}
      enableReinitialize
      validationSchema={ShareProTipSchema}
      onSubmit={onSubmit}
    >
      {() => children}
    </Formik>
  );
};

interface ShareProTipPageProps {
  /* eslint-disable react/require-default-props */
  isSubmitting?: boolean;
  reviewPage?: boolean;
  setReviewPage?: SetStateProp<boolean>;
  /* eslint-enable react/require-default-props */
}

interface ShareProTipPageRef {
  resetForm?: () => void;
}

const ShareProTipPage = React.forwardRef<
  ShareProTipPageRef,
  ShareProTipPageProps
>((props, outerRef) => {
  const { isSubmitting, reviewPage, setReviewPage } = props;
  const { t } = useTranslation();
  const { goBack } = useGoBack();
  const [discardIsOpen, setDiscardIsOpen] = useState(false);

  const { dirty, isValid, handleSubmit, resetForm } =
    useFormikContext<ShareProTipForm>();

  const title = t(
    `HomeApp/ProTips:${toString(
      choose(reviewPage, 'reviewTitle', 'formTitle')
    )}`
  );

  const onBackClick = () => {
    if (and(dirty, !reviewPage)) {
      setDiscardIsOpen(true);
      return;
    }
    if (reviewPage) {
      setReviewPage?.(false);
      return;
    }
    goBack();
  };

  useImperativeHandle(
    outerRef,
    () => ({
      resetForm: () => resetForm({}),
    }),
    [resetForm]
  );

  return (
    <IonPage data-testid="share-pro-tip-page">
      <Header
        title={title}
        backButton={{ onClick: onBackClick, disabled: isSubmitting }}
        testid="page-title"
      />
      <IonContent className={classes.content}>
        <Header pageTitle={title} collapse="condense" testid="title" />
        {choose(
          reviewPage,
          <ProTipReview />,
          <ProTipForm isSubmitting={isSubmitting} />
        )}
        <DiscardModal
          isOpen={discardIsOpen}
          setIsOpen={setDiscardIsOpen}
          title={t('common:discardTitle')}
          discardMsg={t('common:discardMsg')}
          goBackButtonTitle={t('HomeApp/ProTips:dontDiscardButton')}
          discardButtonTitle={t('common:discardButton')}
          onDiscardClick={() => goBack()}
          initialBreakpoint={0.4}
          withRightCloseButton
          testid="pro-tip-discard-modal"
        />
      </IonContent>
      <Footer
        buttons={[
          {
            text: t(`common:${toString(choose(reviewPage, 'edit', 'cancel'))}`),
            testid: 'cancel-button',
            variant: 'secondary',
            disabled: isSubmitting,
            onClick: onBackClick,
          },
          {
            text: t(
              `common:${toString(choose(reviewPage, 'publish', 'review'))}`
            ),
            googleAnalyticsId: `${toString(
              choose(
                reviewPage,
                'protip-publish-button',
                'protip-review-button'
              )
            )}`,
            testid: 'submit-button',
            variant: 'mipro-action',
            disabled: isSubmitting,
            onClick: () => {
              if (!isValid) {
                scrollToFieldError();
              }
              handleSubmit();
            },
          },
        ]}
      />
    </IonPage>
  );
});

const ShareProTip = () => {
  const history = useHistory();
  const location = useLocation();
  const { goBack } = useGoBack();
  const params = new URLSearchParams(location.search);
  const resetView = params.get('reset');
  const pageRef = useRef<ShareProTipPageRef>(null);
  const {
    userState: { corpLoc },
  } = useGetUserInfo();
  const [reviewPage, setReviewPage] = useState(false);

  const doResetForm = () => {
    if (resetView) {
      pageRef.current?.resetForm?.();
      setReviewPage(false);
      history.replace(goToShareProTip());
    }
  };

  useIonViewWillEnter(() => {
    doResetForm();
  }, [resetView]);

  // TUDU needed for click on menu from share pro tip page
  useEffect(() => {
    doResetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetView]);

  const { status, onSubmitProTip } = useSubmitProTip();

  useEffect(() => {
    if (status === 'success') {
      goBack();
    }
  }, [goBack, status]);

  const isSubmitting = status === 'loading';

  return (
    <ShareProTipFormik
      onSubmit={(values) => {
        if (!reviewPage) {
          setReviewPage(true);
          return;
        }
        onSubmitProTip({
          reqEmployeeId: head(values.employee)?.id,
          industryId: map(values.industrySegments, (i) => i.id),
          revenue: toNumber(values.revenue),
          mfrCtlNo: head(values.manufacturer)?.mfrCtlNo,
          subject: values.headline,
          details: values.description,
          miCorpLoc: or(corpLoc, 'AL00'),
          files: values.files,
        });
      }}
    >
      <ShareProTipPage
        ref={pageRef}
        reviewPage={reviewPage}
        setReviewPage={setReviewPage}
        isSubmitting={isSubmitting}
      />
    </ShareProTipFormik>
  );
};

export default ShareProTip;
