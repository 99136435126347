import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GroupedVirtuoso } from 'react-virtuoso';
import classNames from 'classnames';
import { chain, filter, find, get, isEmpty, isNil, map, size } from 'lodash';
import ActivitiesGroupContent from 'ActivitiesApp/components/common/ActivitiesGroupContent';
import ActivityItemContent from 'ActivitiesApp/components/common/ActivityItemContent';
import TabSubComponent from 'ActivitiesApp/components/common/TabSubComponent';
import useActivitiesGroupCounts from 'ActivitiesApp/hooks/useActivitiesGroupCounts';
import type { ActivitiesTabBaseProps } from 'ActivitiesApp/models/Activities';
import {
  crmHasOpenStatus,
  getNumDaysToLimitByShowAfter,
  isActivityOwner,
} from 'ActivitiesApp/utils/helpers';
import Header from 'common/components/Header/Header';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { namespaces } from 'i18n/i18n.constants';
import useFindActivities from 'api/activities/useFindActivities';
import useFindActivityFilters from 'api/activities/useFindActivityFilters';
import useFeatureFlags, { FeatureFlagType } from 'hooks/useFeatureFlags';
import type { ActivityTypeFilter } from 'models/ActivityModels';
import { ActivityFilter } from 'models/ActivityModels';
import type { RootState } from 'store/reducers';
import { DateFormatEnum, formatDate } from 'utils/date';
import { findIcon } from 'utils/icons';
import { getActivityFilterIcon } from 'pages/Activities/ActivityActionCard/ActivityCardConfig';
import type { FilterOption } from 'components/Filter/Filter';
import Filter from 'components/Filter/Filter';
import Text from 'components/Text/Text';
import classes from './ActivitiesTab.module.scss';

interface ActivitiesTabProps
  extends ActivitiesTabBaseProps,
    BaseComponentProps {
  multiSelectList: number[];
  multiSelectEnabled: boolean;
  isSubmitting: boolean;
  triggerResetActivityTab?: number;
  triggerMultiSelectAll?: number;
  selectedTab?: string;
  toggleSelection: (itemId?: number, haptic?: boolean) => void;
  setSelectAll: (b: boolean) => void;
  setMultiSelectList: (list: number[]) => void;
  markAllAsRead: () => void;
  isSnapshotActivities: boolean;
}

const ActivitiesTab = ({
  className,
  multiSelectList,
  multiSelectEnabled,
  triggerMultiSelectAll,
  toggleSelection,
  onDetailClick,
  setSelectAll,
  setMultiSelectList,
  markAllAsRead,
  selectedTab,
  isSubmitting,
  miLoc,
  id,
  triggerResetActivityTab,
  isSnapshotActivities,
}: ActivitiesTabProps) => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const { t } = useTranslation(namespaces.activities);
  const { pathname } = useLocation();
  const isRemoveDataFilter = useFeatureFlags(FeatureFlagType.removeDateFilter);

  const [filterItem, setFilterItem] = useState<FilterOption>();
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const loggedInUserId = get(userInfo, 'userid', '');
  const includeOthers = !isNil(miLoc) && !isNil(id);

  useEffect(() => {
    setFilterItem(undefined);
  }, [triggerResetActivityTab]);

  const { data: activityFilters } = useFindActivityFilters({
    miLoc,
    id,
    includeOthers,
    showDone: !isEmpty(miLoc),
  });

  const filters: ActivityTypeFilter[] = useMemo(
    () =>
      map(activityFilters, ({ id: fId, type, ...activityType }) => {
        let activityFilter: Record<string, unknown> = {};
        if (type === 'event') {
          activityFilter = { eventTagName: fId };
        } else if (type === 'category') {
          activityFilter = { category: fId };
        } else if (fId === 'DONE') {
          activityFilter = { isDone: true, showSnoozed: true };
        } else if (fId === 'FOLLOW_UP') {
          activityFilter = { isFollowUp: true, showSnoozed: true };
        } else if (fId === 'UNREAD') {
          activityFilter = { eventRead: 'N' };
        } else if (fId === 'SHOW_AFTER') {
          activityFilter = {
            showSnoozed: true,
            showAfter: formatDate(Date.now(), DateFormatEnum.ISO),
          };
        }
        return {
          id: fId,
          type,
          ...activityType,
          filter: activityFilter,
        };
      }),
    [activityFilters]
  );

  const selectedFilter = find(filters, { id: filterItem?.key });

  const setFilterData = (option?: FilterOption) => {
    if (!option || filterItem?.key === option.key) {
      setFilterItem(undefined);
    } else {
      setFilterItem(option);
    }
  };

  const activityFilterButtons: FilterOption[] = useMemo(
    () =>
      map(filters, ({ name, id: fId }) => ({
        name,
        key: fId,
        icon: getActivityFilterIcon(fId),
      })),
    [filters]
  );

  const selectedFilterItem = find(activityFilterButtons, {
    key: filterItem?.key,
  });

  const selectedFilters = useMemo(
    () => [selectedFilterItem],
    [selectedFilterItem]
  );

  const {
    activities,
    error,
    fetchNextPage,
    hasError,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    codeList,
  } = useFindActivities({
    miLoc,
    id,
    filter: {
      isDone: isSnapshotActivities ? undefined : false,
      showSnoozed: false,
      numDaysToLimitByShowAfter: getNumDaysToLimitByShowAfter(
        isRemoveDataFilter,
        pathname
      ),
      ...selectedFilter?.filter,
    },
    includeOthers,
    hideVisits: true,
    enabled: selectedTab === 'all',
    enabledDataCodesQuery:
      !selectedFilterItem ||
      selectedFilterItem?.key === ActivityFilter.newCustomerContact,
  });

  useEffect(() => {
    if (triggerMultiSelectAll) {
      setMultiSelectList(
        chain(activities)
          .filter(
            (activity) =>
              !crmHasOpenStatus(activity) &&
              isActivityOwner(loggedInUserId, activity)
          )
          .map(({ historyId }) => historyId)
          .value()
      );
    }
  }, [activities, loggedInUserId, setMultiSelectList, triggerMultiSelectAll]);

  const { groupCounts } = useActivitiesGroupCounts(activities);

  const fetchNextPageAndResetSelectAll = async () => {
    setSelectAll(false);
    await fetchNextPage?.();
  };

  const renderGroupContent = (index: number) => (
    <ActivitiesGroupContent index={index} groupCount={groupCounts[index]} />
  );

  const renderItemContent = (index: number) => (
    <ActivityItemContent
      testid="all-acitivities-content"
      activity={activities[index]}
      multiSelectEnabled={multiSelectEnabled}
      loggedInUserId={loggedInUserId}
      multiSelectList={multiSelectList}
      codeList={codeList}
      toggleSelection={toggleSelection}
      onDetailClick={onDetailClick}
      isSnapshotActivities={isSnapshotActivities}
    />
  );

  return (
    <div className={classNames(classes.wrapper, className)}>
      <Header
        className={classNames(classes.header, classes.contentHeader, {
          [classes.multiSelectHeader]: multiSelectEnabled,
        })}
        collapse="condense"
        customTitle={
          <Text variant="title-screen-section" text={t('allActivities')} />
        }
        testid="activities-content-header"
        headerActions={
          !hasError && !isEmpty(activities) && !isSnapshotActivities
            ? {
                title: t('activities:manageActivities'),
                initialBreakpoint: 0.3,
                className: classes.headerActions,
                options: [
                  {
                    text: t('activities:markAllAsRead'),
                    icon: findIcon('check-double', 'fal'),
                    onClick: markAllAsRead,
                    disabled: isSubmitting,
                    testid: 'mark-all-as-read-button',
                  },
                ],
                testid: 'activities-header-actions',
              }
            : undefined
        }
        endSlotComponent={
          <Filter
            className={classNames(classes.filter, {
              [classes.filterSelected]:
                size(filter(selectedFilters, (o) => !!o)) > 0,
            })}
            selectedItems={selectedFilters}
            setFilterData={[setFilterData]}
            filterOptions={[
              {
                title: t('activityFilter'),
                options: activityFilterButtons,
              },
            ]}
            isOpen={openFilterModal}
            setIsOpen={setOpenFilterModal}
            testid="activity-filters"
            toggleOnSelection
          />
        }
      />
      {!hasError && !isEmpty(activities) && (
        <GroupedVirtuoso
          className={classes.list}
          increaseViewportBy={{
            top: 500,
            bottom: 500,
          }}
          endReached={
            enableInfiniteScroll ? fetchNextPageAndResetSelectAll : undefined
          }
          groupCounts={groupCounts}
          groupContent={renderGroupContent}
          itemContent={renderItemContent}
        />
      )}
      <TabSubComponent
        isEmptyResponse={isEmptyResponse}
        showLoader={showLoader}
        hasError={hasError}
        error={error}
      />
    </div>
  );
};

export default ActivitiesTab;
