import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { findActivityNotesQueryKey } from 'ActivitiesApp/api/notes/useFindActivityNotes';
import { findActivityNotesV2QueryKey } from 'ActivitiesApp/api/notes/useFindActivityNotesV2';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import { getQueryCacheKeys, onSuccessMutation } from 'api/helpers';
import { ToastType } from 'models/Toast';
import { findActivitiesV2QueryKey } from './useFindActivitiesV2';
import { getActivityQueryKey } from './useGetActivity';

interface DeleteActivtyBody {
  success: boolean;
  message: string;
}
interface UseDeleteActivityProps {
  historyId: string;
}
interface DeleteActivityResponse {
  status: MutationStatus;
  deleteActivity: () => void;
}

const useDeleteActivity = ({
  historyId,
}: UseDeleteActivityProps): DeleteActivityResponse => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.activities);
  const { deleteActivityAPI } = useAPIUrl();

  const { mutate, status } = useMutation({
    mutationFn: async () => {
      const { data } = await axios.delete<DeleteActivtyBody>(
        deleteActivityAPI(historyId)
      );
      return data;
    },
    onSuccess: async (vars) => {
      if (vars.success) {
        addToast({
          type: ToastType.success,
          header: t('HomeApp/ProTips:submitSuccessTitle'),
          text: t('activityDeleteSuccess'),
          testid: 'delete-activity-success-toast',
        });
      } else {
        addToast({
          type: ToastType.error,
          text: t('activityDeleteError'),
          testid: 'delete-activity-error-toast',
        });
      }
      queryClient.removeQueries({
        queryKey: getQueryCacheKeys(queryClient, getActivityQueryKey, {
          historyId: toNumber(historyId),
        }),
      });
      queryClient.removeQueries({
        queryKey: getQueryCacheKeys(queryClient, findActivityNotesQueryKey, {
          historyId: toNumber(historyId),
        }),
      });
      queryClient.removeQueries({
        queryKey: getQueryCacheKeys(queryClient, findActivityNotesV2QueryKey, {
          historyId: toNumber(historyId),
        }),
      });
      await onSuccessMutation(queryClient, findActivitiesQueryKey);
      await onSuccessMutation(queryClient, findActivitiesV2QueryKey);
    },
    onError: () => {
      addToast({
        type: ToastType.error,
        text: t('activityDeleteError'),
        testid: 'delete-activity-error-toast',
      });
    },
  });

  return { status, deleteActivity: () => mutate() };
};

export default useDeleteActivity;
