import ActivitiesApp from 'ActivitiesApp/i18n/translations';
import HomeApp from 'HomeApp/Notifications/i18n/translation';
import type { Resource } from 'i18next';
import ProductSearchApp from 'ProductSearchApp/i18n/translations';
import ReportsApp from 'ReportsApp/i18n/translation';
import SearchApp from 'SearchApp/i18n/translations';
import en from './translations/en.json';
import fr from './translations/fr.json';

export const namespaces = {
  common: 'common',
  activities: 'activities',
  reports: 'reports',
  salesPlays: 'salesPlays',
  calendar: 'calendar',
  search: 'search',
  snapshot: 'snapshot',
  ar: 'ar',
  contact: 'contact',
  email: 'email',
  agreementInfo: 'agreementInfo',
  notes: 'notes',
  comments: 'comments',
  images: 'images',
  supplier: 'supplier',
  product: 'product',
  workOrders: 'workOrders',
  costSavings: 'costSavings',
  inventory: 'inventory',
  cart: 'cart',
  checkout: 'checkout',
};

function languages(): Resource {
  const miproEN = {
    ...en,
    ...SearchApp.en,
    ...ReportsApp.en,
    ...HomeApp.en,
    ...ActivitiesApp.en,
    ...ProductSearchApp.en,
  };
  const miproFR = {
    ...fr,
    ...SearchApp.fr,
    ...ReportsApp.fr,
  };
  if (process.env.REACT_APP_MULITPLE_LANGUAGE === 'true') {
    return {
      en: miproEN,
      fr: miproFR,
    };
  }
  return { en: miproEN };
}

export const resources = languages();

export const availableLanguages = Object.keys(resources);
