import React from 'react';
import classNames from 'classnames';
import { toString } from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { ifRender } from 'common/utils/logicHelpers';
import { ReportUserConfigTypeEnum } from 'models/UserConfig';
import CostSavingsReportGrid from 'assets/CostSavingsReportGrid.svg';
import EndOfMonthGrid from 'assets/EndOfMonthGrid.svg';
import MIIcon from 'assets/MIIcon.svg';
import OpenQuotesReportGrid from 'assets/OpenQuotesReportGrid.svg';
import Pick12ReportGrid from 'assets/Pick12ReportGrid.svg';
import ProTipsReportGrid from 'assets/ProTipsReportGrid.svg';
import UnbilledReportGrid from 'assets/UnbilledReportGrid.svg';
import Button from 'components/Button/Button';
import Pick12Icon from 'components/Pick12Icon/Pick12Icon';
import Text from 'components/Text/Text';
import classes from './ReportTile.module.scss';

interface ReportTileProps extends BaseComponentProps {
  id: ReportUserConfigTypeEnum;
  label: string;
  icon?: IconName | string;
  onClick: (id: ReportUserConfigTypeEnum) => void;
}

const tileIcon: Partial<Record<ReportUserConfigTypeEnum, JSX.Element>> = {
  [ReportUserConfigTypeEnum.pick12Detail]: (
    <Pick12Icon
      className={classes.reportIcon}
      testid="report-pick12"
      imgSrc={Pick12ReportGrid}
    />
  ),
  [ReportUserConfigTypeEnum.webPerformance]: (
    <img src={MIIcon} className={classes.reportIcon} alt="Mi Pro" />
  ),
  [ReportUserConfigTypeEnum.endOfMonth]: (
    <img
      src={EndOfMonthGrid}
      className={classes.reportIcon}
      alt="End Of Month Live"
    />
  ),
  [ReportUserConfigTypeEnum.costSavings]: (
    <img
      src={CostSavingsReportGrid}
      className={classes.reportIcon}
      alt="Cost Savings Report"
    />
  ),
  [ReportUserConfigTypeEnum.unbilled]: (
    <img src={UnbilledReportGrid} className={classes.reportIcon} alt="" />
  ),
  [ReportUserConfigTypeEnum.openQuotes]: (
    <img src={OpenQuotesReportGrid} className={classes.reportIcon} alt="" />
  ),
  [ReportUserConfigTypeEnum.proTips]: (
    <img src={ProTipsReportGrid} className={classes.reportIcon} alt="" />
  ),
};

const ReportTile = ({
  id,
  label,
  icon,
  testid = 'report-card-button',
  className,
  onClick: onGridClick,
}: ReportTileProps) => {
  return (
    <Button
      variant="clear"
      testid={testid}
      className={classNames(classes[id], className)}
      onClick={() => onGridClick(id)}
      aria-hidden="true"
    >
      <div className={classes.innerButton}>
        {ifRender(
          icon,
          <FontAwesomeIcon
            icon={['far', icon as IconName]}
            className={classes.reportIcon}
          />
        )}
        {tileIcon[id]}
        <Text
          variant="title-action-card"
          className={classes.itemText}
          text={toString(label)}
        />
      </div>
    </Button>
  );
};

export default ReportTile;
