import React, { useEffect, useState, createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { includes, isEmpty, isNil, toNumber } from 'lodash';
import type { AppState } from '@capacitor/app';
import { App } from '@capacitor/app';
import type { PluginListenerHandle } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { onlineManager, useQueryClient } from '@tanstack/react-query';
import { choose } from 'common/utils/logicHelpers';
import { inAppNotificationsQueryKey } from 'HomeApp/Notifications/api/useFindNotifications';
import useGetOrder from 'ProductSearchApp/api/useGetOrder';
import { onSuccessMutation } from 'api/helpers';
import useGetPing from 'api/user/useGetPing';
import { unreadNoticesQueryKey } from 'api/user/useGetUnreadNoticesCount';
import useChangeAppMode from 'hooks/useChangeAppMode';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import {
  connectionStatusToastId,
  RATE_LIMIT_ERROR_CODE,
  TEA_POT_ERROR_CODE,
} from 'utils/constants';
import { useToasts } from './ToastProvider';
import { useVault } from './VaultProvider';

export enum NetworkStatusEnum {
  Connected = 'Connected',
  ServerUnreachable = 'ServerUnreachable',
  RateLimitError = 'RateLimitError',
  TeaPotError = 'TeaPotError',
  Offline = 'Offline',
}

export interface NetworkStatusContextProps {
  networkStatus?: NetworkStatusEnum;
  isOnline?: boolean;
  buildInfoBranch: string;
}

const NetworkStatusContext = createContext<NetworkStatusContextProps>({
  networkStatus: undefined,
  buildInfoBranch: '',
});

const DefaultRefetchInterval = 5 * 60 * 1000;
const connectionStatusToast = (text = 'No internet connection.') => ({
  id: connectionStatusToastId,
  type: ToastType.error,
  icon: null,
  button: null,
  duration: 0,
  text,
  testid: connectionStatusToastId,
});

const NetworkStatusProvider = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  const { isMiProApp } = useChangeAppMode();
  const queryClient = useQueryClient();
  const { isLockedAfterBackgrounded } = useVault();
  const { addToast, removeToast } = useToasts();
  const [networkStatus, setNetworkStatus] = useState<NetworkStatusEnum>(
    onlineManager.isOnline()
      ? NetworkStatusEnum.Connected
      : NetworkStatusEnum.Offline
  );
  const [refetchInterval, setRefetchInterval] = useState<number>(
    DefaultRefetchInterval
  );
  const [failureCount, setFailureCount] = useState<number>(0);

  const {
    data,
    error: pingError,
    refetch,
    dataUpdatedAt,
    errorUpdatedAt,
  } = useGetPing({ refetchInterval });

  const { currentCartCustomer } = useSelector((state: RootState) => state.user);
  const { refetch: refetchCart } = useGetOrder({
    miLoc: currentCartCustomer?.miLoc,
    orderCtlNo: currentCartCustomer?.orderCtlNo,
    enabled:
      !isEmpty(currentCartCustomer?.miLoc) &&
      !isEmpty(currentCartCustomer?.orderCtlNo),
  });

  useEffect(() => {
    let networkListener: PluginListenerHandle;
    const doAddNetworkListener = async () => {
      // DOC: when app goes to foreground, refetch ping
      networkListener = await App.addListener(
        'appStateChange',
        (state: AppState) => {
          if (state.isActive) {
            setFailureCount(1);
            setRefetchInterval(DefaultRefetchInterval);
            void refetch?.();
            // TODO this can be moved to the cart hook if we follow the Plugin.remove pattern
            void refetchCart?.();
            void onSuccessMutation(queryClient, unreadNoticesQueryKey);
            void onSuccessMutation(queryClient, inAppNotificationsQueryKey);
          }
        }
      );
    };
    void doAddNetworkListener();

    return () => {
      void networkListener?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // DOC: when vault has been locked, refetch ping
    if (isLockedAfterBackgrounded) {
      setFailureCount(1);
      setRefetchInterval(DefaultRefetchInterval);
      void refetch?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLockedAfterBackgrounded]);

  useEffect(() => {
    const networkListener = (isConnected: boolean) => {
      setFailureCount(1);
      setRefetchInterval(DefaultRefetchInterval);
      if (!isConnected) {
        setNetworkStatus(NetworkStatusEnum.Offline);
        if (isMiProApp) {
          addToast(connectionStatusToast());
        }
      } else {
        removeToast(connectionStatusToastId);
        setNetworkStatus(NetworkStatusEnum.Connected);
      }
    };
    void Network.addListener('networkStatusChange', (status) => {
      void networkListener(status.connected);
    });

    return () => {
      void Network.removeAllListeners();
    };
  }, [addToast, isMiProApp, removeToast]);

  useEffect(() => {
    if (!isNil(pingError?.message)) {
      setFailureCount((prev) => prev + 1);
    } else {
      setFailureCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorUpdatedAt, dataUpdatedAt]);

  useEffect(() => {
    if (failureCount > 0) {
      if (failureCount === 2) {
        if (
          !includes(
            [RATE_LIMIT_ERROR_CODE, TEA_POT_ERROR_CODE],
            pingError?.response?.status
          )
        ) {
          setNetworkStatus(NetworkStatusEnum.ServerUnreachable);
          if (isMiProApp) {
            addToast(connectionStatusToast('Unable to reach Mi Pro server.'));
          }
        }
      }
      // Retry Intervals
      // 1 - 10sec
      // 2 - 20sec
      // 3 - 1min
      // 4 - 3min
      // 5 - 5min MAX
      const errorRefetchInterval = toNumber(
        choose(
          failureCount < 3,
          failureCount * 10,
          ((failureCount - 3) * 2 + 1) * 60
        )
      );
      setRefetchInterval(
        Math.min(errorRefetchInterval * 1000, DefaultRefetchInterval)
      );
    } else {
      removeToast(connectionStatusToastId);
      setRefetchInterval(DefaultRefetchInterval);
      if (onlineManager.isOnline()) {
        setNetworkStatus(NetworkStatusEnum.Connected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, failureCount, isMiProApp, removeToast]);

  return (
    <NetworkStatusContext.Provider
      value={{
        networkStatus,
        buildInfoBranch: data?.buildInfo?.branch || '',
      }}
    >
      {children}
    </NetworkStatusContext.Provider>
  );
};

export default NetworkStatusProvider;

export const useNetworkStatus = (): NetworkStatusContextProps => {
  const ctx = useContext(NetworkStatusContext);

  if (!ctx) {
    throw Error(
      'The `useNetworkStatus` hook must be called inside a `NetworkStatusProvider`.'
    );
  }

  return {
    networkStatus: ctx.networkStatus,
    buildInfoBranch: ctx.buildInfoBranch,
    isOnline: ctx.networkStatus === NetworkStatusEnum.Connected,
  };
};
