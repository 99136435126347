import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { SortingRule } from 'react-table';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import { map, size, debounce, isEmpty, head, toString } from 'lodash';
import { IonContent, IonImg, IonPage } from '@ionic/react';
import Header from 'common/components/Header/Header';
import HelpButton from 'common/components/HelpButton/HelpButton';
import { and, choose } from 'common/utils/logicHelpers';
import useFindIndustrySegments from 'HomeApp/ProTips/api/useFindIndustrySegments';
import useGetProTipsReport from 'ReportsApp/api/useGetProTipsReport';
import EmployeeModal from 'SearchApp/components/EmployeeModal/EmployeeModal';
import { SortDirEnum } from 'models/Sort';
import type { ProTipFilterOption } from 'pages/Reports/components/ProTipFilter/ProTipFilter';
import ProTipFilter from 'pages/Reports/components/ProTipFilter/ProTipFilter';
import ProTipsReportGrid from 'assets/ProTipsReportGrid.svg';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import Table from 'components/Table/Table';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import { useGetProTipTableData } from './proTipHelpers';
import classes from './ProTips.module.scss';

const ProTips = (): JSX.Element => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState<string>('creationTmstmp');
  const [sortDir, setSortDir] = useState<SortDirEnum>(SortDirEnum.DESCENDING);
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [employeeId, setEmployeeId] = useState<string>('');

  const { industrySegments } = useFindIndustrySegments();

  const industrySegmentsOptions: ProTipFilterOption[] = map(
    industrySegments,
    ({ sicCode: key, industryName: name }) => ({
      key,
      name,
    })
  );

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilterIndustrySegments, setSelectedFilterIndustrySegments] =
    useState<ProTipFilterOption[] | undefined>([]);

  const {
    rows,
    refetch,
    isLoading,
    dataUpdatedAt,
    error,
    enableInfiniteScroll,
    isEmptyResponse,
    fetchNextPage,
    totals,
  } = useGetProTipsReport({
    sortField,
    sortDir,
    industrySegment: map(selectedFilterIndustrySegments, 'key') as string[],
  });

  const filterNone = size(selectedFilterIndustrySegments) === 0;

  const { tableColumns, totalsRow } = useGetProTipTableData({
    totals,
    openEmployeeModal: (id) => {
      setEmployeeId(id);
      setShowEmployeeModal(true);
    },
  });

  const onSortBy = debounce(
    (sortOption: SortingRule<Dictionary<unknown>>[]) => {
      if (!isEmpty(sortOption)) {
        setSortField(toString(head(sortOption)?.id));
        const sortDirection = head(sortOption)?.desc
          ? SortDirEnum.DESCENDING
          : SortDirEnum.ASCENDING;
        setSortDir(sortDirection);
      }
    },
    300
  );

  const filterButton = (
    <ProTipFilter
      allowMultiple
      className={classes.filter}
      selectedItems={selectedFilterIndustrySegments}
      setFilterData={(option) => {
        setSelectedFilterIndustrySegments(option);
      }}
      filterOptions={{
        title: t('ReportApp-ProTips:industrySegments'),
        options: industrySegmentsOptions,
      }}
      isOpen={isFilterOpen}
      setIsOpen={setIsFilterOpen}
      testid="protips-filter"
      modalTitle={t('common:filter')}
      customButton={{
        className: classNames(classes.filterButton, {
          [classes.filterNone]: filterNone,
          [classes.hasFilters]: !filterNone,
        }),
        text: choose(filterNone, t('common:filter'), toString(1)),
        testid: 'open-quotes-filter-button',
        children: undefined,
        rightIcon: undefined,
        icon: ['far', 'sliders'],
      }}
    />
  );

  return (
    <IonPage>
      <Header
        testid="protips-report-header"
        title={t('ReportApp-ProTips:proTipReport')}
        className={classes.headerClass}
        endSlotComponent={filterButton}
      />
      <IonContent className={classes.proTipWrapper}>
        <Refresher
          slot="fixed"
          className={classes.refresher}
          lastUpdatedAt={dataUpdatedAt}
          onRefresh={refetch}
          testid="pro-tip-report"
          disabled={isLoading}
          hidden
        />
        {!error && (
          <>
            <Header
              testid="protips-report-header"
              collapse="condense"
              customTitle={
                <div className={classes.flex}>
                  <IonImg src={ProTipsReportGrid} />
                  <Text
                    text={t('ReportApp-ProTips:proTipReport')}
                    variant="mipro-h1-headline"
                  />
                  <HelpButton
                    modal={{
                      title: t('ReportApp-ProTips:proTipReporting'),
                      testid: 'pro-tip-help-modal',
                      initialBreakpoint: 0.6,
                      children: (
                        <div className={classes.flexColumn}>
                          <span className={classes.helpText}>
                            <Trans
                              i18nKey={t('ReportApp-ProTips:helpTextLinkedOCN')}
                            />
                          </span>
                          <span className={classes.helpText}>
                            <Trans
                              i18nKey={t(
                                'ReportApp-ProTips:helpTextTotalSales'
                              )}
                            />
                          </span>
                          <span className={classes.helpText}>
                            <Trans
                              i18nKey={t(
                                'ReportApp-ProTips:helpTextOpenOpportunities'
                              )}
                            />
                          </span>
                        </div>
                      ),
                    }}
                    button={{
                      testid: 'pro-tip-help-btn',
                      icon: ['fas', 'info-circle'],
                    }}
                  />
                </div>
              }
              endSlotComponent={filterButton}
            />
            <Table
              columns={tableColumns}
              data={rows as unknown as Dictionary<unknown>[]}
              className={classes.table}
              tdClassName={classes.td}
              thClassName={classes.th}
              sortField={sortField}
              sortDir={sortDir}
              onSortBy={onSortBy}
              totals={totalsRow}
            />
            <InfiniteScroll
              disabled={!enableInfiniteScroll}
              onLoadMore={fetchNextPage}
              testid="infinite-scroll"
            />
          </>
        )}
        <Loader
          className={classes.warningMessage}
          text={t('reports:loadingReports')}
          isOpen={isLoading}
          testid="loader"
        />
        {and(isEmptyResponse, !isLoading) && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title={t('reports:noReports')}
            testid="empty-response"
          />
        )}
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('reports:errorReports')}
            testid="error"
          />
        )}
        <EmployeeModal
          isOpen={showEmployeeModal}
          setIsOpen={setShowEmployeeModal}
          testid="employee-modal"
          id={employeeId}
        />
      </IonContent>
    </IonPage>
  );
};

export default ProTips;
