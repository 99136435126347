import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import AppIcons from 'constants/appIcons';
import classNames from 'classnames';
import {
  endsWith,
  isEmpty,
  isNil,
  isUndefined,
  kebabCase,
  map,
  size,
  toNumber,
  toString,
  trim,
} from 'lodash';
import {
  IonContent,
  IonFooter,
  IonPage,
  IonRow,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import useAddActivityNote from 'ActivitiesApp/api/notes/useAddActivityNote';
import useUpdateActivityNote from 'ActivitiesApp/api/notes/useUpdateActivityNote';
import { findFollowersQuerykey } from 'ActivitiesApp/api/useFindFollowers';
import type { CommentInputRef } from 'ActivitiesApp/components/Comments/CommentInput';
import CommentInput from 'ActivitiesApp/components/Comments/CommentInput';
import type { CommentListRef } from 'ActivitiesApp/components/Comments/CommentList';
import CommentList, {
  onScroll,
} from 'ActivitiesApp/components/Comments/CommentList';
import {
  getUpdatedMentionList,
  parseMentionList,
} from 'ActivitiesApp/components/Comments/utils';
import RelatedOpportunity from 'ActivitiesApp/components/RelatedOpportunity/RelatedOppurtunity';
import useUpdateActivityFlags from 'ActivitiesApp/hooks/useUpdateActivityFlags';
import type { ActivityDetailURLParams } from 'ActivitiesApp/models/ActivityDetail';
import { CustomerVisitTypeEnum } from 'ActivitiesApp/models/CustomerVisit';
import {
  crmDisableComments,
  crmHasOpenStatus,
  highPriorityTask,
} from 'ActivitiesApp/utils/helpers';
import ContactModal from 'common/components/ContactModal/ContactModal';
import type { InputRef } from 'common/components/Forms/Input/Input';
import Header from 'common/components/Header/Header';
import {
  getIntervalRender,
  toggleKeyboard,
  useIonContentRef,
} from 'common/components/utils/renderHelpers';
import {
  and,
  choose,
  ifFunction,
  ifRender,
  or,
} from 'common/utils/logicHelpers';
import { useGetUserInfo } from 'common/utils/userInfo';
import { DataTypeEnum } from 'common/utils/valueFormatter';
import { differenceInMinutes, formatDistance } from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import {
  activitiesURL,
  logAVisitURL,
  searchCustomerURL,
  searchURL,
} from 'navigation';
import { isCustomerLess } from 'ProductSearchApp/util/productSearchUtil';
import EmployeeModal from 'SearchApp/components/EmployeeModal/EmployeeModal';
import useDeleteActivity from 'api/activities/useDeleteActivity';
import useGetActivity from 'api/activities/useGetActivity';
import useMarkActivityAsRead from 'api/activities/useMarkActivityAsRead';
import useMarkActivityAsUnread from 'api/activities/useMarkActivityAsUnread';
import useUpdateActivity from 'api/activities/useUpdateActivity';
import useGetCustomer from 'api/customer/useGetCustomer';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { ActionCardActivity } from 'models/ActivityModels';
import { ActivityType } from 'models/ActivityModels';
import type { UpdateActivityNoteBody } from 'models/Notebook';
import type { SnoozedData } from 'models/SnoozedData';
import { clearDeepLink } from 'store/user';
import {
  DateFormatEnum,
  formatDate,
  formatSnoozeDate,
  getDateLocale,
  parseDate,
} from 'utils/date';
import { getErrorMessage, withStringProp } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import {
  getActivityConfig,
  getActivityType,
  useGetActionCardConfig,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';
import type { ActionCardOption } from 'components/Activities/ActionCard/ActionCard';
import type { ActivityModalData } from 'components/Activities/ActionCardModal/ActionCardModalData';
import ActionCardModalData from 'components/Activities/ActionCardModal/ActionCardModalData';
import OwnerTitle from 'components/Activities/OwnerTitle/OwnerTitle';
import SnoozeModal from 'components/Activities/SnoozeModal/SnoozeModal';
import SnoozeTitle from 'components/Activities/SnoozeTitle/SnoozeTitle';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import SnapshotLink from 'components/Contacts/SnapshotLink/SnapshotLink';
import type HeaderActions from 'components/HeaderActions/HeaderActions';
import type { HeaderActionOption } from 'components/HeaderActions/HeaderActions';
import Loader from 'components/Loader/Loader';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import Refresher from 'components/Refresher/Refresher';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './ActivityDetail.module.scss';

const defaultDataDefinition: ActivityModalData = {
  orderNumber: { label: 'common:ocn', type: DataTypeEnum.ocn },
  orderNumberOrder: { label: 'ocn (order)', type: DataTypeEnum.ocn },
  orderNumberQuote: { label: 'ocn (quote)', type: DataTypeEnum.ocn },
  lines: { label: 'common:lines', type: DataTypeEnum.string },
  total: { label: 'common:total', type: DataTypeEnum.currency },
  custPONumber: { label: 'common:poNumber', type: DataTypeEnum.string },
  expectedDate: { label: 'common:expected', type: DataTypeEnum.date },
  orderDate: { label: 'common:ordered', type: DataTypeEnum.date },
  lastUpdatedDate: { label: 'lastActivity', type: DataTypeEnum.date },
  orderStatus: { label: 'common:status', type: DataTypeEnum.string },
  shipStatus: { label: 'common:status', type: DataTypeEnum.string },
  contact: { label: 'common:contact', type: DataTypeEnum.string },
  company: { label: 'common:company', type: DataTypeEnum.string },
  address: { label: 'common:address', type: DataTypeEnum.address },
  phone: { label: 'common:phone', type: DataTypeEnum.phone },
  email: { label: 'common:email', type: DataTypeEnum.email },
  ocnContactName: { label: 'common:contact', type: DataTypeEnum.contact },
  created: { label: 'common:created', type: DataTypeEnum.date },
  createdBy: { label: 'common:createdBy', type: DataTypeEnum.string },
};

export interface ActivityComponentRef {
  onSubmit: () => void;
}

const ActivityDetail = (): JSX.Element => {
  const dispatch = useDispatch();
  const { userId: loggedInUserId } = useGetUserInfo();
  const ac = useAccessControls(AccessControlType.viewCustomers);
  const { miLoc, id, userId, historyId } = useParams<ActivityDetailURLParams>();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const commentId = params.get('commentId');
  const shouldRefresh = params.get('refresh');
  const isMentioned = params.get('mentioned');
  const { url } = useRouteMatch();
  const { t, i18n } = useTranslation(namespaces.activities);

  const { onMarkActivityAsRead } = useMarkActivityAsRead();
  const { onMarkActivityAsUnread } = useMarkActivityAsUnread();

  const [closeModalIsOpen, setCloseModalIsOpen] = useState(false);
  const [didChange, setDidChange] = useState(false);
  const [hideActivityFlags, setHideActivityFlags] = useState(false);
  const [disableActivityFlags, setDisableActivityFlags] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [isToBeRemoved, setIsToBeRemoved] = useState(false);
  const [isToBeUnread, setIsToBeUnread] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const footerRef = useRef<HTMLDivElement>(null);
  const contentBeforeDetailsRef = useRef<HTMLDivElement>(null);
  const componentRef = useRef<ActivityComponentRef>(null);
  const commentsListRef = useRef<CommentListRef>(null);

  const isActivityDetail = !endsWith(url, logAVisitURL());

  const { data: customerData, isLoading: customerIsLoading } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id,
    enabled: and(!isEmpty(id), !isCustomerLess(id)),
  });

  const {
    data: activity,
    isFetching: activityIsLoading,
    refetch: refetchActivity,
    error: fetchActivityError,
  } = useGetActivity({
    historyId: toNumber(historyId),
    userId: toString(or(userId, loggedInUserId)),
    enabled: isActivityDetail,
  });

  const { deleteActivity } = useDeleteActivity({
    historyId: toString(activity?.historyId),
  });

  const { onUpdateActivity } = useUpdateActivity({
    userId: toString(activity?.userId),
    historyId: toNumber(historyId),
  });

  const customerVisitActivity = {
    userId: loggedInUserId,
    custMiLoc: miLoc,
    custNo: id,
    eventTagName: ActivityType.customerVisit,
    baseConfigVersion: '1',
  } as unknown as ActionCardActivity;
  const { cardType, title: cardTitle } = getActivityType(
    toString(activity?.eventTagName),
    t
  );

  const {
    emailContact,
    emailContactName,
    activityConfig: activityDetailConfig,
    modalHeader: activityDetailEyebrow,
  } = getActivityConfig({
    cardType,
    activityData: or(activity, {} as ActionCardActivity),
    t,
    i18n,
    loggedInUserId,
    compressedName: customerData?.compressedName,
  });

  const {
    activityConfig: customerVisitActivityConfig,
    modalHeader: customerVisitEyebrow,
  } = useGetActionCardConfig({
    cardType: ActivityType.customerVisit,
    activityData: customerVisitActivity,
    loggedInUserId,
    compressedName: customerData?.compressedName,
  });

  const { status } = useUpdateActivity({
    userId: toString(activity?.userId),
    historyId: toNumber(activity?.historyId),
  });

  const { onUpdateActivityFlags } = useUpdateActivityFlags({
    userId,
    historyId: toNumber(historyId),
  });

  const activityConfig = choose(
    isActivityDetail,
    activityDetailConfig,
    customerVisitActivityConfig
  );

  const isLoading = or(activityIsLoading, customerIsLoading);

  const isSubmitting = status === 'loading';

  const isNonLoggedInOwner = activity?.userId !== loggedInUserId;
  const customerName = or(activity?.customerName, customerData?.name);
  const customerURL = concatRoutes(
    searchURL(),
    searchCustomerURL(or(activity?.custMiLoc, miLoc), or(activity?.custNo, id))
  );

  const disableHeaderFlags = activity?.userId !== userId;

  const { node, nodeRef } = useIonContentRef();

  const CustomContent = useMemo(
    () =>
      or(
        activityConfig?.modal?.customContent,
        activityConfig?.modal?.customContentWithRef
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activity?.eventTagName, activity?.historyId]
  );

  const CustomHeader = useMemo(
    () => activityConfig?.modal?.customHeader,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activity?.eventTagName, activity?.historyId]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as React.FC<any>;

  const { title, description, buttons, hideSaveButton, highlight } =
    activityConfig?.modal ?? {};

  const goBack = useCallback(() => {
    if (history.location.pathname === '/tabs') {
      history.push(activitiesURL());
    } else history.goBack();
  }, [history]);

  // TODU: check if this is still in use
  const onSendEmail = () => {
    setSendEmailModal(true);
  };

  const onOpenContactModal = () => {
    if (emailContactName) {
      setShowContactModal(true);
    }
  };

  const onOpenEmployeeModal = (employee: string) => {
    if (!isEmpty(employee)) {
      setEmployeeId(employee);
      setShowEmployeeModal(true);
    }
  };

  // #region activity actions
  const [showAfterData, setShowAfterData] = useState({
    showAfter: activity?.showAfter,
    showAfterISO: activity?.showAfterISO,
    isSnoozed: activity?.isSnoozed,
  });

  const isSnoozedBefore: boolean =
    differenceInMinutes(
      parseDate(showAfterData.showAfterISO),
      parseDate(activity?.creationTimestampISO)
    ) !== 0;

  const snoozedData: SnoozedData = {
    isSnoozed: showAfterData.isSnoozed === 'Y',
    isSnoozedBefore: isSnoozedBefore && showAfterData.isSnoozed === 'N',
    when: choose(
      showAfterData.isSnoozed === 'Y',
      t('snoozeUntilDate', {
        formatDate: formatSnoozeDate(
          parseDate(showAfterData.showAfterISO),
          i18n.language
        ),
      }),
      t('snoozedDate', {
        formatDate: formatDistance(
          parseDate(showAfterData.showAfterISO),
          new Date(),
          {
            addSuffix: true,
            locale: getDateLocale(),
          }
        ),
      })
    ) as string,
  };

  const [isFollowedUp, setIsFollowedUp] = useState(activity?.followUp === 'Y');
  const [isDone, setIsDone] = useState(activity?.done === 'Y');

  const queryClient = useQueryClient();

  const onRefresh = async () => {
    await refetchActivity?.();
    commentsListRef.current?.refetchV2?.();
    await queryClient.invalidateQueries([findFollowersQuerykey]);
  };

  useIonViewWillEnter(() => {
    if (isActivityDetail) {
      void onRefresh();
    }
    setDidChange(false);
    setHideActivityFlags(false);
    setShowAfterData({
      showAfter: activity?.showAfter,
      showAfterISO: activity?.showAfterISO,
      isSnoozed: activity?.isSnoozed,
    });
    setIsFollowedUp(activity?.followUp === 'Y');
    setIsDone(activity?.done === 'Y');
    setIsToBeRemoved(false);
  }, []);

  useEffect(() => {
    setShowAfterData({
      showAfter: activity?.showAfter,
      showAfterISO: activity?.showAfterISO,
      isSnoozed: activity?.isSnoozed,
    });
    setIsFollowedUp(activity?.followUp === 'Y');
    setIsDone(activity?.done === 'Y');
  }, [activity]);

  useEffect(() => {
    setDisableActivityFlags(disableHeaderFlags);
  }, [disableHeaderFlags, activity?.userId, userId]);

  useEffect(() => {
    if (!isNil(activity) && activity.eventRead === 'N') {
      onMarkActivityAsRead({ historyId: activity.historyId });
    }
    dispatch(clearDeepLink());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  const [showSnoozeModal, setShowSnoozeModal] = useState(false);

  const onSnoozeDone = (v: number) => {
    setDidChange(true);
    setShowAfterData({
      isSnoozed: 'Y',
      showAfter: formatDate(v * 1000, DateFormatEnum.showAfterTime),
      showAfterISO: formatDate(v * 1000, DateFormatEnum.ISO),
    });
  };
  const onUnSnooze = () => {
    setDidChange(true);
    setShowAfterData({
      isSnoozed: 'N',
      showAfter: activity?.creationTimestamp,
      showAfterISO: activity?.creationTimestampISO,
    });
  };

  const onFollowUp = () => {
    setDidChange(true);
    setIsFollowedUp((prev) => !prev);
  };

  const onRemoveActivity = () => {
    setIsDone((prev) => !prev);
    const subscribeFlag: { subscribe?: boolean } = {};
    const undoSubscribeFlag: { subscribe?: boolean } = {};
    if (activity?.subscribed) {
      subscribeFlag.subscribe = false;
      undoSubscribeFlag.subscribe = true;
    }
    onUpdateActivityFlags?.({
      undoData: {
        ...undoSubscribeFlag,
        done: activity?.done === 'Y',
      },
      activityUpdate: {
        ...subscribeFlag,
        done: true,
      },
    });
    goBack();
  };

  const onComplete = () => {
    if (didChange) {
      setCloseModalIsOpen(true);
      setIsToBeRemoved(true);
    } else {
      onRemoveActivity();
    }
  };

  const onUnreadActivity = () => {
    if (!isNil(activity)) {
      onMarkActivityAsUnread({ historyId: activity.historyId });
      goBack();
    }
  };

  const onUnreadComplete = () => {
    if (didChange) {
      setCloseModalIsOpen(true);
      setIsToBeUnread(true);
    } else {
      onUnreadActivity();
    }
  };

  const disableActionButtons = or(
    isDone,
    and(
      or(
        isBusy,
        isLoading,
        isSubmitting,
        isActivityDetail,
        disableActivityFlags
      ),
      isNonLoggedInOwner
    )
  );

  const isSnoozedOption: ActionCardOption = {
    isActive: snoozedData.isSnoozed,
    activeClass: classes.activeSnooze,
    inActiveClass: classes.inActiveSnooze,
    icon: AppIcons.snoozedIcon(snoozedData.isSnoozed),
    disabled: disableActionButtons,
    variant: 'primary',
    text: t('snooze'),
    onClick: choose(snoozedData.isSnoozed, onUnSnooze, () =>
      setShowSnoozeModal(true)
    ),
    testid: `snooze-button`,
  };

  const onRemoveOption: ActionCardOption = {
    isActive: isDone,
    activeClass: classes.activeDelete,
    inActiveClass: classes.inActiveDelete,
    icon: AppIcons.doneIcon(isDone),
    disabled: or(
      isLoading,
      crmHasOpenStatus(activity),
      disableActivityFlags,
      isNonLoggedInOwner,
      isDone
    ),
    variant: 'success',
    text: t('common:remove'),
    onClick: onComplete,
    testid: `done-button`,
  };

  const onFollowUpOption: ActionCardOption = {
    isActive: isFollowedUp,
    activeClass: classes.activeFollowUp,
    inActiveClass: classes.inActiveFollowUp,
    icon: AppIcons.followUpIcon(isFollowedUp),
    disabled: disableActionButtons,
    variant: 'primary',
    text: t('followUp'),
    onClick: onFollowUp,
    testid: `follow-up-button`,
  };
  // #endregion

  // #region update activity
  const onSubmit = () => {
    onUpdateActivityFlags({
      undoData: {
        done: activity?.done === 'Y',
        followUp: activity?.followUp === 'Y',
        showAfter: activity?.showAfterISO,
      },
      activityUpdate: {
        done: isDone,
        followUp: isFollowedUp,
        showAfter: showAfterData.showAfterISO,
      },
    });

    goBack();
  };
  // #endregion

  const [saveFromOption, setSaveFromOption] = useState<(h?: string) => void>();
  const hideCustomerLink = activityConfig?.modal?.hideCustomerLink;

  // #region activity headers
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteActivity = () => {
    deleteActivity();
    goBack();
  };
  const DeleteOption: HeaderActionOption = {
    text: t('common:delete'),
    testid: 'delete-activity-btn',
    icon: AppIcons.doneIcon(false),
    className: classes.deleteBtn,
    textClassName: classes.text,
    iconClassName: classes.icon,
    onClick: () => {
      setDeleteModalOpen(true);
    },
  };
  const UnreadOption: HeaderActionOption = {
    text: t('common:markAsUnread'),
    testid: 'unread-activity-btn',
    icon: AppIcons.readIcon(),
    className: classes.unReadBtn,
    textClassName: classes.text,
    iconClassName: classes.icon,
    onClick: () => {
      onUnreadComplete();
    },
  };
  /**
   *
   * @param headerActions : Partial<HeaderActionsProps>
   * @returns headerActions with common activity detail options like delete, etc.
   */
  const addExtraHeaderActionOptions = (
    headerActionsInput: Partial<
      React.ComponentProps<typeof HeaderActions> | undefined
    >
  ) => {
    const addedOptions = [
      choose(
        and(
          !activity?.readOnly,
          activity?.eventTagName !== CustomerVisitTypeEnum.customerVisit
        ),
        UnreadOption
      ),
      choose(activity?.canDelete, DeleteOption),
    ].filter((obj) => !isUndefined(obj));

    const modHeaderActions = { ...(headerActionsInput ?? {}) };
    modHeaderActions.options = [
      ...(modHeaderActions.options ?? []),
      ...(addedOptions as unknown as [HeaderActionOption]),
    ];

    return modHeaderActions;
  };

  const headerActions = addExtraHeaderActionOptions(
    activityConfig?.modal?.headerActions?.(didChange)
  );
  const showHeaderAction = and(
    size(headerActions) > 0,
    or(isActivityDetail, and(!isActivityDetail, didChange))
  );

  // #endregion
  const isHighPriority = activity?.extendedInfo?.priority === highPriorityTask;
  const showHighPriorityLabel = isHighPriority && !showHeaderAction;

  // #region comments
  const [isDiscardCommentOpen, setIsDiscardCommentOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [focusComment, setFocusComment] = useState(false);
  const [editComment, setEditComment] = useState<UpdateActivityNoteBody>();
  const [afterSubmit, setAfterSubmit] = useState(false);
  const [commentRerender, setCommentRerender] = useState(0);
  const [canShowModal, setCanShowModal] = useState(false);

  const inputRef = useRef<InputRef>(null);
  const commentInputRef = useRef<CommentInputRef>(null);

  const hasActivityData = and(!!activity, isActivityDetail);
  const hasTasks = and(hasActivityData, !isEmpty(activity?.extendedInfo?.task));
  const hasEditComment = !!editComment?.noteId;
  const isCRMDisabled = crmDisableComments(activity);
  const hasPendingComment = or(!!comment, hasEditComment);

  const shouldRenderSaveButton = or(
    and(!focusComment, !hasEditComment, didChange),
    !isActivityDetail
  );

  const focusInput = () => {
    getIntervalRender({
      timeout: 150,
      fn: () => inputRef.current?.focusInputWithoutSelect(),
    });
  };

  const resetComments = () => {
    setComment('');
    commentInputRef.current?.closeMentionList(true);
    setEditComment(undefined);
    setCommentRerender(Date.now());
  };

  const {
    status: addCommentStatus,
    onAddActivityNote,
    data: addedComment,
  } = useAddActivityNote({
    historyId,
    miLoc: or(choose(size(miLoc) > 4, 'EXEC'), activity?.custMiLoc, 'EXEC'),
  });

  const { status: updateCommentStatus, onUpdateActivityNote } =
    useUpdateActivityNote({ historyId, miLoc });

  const commentStatus = choose(
    hasEditComment,
    updateCommentStatus,
    addCommentStatus
  );
  const submittingComment = or(commentStatus === 'loading', isCRMDisabled);
  const disableSaveComment = or(
    submittingComment,
    !trim(comment),
    isCRMDisabled
  );

  const scrollComments = (noteId = '') => {
    getIntervalRender({
      timeout: 300,
      fn: () => {
        const commentNode = document.querySelector(
          `[data-testid=comment-${noteId}]`
        );
        if (commentNode) {
          onScroll(commentNode);
        } else {
          document
            .querySelector(`[data-testid=comments-scroll-end]`)
            ?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
      },
    });
  };

  useEffect(() => {
    const refreshPageContentFromPushNotification = async () => {
      if (shouldRefresh) {
        await onRefresh();
        setCommentRerender(Date.now());
      }
    };

    void refreshPageContentFromPushNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefresh, commentId]);

  useIonViewWillEnter(() => {
    setCanShowModal(and(!!commentId, !!isMentioned));
  }, [commentId, isMentioned]);

  useEffect(() => {
    if (and(canShowModal, !activityIsLoading, !activity?.subscribed)) {
      setShowSubscribeModal(true);
      setCanShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canShowModal, activityIsLoading]);

  useEffect(() => {
    ifFunction(addCommentStatus === 'success', () => {
      setAfterSubmit(true);
      scrollComments(addedComment?.ID);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCommentStatus]);

  useEffect(() => {
    ifFunction(updateCommentStatus === 'success', () => {
      setAfterSubmit(true);
      scrollComments(editComment?.noteId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCommentStatus]);

  useEffect(() => {
    ifFunction(afterSubmit, () => {
      resetComments();
      setAfterSubmit(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterSubmit]);

  useEffect(() => {
    ifFunction(hasEditComment, () => {
      setComment(toString(editComment?.text));
    });
  }, [editComment?.text, hasEditComment]);

  useIonViewWillLeave(() => {
    commentInputRef.current?.closeMentionList();
  });
  // #endregion comments

  const customBackButtonClick = () => {
    if (or(didChange, !!trim(comment))) {
      setCloseModalIsOpen(true);
    } else {
      goBack();
    }
  };

  const options = map(headerActions?.options, (item) => {
    const option = item as unknown as React.ComponentProps<typeof Button> & {
      buildHref?: (h?: string) => string;
    };
    const onSaveFromOption = (newId: string) => {
      setSaveFromOption(undefined);
      const href = toString(or(option.buildHref?.(newId), option.href));
      if (href) {
        setTimeout(() => {
          if (newId) {
            history.replace(href);
          } else {
            history.push(href);
          }
          // TUDU: hardcoded to give attachments time for upload,
          // this just improves UX, attachments will always be up-to-date due to refetch logic
        }, 1000);
      }
    };
    return ['delete-activity-btn', 'unread-activity-btn'].includes(
      option.testid
    )
      ? option
      : {
          ...option,
          onClick: (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
            // only present the discard modal if has a pending comment and content did change
            if (hasPendingComment && didChange) {
              setSaveFromOption(() => onSaveFromOption);
              setIsDiscardCommentOpen(true);
              return;
            }
            if (didChange) {
              componentRef.current?.onSubmit();
              setSaveFromOption(() => onSaveFromOption);
              return;
            }
            const href = toString(or(option.buildHref?.(), option.href));
            if (href) {
              history.push(href);
            }
            option.onClick?.(e);
          },
        };
  });

  const availHeaderActions = (testid: string) =>
    showHeaderAction
      ? {
          initialBreakpoint: 0.4,
          title: t('activities:manageActivity'),
          testid: `${testid}-actions`,
          options,
        }
      : undefined;

  const activityTitle = choose(
    isActivityDetail,
    toString(activityConfig?.modal?.title),
    t('snapshot:logNewVisit')
  );

  const deleteActivityNameSpace =
    'ActivitiesApp-Config:Details:deleteActivityModal';

  return (
    <IonPage id="activity-detail">
      {/** Activity Header Action Modals section start */}
      <DiscardModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        title={t(`${deleteActivityNameSpace}:Title`)}
        discardMsg={t(`${deleteActivityNameSpace}:Msg`)}
        goBackButtonTitle={t('common:delete')}
        goBackButtonClassName={classes.deleteOption}
        discardButtonTitle={t('common:cancel')}
        onGoBackClick={() => handleDeleteActivity()}
        initialBreakpoint={0.4}
        withRightCloseButton
        testid="delete-activity-modal"
        discardButtonTestId="delete-activity-modal-cancel-btn"
        goBackButtonTestId="delete-activity-modal-delete-btn"
      />
      {/** Activity Header Action Modals section end */}
      <Header
        backButton={{ onClick: () => customBackButtonClick() }}
        testid="activity-detail-header"
        title={activityTitle}
        subTitle={customerName}
        headerActions={availHeaderActions('activity-detail-header')}
      />
      <IonContent className={classes.content} ref={nodeRef}>
        <Refresher
          slot="fixed"
          onRefresh={onRefresh}
          testid="activity"
          disabled={or(!isActivityDetail, isLoading)}
          hidden
        />
        <div className={classes.activityComponent}>
          <Header
            testid="activity-detail-page-header"
            className={classes.activityHeader}
            collapse="condense"
            customTitle={
              <>
                <div className={classNames(classes.flex, classes.titleHeader)}>
                  <Text
                    text={activityTitle}
                    variant="mipro-h1-headline"
                    testid="activity-detail-title"
                    className={classes.title}
                  />
                  {ifRender(
                    showHighPriorityLabel,
                    <Badge
                      type="error"
                      icon="flag"
                      text={t('activities:highPriority')}
                      testid="on-hold"
                      className={classes.badge}
                      textVariant="content-smaller"
                      textClassName={classNames(
                        classes.linePrefixText,
                        classes.priority
                      )}
                      iconClassName={classNames(
                        classes.prefixIcon,
                        classes.priority
                      )}
                      iconVariant="far"
                    />
                  )}
                </div>
                {ifRender(
                  !hideCustomerLink,
                  <div className={classes.flex}>
                    <SnapshotLink
                      className={classes.snapshotLink}
                      customerNameClass={classNames(classes.customerName, {
                        [classes.disabledCustomerName]:
                          !activity?.userLocationMatch,
                      })}
                      customerNoClass={classes.customerNoClass}
                      eyebrow={
                        choose(
                          isActivityDetail,
                          activityDetailEyebrow,
                          customerVisitEyebrow
                        ) as string
                      }
                      customerPick12={activity?.customerPick12}
                      hasCorpGlobalIcon={withStringProp(activity?.natlAcctNo)}
                      href={customerURL}
                      text={customerName}
                      disabled={or(!activity?.userLocationMatch, !ac)}
                      withIcon={false}
                      testid="customer-snapshot-button"
                    />
                    {ifRender(
                      and(
                        activity?.userId !== loggedInUserId,
                        !activity?.readOnly
                      ),
                      <OwnerTitle
                        nonLoggedInOwnerEmpId={toString(activity?.employeeId)}
                        ownerName={activity?.userFullName}
                        link
                        testid="non-logged-in-employee"
                      />
                    )}
                  </div>
                )}
                <div
                  className={classNames(classes.headerWrapper, {
                    [classes.descriptionWrapper]: hideCustomerLink,
                  })}
                >
                  {ifRender(
                    description,
                    <Text
                      className={classes.content}
                      text={description}
                      textQuery={highlight}
                    />
                  )}
                  {!!CustomHeader && <CustomHeader />}
                </div>
              </>
            }
            headerActions={availHeaderActions('activity-detail-page-header')}
          />
          <div className={classes.spacing}>
            {ifRender(
              and(!hideActivityFlags, !activity?.readOnly),
              <div className={classes.actionsWrapper}>
                <IonRow className={classes.actions}>
                  {map(
                    [isSnoozedOption, onFollowUpOption, onRemoveOption],
                    (
                      { activeClass = '', inActiveClass = '', ...opt },
                      index
                    ) => (
                      <Button
                        key={index}
                        className={classNames(classes.actionButton, {
                          [activeClass]: opt.isActive,
                          [inActiveClass]: !opt.isActive,
                          [classes.actionDisabled]: opt.disabled,
                        })}
                        variant="icon-action"
                        icon={opt.icon}
                        text={opt.text}
                        onClick={opt.onClick}
                        disabled={opt.disabled}
                        testid={opt.testid}
                      />
                    )
                  )}
                </IonRow>
                <SnoozeTitle
                  className={classes.snoozeContainer}
                  snoozedData={snoozedData}
                  testid="visit-form"
                />
              </div>
            )}
          </div>
          <div ref={contentBeforeDetailsRef} />
          <div className={classes.detailCard}>
            <div className={classes.cardContent}>
              {!hideCustomerLink && !isLoading && (
                <div className={classes.header}>
                  <Text
                    text={t('common:details')}
                    className={classes.title}
                    testid="details-activtity-header"
                  />
                </div>
              )}
              <Loader
                testid="loader"
                className={classes.loader}
                text={t('common:loading')}
                isOpen={isLoading}
              />
              {!isLoading &&
                isNil(fetchActivityError) &&
                (!isNil(CustomContent) ? (
                  <CustomContent
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ref={componentRef}
                    updateActivityData={{
                      done: isDone,
                      followUp: isFollowedUp,
                      showAfter: showAfterData.showAfterISO,
                    }}
                    onDone={(newId: unknown) => {
                      if (saveFromOption) {
                        saveFromOption?.(toString(newId));
                      } else {
                        setTimeout(() => goBack());
                      }
                    }}
                    didChange={didChange}
                    onContentChange={() => setDidChange(true)}
                    activity={{ ...activity, custMiLoc: miLoc, custNo: id }}
                    activityData={{
                      miLoc,
                      custNo: id,
                      type: activity?.eventTagName,
                      ...activityConfig?.modal?.defaultData,
                    }}
                    triggerRerender={commentRerender}
                    customerData={customerData}
                    isLoading={isLoading}
                    footerRef={footerRef}
                    hideActivityFlags={setHideActivityFlags}
                    disableActivityFlags={setDisableActivityFlags}
                    onUpdatingForm={setIsBusy}
                    onCompleteActivityFlag={setIsDone}
                    onFollowUpActivityFlag={setIsFollowedUp}
                    onUnSnooze={onUnSnooze}
                    onSendEmail={onSendEmail}
                    onContactClick={onOpenContactModal}
                    onEmployeeClick={onOpenEmployeeModal}
                    currency={activity?.currency}
                    contentBeforeDetailsRef={contentBeforeDetailsRef}
                    loggedInUserId={loggedInUserId}
                    onUpdateActivity={onUpdateActivity}
                    shouldRenderSaveButton={shouldRenderSaveButton}
                    hasPendingComment={hasPendingComment}
                    setIsDiscardCommentOpen={setIsDiscardCommentOpen}
                  />
                ) : (
                  <ActionCardModalData
                    dataDefinition={defaultDataDefinition}
                    activity={activity}
                    activityData={{
                      miLoc,
                      custNo: id,
                      type: activity?.eventTagName,
                      ...activityConfig?.modal?.defaultData,
                    }}
                    onSendEmail={onSendEmail}
                    onContactClick={onOpenContactModal}
                    currency={activity?.currency}
                    scrollParent={node}
                  />
                ))}

              {ifRender(
                and(!!fetchActivityError, fetchActivityError?.status === 404),
                <WarningMessage
                  title={getErrorMessage(null, t('404ErrorTitle'))}
                  body={getErrorMessage(null, t('404ErrorMessage'))}
                  testid="activity-error-msg"
                />
              )}
            </div>
          </div>

          {ifRender(
            isActivityDetail,
            <CommentList
              ref={commentsListRef}
              historyId={toNumber(historyId)}
              eventUserId={choose(!isNonLoggedInOwner, activity?.userId)}
              className={classes.card}
              scrollParent={node}
              isLoading={isLoading}
              disableComments={isCRMDisabled}
              activityTitle={cardTitle}
              showSubscribeModal={showSubscribeModal}
              setShowSubscribeModal={setShowSubscribeModal}
              onEditComment={(v) => {
                setEditComment(v);
                focusInput();
                scrollComments(v.noteId);
              }}
            />
          )}
          {ifRender(
            and(hasTasks, isNil(fetchActivityError), !isNil(activity)),
            <RelatedOpportunity
              activity={activity}
              testid="related-opportunities"
            />
          )}
          <SnoozeModal
            isOpen={showSnoozeModal}
            setIsOpen={setShowSnoozeModal}
            onSnooze={onSnoozeDone}
            onUnSnooze={onUnSnooze}
            testid="snooze-modal"
            snoozedData={snoozedData}
          />
          <ConfirmDialog
            isOpen={closeModalIsOpen}
            setIsOpen={setCloseModalIsOpen}
            title={t('discardChanges')}
            text={t('cancelActivityMessage')}
            primaryText={t('cancelActivityNo')}
            secondaryText={t('cancelActivityYes')}
            onPrimaryClick={() => setIsToBeRemoved(false)}
            onSecondaryClick={() => {
              if (isToBeRemoved) {
                onRemoveActivity();
              } else if (isToBeUnread) {
                onUnreadActivity();
              } else {
                goBack();
              }
            }}
            testid="close-activity-modal"
          />
          <ConfirmDialog
            isOpen={isDiscardCommentOpen}
            setIsOpen={setIsDiscardCommentOpen}
            title={t('ActivitiesApp-Comments:discardComment')}
            text={t('ActivitiesApp-Comments:discardCommentText')}
            primaryText={t('ActivitiesApp-Comments:goBackToComment')}
            onPrimaryClick={() => setIsDiscardCommentOpen(false)}
            secondaryText={t('ActivitiesApp-Comments:discardButtonText')}
            onSecondaryClick={() => {
              if (isDiscardCommentOpen) {
                componentRef.current?.onSubmit();
                return;
              }
              onSubmit();
            }}
            testid="discard-comment-activity-modal"
          />

          <ContactModal
            isOpen={showContactModal}
            setIsOpen={setShowContactModal}
            name={emailContactName}
            phoneNumber={emailContact?.phone}
            email={emailContact?.email}
            testid="contact-model-customer"
          />
          <EmployeeModal
            isOpen={showEmployeeModal}
            setIsOpen={setShowEmployeeModal}
            testid="employe-activity-modal"
            id={employeeId}
          />
          <SendEmailModal
            searchType="customer"
            miLoc={miLoc}
            id={id}
            isOpen={sendEmailModal}
            setIsOpen={setSendEmailModal}
            title={t('common:sendEmail')}
            testid="action-card-modal-send-email-modal"
            defaultRecipients={activityConfig?.modal?.contactEmail}
          />
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          {ifRender(
            isActivityDetail,
            <form
              className={classes.commentForm}
              onSubmit={async (e: React.SyntheticEvent) => {
                e.preventDefault();
                if (hasEditComment) {
                  onUpdateActivityNote({
                    noteId: editComment?.noteId,
                    title: editComment?.title,
                    text: comment,
                    skipSuccessToast: true,
                    newSubscribers: getUpdatedMentionList(
                      parseMentionList(toString(editComment.text)),
                      parseMentionList(comment)
                    ),
                  });
                } else {
                  onAddActivityNote({
                    publicFlag: 'Y',
                    text: comment,
                    skipSuccessToast: true,
                    newSubscribers: parseMentionList(comment),
                  });
                }
                await toggleKeyboard(false);
                scrollComments(editComment?.noteId);
              }}
            >
              <IonRow className={classes.commentRow}>
                <CommentInput
                  key="new-comment-input"
                  ref={commentInputRef}
                  value={comment}
                  onChange={setComment}
                  placeholder={t('comments:writeCommentTagUser')}
                  disabled={or(
                    submittingComment,
                    isNil(activity),
                    !isNil(fetchActivityError)
                  )}
                  onFocus={() => {
                    setTimeout(() => {
                      setFocusComment(true);
                      setCommentRerender(Date.now());
                    }, 100);
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setFocusComment(false);
                      setCommentRerender(Date.now());
                    }, 100);
                  }}
                  testid="new-comment-input"
                />

                {ifRender(
                  !hasEditComment,
                  <Button
                    variant="action"
                    icon={['fas', 'paper-plane-top']}
                    type="submit"
                    disabled={or(
                      disableSaveComment,
                      isNil(activity),
                      !isNil(fetchActivityError)
                    )}
                    testid="new-comment-button"
                  />
                )}
              </IonRow>

              <IonRow className={classes.tagSomeoneRow}>
                <Button
                  onClick={() => {
                    commentInputRef.current?.openMenu();
                  }}
                  variant="link"
                  text={`@ ${t('comments:tagSomeone')}`}
                  testid="tag-someone-btn"
                  disabled={or(isNil(activity), !isNil(fetchActivityError))}
                />
              </IonRow>
              {ifRender(
                hasEditComment,
                <div className={classes.footerButtons}>
                  <div className={classes.commentsButtonsWrapper}>
                    <Button
                      className={classes.button}
                      variant="secondary"
                      text={t('common:cancel')}
                      onClick={resetComments}
                      testid="cancel-edit-comment"
                    />
                    <Button
                      className={classes.button}
                      variant="action"
                      text={t('common:update')}
                      type="submit"
                      disabled={disableSaveComment}
                      testid="edit-comment"
                    />
                  </div>
                </div>
              )}
            </form>
          )}
          <div
            ref={footerRef}
            className={classes.footerButtons}
            data-testid="activity-page-footer"
          >
            {ifRender(
              and(
                shouldRenderSaveButton,
                or(size(buttons) > 0, !hideSaveButton)
              ),
              <div className={classes.buttonsWrapper}>
                {map(buttons, (buttonProps, index) => (
                  <Button
                    className={classes.button}
                    key={index}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...buttonProps}
                    onClick={buttonProps?.onClick}
                  />
                ))}
                {ifRender(
                  !hideSaveButton,
                  <Button
                    className={classes.button}
                    variant="action"
                    text={t('common:save')}
                    onClick={() => {
                      if (hasPendingComment) {
                        setIsDiscardCommentOpen(true);
                        return;
                      }
                      onSubmit();
                    }}
                    disabled={or(!didChange, isSubmitting, isNonLoggedInOwner)}
                    testid={`${kebabCase(toString(title))}-save-button`}
                  />
                )}
              </div>
            )}
          </div>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ActivityDetail;
