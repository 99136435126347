/* istanbul ignore file */

import type { AxiosError } from 'axios';
import type { Order } from 'ProductSearchApp/models/Order';
import type SearchSuggestionCard from 'components/Search/SearchSuggestionCard/SearchSuggestionCard';
import type { Address } from './Address';
import type { Contact } from './Contact';
import type { Customer } from './Customer';
import type { Supplier } from './Supplier';

export interface FindCustomersAPIResponse {
  totalRecords: number;
  records: Customer[];
}

export interface FindSuppliersAPIResponse {
  totalRecords: number;
  records: Supplier[];
}

export interface QueryFlags {
  error?: AxiosError | null;
  hasError?: boolean;
  isLoading: boolean;
  isFetching?: boolean;
  lastUpdatedAt?: number;
  refetch?: () => Promise<void>;
}

export interface InfiniteQueryFlags {
  error?: AxiosError | null;
  hasError?: boolean;
  showLoader: boolean;
  isEmptyResponse: boolean;
  noMoreData?: boolean;
  enableInfiniteScroll?: boolean;
  isFetching?: boolean;
  lastUpdatedAt?: number;
  refetch?: () => Promise<void>;
  fetchNextPage?: () => Promise<void>;
}

export interface SearchResponse {
  total?: number;
  totalCustomers?: number;
  totalSuppliers?: number;
  items: SearchItem[];
}

enum FavoriteItemTypeEnum {
  customer,
  supplier,
}

export enum SearchItemTypeEnum {
  customer,
  supplier,
  employee,
  customerContact,
  supplierContact,
  motionLocation,
  corporateAccounts,
  ocns,
  workOrders,
}

export type SearchItemType = keyof typeof SearchItemTypeEnum;

export type FavoriteItemType = keyof typeof FavoriteItemTypeEnum;

export interface SearchCustomerItem {
  miLoc: string;
  id: string;
}

export interface SearchSupplierItem {
  id: string;
}

export interface SearchEmployeeItem {
  empAddress: string;
  city: string;
  email: string;
  homePhone: string;
  id: string;
  jobCode: string;
  jobPosition: string;
  miAccount: string;
  name: string;
  empName: string;
  postalCode: string;
  state: string;
  title: string;
  miLoc: string;
  mobilePhone: string;
  workPhone: string;
}

// TODO: improve these typings and useGetContacts
export interface SearchCustomerContactItem {
  creationTmstmp?: string;
  creationUserid?: string;
  customerName: string;
  customerNo: string;
  email?: string;
  ext?: string;
  inviteError?: string;
  isMainContact?: string;
  isWebRegistered?: boolean;
  lastSignOn?: string;
  lastUpdTmstmp?: string;
  lastUpdUserid?: string;
  miLoc?: string;
  // name: string;
  phone?: string;
  registrationCreationDate?: string;
  registrationExpiryDate?: string;
  registrationStatus?: 'EXPIRING' | 'NONE' | 'SENT';
  sequenceNo: number;
  customerType?: string;
  type?: string;
  typeDesc?: string;
  name?: string;
  id: string;
  customerPick12?: boolean;
}

export interface SearchSupplierContactItem {
  creationTmstmp?: string;
  creationUserid?: string;
  email?: string;
  faxNo?: string;
  isMainContact?: string;
  lastUpdTmstmp?: string;
  lastUpdUserid?: string;
  miLoc?: string;
  //  name: string;
  phone1?: string;
  phone2?: string;
  sequenceNo: number;
  supplierType?: string;
  supplierLocationNo: string;
  supplierName: string;
  typeDesc?: string;
  name?: string;
  id: string;
}

export interface SearchMotionLocationItem {
  id: string;
  shipToAddressLine1: string;
  shipToAddressLine2: string;
  shipToAddressLine3: string;
  shipToCity: string;
  shipToState: string;
  shipToZIP: string;
}

export interface SearchGlobalOrder extends Order {
  id: string;
}

export type SearchItem = (
  | SearchCustomerItem
  | SearchSupplierItem
  | SearchEmployeeItem
  | SearchCustomerContactItem
  | SearchSupplierContactItem
  | SearchMotionLocationItem
  | SearchGlobalOrder
) & {
  type: SearchItemType;
  text: string;
  description?: string;
  natlAcctNo?: string;
  address?: Address;
  compressedName?: string;
  customerPick12?: boolean;
  isCorpAccountorInfo?: boolean;
};

export enum SearchSuggestionTypeEnum {
  hint = 'hint',
  search = 'search',
  result = 'result',
}

export type SearchSuggestionItem = ({ text: string } | SearchItem) & {
  searchType: SearchSuggestionTypeEnum;
};

export enum SearchResultTabTypeEnum {
  all = 'all',
  customers = 'customers',
  ocns = 'ocns',
  corporateAccounts = 'corporateAccounts',
  customerContacts = 'customerContacts',
  employees = 'employees',
  motionLocations = 'motionLocations',
  suppliers = 'suppliers',
}

export enum SearchResultTabIconEnum {
  all = '',
  corporateAccounts = 'globe',
  customers = 'industry-alt',
  suppliers = 'truck',
  employees = '',
  customerContacts = 'address-book',
  supplierContacts = 'clipboard-list',
  motionLocations = 'building',
  ocns = 'memo-pad',
}

export enum SearchResultTabIconPrefixEnum {
  all = '',
  corporateAccounts = 'fas',
  customers = 'far',
  suppliers = 'far',
  employees = '',
  customerContacts = 'far',
  supplierContacts = 'far',
  motionLocations = 'far',
  ocns = 'far',
}

export enum SearchResultTablabelEnum {
  customers = 'search:searchCustomers',
  suppliers = 'search:searchSuppliers',
  employees = 'search:searchEmployee',
  customerContacts = 'search:searchCustContact',
  supplierContacts = 'search:searchSupContact',
  motionLocations = 'search:searchMotionLocations',
  corporateAccounts = 'search:searchCorporateAccounts',
  ocns = 'search:searchOCNs',
}

export enum SearchResultsSeeMoreEnum {
  customers = 'search:seeMoreCustomersResults',
  suppliers = 'search:seeMoreSuppliersResults',
  employees = 'search:seeMoreEmployeesResults',
  customerContacts = 'search:seeMoreCustomerContactsResults',
  supplierContacts = 'search:seeMoreSupplierContactsResults',
  motionLocations = 'search:seeMoreMotionLocationsResults',
  corporateAccounts = 'search:seeMoreCorporateAccountsResults',
  ocns = 'search:seeMoreOCNResults',
}

export enum SearchResultsSeeAllEnum {
  customers = 'search:allCustomersResults',
  suppliers = 'search:allSuppliersResults',
  employees = 'search:allEmployeesResults',
  customerContacts = 'search:allCustomerContactsResults',
  supplierContacts = 'search:allSupplierContactsResults',
  motionLocations = 'search:allMotionLocationsResults',
  corporateAccounts = 'search:allCorporateAccountsResults',
  ocns = 'search:allOCNResults',
}

export type SearchResultTabTotals = Record<
  SearchResultTabTypeEnum,
  number | undefined
>;

export interface SnapshotsURLParams {
  miLoc: string;
  id: string;
  tab?: string;
}

export interface WithOptimisticUI {
  isOptimisticallyUpdating?: boolean;
}

export interface SelectModalItem {
  key: string;
  title: string;
  description?: string;
  type?: string;
  showAvatar?: boolean;
  customerItem?: React.ComponentProps<typeof SearchSuggestionCard>;
  contactItem?: React.ComponentProps<typeof SearchSuggestionCard>;
}

export interface ContactItem extends SelectModalItem {
  item?: Contact;
}
