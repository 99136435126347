import { useTranslation } from 'react-i18next';
import { size, toNumber, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { findFollowersQuerykey } from 'ActivitiesApp/api/useFindFollowers';
import useAPIUrl from 'api';
import { or } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doPromiseAPI,
  getPlaceholderData,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type {
  ActivityNote,
  ActivityNoteSimple,
  UpdateActivityNoteBody,
} from 'models/Notebook';
import { ToastType } from 'models/Toast';
import useDeleteActivityNoteAttachments from './useDeleteActivityNoteAttachments';
import { findActivityNotesV2QueryKey } from './useFindActivityNotesV2';
import { getActivityNoteQueryKey } from './useGetActivityNote';
import useAddActivityNoteAttachments from './useUpdateActivityNoteAttachments';

interface UseUpdateActivityNoteProps {
  id?: string;
  miLoc: string;
  historyId: string;
}

interface UseUpdateActivityNoteResponse {
  status: MutationStatus;
  onUpdateActivityNote: (body: UpdateActivityNoteBody) => void;
}

const useUpdateActivityNote = ({
  id,
  miLoc,
  historyId,
}: UseUpdateActivityNoteProps): UseUpdateActivityNoteResponse => {
  const { axios } = useAxios();
  const { updateActivityNotesAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.notes);
  const { doUpdateActivityAttachments } = useAddActivityNoteAttachments();
  const { doDeleteActivityAttachments } = useDeleteActivityNoteAttachments();

  const doUpateActivityNote = (body: UpdateActivityNoteBody) => {
    return doPromiseAPI(async (cancelToken) => {
      const request = { ...body };
      delete request.filesForUpload;
      delete request.filesForRemoval;
      return axios.put(
        updateActivityNotesAPI(toNumber(or(body.noteId, id))),
        request,
        { cancelToken }
      );
    });
  };

  const { status, mutate } = useMutation(doUpateActivityNote, {
    onMutate: async (vars: UpdateActivityNoteBody) => {
      const eventNoteId = toNumber(or(vars.noteId, id));
      const oldItem: ActivityNote | undefined =
        getPlaceholderData<ActivityNote>({
          queryClient,
          objectKey: 'rows',
          queryKey: findActivityNotesV2QueryKey,
          findPredicate: { eventNoteId },
        });
      const cachedNote = { ...oldItem };
      if (cachedNote) {
        cachedNote.title = toString(vars?.title);
        cachedNote.text = toString(vars?.text);
        cachedNote.lastUpdatedTimestamp = new Date().toISOString();
        cachedNote.attachments =
          size(cachedNote.attachments) -
            size(vars.filesForRemoval) +
            size(vars.filesForUpload) >
          0
            ? vars.filesForUpload
            : [];
      }
      const listActivityNoteContext = await onMutateUpdate<ActivityNote>({
        queryClient,
        queryKey: findActivityNotesV2QueryKey,
        queryKeyParams: { historyId: toNumber(historyId) },
        updatedItems: [{ ...cachedNote } as ActivityNote],
        dataPath: 'rows',
        findPredicates: [{ eventNoteId }],
        sortPredicate: { sortField: 'sortCol' },
        isInfiniteQuery: true,
      });
      const getActivityNoteContext = await onMutateUpdate<ActivityNote>({
        queryClient,
        queryKey: getActivityNoteQueryKey,
        queryKeyParams: { eventNoteId: id },
        updatedItems: [{ ...cachedNote } as ActivityNote],
        findPredicates: [{ eventNoteId }],
        isSingleQuery: true,
      });
      return { listActivityNoteContext, getActivityNoteContext };
    },
    onSuccess: async (data, vars) => {
      try {
        const eventNoteId = toNumber(or(vars.noteId, id));
        const activityNote = {
          eventNoteId,
          historyId: toNumber(historyId),
          miLoc,
        } as ActivityNoteSimple;
        await doUpdateActivityAttachments({
          activityNote,
          updatedImages: vars.filesForUpload,
        });
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'Upload attachment operation failed.',
          testid: 'upload-notebook-attachment-error-toast',
        });
      }
      try {
        await doDeleteActivityAttachments({
          deletedImages: vars.filesForRemoval,
        });
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'Remove attachment operation failed.',
          testid: 'remove-attachments-error-toast',
        });
      }
      await onSuccessMutation(queryClient, findActivityNotesV2QueryKey, {
        historyId: toNumber(historyId),
      });
      await onSuccessMutation(queryClient, getActivityNoteQueryKey, {
        eventNoteId: id,
      });
      await onSuccessMutation(queryClient, findFollowersQuerykey, {
        historyId,
      });
      if (!vars.skipSuccessToast) {
        addToast({
          variant: 'mipro-toast',
          type: ToastType.success,
          leftIcon: ['fas', 'check-circle'],
          text: or(
            vars.successToastText,
            t('editToast', { title: or(vars?.title, t('notebook')) })
          ),
          testid: 'edit-activity-note-toast',
        });
      }
    },
    onError: (data, vars, context) => {
      addToast({
        type: ToastType.error,
        text: t('editFailureToast'),
        testid: 'edit-notebook-error-toast',
      });

      onErrorUpdate<ActivityNote>({
        queryClient,
        context: context?.getActivityNoteContext,
        isSingleQuery: true,
      });

      onErrorUpdate({
        queryClient,
        context: context?.listActivityNoteContext,
        dataPath: 'rows',
        sortPredicate: { sortField: 'sortCol' },
        isInfiniteQuery: true,
      });
    },
  });

  return {
    status,
    onUpdateActivityNote: (body: UpdateActivityNoteBody) => mutate(body),
  };
};

export default useUpdateActivityNote;
