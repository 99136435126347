import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationToastUtils } from 'common/api/utils/apiHelpers';
import { choose } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import { getOrderQueryKey } from 'ProductSearchApp/api/useGetOrder';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { getActivityQueryKey } from 'api/activities/useGetActivity';
import { onSuccessMutation } from 'api/helpers';
import { ToastType } from 'models/Toast';
import type { FirebaseLinkProTipParams } from 'utils/firebaseAnalytics';
import {
  FirebaseEventNameEnum,
  logFirebaseEvent,
} from 'utils/firebaseAnalytics';
import { linkProTipAPI } from '.';

export enum ProTipLinkerType {
  ocn = 'OCN',
  opportunity = 'OPPRTNTY',
}

export enum LinkTypeEnum {
  'link' = 'link',
  'unlink' = 'unlink',
}

export interface CreateProTipLinkBody extends MutationToastUtils {
  linkerType: ProTipLinkerType;
  linkerId: string;
  miLoc: string;
  tipId: number;
  active: string;
  historyId?: number;
  orderCtlNo?: string;
}

const useLinkProTip = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.notes);

  const response = useMutation({
    mutationFn: async (body: CreateProTipLinkBody) => {
      const firebaseParams: FirebaseLinkProTipParams = {
        linkerType: body.linkerType,
        action: choose(body.active === 'Y', 'link', 'unlink') as LinkTypeEnum,
      };

      logFirebaseEvent(FirebaseEventNameEnum.link_pro_tip, firebaseParams);
      const { data } = await axios.post<CreateProTipLinkBody>(
        linkProTipAPI(),
        body
      );

      return data;
    },
    onSuccess: async (
      data,
      {
        active,
        skipSuccessToast,
        tipId,
        linkerId,
        linkerType,
        miLoc,
        orderCtlNo,
        historyId,
      }
    ) => {
      if (active === 'Y' && !skipSuccessToast) {
        addToast({
          type: ToastType.info,
          icon: ['far', 'check'],
          header: t('HomeApp/ProTips:proTip:linkSuccessTitle'),
          testid: 'link-pro-tip-success-toast',
          variant: 'mipro-toast',
        });
      } else if (active === 'N') {
        addToast({
          type: ToastType.info,
          icon: ['far', 'check'],
          header: t('HomeApp/ProTips:proTip:unLinkSuccessTitle'),
          testid: 'unlink-pro-tip-success-toast',
          variant: 'mipro-toast',
          button: {
            role: 'undo',
            text: t('common:undo'),
            handler: () =>
              response.mutate({
                skipSuccessToast: true,
                tipId,
                linkerType,
                linkerId,
                miLoc,
                active: 'Y',
                orderCtlNo,
                historyId,
              }),
          },
        });
      }

      await onSuccessMutation(queryClient, getOrderQueryKey, { orderCtlNo });
      await onSuccessMutation(queryClient, getActivityQueryKey, { historyId });
    },
    onError: () => {
      addToast({
        type: ToastType.error,
        text: t('HomeApp/ProTips:proTip:linkErrorMessage'),
        testid: 'link-pro-tip-error-toast',
      });
    },
  });

  return { ...response, onLinkProTip: response.mutate };
};

export default useLinkProTip;
