import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { map, toString } from 'lodash';
import type { QueryFlags } from 'common/api/utils/useGetQueryFlags';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { isAfter } from 'date-fns';
import type { NotificationItem } from 'HomeApp/Notifications/models/Notifications';
import { useAxios } from 'providers/AxiosProvider';
import { useMiLocOrTeamId } from 'api/helpers';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';
import { findNoticesAPI } from '.';

export const inAppNotificationsQueryKey = 'inapp-notifications';
export const inAppNotificationsDataPath = 'notifications';

export interface UnreadNoticesCount {
  unreadCount: number;
}

interface FindNoticesAPIResponse {
  notifications: NotificationItem[];
  totalRows: number;
}

interface UseGetUnreadNoticesCountResponse {
  items?: NotificationItem[];
  totalItems?: number;
}

const useFindNotifications = ({
  enabled = true,
  limit = pageSize(),
  sortField = 'creationTimestamp',
  sortDir = 'DESCENDING',
}: {
  enabled?: boolean;
  limit?: number;
  sortField?: string;
  sortDir?: string;
}): UseGetUnreadNoticesCountResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getURLParams } = useMiLocOrTeamId({});
  const params = { sortField, sortDir, limit: toString(limit) };
  const { prevNoticesLastSeenTime } = useSelector(
    (state: RootState) => state.user
  );

  const response = useQuerySetup<NotificationItem, FindNoticesAPIResponse>({
    queryKey: inAppNotificationsQueryKey,
    queryParams: params,
    onAPIRequest: async ({ pageParam = 1, signal }) => {
      const { data } = await axios.get<FindNoticesAPIResponse>(
        findNoticesAPI(getURLParams({ ...params, page: pageParam })),
        {
          signal,
        }
      );
      return { notifications: data.notifications, totalRows: data.totalRows };
    },
    dataPath: inAppNotificationsDataPath,
    totalDataPath: 'totalRows',
    limit,
    enabled,
    isInfiniteQuery: true,
  });

  const {
    items,
    totalItems,
    isLoading,
    isEmptyResponse,
    status,
    ...queryFlags
  } = response;

  const notificationItems = useMemo(() => {
    return map(items, (item: NotificationItem) => {
      return {
        ...item,
        isHighlighted: prevNoticesLastSeenTime
          ? isAfter(
              new Date(item.creationTimestamp),
              new Date(toString(prevNoticesLastSeenTime))
            )
          : true,
      };
    });
  }, [items, prevNoticesLastSeenTime]);

  return {
    items: notificationItems,
    totalItems,
    isLoading,
    isEmptyResponse,
    status,
    ...queryFlags,
  };
};

export default useFindNotifications;
