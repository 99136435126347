import React, { useState } from 'react';
import { map, toString } from 'lodash';
import { IonRow } from '@ionic/react';
import { FormikSelectModal } from 'common/components/SelectModal/SelectModal';
import { or } from 'common/utils/logicHelpers';
import useFindEmployees from 'HomeApp/ProTips/api/useFindEmployees';
import EmployeeModal from 'SearchApp/components/EmployeeModal/EmployeeModal';
import type { Employee } from 'models/Employee';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './EmployeeSelect.module.scss';

interface EmployeeSelectProps
  extends Partial<React.ComponentProps<typeof FormikSelectModal<Employee>>> {
  name: string;
}

const EmployeeSelect = ({ ...props }: EmployeeSelectProps) => {
  const testid = or(props.testid, 'employee-input');
  const modalTestid = or(props.modalTestid, 'employee-modal');
  const [searchQuery, setSearchQuery] = useState('');
  const [contactIsOpen, setContactIsOpen] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>();

  const { employees, ...queryProps } = useFindEmployees({
    query: searchQuery,
  });

  const renderViewOnly = (items: Employee[]) => (
    <IonRow className={classes.viewonlyList} data-testid="viewonly-list">
      {map(items, (i) => (
        <Button
          key={i.id}
          className={classes.employee}
          text={i.name}
          onClick={() => {
            setSelectedEmployeeId(i.id);
            setContactIsOpen(true);
          }}
          variant="link"
          textVariant="content-small-heavy"
          testid={`item-${i.id}`}
        />
      ))}
    </IonRow>
  );

  return (
    <>
      <FormikSelectModal<Employee>
        items={employees}
        getId={(i) => toString(i.id)}
        getName={(i) => i.name}
        setSearchQuery={setSearchQuery}
        queryProps={queryProps}
        testid={testid}
        modalTestid={modalTestid}
        renderItem={(i) => (
          <IonRow className={classes.itemRow}>
            <Text
              className={classes.itemName}
              text={i.name}
              variant="mipro-product-headline"
            />
            <Text variant="content-small" text={i.email} />
            <Text
              variant="content-small"
              text={`${i.jobPosition} ${i.miLoc}`}
            />
          </IonRow>
        )}
        renderViewOnly={renderViewOnly}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <EmployeeModal
        isOpen={contactIsOpen}
        setIsOpen={setContactIsOpen}
        testid="employee-contact-modal"
        id={selectedEmployeeId}
      />
    </>
  );
};

export default EmployeeSelect;
