import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppIcons from 'constants/appIcons';
import classNames from 'classnames';
import { find, isEmpty, map, toNumber, toString } from 'lodash';
import { IonCard, IonCol, IonGrid, IonRow } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import { searchCustomerURL, searchURL } from 'navigation';
import useFindDataCodes from 'api/data/useFindDataCodes';
import type { ActionCardActivity } from 'models/ActivityModels';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import { handleSearchNavigation } from 'utils/search';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './NewContactCustomer.module.scss';

interface NewContactCustomerFormProps {
  activity: ActionCardActivity;
  isLoading?: boolean;
  triggerResize?: (d: number) => void;
  onEmployeeClick?: (employee: string) => void;
}

const NewContactCustomerForm = ({
  activity,
  isLoading: propsIsLoading,
  triggerResize,
  onEmployeeClick,
}: NewContactCustomerFormProps): JSX.Element => {
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const { t } = useTranslation(namespaces.activities);
  const { extendedInfo, fieldsData } = activity || {};

  const badCustomerContactData = isEmpty(extendedInfo);

  const { data: codeList, isLoading: isCodesLoading } = useFindDataCodes({
    codeType: 'CUCONTYP',
  });
  const name = toString(extendedInfo?.name);
  const phoneNo = toString(extendedInfo?.phoneNo);
  const email = toString(extendedInfo?.email);
  const miLoc = toString(extendedInfo?.miLoc);
  const customerNo = toString(extendedInfo?.customerNo);
  const sequenceNo = toNumber(extendedInfo?.sequenceNo);
  const customerName = toString(fieldsData?.CUSTOMER_NAME);

  const typeId = toString(extendedInfo?.type);
  const addedByUserName = toString(extendedInfo?.creationUserName);
  const addedByEmployeeId = toString(extendedInfo?.requesterEmpId);

  const selectOptions = map(codeList, ({ id: codeId, codeName }) => ({
    id: codeId,
    text: codeName,
  }));

  const roleName = find(selectOptions, { id: typeId })?.text || '';
  const customerURL = concatRoutes(
    searchURL(),
    searchCustomerURL(miLoc, customerNo)
  );

  useEffect(() => {
    triggerResize?.(Date.now());
  }, [propsIsLoading, isCodesLoading, triggerResize]);

  return (
    <div className={classes.salesPlayForm}>
      {isCodesLoading && (
        <Loader
          testid="loader"
          text={t('common:loading')}
          className={classes.loader}
          isOpen={isCodesLoading}
        />
      )}
      {!isCodesLoading && badCustomerContactData && (
        <WarningMessage title={t('activities:badCustomerContactData')} />
      )}
      {!isCodesLoading && !badCustomerContactData && (
        <div className={classes.contactContent}>
          {addedByUserName && (
            <div className={classes.employeeContact}>
              <Text
                testid="new-cust-contact-emp-label"
                text={t('newCustomerContactModalDesc')}
              />
              <Button
                variant="link"
                text={addedByUserName}
                onClick={() => onEmployeeClick?.(addedByEmployeeId)}
                testid="new-cust-contact-emp-link"
              />
            </div>
          )}
          <IonCard className={classes.card} data-testid="contact-card">
            <IonRow>
              <IonCol>
                <Text
                  className={classes.name}
                  variant="content-small"
                  testid="customer-contact-no"
                  text={customerNo ? `${miLoc}${customerNo}` : ''}
                />
                <IonRow>
                  <Text
                    className={classes.name}
                    variant="title-screen-section"
                    testid="customer-contact-name"
                    text={name}
                  />
                  <Text
                    className={classes.roleName}
                    variant="content-small"
                    testid="customer-contact-role"
                    text={roleName}
                  />
                </IonRow>
                <Button
                  variant={activity.userLocationMatch ? 'link' : 'clear'}
                  text={customerName}
                  testid="contact-customer-name-button"
                  className={classNames(classes.name)}
                  href={activity.userLocationMatch ? customerURL : undefined}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonGrid>
                <IonRow>
                  <IonCol className={classes.actionBox}>
                    <Button
                      variant="icon-action"
                      icon={AppIcons.phoneIcon()}
                      text={t('call')}
                      testid="contact-card-phone-button"
                      className={classNames(classes.leftButton)}
                      href={`tel:${phoneNo}`}
                      isExternalLink
                      disabled={!phoneNo}
                    />
                  </IonCol>

                  <IonCol className={classes.actionBox}>
                    <Button
                      variant="icon-action"
                      icon={AppIcons.emailIcon()}
                      text={t('email')}
                      testid="contact-card-email-button"
                      className={classNames(classes.leftButton)}
                      onClick={() => setSendEmailModal(true)}
                      disabled={!email}
                    />
                  </IonCol>

                  <IonCol className={classes.actionBox}>
                    <Button
                      variant="icon-action"
                      icon={findIcon('address-card')}
                      text={t('viewContact')}
                      testid="contact-card-view-button"
                      className={classes.leftButton}
                      href={concatRoutes(
                        searchURL(),
                        handleSearchNavigation({
                          type: 'customerContact',
                          miLoc,
                          sequenceNo: toString(sequenceNo),
                          customerId: customerNo,
                        })
                      )}
                      disabled={!activity.userLocationMatch}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonRow>
          </IonCard>
          {email && (
            <SendEmailModal
              searchType="customerContact"
              defaultRecipients={[{ email, name, miLoc, sequenceNo }]}
              miLoc={miLoc}
              isOpen={sendEmailModal}
              setIsOpen={setSendEmailModal}
              title={t('common:sendEmail')}
              testid="send-email-modal"
              canRemoveRecipients={false}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default NewContactCustomerForm;
