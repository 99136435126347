import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, toNumber, toString } from 'lodash';
import { IonRow } from '@ionic/react';
import type { ActivityPageBaseProps } from 'ActivitiesApp/models/ActivityDetail';
import { choose, ifRender, or } from 'common/utils/logicHelpers';
import useFindAttachments from 'api/attachments/useFindAttachments';
import type { ActionCardActivity } from 'models/ActivityModels';
import { AttachmentSize } from 'models/Attachment';
import { formatCardDate } from 'utils/date';
import FileAttachments from 'components/Attachments/FileAttachments';
import Button from 'components/Button/Button';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import Text from 'components/Text/Text';
import classes from './ProTipForm.module.scss';

const ProTipForm = ({
  activity,
  onEmployeeClick,
}: ActivityPageBaseProps): JSX.Element => {
  const domain = 'mprotip';
  const { extendedInfo } = or(activity, {} as ActionCardActivity);
  const subject = toString(extendedInfo?.subject);
  const tipId = toString(extendedInfo?.tipId);
  const manufaturerName = toString(extendedInfo?.mfrName);
  const details = toString(extendedInfo?.details);
  const requestedByUserName = toString(extendedInfo?.requestedByUserName);
  const requestedByEmployeeId = toString(extendedInfo?.requestedByEmployeeId);
  const revenue = toString(extendedInfo?.revenue);
  const creationTmstmp = toString(extendedInfo?.creationTmstmp);
  const { t } = useTranslation('ActivitiesApp-ProTipForm');

  const { data: attachmentsData, isLoading: attachmentsLoading } =
    useFindAttachments({
      domain,
      miLoc: 'EXEC',
      ctlNo: toString(tipId),
      lineNo: '0',
    });

  return (
    <div data-testid={`pro-tip-${tipId}`}>
      <IonRow className={classes.subjectRow}>
        <Text
          className={classes.subject}
          variant="mipro-h2-headline"
          text={subject}
        />
      </IonRow>
      <IonRow className={classes.requestedRow}>
        <Text
          className={classes.requestedBy}
          variant="mipro-h6-headline"
          text={t('by')}
        />
        <Button
          className={classes.requestedUser}
          variant="link"
          textVariant="mipro-h6-headline"
          text={requestedByUserName}
          onClick={() => onEmployeeClick?.(requestedByEmployeeId)}
          testid="requested-user"
        />
      </IonRow>
      <IonRow className={classes.revenueRow}>
        <Text
          className={classes.createdDate}
          variant="content-smaller"
          text={formatCardDate(creationTmstmp, false, false)}
        />
        <Text className={classes.divider} variant="content-smaller" text="|" />
        <CurrencyFormat
          className={classes.revenue}
          value={toNumber(revenue)}
          variant="content-smaller"
          testid="revenue"
          prefix="$"
          suffix={` ${t('revenue')}`}
        />
      </IonRow>
      <IonRow className={classes.detailsRow}>
        <Text
          className={classes.details}
          variant="mipro-body-copy"
          text={details}
        />
      </IonRow>
      {ifRender(
        !isEmpty(manufaturerName),
        <>
          <IonRow className={classes.manufacturerRow}>
            <Text
              variant="mipro-product-headline"
              className={classes.manufaturerLabel}
              text={t('manufacturer')}
            />
          </IonRow>
          <IonRow className={classes.manufaturerNameRow}>
            <Text
              variant="mipro-body-copy"
              className={classes.manufaturerName}
              text={manufaturerName}
            />
          </IonRow>
        </>
      )}
      <Text
        text={choose(
          isEmpty(attachmentsData),
          t('email:noAttachments'),
          t('email:attachments')
        )}
        variant="mipro-product-headline"
        className={classes.manufaturerLabel}
      />
      <FileAttachments
        hidePreviewInputs
        className={classes.fileAttachments}
        domain=""
        name="proTip"
        files={attachmentsData}
        size={AttachmentSize.LARGE}
        disabled={attachmentsLoading}
        enableSmallPreview
        editMode={false}
        testid="pro-tip-attachments"
        prefetchAll
      />
      <IonRow className={classes.proButtonRow}>
        <Button
          className={classes.proButton}
          variant="secondary"
          text={t('contactPro')}
          testid="contact-the-pro"
          onClick={() => onEmployeeClick?.(requestedByEmployeeId)}
        />
      </IonRow>
    </div>
  );
};

export default ProTipForm;
